export const scheduleConstants = {
  GET_REQUEST: 'SCHEDULE_GET_REQUEST',
  GET_SUCCESS: 'SCHEDULE_GET_SUCCESS',
  GET_FAILURE: 'SCHEDULE_GET_FAILURE',

  GET_SCHEDULE_REQUEST: 'SCHEDULE_GET_SCHEDULE_REQUEST',
  GET_SCHEDULE_SUCCESS: 'SCHEDULE_GET_SCHEDULE_SUCCESS',
  GET_SCHEDULE_FAILURE: 'SCHEDULE_GET_SCHEDULE_FAILURE',

  ADD_ONE_REQUEST: 'SCHEDULE_ADD_ONE_REQUEST',
  ADD_ONE_SUCCESS: 'SCHEDULE_ADD_ONE_SUCCESS',
  ADD_ONE_FAILURE: 'SCHEDULE_ADD_ONE_FAILURE',

  UPDATE_REQUEST: 'SCHEDULE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SCHEDULE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SCHEDULE_UPDATE_FAILURE',

  ADD_PLAYLIST_REQUEST: 'SCHEDULE_ADD_PLAYLIST_REQUEST',
  ADD_PLAYLIST_SUCCESS: 'SCHEDULE_ADD_PLAYLIST_SUCCESS',
  ADD_PLAYLIST_FAILURE: 'SCHEDULE_ADD_PLAYLIST_FAILURE',

  DELETE_REQUEST: 'SCHEDULE_DELETE_REQUEST',
  DELETE_SUCCESS: 'SCHEDULE_DELETE_SUCCESS',
  DELETE_FAILURE: 'SCHEDULE_DELETE_FAILURE',

  DELETE_PLAYLIST_REQUEST: 'SCHEDULE_DELETE_PLAYLIST_REQUEST',
  DELETE_PLAYLIST_SUCCESS: 'SCHEDULE_DELETE_PLAYLIST_SUCCESS',
  DELETE_PLAYLIST_FAILURE: 'SCHEDULE_DELETE_PLAYLIST_FAILURE',

  ADD_DAYPART_REQUEST: 'SCHEDULE_ADD_DAYPART_REQUEST',
  ADD_DAYPART_SUCCESS: 'SCHEDULE_ADD_DAYPART_SUCCESS',
  ADD_DAYPART_FAILURE: 'SCHEDULE_ADD_DAYPART_FAILURE',

  UPDATE_DAYPART_REQUEST: 'SCHEDULE_UPDATE_DAYPART_REQUEST',
  UPDATE_DAYPART_SUCCESS: 'SCHEDULE_UPDATE_DAYPART_SUCCESS',
  UPDATE_DAYPART_FAILURE: 'SCHEDULE_UPDATE_DAYPART_FAILURE',

  DELETE_DAYPART_REQUEST: 'SCHEDULE_DELETE_DAYPART_REQUEST',
  DELETE_DAYPART_SUCCESS: 'SCHEDULE_DELETE_DAYPART_SUCCESS',
  DELETE_DAYPART_FAILURE: 'SCHEDULE_DELETE_DAYPART_FAILURE',

  DUPLICATE_REQUEST: 'SCHEDULE_DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS: 'SCHEDULE_DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE: 'SCHEDULE_DUPLICATE_FAILURE',

  ARCHIVE_REQUEST: 'SCHEDULE_ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'SCHEDULE_ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'SCHEDULE_ARCHIVE_FAILURE',

  PLAYLIST_INFO_REQUEST: 'SCHEDULE_PLAYLIST_INFO_REQUEST',
  PLAYLIST_INFO_SUCCESS: 'SCHEDULE_PLAYLIST_INFO_SUCCESS',
  PLAYLIST_INFO_FAILURE: 'SCHEDULE_PLAYLIST_INFO_FAILURE'
};