export const locationConstants = {
  GET_REQUEST: 'LOCATION_GET_REQUEST',
  GET_SUCCESS: 'LOCATION_GET_SUCCESS',
  GET_FAILURE: 'LOCATION_GET_FAILURE',

  GET_ONE_REQUEST: 'LOCATION_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'LOCATION_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'LOCATION_GET_ONE_FAILURE',

  ADD_ONE_REQUEST: 'LOCATION_ADD_ONE_REQUEST',
  ADD_ONE_SUCCESS: 'LOCATION_ADD_ONE_SUCCESS',
  ADD_ONE_FAILURE: 'LOCATION_ADD_ONE_FAILURE',

  UPDATE_ONE_REQUEST: 'LOCATION_UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'LOCATION_UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'LOCATION_UPDATE_ONE_FAILURE',

  ARCHIVE_REQUEST: 'LOCATION_ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'LOCATION_ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'LOCATION_ARCHIVE_FAILURE'
};