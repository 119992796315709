import {artistConstants} from "_constants";

const initialState = {
  loading: false,
  artist: [],
  artistDetails: {}
};
export function artist(state = initialState, action) {
  switch (action.type) {
    case artistConstants.GET_REQUEST:
      return {
        loading: true
      };
    case artistConstants.GET_SUCCESS:
      return {
        loading: false,
        artist: action.artist
      };
    case artistConstants.GET_FAILURE:
      return {
        loading: false
      };
    case artistConstants.GET_ONE_REQUEST:
      return {
        loading: true
      }
    case artistConstants.GET_ONE_SUCCESS:
      return {
        loading: false,
        artistDetails: action.artist
      }
    case artistConstants.GET_ONE_FAILURE:
      return {
        loading: false
      }
      case artistConstants.ADD_ONE_REQUEST:
      return {
        loading: true
      }
    case artistConstants.ADD_ONE_SUCCESS:
      return {
        loading: false,
        artist: action.artist
      }
    case artistConstants.ADD_ONE_FAILURE:
      return {
        loading: false
      }
    default:
      return state;
  }
}
