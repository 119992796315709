import {sampleConstants} from "_constants";
import get from 'lodash.get';

// Selectors
export const getSample = (state, id) => state.samples.samples[id];
export const getSampleSongs = (state, id) => get(getSample(state, id), 'songs', []);
export const isSampleReady = (state, id) => {
    const sample = getSample(state, id);
    return sample && !sample.loading && !sample.error;
}

export const getSampleStats = (state, id) => get(getSample(state, id), 'stats');

export const isLoadingList = (state) => get(state, `samples.lists.loading`, false);
export const isLoadingCalendarList = (state) => get(state, `calendarsamples.lists.loading`, false);
export const getSamples = (state) => state.samples.lists.list;

export const getSelectedSampleId = state => state.samples.selectedSampleId;

export const getActiveSampleId = (state) => state.samples.activeSampleId;
export const getActiveSample = (state) => getSample(state, getActiveSampleId(state));
export const haveActiveSample = state => getActiveSampleId(state) !== -1;

const initialState = {
    activeSampleId: -1,
    selectedSampleId: -1,
    sample: {},
    playlist: {},
    playlistPlayback: null,
    loadedPlaylists: [],
    songId: null,
    showSongDetails: false,
    playing: false,
    currentSongIndex: 0,
    currentSong: null,
    songProgress: 0,
    playlistIndex: 5
};

export function samples(state = initialState, action) {
    switch (action.type) {

        case sampleConstants.GET_DETAIL_REQUEST:
            return {
                ...state,
                sample: {},
                playlist: {}
            };

        case sampleConstants.GET_DETAIL_SUCCESS:
            return {
                ...state,
                sample: action.sample,
            };

        case sampleConstants.GET_DETAIL_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case sampleConstants.GET_PLAYLIST_REQUEST:
            return {
                ...state,
                playlist: {}
            };

        case sampleConstants.GET_PLAYLIST_SUCCESS:
            return {
                ...state,
                playlist: action.playlist,
                loadedPlaylists: [...state.loadedPlaylists,action.playlist]
            };

        case sampleConstants.GET_PLAYLIST_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case sampleConstants.PLAY_PLAYLIST_REQUEST:
            return {
                ...state,
                loadingNowPlaying: false,
            }
        case sampleConstants.PLAY_PLAYLIST_SUCCESS:
            const selectedSong = (action.song ? action.playlistPlayback.songs.find(s => s.id === action.song.id) : null);
            const playlistIndex = action.song ? action.playlistPlayback.songs.indexOf(selectedSong) : 0;
            const newloadedPlaylists=state.loadedPlaylists.map(p=>p.id).indexOf(action.playlistPlayback.id) === -1?
                [...state.loadedPlaylists, action.playlistPlayback]:[...state.loadedPlaylists];
            return {
                ...state,
                loadingNowPlaying: false,
                playlistPlayback: action.playlistPlayback,
                loadedPlaylists: newloadedPlaylists,
                selectedSong: selectedSong,
                songId: (action.song ? action.song.id : action.playlistPlayback.songs[0].id),
                playing: true,
                playlistIndex: playlistIndex
            }

        case sampleConstants.PLAY_PLAYLIST_FAILURE:
            return {
                ...state,
                loadingNowPlaying: false
            }

        case sampleConstants.PLAY_NEXT_SONG_IN_PLAYLIST: {
            const songs = state.playlistPlayback.songs;
            let newPlaylistIndex = (state.playlistIndex + 1) % songs.length
            const song = songs[newPlaylistIndex];
            return {
                ...state,
                loadingNowPlaying: false,
                selectedSong: null,
                currentSong: song,
                songId: song.id,
                playlistIndex: newPlaylistIndex,
            }
        }
            ;

        case sampleConstants.PLAY_PREV_SONG_IN_PLAYLIST : {
            const songs = state.playlistPlayback.songs;
            const newPlaylistIndex = state.playlistIndex - 1
            if (newPlaylistIndex >= 0) {
                let song = songs[newPlaylistIndex];
                return {
                    ...state,
                    loadingNowPlaying: false,
                    selectedSong: null,
                    currentSong: song,
                    songId: song.id,
                    playlistIndex: newPlaylistIndex,
                }
            } else {
                return {
                    ...state
                }
            }
        }
        case sampleConstants.SONG_VOTE_SUCCESS :
            return {
                ...state,
                sample: {...state.sample, votes: [...state.sample.votes, action.vote]}
            }

        case sampleConstants.SHOW_SONG_DETAILS :
            return {
                ...state,
                showSongDetails: true
            }

        case sampleConstants.HIDE_SONG_DETAILS :
            return {
                ...state,
                showSongDetails: false
            }

        case sampleConstants.PAUSE_SONG_PLAYING :
            return {
                ...state,
                playing: false
            }

        case sampleConstants.RESUME_SONG_PLAYING :
            return {
                ...state,
                playing: true
            }

        case sampleConstants.UPDATE_PLAYBACK_TIME :
            return {
                ...state,
                time: action.time,
                duration: action.duration,
            }
        case sampleConstants.SKIP_PLAYBACK_TO :
            return {
                ...state,
                timeSent: action.time,
            }

        default:
            return state;
    }
}
