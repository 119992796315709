import {zonesConstants} from "_constants";

const initialState = {
  loading: false,
  zone: [],
  zoneDetails: {}
};
export function zones(state = initialState, action) {
  switch (action.type) {
    case zonesConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case zonesConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        zone: action.zones
      };
    case zonesConstants.GET_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.GET_PAGINATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case zonesConstants.GET_PAGINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        zone: (state, action) => {
          //TODO: add pagination
          return action.zone;
        }
      };
    case zonesConstants.GET_PAGINATION_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.GET_ONE_REQUEST:
      return {
        ...state,
        zoneDetails: {},
        loading: true
      };
    case zonesConstants.GET_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        zoneDetails: action.zones
      };
    case zonesConstants.GET_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.ADD_ONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case zonesConstants.ADD_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        zone: [...state.zone, action.zone]
      };
    case zonesConstants.ADD_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.ADD_PLAYER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case zonesConstants.ADD_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.ADD_PLAYER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.ADD_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case zonesConstants.ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.ADD_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.DELETE_PLAYER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case zonesConstants.DELETE_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.DELETE_PLAYER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.DELETE_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case zonesConstants.DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.DELETE_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.PLAY_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case zonesConstants.PLAY_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        zones: action.zones
      };
    case zonesConstants.PLAY_HISTORY_FAILURE:
      return {
        ...state,
        loading: false
      };
    case zonesConstants.UPDATE_ZONE_SUCCESS:
      return {
        ...state,
        zone: state.zone.map(z => {
          if (z.id !== action.id) return z;
          return {
            ...z,
            name: action.name,
            type: action.zoneType
          }
        }),
        zoneDetails: state.zoneDetails.id !== action.id ? state.zoneDetails : {
          ...state.zoneDetails,
          name: action.name,
          type: action.zoneType
        }
      }

    default:
      return state;
  }
}
