import {clientConstants} from "_constants";
import {clientService} from "_services";
import {alertActions} from "./alert.actions";

export const clientActions = {
  setSelected,
  getAllClients,
  getOne,
  createClient,
  updateClient,
  getUsers,
  archive
};

function setSelected(dispatch, selected) {
  dispatch({ type: clientConstants.SET_SELECTED, client: selected });
}

async function getAllClients(dispatch) {
  dispatch({ type: clientConstants.GET_REQUEST });
  
  try {
    const client = await clientService.getAllClients();
    dispatch({ type: clientConstants.GET_SUCCESS, client })
  } catch (e) {
    dispatch({ type: clientConstants.GET_FAILURE, error: e.toString() })
    throw e;
  }
}

function getOne(id) {
  return dispatch => {
    dispatch(request());

    clientService.getOne(id).then(
      client => {
        dispatch(success(client));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: clientConstants.GET_ONE_REQUEST };
  }
  function success(client) {
    return { type: clientConstants.GET_ONE_SUCCESS, client };
  }
  function failure(error) {
    return { type: clientConstants.GET_ONE_FAILURE, error };
  }
}

async function createClient(dispatch, client) {
  dispatch({ type: clientConstants.CREATE_CLIENT_REQUEST });
  try {
    const clientResponse = await clientService.createClient(client);
    dispatch({
      type: clientConstants.CREATE_CLIENT_SUCCESS,
      client: clientResponse
    });
    return clientResponse;
  } catch (e) {
    dispatch({
      type: clientConstants.CREATE_CLIENT_FAILURE,
      error: e.toString()
    });
    throw e;
  }
}

async function archive(dispatch, id) {
  dispatch({ type: clientConstants.ARCHIVE_REQUEST });
  try {
    const clientResponse = await clientService.archive(id);
    dispatch({type: clientConstants.ARCHIVE_SUCCESS, id});
    return clientResponse;
  } catch (e) {
    dispatch({type: clientConstants.ARCHIVE_FAILURE, error: e.toString()});
    throw e;
  }
}

async function updateClient(dispatch, client, id) {
  dispatch({ type: clientConstants.UPDATE_CLIENT_REQUEST });
  try {
    const clientResponse = await clientService.updateClient(client, id);
    dispatch({
      type: clientConstants.UPDATE_CLIENT_SUCCESS,
      client: clientResponse
    });
    return clientResponse;
  } catch (e) {
    dispatch({
      type: clientConstants.UPDATE_CLIENT_FAILURE,
      error: e.toString()
    });
    throw e;
  }
}

function getUsers() {
  return dispatch => {
    dispatch(request());

    clientService.getUsers().then(
      client => {
        dispatch(success(client));
        dispatch(alertActions.success());
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: clientConstants.GET_CLIENT_USERS_REQUEST };
  }
  function success(client) {
    return { type: clientConstants.GET_CLIENT_USERS_SUCCESS, client };
  }
  function failure(error) {
    return { type: clientConstants.GET_CLIENT_USERS_FAILURE, error };
  }
}
