import {api} from './api';

export const scheduleService = {
  get,
  getSchedule,
  addOne,
  update,
  deleteOne,
  addPlaylist,
  deletePlaylist,
  addDayPart,
  updateDayPart,
  deleteDayPart,
  duplicate,
  archive,
  info,
  getTotalTracks
};

function get() {
  return api("GET", "schedule", undefined, schedule => schedule);
}

function getSchedule(id) {
  return api("GET", `schedule/${id}`, undefined, schedule => schedule);
}

function addOne(schedule) {
  return api("POST", "schedule", schedule, scheduleResponse => scheduleResponse);
}

function update(schedule, id) {
  return api("PUT", `schedule/${id}`, schedule, schedule => schedule);
}

function deleteOne(scheduleId) {
  return api("DELETE", `schedule/${scheduleId}`, undefined, schedule => schedule);
}

function addPlaylist(scheduleId, playlistId) {
  return api("POST", `schedule/${scheduleId}/playlist`, playlistId, schedule => schedule);
}

function deletePlaylist(scheduleId, playlistId) {
  return api("DELETE", `schedule/${scheduleId}/playlist/${playlistId}`, undefined, schedule => schedule);
}

function addDayPart(daypart) {
  delete daypart.id
  return api("POST", "daypart", daypart, schedule => schedule);
}

function updateDayPart(dayPartId, daypart) {
  return api("PUT", `daypart/${dayPartId}`, daypart, schedule => schedule);
}

function deleteDayPart(dayPartId) {
  return api("DELETE", `daypart/${dayPartId}`, undefined, schedule => schedule);
}

function duplicate(id) {
  return api("POST", `schedule/${id}/duplicate`, undefined, response => response);
}

function archive(id) {
  return api("GET", `schedule/${id}/archive`, undefined, schedule => schedule);
}

function info(ids) {
  return api("GET", `playlist/playlistsinfo?playlistIds=` + ids.join(','), undefined, response => response);
}

function getTotalTracks(scheduleId) {
  return api("GET", `schedule/${scheduleId}/total-tracks`, undefined, response => response);
}
