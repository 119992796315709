import get from 'lodash.get';

export const Match = (item, query, fields) => _Match(item, query.toLowerCase(), fields);
export const _Match = (item, lowercaseQuery, fields) => undefined !== fields.find(
  field => get(item, field, '').toString().toLowerCase().includes(lowercaseQuery)
);

/*
list: array of objects to search on
query: the string to query for
fields: array of properties to search on for each entry
  These are evaluated via lodash.get, and can contain any supported property query string
  Eg:     Matches([...], "Find me!", ["name", "artist.name", "album.name"])
*/
export const Matches = (list, query, fields) => {
  if (list.length === 0 || query === "") return list;
  let lowercaseQuery = query.toLowerCase();
  return list.filter(item => _Match(item, lowercaseQuery, fields));
};
