import React from 'react'
import styled from 'styled-components'
import {DefaultRowContainer} from './Container.style';
import {DefaultLabel} from './Label.style';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Icon = styled(FontAwesomeIcon)`
  color: white;
`;

const HeadingContainer = styled(DefaultRowContainer)`
  padding: 20px;
  background-color: #36363650;
  cursor: pointer;
  flex-grow: 1;
  align-items: center;
  width: 100%;
`;

const InfoLabel = styled(DefaultLabel)`
  width: 50%;
  color: gray;
  text-align: right;
`;

const NameLabel = styled(DefaultLabel)`
  color: white;
  margin-left: 10px;
  font-size: ${props => (props.isinside === "true" ? "16px" : "18px")};
`;

export const Heading = ({ item, isinside, isOpen }) => (
  <HeadingContainer>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginLeft: isinside.toString() === "true" ? "20px" : "0",
        width: "35%",
        alignItems: "center"
      }}
    >
      <Icon icon={isOpen ? "chevron-down" : "chevron-right"} />
      <NameLabel isinside={isinside.toString()}>{item.name}</NameLabel>
    </div>
    {item.locations !== undefined && (
      <InfoLabel>{`${item.locations.length} ${
        item.locations.length === 1 ? "Location" : "Locations"
      }`}</InfoLabel>
    )}
    {item.zones !== undefined && (
      <InfoLabel>{`${item.zones.length} ${
        item.zones.length === 1 ? "Zone" : "Zones"
      }`}</InfoLabel>
    )}
  </HeadingContainer>
);