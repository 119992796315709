import {api} from './api';

export const songsService = {
    getSongs,
    getOne,
    songSearch,
    getSongPlaylists,
    getGenres,
    archive,
    updateSongExplicitType,
};

function getSongs() {
    return api("GET", "song/simplePlaylists", undefined, songs => songs);
}

function getOne(id) {
    return api("GET", `song/${id}`, undefined, song => song);
}

function songSearch(search) {
    return api("GET", `search/${search}`, undefined, song => song);
}

function getSongPlaylists(id) {
    return api("GET", `song/${id}/playlists`, undefined, response => response);
}

function getGenres() {
    return api("GET", 'song/genres', undefined, response => response);
}

function archive(id) {
    return api("GET", `song/${id}/archive`, undefined, response => response);
}

function updateSongExplicitType(id, explicitType, verified, notes) {
    return api("POST", `song/${id}/updateExplicitType`, {explicitType, verified, notes}, response => response);
}
