import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import {theme} from '_styles';
import {TALEA_ENVIRONMENT} from "_services/api";

import "_styles/app.css";

import {
    AllClients,
    AllDevices,
    CalendarView,
    Location,
    Music,
    Overview,
    PlayerHistory,
    PrivateRoute,
    Reports,
    SampleLayout,
    Schedule,
    Scratch,
    SignIn,
    Users,
    Zones
} from '_containers';
import {FullViewPrivateRoute, SamplePlaylist} from "_components";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {library} from "@fortawesome/fontawesome-svg-core";
import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {AllCampaigns} from "./_containers/AllCampaigns";
import {Sample} from "./_components/Samples/Sample";
import { Logout } from "./_containers/Logout";

library.add(fal);
library.add(far);
library.add(fas);

toast.configure();

export class App extends React.Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <BrowserRouter basename="/">
                    <Switch>
                        <PrivateRoute exact path="/dashboard" component={Overview}/>
                        <PrivateRoute exact path="/" component={Overview}/>

                        {/* Clients */}
                        <PrivateRoute exact path="/clients" component={AllClients}/>
                        {/* Campaigns */}
                        <PrivateRoute exact path="/samples" component={AllCampaigns}/>


                        {/* Admin */}
                        <PrivateRoute exact path="/users" component={Users}/>
                        <PrivateRoute exact path="/reports" component={Reports}/>

                        {/* Music */}
                        <PrivateRoute path="/search" component={Music}/>
                        <PrivateRoute path="/songs" component={Music}/>
                        <PrivateRoute path="/playlists" component={Music}/>
                        <PrivateRoute path="/playlist" component={Music}/>
                        <PrivateRoute exact path="/feedback" component={Music}/>
                        <PrivateRoute path="/song" component={Music}/>

                        {/* Players */}
                        <PrivateRoute exact path="/zones" component={Zones}/>
                        <PrivateRoute exact path="/locations" component={Location}/>

                        <PrivateRoute exact path="/schedules" component={Schedule}/>
                        <PrivateRoute path="/schedule/:id" component={CalendarView}/>

                        <PrivateRoute exact path="/devices" component={AllDevices}/>
                        <PrivateRoute exact path="/devices/:id" component={PlayerHistory}/>

                        <PrivateRoute exact path="/scratch" component={Scratch}/>
                        <FullViewPrivateRoute exact path="/scratch-full" component={Scratch}/>
                        <Route exact path="/playerops/:playerId/:operation" component={({match}) => {
                            if (localStorage.getItem('user')) {
                                const {playerId, operation} = match.params;
                                window.location = "taleassh://" + operation + "_" + playerId + "@" + TALEA_ENVIRONMENT;
                                return null
                            } else {
                                return <Redirect to={{pathname: '/sign'}}></Redirect>
                            }
                        }}>
                        </Route>
                        <Route exact path="/clientsample/:sampleToken"
                               render={props => <SampleLayout><Sample {...props}></Sample></SampleLayout>}/>
                        <Route exact path="/clientsample/:sampleToken/playlist/:playlistId" render={props =>
                            <SampleLayout><SamplePlaylist {...props}></SamplePlaylist></SampleLayout>}/>
                        <Route exact path="/clientsample/:sampleId/playlist/:playlistId/song/:songId"
                               render={() => <SampleLayout><SamplePlaylist></SamplePlaylist></SampleLayout>}/>
                        <Route exact path="/sign" component={SignIn}/>
                        <Route exact path="/logout" component={Logout}/>
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        );
    }
}
