import {api, apiUpload} from './api';

export const clientService = {
  getAllClients,
  getOne,
  createClient,
  updateClient,
  uploadArtwork,
  getUsers,
  archive
};

function getAllClients() {
  return api("GET", "client", undefined, clients => clients);
}

function getOne(id) {
  return api("GET", `client/${id}`, undefined, client => client);
}

function createClient(client) {
  return api("POST", "client", client, clientResponse => clientResponse);
}

function updateClient(client, id) {
  return api("PUT", `client/${id}`, client, clientResponse => clientResponse)
}

function getUsers() {
  return api("GET", "client/user", undefined, client => client);
}

function archive(id) {
    return api("GET", `client/${id}/archive`, undefined, response => response);
}

function uploadArtwork(clientId, file) {
  return apiUpload( `client/${clientId}/artwork`, "imageFile", file,response => response)
}
