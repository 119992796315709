import {api} from './api';

export const albumService = {
  get,
  getOne,
  addOne
};

function get() {
  return api("GET", `album`, undefined, album => album);
}

function getOne(id) {
  return api("GET", `album/${id}`, undefined, album => album);
}

function addOne(album) {
  // TODO NotImplemented
  return api("POST", `album`, undefined, album => album);
}