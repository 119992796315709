import {userConstants} from "../_constants";

const initialState = {
  loading: false,
  users: [],
  userDetails: {},
  profileUser: {},
  dashboard: {}
};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.dashboard
      };
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case userConstants.GET_ONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileUser: action.users
      };
    case userConstants.GET_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case userConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users
      };
    case userConstants.GET_FAILURE:
      return {
        ...state,
        loading: false
      };
    case userConstants.ADD_ONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.ADD_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
