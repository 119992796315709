import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
import styled from "styled-components";
import { SelectionContextMenu, SingleContextMenu, TaleaContextMenu } from '_components/Standard';
import { AddToMultipleButton } from '_styles';
import get from 'lodash.get';

const SaveMultiple = styled.div`
  display: flex;
  cursor: ${props => props.enabled ? "pointer" : "not-allowed"};
  height: 28px;
  width: 100px;
  padding: 0px 8px;
  margin: 8px auto;
  border-radius: 8px;
  border: 1px solid ${props => props.enabled ? "orange" : "#4B4B4B"};
  background-color: ${props => props.enabled ? "orange" : "transparent"};
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: all 250ms linear;
`;

const Title = styled.div`
  color: ${props => props.enabled ? "white" : "#4B4B4B"};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 6px;
  transition: all 220ms linear;
`;

class AddToMultiple extends React.Component {
    state = {
        selectedIds: []
    }

    itemSelected = item => {
        const { selectedIds } = this.state;
        const update = selectedIds.includes(item.id) ? selectedIds.filter(id => id !== item.id) : [...selectedIds, item.id];
        this.setState({selectedIds: update});
    }

    selected = () => {
        let list = []
        this.props.items.forEach(i => {
            if (this.state.selectedIds.includes(i.id)) list.push(i);
        })
        return list;
    }

    onSave = () => {
        this.props.onSave(this.selected());
    }

    render() {
        const hasSelection = this.state.selectedIds.length > 0;

        return (
            <TaleaContextMenu width={'auto'} style={{paddingLeft:10}}>
                <SelectionContextMenu items={this.props.items} selectedIds={this.state.selectedIds} onListItemClicked={this.itemSelected} />
                <SaveMultiple onClick={hasSelection ? this.onSave : () => {}} enabled={hasSelection} data-tip data-for='multiple'>
                    <Title enabled={hasSelection}>Add</Title>
                    <FontAwesomeIcon icon="layer-plus" color={hasSelection ? "white" : "#4B4B4B"} />
                </SaveMultiple>
                {hasSelection ? (
                    <ReactTooltip effect='solid' place="right" type='light' id='multiple'>
                        {this.selected().map(z =>
                            <div key={z.id}>{z.text}</div>
                        )}
                    </ReactTooltip>
                ) : (
                    <ReactTooltip effect='solid' place="right" type='info' id='multiple'>
                        Nothing selected
                    </ReactTooltip>
                )}
            </TaleaContextMenu>
        )
    }
}

export const AddToMultipleComponent = ({songCount, onSave, playlists}) => {
    // Redux playlist
    const playlistOptions = (playlists || [])
        .sort((a, b) => get(a, 'client.name', '').localeCompare(get(b, 'client.name', '')))
        .map(p => ({
                id: p.id,
                text: p.client ? `${p.client.name} - ${p.name}` : `Master - ${p.name}`
            })
        );

    return (
        <SingleContextMenu triggerComponent={
            <AddToMultipleButton enabled={songCount > 0} count={songCount} />
        } offset={{top: 0, left: 0}}>
            {({hide}) => <AddToMultiple onSave={(pl) => onSave(pl, hide)} items={playlistOptions} />}
        </SingleContextMenu>
    );
}