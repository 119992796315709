import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import {DefaultButton, DefaultCancelButton, DefaultGrayButton, DefaultLabel} from "_styles";


const Label = styled(DefaultLabel)`
  min-width: 120px;
  max-width: 120px;
  color: white;
  text-align: right;
  margin-right: 20px;
  overflow-wrap: break-word;
  white-space: normal;
`;

const Cancel = styled(DefaultGrayButton)`
  width: 100px;
  color: white;
`;

const Ok = styled(DefaultButton)`
  width: 100px;
  color: #F1A823 ;
`;


const SubHeader = styled.div`
  margin: 18px 0 8px 0;
  font-size: 16px;
  color: white;
`;

export const ModalAddPlaylist = ({
  playlist,
  masterPlaylists,
  otherPlaylist,
  handleAddPlaylist,
  handleClose
}) => {
  const [pl, setPl] = useState(null);
  const [mpl, setMpl] = useState(null);

  const playlistSelect = (source) => {
    let array = [];
    const stringToColour = function(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    }
    source.forEach(el => {
      const labelHtml =  el.name + (el.playlistGroup? "&nbsp;<font color='"+stringToColour(el.playlistGroup)+"'>(" + el.playlistGroup+ ")</font>":"");
      //const label = <span dangerouslySetInnerHTML={{ __html: labelHtml }}></span>
      if (otherPlaylist.findIndex(pl => pl.name === el.name) === -1) {
        array.push({ value: el.id, label: labelHtml });
      } else {
        array.push({ value: el.id, label: labelHtml, isDisabled: true });
      }
    });

    return array;
  };

  const handleAccept = () => {
    handleAddPlaylist([...(pl || []), ...(mpl || [])]);
  };

  return (
    <Modal show={true} className="modal-pick" onHide={handleClose}>
      <Modal.Header>
        <Label>Add Playlists</Label>
      </Modal.Header>
      <Modal.Body>
        <SubHeader>Client Playlists</SubHeader>
        <Select
          options={playlistSelect(playlist)}
          formatOptionLabel={function(data) {
            return (
                <span dangerouslySetInnerHTML={{ __html: data.label }} />
            );
          }}
          isSearchable={true}
          name="add-playlists"
          isMulti
          onChange={setPl}
        />

        <SubHeader>Master Playlists</SubHeader>
        <Select
          options={playlistSelect(masterPlaylists)}
          name="add-playlists"
          formatOptionLabel={function(data) {
            return (
                <span dangerouslySetInnerHTML={{ __html: data.label }} />
            );
          }}
          isSearchable={true}
          isMulti
          onChange={setMpl}
        />
      </Modal.Body>
      <Modal.Footer>
        <Cancel onClick={handleClose}>Cancel</Cancel>
        <Ok onClick={handleAccept}>Ok</Ok>
      </Modal.Footer>
    </Modal>
  );
};
