import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {theme} from './Theme'

const CheckboxFill = styled.div.attrs({
    className: "checkbox-fill"
})`
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border: 1px solid black;
    border-radius: 3px;
`;

const SVG = styled.svg.attrs({
    className: "checkbox-svg"
})`
    position: absolute;
    top: -1px;
    left: -1px;

    path {
        fill: white;
        stroke: ${props => props.theme.highlight};
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 71px;
        stroke-dashoffset: 71px;
        transition: all 0.6s ease;
    }

    polyline {
        fill: none;
        stroke: white;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 18px;
        stroke-dashoffset: 18px;
        transition: all 0.3s ease;
    }
`;

const Input = styled.input.attrs({
    className: "checkbox-input",
    type: "checkbox"
})`
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked {
        & + .checkbox-fill {
            border-color: white;

            .checkbox-svg {
                path {
                    fill: ${props => props.theme.highlight};
                    stroke-dashoffset: 0;
                }

                polyline {
                    stroke-dashoffset: 2;
                }
            }
        }
    }
`;

const Text = styled.span.attrs({
    className: "checkbox-text"
})`
    pointer-events: none;
    vertical-align: middle;
    font-size: 16px;
`;

const CheckboxLabel = styled.label.attrs({
    className: "checkbox-label"
})`
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-weight: normal;

    ${Text} {
        margin-left: 10px;
    }

    &:hover {
        .checkbox-fill {
            .checkbox-svg {
                path {
                    stroke-dashoffset: 0;
                }
            }
        }
    }
`;

export const CheckboxButton = ({
                                   id,
                                   name,
                                   value,
                                   defaultValue,
                                   children,
                                   handleChange,
                                   ...props
                               }) => (
    <ThemeProvider theme={theme}>
        <CheckboxLabel {...props}>
            <Input
                id={id}
                name={name}
                value={props.checked}
                checked={props.checked}
                onChange={handleChange ? handleChange : () => {
                }}
            />
            <CheckboxFill>
                <SVG width="20px" height="20px" viewBox="0 0 20 20">
                    <path
                        d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"/>
                    <polyline points="4 11 8 15 16 6"/>
                </SVG>
            </CheckboxFill>
            <Text>{children}</Text>
        </CheckboxLabel>
    </ThemeProvider>
);
