import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {Modal} from "react-bootstrap";
import {DefaultGrayButton, DefaultRowContainer, theme} from "_styles";

const ModalContainer = styled(Modal)``;

const ModalHeader = styled(Modal.Header)`
  background-color: ${props => props.theme.background};
  border: 0;
  justify-content: center;
`;

const ModalBody = styled(Modal.Body)`
  background-color: ${props => props.theme.background};
`;

const Title = styled(Modal.Title)`
  color: white;
`;

const Container = styled(DefaultRowContainer)`
  justify-content: space-between;
`;

const ImportButtons = styled(DefaultGrayButton)`
  width: 49%;
`;

const SpotifyButton = styled(ImportButtons)`
  background-color: #1db954;
`;

const ITunesContainer = styled.div`
  background: linear-gradient(to right, rgb(88, 86, 214), rgb(255, 45, 85));
  padding: 3px;
  width: 49%;
  border-radius: 5px;
`;

const ITunesButton = styled(DefaultGrayButton)`
  background-color: white;
  color: black;
  width: 100%;
  height: 44px;
`;

const ITunes = ({ onClick }) => (
  <ITunesContainer>
    <ITunesButton onClick={onClick}>iTunes</ITunesButton>
  </ITunesContainer>
);

export const ModalImportPlaylist = ({ handleClose }) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalContainer show={true} onHide={handleClose}>
        <ModalHeader closeButton>
          <Title>Import Playlist</Title>
        </ModalHeader>
        <ModalBody>
          <Container>
            <SpotifyButton>Spotify</SpotifyButton>
            <ITunes />
          </Container>
        </ModalBody>
      </ModalContainer>
    </ThemeProvider>
  );
};
