import {campaignConstants} from "_constants";

const initialState = {
  loading: false,
  selectedCampaign: { id: -1, text: "All Campaigns"},
  campaign: [],
  campaignUsers: [],
  campaignDetails: {}
};
export function campaign(state = initialState, action) {
  switch (action.type) {
    case campaignConstants.SET_SELECTED:
      return {
        ...state,
        selectedCampaign: action.campaign
      };
    case campaignConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case campaignConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: action.campaign
      };
    case campaignConstants.GET_FAILURE:
      return {
        ...state,
        loading: false
      };
    case campaignConstants.ARCHIVE_SUCCESS:
      return {
        ...state,
        campaign: state.campaign.filter(campaign => campaign.id === action.id)
      }
    case campaignConstants.GET_ONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case campaignConstants.GET_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignDetails: action.campaign
      };
    case campaignConstants.GET_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case campaignConstants.CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case campaignConstants.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case campaignConstants.CREATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false
      };
    case campaignConstants.GET_CAMPAIGN_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case campaignConstants.GET_CAMPAIGN_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignUsers: action.campaign
      };
    case campaignConstants.GET_CAMPAIGN_USERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
