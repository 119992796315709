import React, {useState} from "react";
import styled, {ThemeProvider} from "styled-components";
import {CreationForms, DefaultBlackFormControl, DefaultLabel, theme} from "_styles";
import JSONTree from 'react-json-tree';
import {ModalDecisionBuilder} from "./Utils";
import {alertActions} from "../_actions";
import {playerService} from "../_services";
import {Link} from "react-router-dom";
import {Badge} from "react-bootstrap";

const FormLabel = styled(DefaultLabel)`
    color: ${props => props.theme.formlabel};
    font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
    margin-top: 5px;
`;

export const EditPlayer = ({dispatch, onArchive, player, handlePlayerEdit, handleBackButton}) => {
    const [name, setName] = useState(player.name);
    const [notes, setNotes] = useState(player.notes);
    const [confirmArchiving, setConfirmArchiving] = useState(false);
    const [error, setError] = useState('');

    const handleName = e => {
        setName(e.target.value);
    };

    const handleNotes = e => {
        setNotes(e.target.value);
    };

    const handleSubmit = e => {
        if (name.length > 0) {
            handlePlayerEdit(player.id, name, notes);
        } else {
            setError("Player name cannot be empty.")
        }
    };

    const handleArchive = e => {
        if (confirmArchiving)
            return;
        setConfirmArchiving(true)
    }

    const doArchiving = async () => {
        try {
            await playerService.archive(player.id);
            alertActions.notificationSuccess(dispatch, "Player archived", player.name);
            setConfirmArchiving(false);
            onArchive();
        } catch (e) {
            setError(e.userMessage);
            setConfirmArchiving(false);
        }
    }

    function format_date(s) {
        const options = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hourCycle: 'h23',
        };
        const dtFormat = new Intl.DateTimeFormat('en-US', options);
        return dtFormat.format(new Date(s));
    }

    player.serverScheduleUpdateDate = player.serverScheduleUpdateDate ? format_date(player.serverScheduleUpdateDate) : "";
    player.dateCreated = format_date(player.dateCreated);
    player.lastPing = player.lastPing ? format_date(player.lastPing) : "";
    if (player.info && player.info.scheduleUpdateDate) {
        player.info.scheduleUpdateDate = format_date(player.info.scheduleUpdateDate);
    }
    const scheduleLink = player.info && player.info.scheduleId ? `/schedule/${player.info.scheduleId}` : "";
    const zoneLink = player.zone && player.zone.id ? `/zones?zoneId=${player.zone.id}` : "";

    return (
        <ThemeProvider theme={theme}>
            <CreationForms
                message="This will edit the player, please check the information entered above is correct."
                handleBackButton={handleBackButton}
                handleSubmit={handleSubmit}
                onDelete={handleArchive}
                alert={error}
                title="Edit Player"
            >
                <FormLabel>Name</FormLabel>
                <Form
                    style={{marginTop: 10, marginBottom: 10}}
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={handleName}
                />
                <FormLabel style={{marginTop: 10}}>Notes</FormLabel>
                <Form
                    style={{marginTop: 10, marginBottom: 10}}
                    as="textarea"
                    name="notes"
                    placeholder="Notes"
                    value={notes}
                    onChange={handleNotes}
                />
                {zoneLink || scheduleLink ?
                    <div style={{marginTop: 25}}><FormLabel style={{marginBottom: 10}}>Assignments</FormLabel>
                    </div> : null}
                {zoneLink &&
                    <Link style={{color: "#F7A83C", fontSize: 16, textDecoration: 'none'}} to={zoneLink}
                          target="_blank" rel="noopener noreferrer">zone</Link>}
                {scheduleLink &&
                    <Link style={{color: "#F7A83C", fontSize: 16, marginLeft: 10, textDecoration: 'none'}}
                          to={scheduleLink} target="_blank" rel="noopener noreferrer">schedule</Link>}
                {zoneLink || scheduleLink ? <br></br> : null}
                <FormLabel style={{marginTop: 25}}>Player Details</FormLabel>
                <JSONTree data={player} theme={{
                    ...theme, tree: ({style}) => ({
                        style: {...style, padding: 12, borderRadius: 10},
                    })
                }} invertTheme={false}/>
                {confirmArchiving && (
                    <ModalDecisionBuilder
                        title={"Are you sure you want to archive this player?"}
                        handleClose={() => {
                            setConfirmArchiving(false)
                        }}
                        handleAccept={doArchiving}
                    />
                )}
            </CreationForms>
        </ThemeProvider>
    );
}