import React from "react";
import {AlertList} from "react-bs-notifier";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {uiActions, alertActions, clientActions, playlistActions, userActions, songsActions} from "_actions";
import {SideNavbar} from '_components';
import {getNotifications} from "_reducers/alert.reducer";
import {
    getNowPlayingLoading,
    getNowPlayingArtist,
    getNowPlayingTitle,
    getNowPlayingUrl,
    getNowPlayingArtworkUrl,
    getPlaylistPlayback,
    getPlayerSongId
} from "_reducers/player.reducer";
import {getActivePlaylistId, getPlaylistLookupView, getPlaylists} from "_reducers/playlist.reducer";
import {getNavigationPlaylistExpand} from "_reducers/ui.reducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TaleaPlaybackController} from "../_components/Standard/TaleaPlaybackController";



class Logout extends React.Component {
    handleLogout = () => {
        this.props.dispatch(userActions.logout(this.props.history));
    };

    render() {
        this.handleLogout();
        return "hello";
    }
}

const mapStateToProps = state => ({
});

const connectedLogout = withRouter(connect(mapStateToProps)(Logout));
export {connectedLogout as Logout};

