import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {DefaultRowContainer} from './Container.style';
import {DefaultLabel} from './Label.style';
import {theme} from './Theme';


const Container = styled(DefaultRowContainer)`
  min-width: 600px;
  padding: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const InnerContainer = styled(DefaultRowContainer)`
  justify-content: flex-end;
`;

const Name = styled(DefaultLabel)`
  color: white;
  font-size: 16px;
  flex-grow: 1;
`;

const Logout = styled(FontAwesomeIcon)`
  margin-left: 20px;
  color: white;
  cursor: pointer;
`;

export const TopNavbar = ({ name, handleLogout }) => (
  <ThemeProvider theme={theme}>
    <Container>
      <InnerContainer>
        <Name>{name}</Name>
        <Logout onClick={handleLogout} icon="power-off" />
      </InnerContainer>
    </Container>
  </ThemeProvider>
);
