export const artistConstants = {
  GET_REQUEST: 'ARTIST_GET_REQUEST',
  GET_SUCCESS: 'ARTIST_GET_SUCCESS',
  GET_FAILURE: 'ARTIST_GET_FAILURE',

  GET_ONE_REQUEST: 'ARTIST_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'ARTIST_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'ARTIST_GET_ONE_FAILURE',

  ADD_ONE_REQUEST: 'ARTIST_ADD_ONE_REQUEST',
  ADD_ONE_SUCCESS: 'ARTIST_ADD_ONE_SUCCESS',
  ADD_ONE_FAILURE: 'ARTIST_ADD_ONE_FAILURE'
};