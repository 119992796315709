import styled from "styled-components";
import {Modal} from "react-bootstrap";
import media from "../../_helpers/media";

export const SamplesModal = styled(Modal)`
  color:white;
  textarea {
    color:white
  }
  .modal-title {
    ${media.mobile`    
font-size:16px 
`};
    ${media.tablet`
font-size:24px
`}
  }

  .modal-header {
    border-radius: 15px 15px 0 0;
    border: 0;
  }

  .modal-body {
    border-radius: 0px 0px 15px 15px;
  }

  .modal-content {
    background-color: transparent;
  }

  .close {
    color: white;
  }
`;
