import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SelectionContextMenu } from './SelectionContextMenu';
import { SingleContextMenu, TaleaContextMenu } from './ContextMenu';
import { theme } from '_styles/Theme';

const FilterBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    width: 200px;
    height: 42px;
    font-size: 16px;

    box-sizing: border-box;
    border-bottom: 1px solid ${theme.fadeLine};
    color: ${props => props.haveValue ? (props.color || 'white') : theme.fade};

    &:hover {
        color: white;
    }
`;

export const DropdownFilterBox = ({color, placeholder, value, onClear}) => {
  const haveValue = value;
  return (
      <FilterBox haveValue={haveValue} color={color}>
            {value ? value : placeholder}
        <div>
                {value ? (
                    <FontAwesomeIcon onClick={onClear} color={theme.fade} icon={['fal', 'times']} />
                ) : (
                    <FontAwesomeIcon color={theme.fade} icon={['fal', 'chevron-down']} />
                )}
            </div>
        </FilterBox>
  )
}

export const DropdownFilter = ({
                                 component = undefined,
                                 selectedId: inSelectedId = undefined,
                                 onSelect,
                                 offset = {top: 0, left: 0},
                                 options,
                                 placeholder,
                                 selectedTextColor = 'orange',
                                 menuStyle = {paddingLeft: 10, width: "100%"},
                                 maxItems = -1
                               }) => {
  const selectedOption = inSelectedId ? options.find(o => o.id === inSelectedId) : undefined;

  const [selectedId, setSelectedId] = useState(inSelectedId);
  const [selectedValue, setSelectedValue] = useState((selectedOption || {text: ''}).text);

  const handleSelect = selected => {
    // If not explicitly false, assume accepted (to support existing functions without updating)
    if (onSelect(selected) !== false) {
      setSelectedId(selected.id);
      setSelectedValue(selected.text);
    }
  }

  useEffect(() => {
    if (!selectedOption) return;
    handleSelect(selectedOption);
  }, []);

  const clearSelection = e => {
    e.stopPropagation();
    setSelectedId(-1);
    setSelectedValue('');
    onSelect(undefined);
  }

  const triggerComponent = component ||
      <DropdownFilterBox color={selectedTextColor} placeholder={placeholder} value={selectedValue}
          onClear={clearSelection} />;
  const menuOffset = component ? offset : {top: 40, left: -12};

  return (
      <>
            <SingleContextMenu triggerComponent={triggerComponent} offset={menuOffset}>
                {({hide}) => (
                    <TaleaContextMenu style={menuStyle}>
                        <SelectionContextMenu items={options}
                            selectedIds={[selectedId]}
                            maxItems={maxItems}
                            onListItemClicked={e => {
                              hide();
                              handleSelect(e);
                            }} />
                    </TaleaContextMenu>
                )}
            </SingleContextMenu>
        </>
  );
};