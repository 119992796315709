import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { DefaultButton, DefaultCancelButton, DefaultLabel } from "_styles";

const Cancel = styled(DefaultCancelButton)`
  width: 100px;
  color: black;
`;

const Ok = styled(DefaultButton)`
  width: 100px;
  color: black;
`;

const Title = styled(DefaultLabel)`
  color: black;
`;

// const SubHeader = styled.div`
//   margin: 18px 0 8px 0;
//   font-size: 16px;
// `;

export const ModalPickZone = ({
  zones,
  handlePickZone,
  handleClose
}) => {
    const [zone, setZone] = useState(null);
    const zoneSelect = (zones) => {
    var localOffset = (new Date().getTimezoneOffset()/60)*-1;
    if (localOffset > 0){
        localOffset = "+"+(localOffset<10?"0"+localOffset:""+localOffset)+":00";
    }else{
        localOffset = "-"+(localOffset<-9?"0"+(localOffset*-1):""+localOffset)+":00";
    }
    let array = [{ value: {name:"local",timeZoneOffset:localOffset}, label: "local ("+localOffset+")"  }];
    zones.forEach(zone => {
        array.push({ value: zone, label: zone.name + " ("+zone.timeZoneOffset+")"  });
    });
    return array;
  };

  const handleAccept = () => {
      handlePickZone(zone.value);
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header>
        <Title>Pick a zone</Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          options={zoneSelect(zones)}
          name="add-playlists"
          onChange={(zone) => setZone(zone)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Cancel onClick={handleClose}>Cancel</Cancel>
        <Ok onClick={handleAccept}>Ok</Ok>
      </Modal.Footer>
    </Modal>
  );
};
