import {api, BaseUrl} from './api';

export const userService = {
  login,
  logout,
  register,
  getUser,
  getUsers,
  addOne,
  updateOne,
  archive,
  getDashboard,
  login2fa,
  submit2FACode
};

let api_url = BaseUrl;

function login(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${api_url}login`, requestOptions)
    .then(async (response) => {
      if (response.ok) return response.json();
      const errorJson = await response.json();
      return Promise.reject(errorJson.error);
    })
    .then(user => {
      if (user.authToken) {
        localStorage.setItem("user", JSON.stringify(user));
      }

      return user;
    });
}

function login2fa(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${api_url}login/login2fa`, requestOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const errorJson = await response.json();
        return Promise.reject(errorJson.error);
      })
      .then(responseData => {
        return responseData;
      });
}

function submit2FACode(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify(user)
  };

  return fetch(`${api_url}login/2facode`, requestOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const errorJson = await response.json();
        return Promise.reject(errorJson.error);
      })
      .then(user => {
        if (user.authToken) {
          localStorage.setItem("user", JSON.stringify(user));
        }
        return user;
      });
}

function register(user) {
  throw new Error('Not implemented!');
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getUser(username) {
  return api("GET", `user/${username}`, undefined, user => user);
}

function getUsers() {
  // Gets internal users ONLY!!!!
  return api("GET", "user/internal", undefined, users => users);
}

function addOne(user) {
  return api("POST", "user", user, response => response);
}

function updateOne(user, id) {
  return api("PUT", `user/${id}`, user, response => response);
}

function getDashboard(userId) {
  return api("GET", `user/${userId}/dashboarddata`, undefined, response => response);
}

function archive(id) {
  return api("GET", `user/${id}/archive`, undefined, response => response);
}
