import React from "react";
import styled from "styled-components";
import image from "_assets/images/default-profile.png";
import {DefaultColumnContainer} from './Container.style';
import {DefaultLabel} from './Label.style';

const Clickable = styled.div``;

const Container = styled(DefaultColumnContainer)`
  justify-content: center;
  align-items: center;
`;

const Title = styled(DefaultLabel)`
  font-size: 18px;
  color: white;
`;

const Image = styled.img`
  height: 180px;
  width: 200px;
  margin-top: 20px;
`;

const Name = styled(DefaultLabel)`
  font-size: 24px;
  color: white;
  margin-top: 20px;
`;

// const Next = styled(DefaultLabel)`
//   font-size: 16px;
//   color: white;
// `;

const defineTitle = title => {
  switch (title) {
    case null:
      return "Mysterious Person";
    case "ADMIN":
      return "Administrator";
    case "CURATOR":
    default:
      return "Music Curator";
  }
};

export const ProfileCard = ({ title, name, handleViewAccount }) => (
  <Clickable>
    <Container>
      <Title>{defineTitle(title)}</Title>
      <Image src={image} />
      <Name>{`Welcome ${name}`}</Name>
      {/*<Next onClick={handleViewAccount}>View my Account ></Next>*/}
    </Container>
  </Clickable>
);
