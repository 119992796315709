import React from "react";
import styled from "styled-components";
import {Table} from "react-bootstrap";

const ScheduleTable = styled(Table)`
  width: 100%;
  margin-top: 20px;
  background-color: transparent !important;
`;

const Headers = styled.thead``;

const Row = styled.tr``;

const HeaderColumn = styled.th`
  text-align: center;
  border-color: white !important;
  min-width: 85px !important;
`;

const Body = styled.tbody``;

const BodyColumn = styled.td`
  text-align: center;
  border-color: white !important;
`;

const PlaylistColumn = styled.td`
  text-align: right;
  border-color: white !important;
`;

const BodyColoredColumn = styled.td`
  background-color: ${props => (props.exists ? "#1bb499" : "transparent")};
  border-color: white !important;
  width: 110px !important;
`;

let daysOfTheWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

export const SchedulesTable = ({ header, data }) => (
  <ScheduleTable bordered variant="dark">
    <Headers>
      <Row>
        {header.map((head, index) => (
          <HeaderColumn key={`${head}-header-${index}`}>{head}</HeaderColumn>
        ))}
      </Row>
    </Headers>
    <Body>
      {data.map((row, index) => (
        <Row key={`${row.playlist.name}-data-${index}`}>
          <PlaylistColumn>
            <span style={{ fontSize: "18px" }}>{row.playlist.name}</span>
            <br></br>
            <span style={{ fontSize: "12px" }}>
              {`${row.playlist.tracks} tracks`}
            </span>
          </PlaylistColumn>
          {daysOfTheWeek.map((day, index) => (
            <BodyColoredColumn key={`${day}-daysofweek-${index}`} exists={row.days.split(",").indexOf(day) > -1} />
          ))}
          <BodyColumn>{row.time}</BodyColumn>
          <BodyColumn>{row.date}</BodyColumn>
          <BodyColumn>{row.options}</BodyColumn>
        </Row>
      ))}
      <Row><BodyColumn colSpan={header.length} style={{ textAlign: 'left'}}>+ Add Schedule</BodyColumn></Row>
    </Body>
  </ScheduleTable>
);
