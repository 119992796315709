import {songsConstants} from "_constants";
import {songsService} from "_services";
import {alertActions} from "./alert.actions";


export const songsActions = {
    getSongs,
    getOne,
    songSearch,
    getSongPlaylists,
    getGenres,
    archive,
    songStatusChanged
};

async function getSongs(dispatch) {
    dispatch({type: songsConstants.GET_REQUEST});

    try {
        const songs = await songsService.getSongs();
        dispatch({type: songsConstants.GET_SUCCESS, songs})
        return songs;
    } catch (e) {
        dispatch({type: songsConstants.GET_FAILURE, error: e.toString()})
        throw e;
    }
}

async function getSongPlaylists(dispatch, id) {
    dispatch({type: songsConstants.GET_SONG_PLAYLISTS_REQUEST});

    try {
        const playlists = await songsService.getSongPlaylists(id);
        dispatch({type: songsConstants.GET_SONG_PLAYLISTS_SUCCESS, id, playlists})
        return playlists;
    } catch (e) {
        dispatch({type: songsConstants.GET_SONG_PLAYLISTS_FAILURE, error: e.toString()})
        throw e;
    }
}

async function getOne(dispatch, id) {
    dispatch({type: songsConstants.GET_ONE_REQUEST});

    try {
        const song = await songsService.getOne(id);
        dispatch({type: songsConstants.GET_ONE_SUCCESS, song})
        return song;
    } catch (e) {
        dispatch({type: songsConstants.GET_ONE_FAILURE, error: e.toString()})
        throw e;
    }
}

function songSearch(search) {
    return dispatch => {
        dispatch(request());

        songsService.songSearch(search).then(
            songs => {
                dispatch(success(songs));
                dispatch(alertActions.success())
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: songsConstants.SEARCH_REQUEST};
    }

    function success(songs) {
        return {type: songsConstants.SEARCH_SUCCESS, songs};
    }

    function failure(error) {
        return {type: songsConstants.SEARCH_FAILURE, error};
    }
}

async function getGenres(dispatch) {
    dispatch({type: songsConstants.GET_GENRES_REQUEST});

    try {
        const genres = await songsService.getGenres();
        dispatch({type: songsConstants.GET_GENRES_SUCCESS, genres});
        return genres;
    } catch (e) {
        dispatch({type: songsConstants.GET_GENRES_FAILURE, error: e.toString()});
        throw e;
    }
}

async function archive(dispatch, id) {
    dispatch({type: songsConstants.ARCHIVE_REQUEST});
    try {
        const archiveResponse = await songsService.archive(id);
        dispatch({type: songsConstants.ARCHIVE_SUCCESS, id});
        return archiveResponse;
    } catch (e) {
        dispatch({type: songsConstants.ARCHIVE_FAILURE, error: e.toString()});
        throw e;
    }

}

async function songStatusChanged(dispatch) {
    dispatch({type: songsConstants.CHANGE_SONG_STATUS});
}
