import React from "react";
import ReactLoading from "react-loading";

export const Loading = ({ children, size = 24 }) => (
  <div className="center">
    <ReactLoading
      height={size} width={size}
      type={"bars"}
      color={"white"}
    />
    {children}
  </div>
);
