import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "./Theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 300px;
`;

const Input = styled.input`
  width: 300px;
  height: 42px;
  padding: 4px 4px 4px 42px;
  font-size: 16px;

  background-color: ${props => props.theme.darkGray};
  border: 0;
  outline: none;
  box-shadow: none;
  caret-color: white;
  color: white;

  &:focus,
  &:active,
  &:hover {
    background-color: ${props => props.theme.optionalGray};
    border: 0;
    outline: none;
    box-shadow: none;
    caret-color: white;
    color: white;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 13px;
  top: 13px;
  color: grey;
`;

export const FilterControl = ({ handleFilter, placeholder="Filter" }) => (
  <ThemeProvider theme={theme}>
    <Form>
      <Input type="text" placeholder={placeholder} onChange={e => handleFilter(e.target.value)} />
      <Icon icon="search" />
    </Form>
  </ThemeProvider>
);
