import React from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';

const Container = styled.div`
  display: flex;
  cursor: ${props => props.enabled ? "pointer" : "not-allowed"};
  height: 28px;
  width: 70px;
  padding: 0px 8px;
  border-radius: 8px;
  border: 1px solid ${props => props.enabled ? "orange" : "#4B4B4B"};
  background-color: ${props => props.enabled ? "orange" : "transparent"};
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: all 250ms linear;
`;

const CountBadge = styled.div`
  height: 20px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  transition: all 220ms linear;
  color: ${props => props.enabled ? 'white' : '#4B4B4B'};
`;

const getCountString = count => {
    if (count > 99) return '>99';
    return count;
}

export const AddToMultipleButton = ({enabled = true, count, onClick, pinned}) => (
    <>
        <Container enabled={enabled} onClick={enabled ? onClick : () => {
        }} data-tip={pinned ? "Add to Pinned Playlist" : "Add To Multiple Playlists"}>
            < CountBadge enabled={enabled}>{getCountString(count)}</CountBadge>
            <FontAwesomeIcon icon="plus" color={enabled ? "white" : "#4B4B4B"}/>
        </Container>
        <ReactTooltip effect='solid' place="right" type='info'/>
    </>
);