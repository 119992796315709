export const theme = {

  colors: {
    primary: 'white',
    secondary: '#99999E',
    tertiary: '#64636C'
  },

  

  bodyBackground: '#222129',
  navBackground: '#1B1A20',
  pinnedBackground: '#2C2A36',
  inputBackground: '#1B1A20',
  modalBackground: '#2c2b34',

  rowSelect: '#7E7B91',
  rowHover: '#3D3A49',

  fadeLine: '#343434',
  fade: '#979797',

// TopGradient: 			#222129 -> #383644

  taleaYellow: '#F7A83C',
  onlineGreen: '#1BB499',
  offlineRed:	'#F7615D', //'#CC1534',

  borderColor: '#525252',

  a1White: 'white',
  a2White: '',
  a3White: '',

  dim: '#4A494E',
  lightDim: '#7A797E',

  hoverWhite: 'white',


  // Original list - not ready to complete standardizing colors
  main: "#222129",
  leftMenu: '#1B1A20',

  darkGray: "#464645",
  lightGray: "#949ea9",

  highlight: "#f1a822",
  lightYellow: "#E39035",
  darkYellow: "#8e4820",

  red: "#60261D",
  brown: "#685747",
  green: "#1f2823",

  optionalGray: "#3c3c3c",
  background: "#1d1d1d",

  searchBox: "#2c2c2c",
  searchBoxBorder: "#414141",
  formlabel: "#808080",
  mostGrays: "#454546",

  offWhite: "#dfe3e8",
  offWhiteHover: "#d0d5da"

// FilterLine:			#4B4A50
// FadedLine:			#2E2E2E
    

// Font & Text Styles
// Font-Family: Apercu
// Main Body Font-size: 14px

// Body (tables)			-> PrimaryWhite
// Table Headers 		-> OffWhite		 
// SecondaryBody		-> Grey			// bpm, year, faded etc.

// H1 {
// 	font-size: 56px
// 	color: white
// } // main page titles

// H2 {
// 	Font-size: 42px
// 	Color: white
// } // modal headers

// H3 {
// 	Font-size: 24px
// 	Color: “Primarywhite”
// } // now playing header, modal sub headers, Zones Client Name

// H4 {
// 	Font-size: 18px
// 	Color: “OffWhite”
// } // now playing artist name, modal third tier header, tabs, zones location names, breakcrumbs in zones modal, 

// H5 {
// 	Font-size: 16px
// 	Color: “TertiaryGrey”
// } // now playing label


// Filters {
// 	text size: 14
// 	Placeholder text: #64636C	
	
// 	Active Filter: bubble with text {
// 		background color: OffWhite
// 		text-color: BackgroundBody
// 		X color: BackgroundBody
// 	Dropdown arrow : OffWhite

// Icon in left column {
// 	tint color: 		TertiaryGrey
// 	illuminated hover: 	PrimaryWhite
// 	selected: 		TaleaYellow
// 	}

// Filter Underline {
// 	Color of underline: 	FilterLine
// 	Thickness of line: 	1px
// 	}

// Left Menu
// 	Section headers {
// 		color: 		TertiaryGrey
// 		font-size: 	10px

// Pinned Playlist Tray {
// 	Title: PrimaryWhite
// 	Created by: OffWhite
	
// 	Song Titles: Primary White
// 	Artist/Album Names: OffWhite
// 	}


// KEEPING MAJORITY OF ORIGINAL MODAL FOR JULY 1 ->
// Modals {
// 	Modal Header {
// 		font-size: 42px
// 		Color:	“PrimaryWhite”

// 	Modal Secondary Header {
// 		font-size: 24px	
// 		color: 	“TertiaryGrey”
// 		}
	
// 	Modal Dropdown {
// 		Keep default
// 	}

// 	Modal Multi-select {
// 		Keep default
// 	}

// 	Modal text input {
// 		background-color: 	“InputBackground”
		

// Tables	
// 	Rows {
// 		Hover color:	2A2833
// 		Selection color: 7E7B91
// 	}	
	
// 	Text in Rows {
// 		color: white
// 		font-size: 14
// 	}
}