import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
import styled from "styled-components";
import {
    DropdownFilter, SelectionContextMenuSuggested, SingleContextMenu, TaleaContextMenu
} from '_components/Standard';

import {AddToMultipleButton, SortSuggestedButton} from '_styles';
import {Col, Container, Row} from "react-bootstrap";
import {SortSuggestedOptionsDialog} from "./SortSuggestedOptionsDialog";
import {playlistActions} from "../_actions";
import {ClientDropdownFilter} from "./ClientDropdownFilter";

const SaveMultiple = styled.div`
  display: flex;
  cursor: ${props => props.enabled ? "pointer" : "not-allowed"};
  height: 28px;
  width: 100px;
  padding: 0px 8px;
  margin: 8px auto;
  border-radius: 8px;
  border: 1px solid ${props => props.enabled ? "orange" : "#4B4B4B"};
  background-color: ${props => props.enabled ? "orange" : "transparent"};
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: all 250ms linear;
`;

const Title = styled.div`
  color: ${props => props.enabled ? "white" : "#4B4B4B"};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 6px;
  transition: all 220ms linear;
`;

class AddToMultiple extends React.Component {
    state = {
        selectedIds: [],
        showSortSuggestionDialog: false,
        showSuggestions: true,
        clientFilter: [],
        genreTagFilter: [],
        scheduleTagFilter: [],
        activeFilter: true

    }

    itemSelected = item => {
        const {selectedIds} = this.state;
        const update = selectedIds.includes(item.playlist2Id) ? selectedIds.filter(id => id !== item.playlist2Id) : [...selectedIds, item.playlist2Id];
        this.setState({selectedIds: update});
    }

    selected = () => {
        return this.props.playlists.filter(p => this.state.selectedIds.includes(p.id));
    }

    onSave = () => {
        this.props.onSave(this.selected());
    }
    handleFilterActive = (option) => {
        console.log(option);
    };
    handleFilterGenreTags = (option) => {
        console.log(option);
    };
    handleFilterScheduleTags = (option) => {
        console.log(option);
    };
    handleClientSelect = (client) => {
        this.setState({clientFilter: client ? [client.id] : []});
    };

    render() {
        const hasSelection = this.state.selectedIds.length > 0;

        const shortSuggestionButtonClicked = () => {
            this.props.showSongSuggestionAction(true);
        }

        let filteredData = this.props.suggestionData ? this.props.suggestionData.filter(playlistData => {
            return this.state.clientFilter.length === 0 || this.state.clientFilter.includes(playlistData.clientId);
        }) : undefined;

        return (<TaleaContextMenu style={{width: this.props.suggestionData ? 1200 : "auto"}}>
            <Container fluid>
                <Row>
                    <Col xs={9}>
                        <SelectionContextMenuSuggested playlistId={this.props.playlistId}
                                                       songsData={this.props.songsData}
                                                       suggestionData={filteredData}
                                                       items={this.props.playlists}
                                                       selectedIds={this.state.selectedIds}
                                                       onListItemClicked={this.itemSelected}/>
                    </Col>
                    <Col style={{borderLeft: "1px solid #7E7E7E", paddingLeft: 50}} xs={3}>
                        <Row>
                            <SortSuggestedButton
                                onClick={shortSuggestionButtonClicked.bind(this)}> </SortSuggestedButton>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <ClientDropdownFilter withoutCurrent={true} withMaster={true} clientSelect={this.handleClientSelect}/>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <DropdownFilter onSelect={this.handleFilterActive} placeholder="Active"
                                            options={[{id: 0, text: 'Active', start: 0, end: 60},]}/>
                        </Row>
                    </Col>
                </Row>
                <SaveMultiple onClick={hasSelection ? this.onSave : () => {
                }} enabled={hasSelection} data-tip data-for='multiple'>
                    <Title enabled={hasSelection}>Add</Title>
                    <FontAwesomeIcon icon="layer-plus" color={hasSelection ? "white" : "#4B4B4B"}/>
                </SaveMultiple>
                {hasSelection ? (<ReactTooltip effect='solid' place="right" type='light' id='multiple'>
                    {this.selected().map(z => <div key={z.id}>{z.text}</div>)}
                </ReactTooltip>) : (<ReactTooltip effect='solid' place="right" type='info' id='multiple'>
                    Nothing selected
                </ReactTooltip>)}
            </Container>
        </TaleaContextMenu>)
    }
}

export const AddToMultipleWithSuggestionsComponent = ({songsData, onSave, playlists, playlistId}) => {
    const [showSortSuggestionDialog, setShowSortSuggestionDialog] = useState(false);
    const [suggestionData, setSuggestionData] = useState(undefined)

    useEffect(() => {
        const fetchData = async () => {
            if (playlistId) {
                let data = await playlistActions.getSuggestedSimilarity(playlistId);
                setSuggestionData(data);
            }
        }
        fetchData();
    }, [playlistId]);
    let onSortSuggestedDone = async (sortByType, targetPlaylist, clients, minimumTracks, minimumPercentage) => {
        setShowSortSuggestionDialog(false);
        let data = await playlistActions.getSuggestedSimilarity(targetPlaylist);
        setSuggestionData(data);
    };

    return (<>
        <SingleContextMenu playlistId={playlistId}
                           hide={!showSortSuggestionDialog}
                           triggerComponent={<AddToMultipleButton enabled={songsData.length > 0}
                                                                  count={songsData.length}/>}
                           offset={{top: 0, left: 0}}>
            {({hide}) => <AddToMultiple playlistId={playlistId}
                                        songsData={songsData}
                                        showSongSuggestionAction={setShowSortSuggestionDialog}
                                        suggestionData={suggestionData}
                                        onSave={(pl) => onSave(pl, hide)}
                                        playlists={playlists}/>}
        </SingleContextMenu>
        {showSortSuggestionDialog && (
            <SortSuggestedOptionsDialog playlistId={playlistId}
                                        playlists={playlists} onBack={() => setShowSortSuggestionDialog(false)}
                                        onOptionsSelected={onSortSuggestedDone}></SortSuggestedOptionsDialog>)}
    </>);
}