import {playlistConstants} from "_constants";
import {playlistService} from "_services";
import {alertActions} from "./alert.actions";

export const playlistActions = {
    setActivePlaylistId,
    setSelectedPlaylistId,
    clearSelectedPlaylistId,
    get,
    getSimple,
    clearActivePlaylistId,
    getPlaylistSchedules,
    getPlaylistZones,
    editPlaylistDetails,
    getPlaylist,
    createPlaylist,
    deletePlaylist,
    addSongs,
    deleteSongs,
    publish,
    revert,
    duplicate,
    getCalendarView,
    getSuggestedSimilarity,
    getSimilar,
    getHistory
};

function setActivePlaylistId(playlistId) {
    return {type: playlistConstants.SET_ACTIVE_PLAYLIST_ID, playlistId: parseInt(playlistId)};
}

function setSelectedPlaylistId(playlistId) {
    return {type: playlistConstants.SET_SELECTED_PLAYLIST_ID, playlistId: parseInt(playlistId)};
}

function clearSelectedPlaylistId() {
    return setSelectedPlaylistId(-1);
}

function clearActivePlaylistId() {
    return setActivePlaylistId(-1);
}

async function editPlaylistDetails(dispatch, playlistId, name, playlistGroup, description, tags, internalDescription, allowedExplicitType) {
    dispatch({
        type: playlistConstants.EDIT_DETAILS_REQUEST,
        id: playlistId,
        name,
        playlistGroup,
        description,
        tags,
        internalDescription,
        allowedExplicitType
    });

    try {
        await playlistService.editPlaylistDetails(playlistId, name, playlistGroup, description, tags, internalDescription, allowedExplicitType);
        dispatch({
            type: playlistConstants.EDIT_DETAILS_SUCCESS,
            id: playlistId,
            name,
            playlistGroup,
            description,
            tags,
            internalDescription,
            allowedExplicitType
        });
    } catch (e) {
        dispatch({type: playlistConstants.EDIT_DETAILS_SUCCESS, id: playlistId, error: e.toString()});
        throw e;
    }
}

async function duplicate(dispatch, playlistId, name, description, tags, client) {
    dispatch({type: playlistConstants.DUPLICATE_REQUEST, id: playlistId, name, description, tags, client});
    try {
        await playlistService.duplicate(playlistId, name, description, tags, client);
        dispatch({type: playlistConstants.DUPLICATE_SUCCESS, id: playlistId, name, description, tags});
    } catch (e) {
        dispatch({type: playlistConstants.DUPLICATE_FAILURE, id: playlistId, error: e.toString()});
        throw e;
    }
}


function getPlaylistSchedules(playlistId) {
    return dispatch => {
        dispatch({type: playlistConstants.GET_SCHEDULES_REQUEST, id: playlistId});

        playlistService.getSchedules(playlistId).then(
            schedules => {
                dispatch({type: playlistConstants.GET_SCHEDULES_SUCCESS, id: playlistId, schedules});
            },
            error => {
                dispatch({type: playlistConstants.GET_SCHEDULES_FAILURE, id: playlistId, error: error.toString()});
            }
        );
    };
}

function getPlaylistZones(playlistId) {
    return dispatch => {
        dispatch({type: playlistConstants.GET_ZONES_REQUEST, id: playlistId});

        playlistService.getZones(playlistId).then(
            zones => {
                dispatch({type: playlistConstants.GET_ZONES_SUCCESS, id: playlistId, zones});
            },
            error => {
                dispatch({type: playlistConstants.GET_ZONES_FAILURE, id: playlistId, error: error.toString()});
            }
        );
    };
}

function get() {
    return dispatch => {
        dispatch(request());

        playlistService.get().then(
            playlist => {
                dispatch(success(playlist));
                dispatch(alertActions.success())
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return {type: playlistConstants.GET_REQUEST};
    }

    function success(playlist) {
        return {type: playlistConstants.GET_SUCCESS, playlist};
    }

    function failure(error) {
        return {type: playlistConstants.GET_FAILURE, error};
    }
}

function getSimple() {
    return dispatch => {
        dispatch(request());

        playlistService.getSimple().then(
            playlist => {
                dispatch(success(playlist));
                dispatch(alertActions.success())
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return {type: playlistConstants.GET_REQUEST};
    }

    function success(playlist) {
        return {type: playlistConstants.GET_SUCCESS, playlist};
    }

    function failure(error) {
        return {type: playlistConstants.GET_FAILURE, error};
    }
}

function getCalendarView(clientId) {
    return dispatch => {
        dispatch(request());

        playlistService.getCalendarView(clientId).then(
            playlist => {
                dispatch(success(playlist));
                dispatch(alertActions.success())
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return {type: playlistConstants.GET_CALENDAR_REQUEST};
    }

    function success(playlist) {
        return {type: playlistConstants.GET_CALENDAR_SUCCESS, playlist};
    }

    function failure(error) {
        return {type: playlistConstants.GET_CALENDAR_FAILURE, error};
    }
}

async function getPlaylist(dispatch, id) {
    if (!id) return;

    dispatch({type: playlistConstants.GET_DETAIL_REQUEST, id});
    try {
        const playlist = await playlistService.getOne(id);
        dispatch({type: playlistConstants.GET_DETAIL_SUCCESS, playlist});
        return playlist;
    } catch (e) {
        dispatch({type: playlistConstants.GET_DETAIL_FAILURE, id, error: e.toString()})
        throw e;
    }
}

async function createPlaylist(dispatch, playlist) {
    dispatch({type: playlistConstants.CREATE_PLAYLIST_REQUEST});

    try {
        const playlistResponse = await playlistService.createPlaylist(playlist);
        dispatch({type: playlistConstants.CREATE_PLAYLIST_SUCCESS, playlist: playlistResponse})
        return playlistResponse;
    } catch (error) {
        dispatch({type: playlistConstants.CREATE_PLAYLIST_FAILURE, error: error.toString()});
        throw error;
    }
}

async function deletePlaylist(dispatch, id) {
    dispatch({type: playlistConstants.DELETE_PLAYLIST_REQUEST});
    try {
        const playlist = await playlistService.deletePlaylist(id);
        dispatch({type: playlistConstants.DELETE_PLAYLIST_SUCCESS, playlist});
        return playlist;
    } catch (error) {
        dispatch({type: playlistConstants.DELETE_PLAYLIST_FAILURE, error: error.toString()});
        throw error;
    }

}

async function addSongs(dispatch, playlistId, songIds) {
    if (playlistId === -1) {
        // Don't report error (quietly ignore)
        return;
    }

    dispatch({type: playlistConstants.ADD_SONGS_REQUEST, playlistId, songIds});

    try {
        const response = await playlistService.addSongs(playlistId, songIds);
        dispatch({type: playlistConstants.ADD_SONGS_SUCCESS, playlistId, response});
        return response;
    } catch (e) {
        dispatch({type: playlistConstants.ADD_SONGS_FAILURE, playlistId, error: e.toString()})
        throw e;
    }
}

async function deleteSongs(dispatch, playlistId, songIds) {
    dispatch({type: playlistConstants.DELETE_SONGS_REQUEST, playlistId, songIds});

    try {
        const response = await playlistService.deleteSongs(playlistId, songIds);
        dispatch({type: playlistConstants.DELETE_SONGS_SUCCESS, playlistId, response});
        return response;
    } catch (e) {
        dispatch({type: playlistConstants.DELETE_SONGS_FAILURE, playlistId, songIds, error: e.toString()})
        throw e;
    }
}

async function publish(dispatch, playlistId) {
    dispatch({type: playlistConstants.PUBLISH_REQUEST, playlistId});
    try {
        const playlist = await playlistService.publish(playlistId);

        // HACK SERVER FIX FIX TODO - these values should be zero from server after publish
        playlist.added = 0;
        playlist.removed = 0;

        dispatch({type: playlistConstants.PUBLISH_SUCCESS, playlist});
        return playlist;
    } catch (e) {
        dispatch({type: playlistConstants.PUBLISH_FAILURE, playlistId, error: e.toString()})
        throw e;
    }
}

async function revert(dispatch, playlistId) {
    dispatch({type: playlistConstants.REVERT_REQUEST});

    try {
        const playlist = await playlistService.revert(playlistId);
        dispatch({type: playlistConstants.REVERT_SUCCESS, id: playlistId, playlist});
        return playlist;
    } catch (e) {
        dispatch({type: playlistConstants.REVERT_FAILURE, id: playlistId, error: e.toString()});
        throw e;
    }
}

function getSuggestedSimilarity(playlistId) {
    return playlistService.getSuggestedSimilarity(playlistId);
}

function getSimilar(playlistId) {
    return dispatch => {
        dispatch(request());
        playlistService.getSuggestedSimilarity(playlistId).then(
            similarPlaylists => {
                dispatch(success(similarPlaylists));
                dispatch(alertActions.success())
            },
            error => {
                dispatch(failure(error.toString()));
            }
        )
    };

    function request() {
        return {type: playlistConstants.GET_SIMILAR_REQUEST};
    }

    function success(similarPlaylists) {
        return {type: playlistConstants.GET_SIMILAR_SUCCESS, similarPlaylists};
    }

    function failure(error) {
        return {type: playlistConstants.GET_SIMILAR_FAILURE, error};
    }
}

function getHistory(playlistId) {
    return dispatch => {
        dispatch(request());
        playlistService.getHistory(playlistId).then(
            playlistHistory => {
                dispatch(success(playlistHistory));
                dispatch(alertActions.success())
            },
            error => {
                dispatch(failure(error.toString()));
            }
        )
    };

    function request() {
        return {type: playlistConstants.GET_HISTORY_REQUEST};
    }

    function success(playlistHistory) {
        return {type: playlistConstants.GET_HISTORY_SUCCESS, playlistHistory};
    }

    function failure(error) {
        return {type: playlistConstants.GET_HISTORY_FAILURE, error};
    }
}