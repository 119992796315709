import {createCanBoundTo} from "@casl/react";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Select from "react-select";
import styled, {ThemeProvider} from "styled-components";
import {alertActions, clientActions, userActions, locationActions} from "_actions";
import image from "_assets/images/elena-saharova.jpg";
import {getAbility} from "_helpers";
import {
    CheckboxButton,
    CreationForms,
    DefaultBlackFormControl,
    DefaultColumnContainer,
    DefaultLabel,
    DefaultRowContainer,
    FormRadioButton,
    Helper,
    theme
} from "_styles";
import {ValidateEmail} from "./Utils/ValidateEmail";
import get from 'lodash.get';
import {ModalDecisionBuilder} from "./Utils";
import {Col, Row} from "react-bootstrap";

const DetailsContainer = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
`;

const Label = styled(DefaultLabel)`
    color: white;
    font-size: 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
`;

const CheckBoxLabel = styled(DefaultLabel)`
    color: white;
    padding-top: 12px;
    font-size: 14px;
`;


const RowContainer = styled(DefaultRowContainer)`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
`;

const HalfFormContainer = styled(DefaultColumnContainer)`
    width: 49%;
    align-items: flex-start;
`;

const HalfForm = styled(DefaultBlackFormControl)`
    margin-top: 5px;
`;

const FormContainerWithoutHelper = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;
`;

const FormLabel = styled(DefaultLabel)`
    color: ${props => props.theme.formlabel};
    font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
    margin-top: 5px;
`;

const RSelect = styled(Select)`
    width: 100%;
    margin-top: 10px;
`;

const RCheckbox = styled(CheckboxButton)`
    width: 100%;
    margin-top: 10px;
`;

const ability = getAbility();
const Can = createCanBoundTo(ability);

const UserTypes = {
    "VIEW_ONLY": "View Only",
    "BRAND_MANAGER": "Brand Manager",
    "PROPERTY_MANAGER": "Property Manager"
};
const UserTypeOptions = Object.values(UserTypes);

const getOptionFromType = type => UserTypes[type] ? UserTypes[type] : UserTypes['VIEW_ONLY'];
const getTypeFromOption = option => get(Object.entries(UserTypes).find(e => e[1] === option), '[0]') || UserTypes['VIEW_ONLY'];

const ALL_LOCATIONS_INDEX = -1;
const AllLocationsOption = {value: ALL_LOCATIONS_INDEX, label: "All Locations"};
const getLocationOptions = locations => locations.sort((a, b) => a.name.localeCompare(b.name)).map(location => ({
    value: location.id,
    label: location.name
}));

const ALL_ZONES_INDEX = -1;
const AllZonesOption = {value: ALL_ZONES_INDEX, label: "All Zones"};
const getZoneOptions = zones => zones.map(zone => ({
    value: zone.id,
    label: zone.name
}));

const CreateClientUser = ({
                              user = undefined,
                              onBack,
                              onCreate,
                              onArchive,
                              dispatch,
                              clients,
                              locations: propsLocations
                          }) => {
    const isEditing = user !== undefined;

    const [userType, setUserType] = useState(isEditing ? getOptionFromType(user.userType) : 'View Only');
    const [firstName, setFirstName] = useState(isEditing ? user.firstName : '');
    const [lastName, setLastName] = useState(isEditing ? user.lastName : '');
    const [canChangeVolume, setCanChangeVolume] = useState(isEditing ? user.canChangeVolume : false);
    const [canVoteSongs, setCanVoteSongs] = useState(isEditing ? user.canVoteSongs : true);
    const [canChangePlaylist, setCanChangePlaylist] = useState(isEditing ? user.canChangePlaylist : true);
    const [phone, setPhone] = useState(isEditing ? user.phoneNumber : '');
    const [email, setEmail] = useState(isEditing ? user.email : '');
    const [password, setPassword] = useState('');
    const [client, setClient] = useState(isEditing ? {
        value: user.clientExternal.id,
        label: user.clientExternal.name
    } : undefined);
    const [locations, setLocations] = useState(isEditing ? (user.allLocations ? [AllLocationsOption] : getLocationOptions(user.locationsExternal)) : []);
    const [zones, setZones] = useState(isEditing ? (user.allZones ? [AllZonesOption] : getZoneOptions(user.zonesExternal)) : []);
    const [zonesOptions, setZonesOptions] = useState(client && isEditing && !user.allZones ? [AllZonesOption].concat(getZoneOptions(user.zonesExternal)) : []);
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [archiving, setArchiving] = useState(false);
    const [confirmArchiving, setConfirmArchiving] = useState(false);

    useEffect(() => {
        userActions.getUsers(dispatch);
        clientActions.getAllClients(dispatch);
        dispatch(locationActions.get());
    }, []);

    const getAllLocationsIndex = entries => entries.findIndex(entry => entry.value === ALL_LOCATIONS_INDEX);
    const allLocationsSelected = entries => getAllLocationsIndex(entries) !== -1;

    const getAllZonesIndex = entries => entries.findIndex(entry => entry.value === ALL_ZONES_INDEX);
    const allZonesSelected = entries => getAllZonesIndex(entries) !== -1;

    const handleLocationSelect = e => {

        const allLocationsIndex = getAllLocationsIndex(e);
        if (e.length === 0) {
            setLocations([]);
            setZones([]);
            setZonesOptions([]);
            return;
        }

        if (allLocationsIndex === e.length - 1) {
            // Just added, clear all other entries
            const locations = [e[e.length - 1]];
            setLocations(locations);
            const zonesOptions = [AllZonesOption].concat(getZoneOptions(clientLocations.flatMap(l => l.zones)));
            setZones([]);
            setZonesOptions(zonesOptions)
            return;
        }

        if (e.length > 1 && allLocationsIndex !== -1) {
            const locations = e.splice(1);
            setLocations(locations);
            const locationIds = locations.map(l => l.id);
            const locationsFiltered = clientLocations.filter(loc => locationIds.includes(loc.id));
            const zonesOptions = [AllZonesOption].concat(getZoneOptions(locationsFiltered.flatMap(l => l.zones)));
            setZones([]);
            setZonesOptions(zonesOptions);
            return;
        }

        setLocations(e);
        const locationIds = e.map(l => l.value);
        const locationsFiltered = clientLocations.filter(loc => locationIds.includes(loc.id));
        const locationZones = locationsFiltered.flatMap(l => l.zones);
        setZones([]);
        setZonesOptions([AllZonesOption].concat(getZoneOptions(locationZones)));
    };

    const handleZonesSelect = e => {
        const allZonesIndex = getAllZonesIndex(e);
        if (allZonesIndex === e.length - 1) {
            // Just added, clear all other entries
            setZones([e[e.length - 1]])
            return;
        }

        if (e.length > 1 && allZonesIndex !== -1) {
            setZones(e.splice(1));
            return;
        }

        setZones(e);
    };

    const submit = async () => {
        if (!ValidateEmail(email)) {
            setError('Please insert a valid email');
            return;
        }

        if (isEditing && email !== user.email && !password) {
            setError('When changing the email password is required');
            return;
        }

        if (firstName === "" || lastName === "" || email === "" || (!isEditing && password === "") || client === "") {
            setError('Please fill out all the required fields');
            return;
        }

        const idComposite = isEditing ? {
            id: user.id
        } : {};

        const isAllLocationsSelected = allLocationsSelected(locations);
        const locationsComposite = !isAllLocationsSelected ? {
            locationsExternal: locations.map(el => ({id: el.value})),
        } : {};

        const isAllZonesSelected = allZonesSelected(zones);
        const zonesComposite = !isAllZonesSelected ? {
            zonesExternal: zones.map(el => ({id: el.value})),
        } : {};

        const userData = {
            ...idComposite,
            ...locationsComposite,
            ...zonesComposite,
            allLocations: isAllLocationsSelected,
            allZones: isAllZonesSelected,
            canChangeVolume: canChangeVolume,
            canVoteSongs: canVoteSongs,
            canChangePlaylist: canChangePlaylist,
            firstName,
            lastName,
            phoneNumber: phone,
            ...(password.trim() !== "") && {password},
            ...(!isEditing || (email.trim() !== user.email.trim())) && {email},
            clientExternal: {id: client.value},
            internal: false,
            userType: getTypeFromOption(userType)
        };

        setSubmitting(true);
        setError('');

        try {
            isEditing ? await userActions.updateOne(dispatch, userData, user.id) : await userActions.addOne(dispatch, userData);
            setSubmitting(false);
            alertActions.notificationSuccess(dispatch, isEditing ? "Client User Updated" : "Client User Created", `${firstName} ${lastName}`);
            onCreate();
        } catch (e) {
            setError(e.userMessage || e.toString());
            setSubmitting(false);
        }
    }

    const clientLocations = client ? propsLocations.filter(l =>
        l.client.id === client.value
    ) : [];

    const locationOptions = client ? [AllLocationsOption].concat(getLocationOptions(clientLocations)) : [];

    const closeDeleteConfirmationDialog = async () => {
        setConfirmArchiving(false);
    }

    const handleArchive = async () => {
        if (confirmArchiving)
            return;
        setConfirmArchiving(true);
    }

    const doArchiving = async () => {
        if (archiving)
            return;
        setArchiving(true);
        setError('');
        setConfirmArchiving(false);

        try {
            await userActions.archive(dispatch, user.id);
            setArchiving(false);
            alertActions.notificationSuccess(dispatch, "User archived", user.email);
            onArchive();
        } catch (e) {
            setError(e.userMessage);
            setArchiving(false);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CreationForms
                message={`This will ${isEditing ? "edit a" : "create a new"} client user, please check the information entered above is correct.`}
                handleBackButton={onBack}
                handleSubmit={submit}
                submitting={submitting}
                onDelete={isEditing ? handleArchive : null}
                alert={error}
                image={image}
                title={isEditing ? "Edit Client User" : "Create Client User"}
            >
                <Label>Choose User Type</Label>
                <FormRadioButton
                    handleChange={setUserType}
                    name={`client-user-type-button-group`}
                    value={userType}
                    options={UserTypeOptions}
                />
                <DetailsContainer>
                    <Label>Enter User Details</Label>
                    <RowContainer>
                        <HalfFormContainer>
                            <FormLabel>FIRST NAME</FormLabel>
                            <HalfForm
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                            />
                        </HalfFormContainer>
                        <HalfFormContainer>
                            <FormLabel>LAST NAME</FormLabel>
                            <HalfForm
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                            />
                        </HalfFormContainer>
                    </RowContainer>
                    <FormContainerWithoutHelper>
                        <FormLabel>PHONE NUMBER</FormLabel>
                        <Form
                            type="text"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                        <Helper message="You can choose this later"/>
                    </FormContainerWithoutHelper>
                    <FormContainerWithHelper>
                        <FormLabel>EMAIL</FormLabel>
                        <Form
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Helper
                            message={isEditing ? "To change the email you must set the password too" : "The email address will be used as the username"}/>
                    </FormContainerWithHelper>
                    <FormContainerWithHelper>
                        <FormLabel>PASSWORD</FormLabel>
                        <Form
                            type="text"
                            name="password"
                            placeholder={isEditing ? "Change password" : "Password"}
                            value={password}
                            onChange={e => {
                                const value = e.target.value.trim();
                                console.log(value);

                                if (value.length !== 0 || password.length === 1) {
                                    if (value.indexOf(' ') === -1) {
                                        setPassword(value);
                                    }
                                }
                            }}
                        />
                        <Helper message="Enter a temporary password for the user to change"/>
                    </FormContainerWithHelper>
                    <Can I="edit" a="client user">
                        <DetailsContainer>
                            <Label>Choose client associated with user</Label>
                            <RSelect
                                options={clients.sort((a, b) => a.name.localeCompare(b.name)).map(client => ({
                                    value: client.id,
                                    label: client.name
                                }))}
                                name="client-list"
                                onChange={e => {
                                    setClient(e);
                                    setLocations([]);
                                }}
                                value={client}
                            />
                            <Helper message="Enter one client"/>
                        </DetailsContainer>
                    </Can>
                    <DetailsContainer>
                        <Label>Locations this user can manage</Label>
                        <RSelect
                            isDisabled={client === undefined}
                            options={locationOptions}
                            isMulti
                            name="location-list"
                            onChange={handleLocationSelect}
                            value={locations}
                        />
                        <Helper message="You can add more than one location"/>
                    </DetailsContainer>
                    <DetailsContainer>
                        <Label>Zones this user can manage</Label>
                        <RSelect
                            isDisabled={client === undefined}
                            options={zonesOptions}
                            isMulti
                            name="location-list"
                            onChange={handleZonesSelect}
                            value={zones}
                        />
                        <Helper message="You can add more than one zone"/>
                    </DetailsContainer>
                    <DetailsContainer>
                        <Label>User is allowed to:</Label>
                        <Row noGutters>
                            <Col noGutters>
                                <RCheckbox
                                    checked={canChangeVolume}
                                    onChange={e => {
                                        setCanChangeVolume(e.target.checked)
                                    }}
                                />
                            </Col>
                            <Col>
                                <CheckBoxLabel>Change volume</CheckBoxLabel>
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col noGutters>
                                <RCheckbox
                                    checked={canVoteSongs}
                                    onChange={e => {
                                        setCanVoteSongs(e.target.checked)
                                    }}
                                />
                            </Col>
                            <Col>
                                <CheckBoxLabel>Vote songs</CheckBoxLabel>
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col noGutters>
                                <RCheckbox
                                    checked={canChangePlaylist}
                                    onChange={e => {
                                        setCanChangePlaylist(e.target.checked)
                                    }}
                                />
                            </Col>
                            <Col>
                                <CheckBoxLabel>Change playlist</CheckBoxLabel>
                            </Col>
                        </Row>
                    </DetailsContainer>
                </DetailsContainer>
                {confirmArchiving && (
                    <ModalDecisionBuilder
                        title={"Are you sure you want to archive this user?"}
                        handleClose={closeDeleteConfirmationDialog}
                        handleAccept={doArchiving}
                    />
                )}
            </CreationForms>
        </ThemeProvider>
    )
        ;
}

const mapStateToProps = state => ({
    users: state.users.users,
    clients: state.client.client,
    locations: state.location.location
});

const connectedCreateClientUser = withRouter(
    connect(mapStateToProps)(CreateClientUser)
);
export {connectedCreateClientUser as CreateClientUser};

