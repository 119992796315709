import get from 'lodash.get';
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {userActions} from '_actions';
import clients_icon from "_assets/images/overview/clients_icon.png";
import location_icon from "_assets/images/overview/location_icon.png";
import playlist_icon from "_assets/images/overview/playlist_icon.png";
import schedule_icon from "_assets/images/overview/schedule_icon.png";
import {FilterSection, RowSpaced, SectionHeader, SectionTitle} from "_components";
import {AreaCard, DefaultColumnContainer, ListUsersCard, ProfileCard} from "_styles";


const OverviewContainer = styled.div`
  height: 600px;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 10px;
  margin: 15px;
  background-color: ${props => props.theme.main};
  height: 100%;
`;

const InnerGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
`;

const Card = styled(DefaultColumnContainer)`
  width: 100%;
  background-image: linear-gradient(#464252, #232128);
  padding: 40px 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
`;

const SmallCard = styled(DefaultColumnContainer)`
  width: 100%;
  background-image: linear-gradient(#464252, #232128);
  padding: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
`;

class Overview extends React.Component {
    state = {}

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.props.dispatch(userActions.getUser(user.userId));

        userActions.getDashboard(this.props.dispatch, user.userId);
    }

    goTo = place => {
        this.props.history.push("/" + place);
    };

    render() {
        const {user} = this.props;

        const online = get(this.props.dashboard, 'onlinePlayers', 0);
        const offline = get(this.props.dashboard, 'offlinePlayers', 0);
        const inactive = get(this.props.dashboard, 'inactivePlayers', 0);
        const unassigned = get(this.props.dashboard, 'unassignedPlayers', 0);
        const total = get(this.props.dashboard, 'playerCount', 0);

        return (
            <OverviewContainer>
                <SectionHeader>
                    <RowSpaced>
                        <SectionTitle>Home</SectionTitle>
                    </RowSpaced>
                    <FilterSection>
                        {/* <SubTitle>Last login {this.state.overview.lastLogin}</SubTitle> */}
                    </FilterSection>
                </SectionHeader>
                <CardContainer>
                    <Card onClick={() => this.goTo("devices")}>
                        <ListUsersCard data={[
                            {
                                label: "Players online",
                                value: online,
                                stroke: "green"
                            },
                            {
                                label: "Players offline",
                                value: offline,
                                stroke: "red"
                            },
                            {
                                label: "Players unassigned",
                                value: unassigned,
                                stroke: "lightblue"
                            },
                            {
                                label: "Players inactive",
                                value: inactive,
                                stroke: "darkgrey"
                            }

                        ]}
                        />
                    </Card>
                    <InnerGridContainer>
                        <SmallCard onClick={() => this.goTo("clients")}>
                            <AreaCard image={clients_icon} text="Clients"/>
                        </SmallCard>
                        <SmallCard onClick={() => this.goTo("playlists")}>
                            <AreaCard image={playlist_icon} text="Playlists"/>
                        </SmallCard>
                        <SmallCard onClick={() => this.goTo("zones")}>
                            <AreaCard image={location_icon} text="Zones"/>
                        </SmallCard>
                        <SmallCard onClick={() => this.goTo("schedules")}>
                            <AreaCard image={schedule_icon} text="Schedules"/>
                        </SmallCard>
                    </InnerGridContainer>
                    <Card>
                        <ProfileCard
                            name={`${user.firstName} ${user.lastName}`}
                            title={user.userType}
                        />
                    </Card>
                </CardContainer>
            </OverviewContainer>
        );
    }
}

const mapStateToProps = state => ({
    user: state.users.profileUser,
    dashboard: state.users.dashboard
});

const connectedOverview = withRouter(connect(mapStateToProps)(Overview));
export {connectedOverview as Overview};

