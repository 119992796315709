import React from "react";
import {Button} from "react-bootstrap";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "./Theme";

const CustomButton = styled(Button)`
  color: ${props => props.theme.highlight};
  background-color: transparent;
  border: 1px solid ${props => props.theme.highlight};
  text-decoration: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  box-shadow: unset;
  letter-spacing: 1px;
  height: 50px;

  cursor: pointer;

  &:focus,
  &:disabled:focus {
    color: white;
    background-color: ${props => props.theme.highlight};
    outline: none;
    box-shadow: unset;
    border: 1px solid ${props => props.theme.highlight};
  }

  &:hover {
    color: white;
    background-color: ${props => props.theme.highlight};
    outline: none;
    box-shadow: unset;
    border: 1px solid ${props => props.theme.highlight};
  }

  &:active {
    color: white;
    background-color: ${props => props.theme.highlight};
    outline: none;
    box-shadow: unset;
    border: 1px solid ${props => props.theme.highlight};
  }

  &:active:focus,
  &.active:focus,
  &:active:hover,
  &.active:hover {
    outline: none;
    color: white;
    background-color: ${props => props.theme.highlight};
    border: 1px solid ${props => props.theme.highlight};
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    color: black;
    background-color: white;
    opacity: 0.65;
    filter: alpha(opacity=65);
  }
`;

export const DefaultButton = props => (
  <ThemeProvider theme={theme}>
    <CustomButton {...props} />
  </ThemeProvider>
);

export const FilledDefaultButton = styled(DefaultButton)`
  background-color: ${props => props.theme.highlight};
  border: 0;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.lightYellow};
    border: 0;
    color: white;
  }

`

export const DefaultCancelButton = styled(DefaultButton)`
  background-color: ${props => props.theme.darkGray};
  border: 0;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.optionalGray};
    border: 0;
    color: white;
  }
`;

export const DefaultGrayButton = styled(DefaultButton)`
  background-color: ${props => props.theme.optionalGray};
  border: 0;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.darkGray};
    border: 0;
    color: white;
  }
`;
