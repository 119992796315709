import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { locationActions } from "_actions";
import {
  ClientDropdownFilter,
  CreateLocation,
  FilterSection,
  PrimaryButton,
  RowSpaced,
  SearchFilter,
  SectionGutter,
  SectionHeader,
  SectionTitle
} from "_components";
import { SortTable } from '_components/Standard';
import { Matches } from '_helpers';

class Location extends React.Component {
  state = {
    showAddLocation: false,
    search: "",
    location: undefined,
    filterClientId: -1
  };

  componentDidMount() {
    this.props.dispatch(locationActions.get());
  }

  handleSearch = filterText => {
    this.setState({search: filterText});
  };

  handleEdit = info => {
    this.setState({showAddLocation: true, location: info});
  };

  closeCreate = () => {
    this.setState({showAddLocation: false, location: undefined});
  }

  successAddLocation = () => {
    this.closeCreate();
    this.props.dispatch(locationActions.get());
  };

  filterClient = (client) => {
    this.setState({filterClientId: client ? client.id : -1});
  }

  render() {
    const {showAddLocation} = this.state;
    const {location = [], loading} = this.props;

    let locationData = location.filter(l => this.state.filterClientId === -1 || l.client.id === this.state.filterClientId);
    locationData = Matches(locationData, this.state.search, ["name", "address"]);
    let imagesUrls = location.filter(l => l.logoUrl).map(l => ({
          clientId: l.client.id,
          logoUrl: l.logoUrl
        }
    ));
    imagesUrls = [...imagesUrls, ...location.filter(l => l.client && l.client.logoUrl).map(l => ({
          clientId: l.client.id,
          logoUrl: l.client.logoUrl
        }
    ))];

    imagesUrls = imagesUrls.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.clientId === current.clientId && item.logoUrl === current.logoUrl)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    return (
        <>
                {showAddLocation && (
                    <CreateLocation editLocation={this.state.location} onArchive={this.successAddLocation}
                        onBack={this.closeCreate} onCreate={this.successAddLocation}
                        imagesUrls={imagesUrls} />
                )}

          <SectionHeader>
                    <RowSpaced>
                        <SectionTitle>Locations</SectionTitle>
                        <PrimaryButton onClick={() => this.setState({showAddLocation: true})}>+ Add
                            Location</PrimaryButton>
                    </RowSpaced>

                    <FilterSection>
                        <SearchFilter onChange={this.handleSearch} value={this.state.search} />
                        <ClientDropdownFilter clientSelect={this.filterClient} />
                    </FilterSection>
                </SectionHeader>

                <SortTable
                    defaultSortField='client.name'
                    loading={loading}
                    rows={locationData}
                    gutter={SectionGutter}
                    onRowClick={this.handleEdit}
                    columns={[
                      {percent: 1, field: 'client.name', title: 'client'},
                      {percent: 1, field: 'name', title: 'location'},
                      {percent: 1, field: 'address', title: 'address'},
                      {percent: 1, field: 'pointOfContact', title: 'contact'},
                      {percent: 1, field: 'zones.length', title: 'zones', isNumber: true}
                    ]}
                />
            </>
    );
  }
}

const mapStateToProps = state => ({
  alert: state.alert,
  loading: state.location.loading,
  location: state.location.location
});

const connectedLocation = withRouter(connect(mapStateToProps)(Location));
export { connectedLocation as Location };

