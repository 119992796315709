import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {userActions} from "_actions";
import {LoginContainer} from "_styles";
import {LoginVerificationContainer} from "../_styles/LoginVerificationContainer.style";


class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            validationCode: "",
            showValidation: false,
            error: ''
        };
    }

    handleChange = e => {
        const {name, value} = e.target;
        this.setState({[name]: value, error: ''});
    };

    handleSubmitLogin = async () => {
        const {username, password} = this.state;

        if (username === "" || password === "") {
            this.setState({error: "Username and password are required"})
            return;
        }

        try {
           const userResponse = await userActions.login2fa(this.props.dispatch, {username, password});
           this.setState({showValidation: true,error: ''});
        } catch (e) {
            this.setState({error: e.toString()})
        }
    };
    handleValidationSubmit = async () => {
        const {validationCode,username} = this.state;
        const {twoFAData} = this.props;
        try {
            await userActions.submit2FACode(this.props.dispatch, {username, twoFACode:validationCode,twoFAId:twoFAData.twoFAId});
            this.props.history.replace("/");
        } catch (e) {
            this.setState({error: e.toString()})
        }

    }

    render() {
        const {username, password, validationCode,showValidation,error} = this.state;

        return (
            !showValidation ?
                <LoginContainer
                    alert={error}
                    username={username}
                    password={password}
                    onChange={this.handleChange}
                    handleSubmit={this.handleSubmitLogin}
                    changeRegister={this.changeRegister}
                /> : <LoginVerificationContainer
                     alert={error}
                     validationCode={validationCode}
                     onChange={this.handleChange}
                     handleSubmit={this.handleValidationSubmit}
                 />
        );
    }
}

const mapStateToProps = state => ({
    loading: state.authentication.loading,
    twoFAData: state.authentication.twoFAData
});

const connectedSignIn = withRouter(connect(mapStateToProps)(SignIn));
export {connectedSignIn as SignIn};
