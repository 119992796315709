import React, {createRef, useEffect, useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {sampleActions} from "../../_actions/sample.actions";
import {Col, Container, Row} from "react-bootstrap";
import {ExpandLess} from "@material-ui/icons";

// Give fixed sized to prevent resizes while numbers update
const TimeLabel = styled.div`
  color: white;
  font-size: 12px;
  margin-left: 8px;
  width: 80px;
  text-align: right;
`;

const TrackName = styled.div`
  margin-top: 25px;
  font-size: 16px;
  color: white;
`;

const ArtistName = styled.div`
  margin-top: 5px;
  color: #787879;
  font-size: 16px;
  text-align: left;
`;


const PlaylistName = styled.div`
  color: white;
  font-size: 12px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: right;
`;

const SongInfoContainer = styled(Container)`
  :hover {
    cursor: pointer;
  }
`
const SongIndex = styled.div`
  color: white;
  font-size: 12px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
  width: 50px;
`;

const PlayPause = styled.div`
  :hover {
    cursor: pointer;
  }

  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 12px 0px 12px;
`;

const AlbumArt = styled.img`
  border: none;
  width: 84px;
  height: 84px;
`;

const Slider = ({value, onChange, ...props}) => {
    const [val, setVal] = useState(0);

    const handleChange = e => {
        const v = e.target.value;
        setVal(v);
        onChange(v);
    }

    useEffect(() => setVal(value), [value]);

    return (
        <input {...props} type="range" min="0" max="100" step="0.01" value={val} onChange={handleChange}/>
    )
}

const AudioPlayer = ({
                         source = '',
                         onTimeUpdate,
                         onPlayStateChange,
                         play,
                         volume,
                         dispatch,
                         timeSent,
                         ...props
                     }) => {
    const [time, setTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const audioRef = createRef();

    const handleTimeUpdate = async e => {
        const ltime = e.target.currentTime || 0;
        const lduration = e.target.duration || 0;
        setTime(ltime);
        setDuration(lduration);
        onTimeUpdate(ltime, lduration);
        await sampleActions.updatePlaybackTime(dispatch, ltime, lduration);
    }

    useEffect(() => {
        const ref = audioRef.current;
        ref.addEventListener('timeupdate', handleTimeUpdate);
        return () => {
            ref.removeEventListener('timeupdate', handleTimeUpdate);
        }
    });

    useEffect(() => {
        if (timeSent) {
            audioRef.current.currentTime = timeSent;
        }
    }, [timeSent]);

    useEffect(() => {
        if (play) audioRef.current.play();
        else audioRef.current.pause();
    }, [play]);

    useEffect(() => {
        audioRef.current.volume = volume * .01;
    }, [volume]);

    const handleChange = val => {
        if (duration === 0) return;
        audioRef.current.play();
        audioRef.current.currentTime = val * .01 * duration;
    }

    const playingHandler = () => {
        onPlayStateChange(false);
    }

    const pausedHandler = () => {
        onPlayStateChange(true);
    }

    return (
        <>
            <audio ref={audioRef} src={source} controls={false} autoPlay onPlay={playingHandler}
                   onPause={pausedHandler}/>
        </>
    )
}

const pad = (num, size) => {
    let s = "000000000" + num;
    return s.substr(s.length - size);
}

const minutesSecondsString = seconds => {
    const minutes = Math.floor(seconds / 60);
    const sec = pad(Math.floor(seconds % 60), 2);
    return `${minutes}:${sec}`;
}

const transparent = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

function shortenMobile(title) {
    if (window.innerWidth < 768) {
        return (title.length > 20) ? title.substr(0, 20 - 1) + "..." : title;
    } else {
        return title;
    }
}

export const TaleaAudioPlayerSamples = (props) => {
    const {
        showSongDetails,
        source = '',
        title = '',
        artist = '',
        artworkUrl = '',
        playlistName,
        nextSong,
        playing,
        dispatch,
        timeSent
    } = props;
    const [timeString, setTimeString] = useState('0:00');
    const [durationString, setDurationString] = useState('0:00');
    const [volume, setVolume] = useState(100);

    const timeUpdate = (time, duration) => {
        setTimeString(minutesSecondsString(time));
        setDurationString(minutesSecondsString(duration));
    }

    const playStateChange = async paused => {
        await sampleActions.switchPlayPause(dispatch, !paused);
        if (timeString === durationString && timeString !== "0:00") {
            if (nextSong) {
                setTimeString("0:00");
                nextSong();
                sampleActions.switchPlayPause(dispatch, true);
            }
        }
    }

    const pausePlay = () => {
        if (source === '') {
            return;
        }
        if (!playing) {
            sampleActions.switchPlayPause(dispatch, true);
        } else {
            sampleActions.switchPlayPause(dispatch, false);
        }
    }

    const haveSong = source.length !== 0;

    const showPlaylistControls = playlistName != null;

    return (
        <SongInfoContainer>
            <Row xs={12} style={{marginLeft: "auto", marginRight: "auto", height: 5}} onClick={showSongDetails}>
                <Col style={{color: "white", zIndex: 1000}}
                     className={"d-flex justify-content-center"}>
                    <ExpandLess onClick={showSongDetails}></ExpandLess>
                </Col>
            </Row>
            <Row xs={12} style={{marginLeft: "auto", marginRight: "auto"}}>
                <Col xs={12} className={"d-flex justify-content-center"}>
                    <Col xs={"auto"} onClick={showSongDetails}>
                        <AlbumArt src={!artworkUrl || artworkUrl.length === 0 ? transparent : artworkUrl}/>
                    </Col>
                    <Col xs={8} onClick={showSongDetails}>
                        {haveSong && (
                            <div>
                                <TrackName>{shortenMobile(title)}</TrackName>
                                <ArtistName>by {shortenMobile(artist)}</ArtistName>
                            </div>
                        )}
                    </Col>
                    {/*{showPlaylistControls ?*/}
                    {/*    <>*/}
                    {/*        <FontAwesomeIcon color="white" icon={['fas', 'step-backward']}*/}
                    {/*                         onClick={prevSong}/>*/}
                    {/*        <SongIndex> {playlistIndex + 1} / {playlistTotal}</SongIndex>*/}
                    {/*        <FontAwesomeIcon color="white" style={{marginRight: '8px'}}*/}
                    {/*                         icon={['fas', 'step-forward']} onClick={nextSong}/>*/}
                    {/*        <PlaylistName>{playlistName}</PlaylistName>*/}
                    {/*    </> : <></>}*/}
                    <Col xs={"auto"} className="d-none d-sm-block" style={{padding: 0, marginTop: 32}}>
                        <FontAwesomeIcon color="white" style={{marginRight: '8px'}}
                                         icon={['fas', 'volume-up']}/>
                    </Col>
                    <Col xs={"auto"} className="d-none d-sm-block" style={{marginTop: 25}}>
                        <Slider style={{padding: 0, paddingTop: 8}} value={volume} onChange={setVolume}
                                className="volume_slider"/>
                    </Col>
                    <AudioPlayer volume={volume} play={playing} source={source} onTimeUpdate={timeUpdate}
                                 showSongDetails={showSongDetails}
                                 dispatch={dispatch}
                                 timeSent={timeSent}
                                 onPlayStateChange={playStateChange}/>

                    {/*<TimeLabel>{timeString} / {durationString}</TimeLabel>*/}
                    <Col xs={"auto"}>
                        <PlayPause onClick={pausePlay} playing={playing}>
                            {!playing ? <FontAwesomeIcon color="black" icon={['fas', 'play']}/> :
                                <FontAwesomeIcon color="black" icon={['fas', 'pause']}/>}
                        </PlayPause>
                    </Col>
                </Col>
            </Row>
        </SongInfoContainer>

    )
}
