import {locationConstants} from "_constants";
import {locationService} from "_services";
import {alertActions} from "./alert.actions";

export const locationActions = {
  get,
  getOne,
  addOne,
  updateOne,
  archive
};

function get() {
  return dispatch => {
    dispatch(request());

    locationService.get().then(
      location => {
        dispatch(success(location));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: locationConstants.GET_REQUEST };
  }
  function success(location) {
    return { type: locationConstants.GET_SUCCESS, location };
  }
  function failure(error) {
    return { type: locationConstants.GET_FAILURE, error };
  }
}

async function archive(dispatch, id) {
  dispatch({ type: locationConstants.ARCHIVE_REQUEST });
  try {
    const response = await locationService.archive(id);
    dispatch({type: locationConstants.ARCHIVE_SUCCESS, id});
    return response;
  } catch (e) {
    dispatch({type: locationConstants.ARCHIVE_FAILURE, error: e.toString()});
    throw e;
  }
}

function getOne(id) {
  return dispatch => {
    dispatch(request());

    locationService.getOne(id).then(
      location => {
        dispatch(success(location));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: locationConstants.GET_ONE_REQUEST };
  }
  function success(location) {
    return { type: locationConstants.GET_ONE_SUCCESS, location };
  }
  function failure(error) {
    return { type: locationConstants.GET_ONE_FAILURE, error };
  }
}

async function addOne(dispatch, location) {
  dispatch(request());
  try {
    const locationResponse = await locationService.addOne(location);
    dispatch(success(locationResponse));
    return locationResponse;
  } catch (e) {
    dispatch(failure(e.toString()));
    throw e;
  }

  function request() {
    return { type: locationConstants.ADD_ONE_REQUEST };
  }
  function success(location) {
    return { type: locationConstants.ADD_ONE_SUCCESS, location };
  }
  function failure(error) {
    return { type: locationConstants.ADD_ONE_FAILURE, error };
  }
}

async function updateOne(dispatch, location, id) {
  dispatch(request());
  try {
    const locationResponse = await locationService.updateOne(location, id);
    dispatch(success(locationResponse));
    return locationResponse;
  } catch (e) {
    dispatch(failure(e.toString()));
    throw e;
  }

  function request() {
    return { type: locationConstants.UPDATE_ONE_REQUEST };
  }
  function success(location) {
    return { type: locationConstants.UPDATE_ONE_SUCCESS, location };
  }
  function failure(error) {
    return { type: locationConstants.UPDATE_ONE_FAILURE, error };
  }
}

