import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {
  getNowPlayingArtist,
  getNowPlayingArtworkUrl,
  getNowPlayingLoading,
  getNowPlayingTitle,
  getNowPlayingUrl
} from "_reducers/player.reducer";

import {TaleaPlaybackControllerSamples} from "../_components/Standard/TaleaPlaybackControllerSamples";
import {sampleActions} from "../_actions/sample.actions";
import {SongDetails} from "../_components/Samples/SongDetails";
import {Transition} from "react-transition-group";

const FooterHeight = 94;
const GradientContainer = styled.div`
    position: absolute;
    height: 350px;
    width: 100%;
    background: linear-gradient(180deg, rgba(235, 173, 24, 0.25) 24.82%, rgba(218, 218, 218, 0) 100%);
`
const InnerContainer = styled.div`
    button:focus {
        box-shadow: 0 0 0 0 rgb(0 0 0 / 50%) !important;
    }

    button:active:focus {
        box-shadow: 0 0 0 0 rgb(0 0 0 / 50%) !important;
    }

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: ${FooterHeight}px;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;

    ::-webkit-scrollbar {
        width: 0px;
    }
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: ${FooterHeight}px;
    border-top: 1px solid ${props => props.theme.dim};
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: #222129;
`;


class SampleLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {width: 0, height: 0};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }


  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(sampleActions.getSample(this.props.sampleToken));
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  componentDidUpdate() {
  }

  render() {
    const transitionDuration = 500;
    const containerWidth = 1000;

    const defaultStyle = {
      transition: `opacity ${transitionDuration}ms ease-in-out`,
      opacity: 1,
    }

    const transitionStyles = {
      entering: {opacity: 1},
      entered: {opacity: 1},
      exiting: {opacity: 0},
      exited: {opacity: 0},
    };

    const defaultStyleFooter = {
      transition: `bottom ${transitionDuration}ms ease-in-out`,
      bottom: 0,
    }

    const transitionStylesFooter = {
      entering: {bottom: this.state.height},
      entered: {bottom: this.state.height},
      exiting: {bottom: 0},
      exited: {bottom: 0, displaySongDetails: 'none'},
    };
    const padding = Math.max(0, (this.state.width - containerWidth) / 2);
    const innerContainerStyle = {paddingLeft: padding, paddingRight: padding};
    const innerContainerStyle2 = {};
    if (this.props.showSongDetails || !this.props.playlistPlayback) {
      innerContainerStyle2['bottom'] = 0;
    }

    return (<>


      <InnerContainer style={innerContainerStyle2}>
        <GradientContainer></GradientContainer>
        <div style={innerContainerStyle}>
          <Transition in={!this.props.showSongDetails} timeout={5000}>
            {state => {
              return <div>
                <div style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}>
                  {this.props.children}
                </div>
              </div>
            }
            }
          </Transition>
        </div>
      </InnerContainer>
      {this.props.playlistPlayback &&
          <Transition in={this.props.showSongDetails} timeout={transitionDuration}>
            {state => {
              return <Footer style={{
                ...defaultStyleFooter,
                ...transitionStylesFooter[state]
              }}>
                <FooterContainer>
                  <TaleaPlaybackControllerSamples loading={this.props.nowPlayingLoading}
                                                  playlistPlayback={this.props.playlistPlayback}
                                                  selectedSong={this.props.selectedSong}
                                                  dispatch={this.props.dispatch}
                                                  playing={this.props.playing}
                                                  timeSent={this.props.timeSent}
                                                  playlistIndex={this.props.playlistIndex}
                  >
                  </TaleaPlaybackControllerSamples>
                  <SongDetails style={{display: transitionStylesFooter[state]['displaySongDetails']}}>
                  </SongDetails>}
                </FooterContainer>
              </Footer>
            }
            }
          </Transition>
      }
    </>);
  }
}

const mapStateToProps = (state, props) => ({
  sampleToken: props.match.params.sampleToken,
  nowPlayingLoading: getNowPlayingLoading(state),
  artist: getNowPlayingArtist(state),
  artworkUrl: getNowPlayingArtworkUrl(state),
  songUrl: getNowPlayingUrl(state),
  songTitle: getNowPlayingTitle(state),
  sample: state.samples.sample,
  playlistPlayback: state.samples.playlistPlayback,
  selectedSong: state.samples.selectedSong,
  showSongDetails: state.samples.showSongDetails,
  playing: state.samples.playing,
  playlistIndex: state.samples.playlistIndex,
  timeSent: state.samples.timeSent,
  time: state.samples.time
});

const connectedLayout = withRouter(connect(mapStateToProps)(SampleLayout));
export {connectedLayout as SampleLayout};
