import React from "react";
import styled from "styled-components";
import {DefaultColumnContainer} from './Container.style';
import {DefaultLabel} from './Label.style';

const Clickable = styled.div``;

const ColumnContainer = styled(DefaultColumnContainer)``;

const Image = styled.img`
  height: 100px;
`;

const Title = styled(DefaultLabel)`
color: white;
font-size: 22px;
margin-top: 20px;
`;

export const AreaCard = ({ image, text }) => (
  <Clickable>
    <ColumnContainer>
      <Image src={image} />
      <Title>{text}</Title>
    </ColumnContainer>
  </Clickable>
);
