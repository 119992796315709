import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const FilterInputWidth = '200px';
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: ${FilterInputWidth};
`;

const FilterInput = styled.input`
    width: ${FilterInputWidth};
    height: 42px;
    padding: 11px 4px 11px 29px;
    font-size: 16px;
    box-sizing: border-box;
    border: unset;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    caret-color: white;

    &:focus,
    &:active,
    &:hover {
        outline: none;
        box-shadow: none;
        caret-color: white;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        // -webkit-text-fill-color: white;
        transition: background-color 5000s ease-in-out 0s;
    }
`;

const FilterIcon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 13px;
    color: #979797;
`;

export const SearchFilter = ({onChange, placeholder = "Filter", value = '', textColor, autofocus = true}) => {
  const ref = useRef();
  if (autofocus) {
    useEffect(() => {
      ref.current.focus();
    }, [])
  }

  return <Container>
    <FilterInput style={{color: textColor ? textColor : '#EBAD18'}}
        ref={ref} value={value} type="search" placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()} />
    <FilterIcon icon="search" />
  </Container>

}