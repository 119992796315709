import styled from 'styled-components'
import {Badge} from 'react-bootstrap'

export const DefaultLabel = styled(Badge).attrs({
})`
  line-height: 1.2;
  letter-spacing: 1px;
  font-weight: normal;
  margin-bottom: unset;
  font-size: 16px;
  padding: 0;
  margin: 0;
`;
