import React from 'react'
import styled, {ThemeProvider} from 'styled-components'
import {DefaultButton, DefaultLabel, theme} from '_styles'
import {Modal} from 'react-bootstrap'

const Button = styled(DefaultButton)``;

export const ModalDecisionBuilder = ({
                                         title,
                                         method,
                                         content,
                                         size,
                                         handleClose,
                                         handleAccept,
                                         warning
                                     }) => {
    let background = warning ? "#463b3b" : null;
    return <ThemeProvider theme={theme}>
        <Modal className="modal-pick" style={{color: 'white', zIndex: 20000}} show={true}
               backdrop={true} size={size} onHide={() => handleClose(method)}>
            <Modal.Header style={{background}} closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {content && (
                <Modal.Body style={{background}}>
                    <DefaultLabel>{content}</DefaultLabel>
                </Modal.Body>
            )}
            <Modal.Footer style={{background}}>
                <Button onClick={() => handleClose(method)}>
                    No
                </Button>
                <Button onClick={() => handleAccept(method)}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    </ThemeProvider>
};
