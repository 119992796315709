export const BaseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : "/api/";
let domain = window.location.hostname.split(".")[0];
if (domain === 'app') {
    domain = 'talea';
}
if (domain === 'localhost'){
    domain = 'staging'
}
export const TALEA_ENVIRONMENT = domain;

const getUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : undefined;
}

// Could also write http interceptor
export const getBearer = () => 'Bearer ' + (getUser() || {}).authToken;

const logout = () => {
    // Remove any user-data from local storage
    localStorage.removeItem("user");

    // send user back to front-page / login
    window.location.href = "/";
}

/*
  Example usage:
    api("GET", `playlist/${id}`, undefined, (result) => {})
 */
export function api(method, path, data, resultHandler, file, annonymous) {
    const user = getUser();
    const authToken = user ? user.authToken : undefined;

    if (!annonymous && !authToken) {
        console.error("No auth token available for request ", method, path);
        logout();
        return;
    }

    let options = {
        method,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Authorization": `Bearer ${authToken}`
        },
        body: data ? JSON.stringify(data) : undefined
    };

    return fetch(`${BaseUrl}${path}`, options)
        .then(async (response) => {
            if (response.ok) {
                const text = await response.text();
                try {
                    return JSON.parse(text);
                } catch (e) {
                    console.warn(`Endpoint did not return JSON`, method, path, data);
                    return {};
                }
            }

            const HttpUnauthorized = 401;
            if (response.status === HttpUnauthorized) {
                logout();
                return Promise.reject(new Error('Unauthorized'));
            }

            const errorJson = await response.json();
            return Promise.reject(errorJson);
        }).then(resultHandler);
}

export function apiUpload(path, fileParam ,file, resultHandler) {
    const user = getUser();
    const authToken = user ? user.authToken : undefined;

    if (!authToken) {
        console.error("No auth token available for request ", path);
        logout();
        return;
    }

    const data = new FormData()
    data.append(fileParam, file)
    let options = {
        method:"POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${authToken}`
        },
        body: data
    };
    return fetch(`${BaseUrl}${path}`, options)
        .then(async (response) => {
            if (response.ok) {
                const text = await response.text();
                try {
                    return JSON.parse(text);
                } catch (e) {
                    console.warn(`Endpoint did not return JSON`, "POST", path, data);
                    return {};
                }
            }

            const HttpUnauthorized = 401;
            if (response.status === HttpUnauthorized) {
                logout();
                return Promise.reject(new Error('Unauthorized'));
            }
            const errorJson = await response.json();
            return Promise.reject(errorJson);
        }).then(resultHandler);
}
