import {Notes} from "@material-ui/icons";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StandardMoment} from "../_styles";
import {addLineBreaks} from "./Utils";
import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

const VerificationReactTooltip = styled(ReactTooltip)`
    background-color: #5B5A62 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
`;
export const explicitWords = [
  "Arsehole", "Arseholes", "Arsehole's", "Asshole", "Assholes", "Asshole's", "Bitch", "Bullshit", "Cock", "Cocks", "Cock's", "Cocksucker", "Cocksuckers", "Cocksucker's", "Cunt", "Cunts", "Cunt's", "Cunty", "Cum", "Cums", "Cum's", "Cumming", "Cummy", "Faggot", "Faggots", "Faggot's", "Faggoty", "Fuck", "Fucks", "Fuck's", "Fucker", "Fuckers", "Fucker's", "Fucking", "Fuckery", "Fucked", "Fucked's", "Fuckin", "Fuckin's", "Fuckboy", "Fuckboys", "Fuckboy's", "Shit", "Shits", "Shit's", "Shitting", "Shitty", "Pussy", "Pussies", "Pussy's", "Motherfucker", "Motherfuckers", "Motherfucker's", "motherfucking", "Mothafucker", "Mothafuckers", "Mothafucker's", "Nigga", "Nigga's", "Nigger", "Niggers", "Nigger's", "Niggas", "Niggas's", "Nigra", "Nigras", "Nigra's", "Wanker", "Wankers", "Wanker's", "Wanking", "Wanky"
];

export const adultWords = [
  "Ass","Asses","Ass's","Asses","Assy","Arse","Arses","Arse's","Arsey","Bloody","Bloody's","Bitch","Bitches","Bitch's","Bitching","Bitchy","Bum","Bums","Bum's","Bitch'in","Butt","Butts","Butt's","Bums","Boob","Boobs","Boobies","Boob's","Booby","Cocky","Cocky's","Crap","Craps","Crap's","Crapping","Crappy","Dick","Dicks","Dick's","Dicking","Dicky","Dickhead","Dickheads","Dickhead's","Dyke","Dykes","Dyke's","Dykey","Puss","Drug","Drugs","MDMA","Piss","Pisses","Pissing","Pissy","Prick","Pricks","Slut","Sluts","Slut's","Slutting","Slutty","Tit","Tits","Turd","Turds","Whore","Whores","Whore's","Whoreing","inside me","I like Drugs","getting me wet","Goddam","Negro","Negro's","Twat","Twats","Spastic","Spastics","Retard","Retards","Retarded","Pissed Off","Sex","Sexes","Sexed","God's","God","Jesus's","Jesus","Cocaine","MDMA","Weed","Crystal Meth","Ultrasex","Pill","Pills","Pilling","Pilly","Suck"
];

const AvatarStyled = styled.div`
    padding: 5px;
    border-radius: 5px;
    width: auto;
    font-family: "Apercu";
    font-size: 13px;
    font-weight: 400


`;
const Avatar = (props) => {
  const {color, fgColor, name, size} = props;
  return <AvatarStyled style={{
    color: fgColor, background: color
  }}>{name}</AvatarStyled>

}
export const formatExplicitType = (type) => {
  let color = "";
  if (type === "CLEAN") {
    color = "white";
  }
  if (type === "EXPLICIT") {
    color = "#FF8989";
  }
  if (type === "ADULT") {
    color = "#F8D686";
  }
  return <span style={{fontFamily: 'Apercu', color: color, fontWeight: 700}}>{type}</span>;
}

export const ExplicitAvatar = (songExplicitType, verified) => {
  let avatar = <></>
  if (songExplicitType === "_") {
    avatar = <Avatar name="_" size={20}
                     fgColor={"#FFFFFF80"}
                     color={"transparent"}
    />
  }
  if (songExplicitType === "CLEAN") {
    avatar = <Avatar name="C" size={20}
                     fgColor={verified ? "white" : "#FFFFFF80"}
                     color={verified ? "#5B5A62" : "transparent"}
    />
  }
  if (songExplicitType === "EXPLICIT") {
    avatar = <Avatar name="E" size={20}
                     fgColor={verified ? "#FF8989" : "#FF898980"}
                     color={verified ? "#593C41" : "transparent"}
    />;
  }
  if (songExplicitType === "ADULT") {
    avatar = <Avatar name="A" size={20}
                     fgColor={verified ? "#F8D686" : "#F8D68680"}
                     color={verified ? "#595145" : "transparent"}
    />
  }
  return avatar;
}

export const ExplicitBadge = (songExplicitType, verified) => {
  let avatar = <></>
  if (songExplicitType === "_") {
    avatar = <Avatar name="_" size={60}
                     style={{maxWidth: 60}}
                     fgColor={"#FFFFFF80"}
                     color={"transparent"}
    />
  }
  if (songExplicitType === "CLEAN") {
    avatar = <Avatar name="CLEAN" size={60}
                     style={{maxWidth: 60}}
                     fgColor={verified ? "white" : "#FFFFFF80"}
                     color={verified ? "#5B5A62" : "transparent"}
    />
  }
  if (songExplicitType === "EXPLICIT") {
    avatar = <Avatar name="EXPLICIT"
                     style={{maxWidth: 80}}
                     fgColor={verified ? "#FF8989" : "#FF898980"}
                     color={verified ? "#593C41" : "transparent"}
    />;
  }
  if (songExplicitType === "ADULT") {
    avatar = <Avatar name="ADULT" size={80} round="5px" textSizeRatio={1}
                     style={{maxWidth: 100}}
                     fgColor={verified ? "#F8D686" : "#F8D68680"}
                     color={verified ? "#595145" : "transparent"}
    />
  }
  return avatar;
}

export const explicitInfoRowRenderer = (songData) => {
  if (!songData) {
    return;
  }
  let notesIcon = songData.notes &&
      <Notes style={{color: "white", fontSize: 16, marginTop: 2, marginLeft: 5}}></Notes>
  let tooltip = <VerificationReactTooltip
      effect='solid'
      place="left"
      id={"verify_info_" + songData.id}>
    <Container style={{minWidth: 250, margin: 0, padding: 0}}>
      <Row style={{margin: 0}}>
        <Col className={"d-flex justify-content-start"}>
          {formatExplicitType(songData.songExplicitType)}
        </Col>
        <Col className={"d-flex justify-content-end"}>
          {songData.verified ? <><span style={{fontSize: 12}}>{songData.verifier.split(" ").map((n) => n[0]).join("")}</span>
            <FontAwesomeIcon
                style={{marginLeft: 5}}
                size={"xs"}
                icon={"check"}/></> : "Not verified"}
        </Col>
      </Row>
      {songData.verifiedAt && <Row style={{margin: 0, marginTop: 10}}>
        <Col>
                    <span style={{
                      fontFamily: "Apercu",
                      fontSize: 11,
                      fontWeight: 400
                    }}>
                        <StandardMoment>{songData.verifiedAt}</StandardMoment>
                        </span>
        </Col>
      </Row>
      }
      {songData.notes && <Row style={{margin: 0, marginTop: 10, maxWidth: 300}}>
        <Col style={{maxWidth: 300, wordBreak: "break-word"}}>
          <div style={{
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: "Apercu",
            fontSize: 11,
            fontWeight: 400,
            maxWidth: 250,
          }}>{addLineBreaks(songData.notes)}</div>
        </Col>
      </Row>}
    </Container>
  </VerificationReactTooltip>;

  let avatar = ExplicitAvatar(songData.songExplicitType, songData.verified);
  return <span data-tip data-for={"verify_info_" + songData.id}>
           <div style={{display: "flex"}}>{avatar}
             {notesIcon}
             {tooltip}</div>
        </span>


};