import styled from "styled-components";

export const SectionGutter = 54;

export const SectionGutterContainer = styled.div`
  padding: 0 ${SectionGutter}px;
`;

export const SectionHeader = styled.div`
  margin-left: ${SectionGutter}px;
  margin-right: ${SectionGutter}px;
  margin-top: 80px;
`;

export const SectionTitle = styled.div`
  color: #FFFFFF;
  font-size: 56px;
  letter-spacing: -2.36px;
  line-height: 70px;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const RowSpaced = styled(Row)`
  justify-content: space-between;
`;

export const RowSpacing = styled(Row)`
  & > * {
    margin-right: 24px;
  }
`;

export const FilterSection = styled(RowSpacing)`
  flex-wrap: wrap;
  margin-bottom: 46px;
`;
