import React from 'react'
import {connect} from "react-redux";
import {DropdownFilter} from './Standard/DropdownFilter';
import {uiActions} from '_actions';

const ClientDropdownFilter = ({clientSelect, activeClientId, withMaster, options, withoutCurrent, dispatch}) => {
    if (withMaster) {
        options = [{id: 0, text: 'Master'}, ...options]
    }

    const onSelect = (client) => {
        if (!withoutCurrent) {
            uiActions.setClientId(dispatch, client ? client.id : -1);
        }
        clientSelect(client);
    }

    return <DropdownFilter selectedTextColor='orange' onSelect={onSelect}
                           selectedId={withoutCurrent ? undefined : activeClientId} placeholder="Client"
                           options={options}/>;
}

const mapStateToProps = state => ({
    options: state.client.client.sort((a, b) => a.name.localeCompare(b.name)).map(c => ({id: c.id, text: c.name})),
    activeClientId: state.ui.clientId
});

const _ClientDropdownFilter = connect(mapStateToProps)(ClientDropdownFilter);
export {_ClientDropdownFilter as ClientDropdownFilter};
