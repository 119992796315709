import React from "react";
import styled from "styled-components";

const Table = styled.div`
  user-select: none;
`;

const documentElement = () => ({
    isDoc: true,
    element: document.body,
    eventTarget: document
});

const standardElement = (ele) => ({
    isDoc: false,
    element: ele,
    eventTarget: ele
});

function getScrollParent(element, includeHidden = true) {
    let style = getComputedStyle(element);
    if (style.position === "fixed") return documentElement();

    const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
    const excludeStaticParent = style.position === "absolute";

    for (let parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);

        if (excludeStaticParent && style.position === "static") continue;

        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return standardElement(parent);
    }

    return documentElement();
}

export default class VirtualList extends React.Component {
    state = {
        start: 0,
        end: -1,
        scrollX: 0,
        scrollY: 0
    }

    state = 0;
    end = -1;

    componentDidMount() {
        this.container = getScrollParent(this.refs.table);
        this.container.eventTarget.addEventListener('scroll', this.updateRender, true);
        window.addEventListener('resize', this.updateRender);
        this.updateRender();
    }

    componentWillUnmount() {
        this.container.eventTarget.removeEventListener('scroll', this.updateRender, true);
        window.removeEventListener('resize', this.updateRender);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rows !== this.props.rows) {
            this.updateRender();
        }
    }

    updateRender = () => {

        const parent = this.container.element.getBoundingClientRect();
        const table = this.refs.table.getBoundingClientRect();

        const top = this.container.isDoc ? table.top : table.top - parent.top;

        const start = Math.floor(Math.max(0, -top) / this.props.fixedRowHeight);
        const count = Math.floor((parent.height - Math.max(0, top)) / this.props.fixedRowHeight) + 2;
        const end = Math.min(start + count, this.props.rows.length - 1);

        if (this.start === start && this.end === end) return;

        this.start = start;
        this.end = end;
        const scrollX = this.container.element.scrollLeft;
        const scrollY = this.container.element.scrollTop;
        this.setState({start, end, scrollX, scrollY});
    }

    renderRows = () => {
        let rows = [];

        if (this.props.rows.length === 0) return null;

        const start = Math.min(this.state.start, this.props.rows.length - 1);
        const end = Math.min(this.state.end, this.props.rows.length - 1);

        for (let i = start; i <= end; i++) {
            rows.push(this.props.rowRenderer(this.props.rows[i], i));
        }

        return rows;
    }

    render() {
        const paddingTop = `${this.state.start * this.props.fixedRowHeight}px`;
        const paddingBottom = `${(this.props.rows.length - 1 - this.state.end) * this.props.fixedRowHeight}px`;

        const {style, rows, ...props} = this.props;
        return (
            <Table ref='table' style={{paddingTop, paddingBottom, width: '100%', ...this.props.style}} {...props}>
                {this.renderRows()}
            </Table>
        )
    }
}