import {scheduleConstants} from "_constants";
import {scheduleService} from "_services";
import {alertActions} from "./alert.actions";

export const scheduleActions = {
  get,
  getSchedule,
  addOne,
  update,
  deleteOne,
  addPlaylist,
  deletePlaylist,
  addDayPart,
  updateDayPart,
  deleteDayPart,
  duplicate,
  archive,
  getPlaylistsInfo
};

async function getPlaylistsInfo(dispatch, ids) {
  dispatch({ type: scheduleConstants.PLAYLIST_INFO_REQUEST, ids });

  try {
    const info = await scheduleService.info(ids);
    dispatch({ type: scheduleConstants.PLAYLIST_INFO_SUCCESS, ids, info });
  } catch (e) {
    dispatch({ type: scheduleConstants.PLAYLIST_INFO_FAILURE, ids, error: e.toString()});
    throw e;
  }
}

function get() {
  return dispatch => {
    dispatch(request());

    scheduleService.get().then(
      schedule => {
        dispatch(success(schedule));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: scheduleConstants.GET_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.GET_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.GET_FAILURE, error };
  }
}

async function getSchedule(dispatch, id) {
  dispatch({ type: scheduleConstants.GET_SCHEDULE_REQUEST })
  try {
    const schedule = await scheduleService.getSchedule(id);
    dispatch({ type: scheduleConstants.GET_SCHEDULE_SUCCESS, schedule });
  } catch (e) {
    dispatch({ type: scheduleConstants.GET_SCHEDULE_FAILURE, error: e.toString() })
    throw e;
  }
}

async function duplicate(dispatch, id) {
  dispatch({ type: scheduleConstants.DUPLICATE_REQUEST })
  try {
    const response = await scheduleService.duplicate(id);
    dispatch({ type: scheduleConstants.DUPLICATE_SUCCESS, id: response.id });
    return response.id;
  } catch (e) {
    dispatch({ type: scheduleConstants.DUPLICATE_FAILURE, error: e.toString() })
    throw e;
  }
}

async function archive(dispatch, id) {
  dispatch({ type: scheduleConstants.ARCHIVE_REQUEST })
  try {
    const response = await scheduleService.archive(id);
    dispatch({ type: scheduleConstants.ARCHIVE_SUCCESS, id: response.id });
    return response;
  } catch (e) {
    dispatch({ type: scheduleConstants.ARCHIVE_FAILURE, error: e.toString() })
    throw e;
  }
}

async function addOne(dispatch, schedule) {
  dispatch(request());
  try {
    const scheduleResponse = await scheduleService.addOne(schedule);
    dispatch(success(scheduleResponse));
    return scheduleResponse;
  } catch (e) {
    dispatch(failure(e.toString()));
    throw e;
  }

  function request() {
    return { type: scheduleConstants.ADD_ONE_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.ADD_ONE_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.ADD_ONE_FAILURE, error };
  }
}

function update(schedule, id) {
  return dispatch => {
    dispatch(request());

    scheduleService.update(schedule, id).then(
      schedule => {
        dispatch(success(schedule));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: scheduleConstants.UPDATE_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.UPDATE_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.UPDATE_FAILURE, error };
  }
}


function deleteOne(scheduleId) {
  return dispatch => {
    dispatch(request());

    scheduleService.deleteOne(scheduleId).then(
      schedule => {
        dispatch(success(schedule));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: scheduleConstants.DELETE_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.DELETE_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.DELETE_FAILURE, error };
  }
}

function addPlaylist(scheduleId, playlistId) {
  return dispatch => {
    dispatch(request());

    scheduleService.addPlaylist(scheduleId, playlistId).then(
      schedule => {
        dispatch(success(schedule));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: scheduleConstants.ADD_PLAYLIST_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.ADD_PLAYLIST_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.ADD_PLAYLIST_FAILURE, error };
  }
}

function deletePlaylist(scheduleId, playlistId) {
  return dispatch => {
    dispatch(request());

    scheduleService.deletePlaylist(scheduleId, playlistId).then(
      schedule => {
        dispatch(success(schedule));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: scheduleConstants.DELETE_PLAYLIST_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.DELETE_PLAYLIST_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.DELETE_PLAYLIST_FAILURE, error };
  }
}

function addDayPart(dayPart) {
  return dispatch => {
    dispatch(request());

    scheduleService.addDayPart(dayPart).then(
      schedule => {
        dispatch(success(schedule));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: scheduleConstants.ADD_DAYPART_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.ADD_DAYPART_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.ADD_DAYPART_FAILURE, error };
  }
}

function updateDayPart(dayPartId, dayPart) {
  return dispatch => {
    dispatch(request());

    scheduleService.updateDayPart(dayPartId, dayPart).then(
      schedule => {
        dispatch(success(schedule));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: scheduleConstants.UPDATE_DAYPART_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.UPDATE_DAYPART_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.UPDATE_DAYPART_FAILURE, error };
  }
}

function deleteDayPart(dayPartId) {
  return dispatch => {
    dispatch(request());

    scheduleService.deleteDayPart(dayPartId).then(
      schedule => {
        dispatch(success(schedule));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: scheduleConstants.DELETE_DAYPART_REQUEST };
  }
  function success(schedule) {
    return { type: scheduleConstants.DELETE_DAYPART_SUCCESS, schedule };
  }
  function failure(error) {
    return { type: scheduleConstants.DELETE_DAYPART_FAILURE, error };
  }
}
