import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import styled from "styled-components";
import {DefaultButton, DefaultGrayButton} from './Button.style';
import {DefaultColumnContainer, DefaultRowContainer} from './Container.style';
import {DefaultFormControl} from './Input.style';
import {DefaultLabel} from './Label.style';
import {ModalDecisionBuilder} from '../_components/Utils';

const DeleteButton = styled.div`
  border-radius: 8px;
  border: 2px solid #CCC;
  background-color: #EEE;
  padding: 10px 10px;
  color: red;
  display: flex;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: red;
    color: white;
    border-color: red;
  }
`;

const DangerLabel = styled.div`
  font-size: 18px;
  color: red;
  margin: 20px 0px;
`;

const DangerTable = styled.div`
  background-color: #eee;
  border: 1px solid red;
  border-radius: 8px;
  
  & td {
    padding: 20px;
  }
`;

const ContentContainer = styled(DefaultColumnContainer)``;

const RowContainer = styled(DefaultRowContainer)`
  width: 100%;
  align-items: center;
  min-height: 60px;
`;

const LeftContainer = styled(DefaultRowContainer)`
  margin-right: 5px;
  justify-content: flex-start;
  width: 350px;
`;

const RightContainer = styled(DefaultRowContainer)`
  margin-left: 5px;
  justify-content: flex-start;
  width: 100%;
`;

const Label = styled(DefaultLabel)`
  color: white;
`;

const RSelect = styled(Select)`
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  width: 50%;

  & + & {
    margin-left: 10px;
  }
`;

const DatePickerButton = styled.button`
  border: 1px solid ${props => props.theme.optionalGray};
  border-radius: 5px;
  color: white;
  padding: 4px 4px 4px 10px;
  letter-spacing: 1px;
  height: 40px;
  background-color: ${props => props.theme.darkGray};
  outline: none;
  box-shadow: none;
  width: 100%;
  text-align: left;

  ::placeholder {
    color: white;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: ${props => props.theme.optionalGray};
    border: 0;
    outline: none;
    box-shadow: none;
    caret-color: white;
    color: white;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Form = styled(DefaultFormControl)`
  width: 60px;
  height: 40px;

  & + & {
    margin-left: 10px;
  }
`;

export const Active = styled(DefaultButton)`
  background-color: ${props => props.theme.highlight};
  color: white;
  align-items: flex-end;
  width: 100px;
  height: 35px;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.lightYellow};
    color: white;
  }
`;

export const Inactive = styled(DefaultGrayButton)`
  color: white;
  align-items: flex-end;
  width: 100px;
  height: 35px;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.darkGray};
    color: white;
  }
`;

const Row = styled(DefaultRowContainer)`
  align-items: center;
`;

const RowLabel = styled(DefaultLabel)`
  margin-left: 5px;
  color: white;
`;

class DatePickerInput extends React.Component {
  render() {
    return (
      <DatePickerButton onClick={this.props.onClick}>
        {this.props.value === "" ? this.props.label : this.props.value}
      </DatePickerButton>
    );
  }
}

export const SettingsContainer = ({
  settings,
  playlists,
  setDate,
  setInput,
  setBoolean,
  onDelete,
  confirmArchiving,
  onShowConfirmArchiving,
  onCloseConfirmArchiving,
  onConfirmArchiving
}) => {
      
    
  const getContent = setting => {
    switch (setting.type) {
      case "dropdown":
        return (
          <RSelect
            options={playlists}
            value={setting.value} /*onChange={e => setDropdown(rule.id, e)} */
          />
        );
      case "boolean":
        return setting.value ? (
          <Active onClick={e => setBoolean(setting.id, "setting", "value", false)}>
            On
          </Active>
        ) : (
          <Inactive
            onClick={e => setBoolean(setting.id, "setting", "value", true)}
          >
            Off
          </Inactive>
        );
      case "input":
        return setting.setting === "Volume" ? (
          <Row>
            <Form
              type="number"
              placeholder="volume"
              min="0"
              max="100"
              value={setting.value}
              onChange={e => setInput(setting.id, "setting", "value", e)}
            />
          </Row>
        ) : (
          <Row>
            <Form
              type="number"
              placeholder="seconds"
              value={setting.value}
              onChange={e => setInput(setting.id, "setting", "value", e)}
            />
            <RowLabel>seconds</RowLabel>
          </Row>
        );
      case "datepicker":
        return (
          <React.Fragment>
            <DatePickerWrapper>
              <DatePicker
                selected={setting.start}
                onChange={value =>
                  setDate(setting.id, "setting", "start", value)
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="hh:mm aa"
                timeCaption="Time"
                customInput={<DatePickerInput label="Start Time" />}
                placeholderText="Start Time"
              />
            </DatePickerWrapper>
            <DatePickerWrapper>
              <DatePicker
                selected={setting.end}
                onChange={value =>
                  setDate(setting.id, "setting", "end", value)
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="hh:mm aa"
                timeCaption="Time"
                customInput={<DatePickerInput label="End Time" />}
                placeholderText="End Time"
              />
            </DatePickerWrapper>
          </React.Fragment>
        );
      default:
        return <Label>Something</Label>;
    }
  };

  

  return (
        
    <ContentContainer>
      {settings.map((st, index) => (
        <RowContainer key={`settings-${index}-${st.id}`}>
          <LeftContainer>
            <Label>{st.setting}</Label>
          </LeftContainer>
          <RightContainer>{getContent(st)}</RightContainer>
        </RowContainer>
      ))}
      <DangerLabel>Danger Zone</DangerLabel>
      <DangerTable>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td>Archiving the zone may cause the player to stop playing music.</td>
              <td><DeleteButton onClick={onShowConfirmArchiving}>Archive Zone</DeleteButton></td>
            </tr>
          </tbody>
        </table>
      </DangerTable>
      {confirmArchiving && (
                            <ModalDecisionBuilder
                                title={"Are you sure you want to archive this zone?"}
                                handleClose={onCloseConfirmArchiving}
                                handleAccept={onConfirmArchiving}
                                />
                                )}
    </ContentContainer>
  );
};
