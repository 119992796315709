export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGIN_VALIDATION: 'USERS_LOGIN_FAILURE',

  LOGIN_2FA_REQUEST: 'USERS_LOGIN_2FA_REQUEST',
  LOGIN_2FA_SUCCESS: 'USERS_LOGIN_2FA_SUCCESS',
  LOGIN_2FA_FAILURE: 'USERS_LOGIN_2FA_FAILURE',

  SEND_2FACODE_REQUEST: 'SEND_2FACODE_REQUEST',
  SEND_2FACODE_SUCCESS: 'SEND_2FACODE_SUCCESS',
  SEND_2FACODE_FAILURE: 'SEND_2FACODE_FAILURE',


  LOGOUT: 'USERS_LOGOUT',

  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  GET_ONE_REQUEST: 'USER_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'USER_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'USER_GET_ONE_FAILURE',

  GET_REQUEST: 'USER_GET_REQUEST',
  GET_SUCCESS: 'USER_GET_SUCCESS',
  GET_FAILURE: 'USER_GET_FAILURE',

  ADD_ONE_REQUEST: 'USER_ADD_ONE_REQUEST',
  ADD_ONE_SUCCESS: 'USER_ADD_ONE_SUCCESS',
  ADD_ONE_FAILURE: 'USER_ADD_ONE_FAILURE',

  UPDATE_ONE_REQUEST: 'USER_UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'USER_UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'USER_UPDATE_ONE_FAILURE',

  GET_CLIENT_USERS_REQUEST: 'USER_CLIENT_USERS_REQUEST',
  GET_CLIENT_USERS_SUCCESS: 'USER_CLIENT_USERS_SUCCESS',
  GET_CLIENT_USERS_FAILURE: 'USER_CLIENT_USERS_FAILURE',

  DASHBOARD_REQUEST: 'USER_DASHBOARD_REQUEST',
  DASHBOARD_SUCCESS: 'USER_DASHBOARD_SUCCESS',
  DASHBOARD_FAILURE: 'USER_DASHBOARD_FAILURE',

  ARCHIVE_REQUEST: 'USER_ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'USER_ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'USER_ARCHIVE_FAILURE',
};
