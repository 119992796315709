import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import {
  DefaultRowContainer,
  DefaultButton,
  DefaultLabel,
  DefaultColumnContainer
} from "_styles";
import Moment from "react-moment";
import moment from 'moment';

const Content = styled(DefaultRowContainer)`
  margin-top: 10px;
  justify-content: flex-end;
`;

const DeleteButton = styled(DefaultButton)`
  width: 100px;
  height: 40px;
`;

const EditButton = styled(DeleteButton)`
  margin-right: 10px;
`

const Label = styled(DefaultLabel)`
  color: white;
`;

const DateContainer = styled(DefaultColumnContainer)`
  align-items: flex-start;
`;

const Playlist = styled(DefaultLabel)`
  color: white;
  padding: 10px 0;

  &:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: ${props => props.color};
    margin-right: 10px;
    text-align: center;
  }
`;

const Time = styled(DefaultRowContainer)``;

const TimeColumn = styled(DefaultColumnContainer)``;

const HeaderTitle = styled(DefaultLabel)`
  color: white;
  font-size: 18px;
`;

export const ModalEventDetails = ({
  event,
  match,
  color,
  handleClose,
  handleEdit,
  handleDelete
}) => {
  const getStartTime = event => {
    if (event.extendedProps !== undefined) {
      if (event.extendedProps.override) {
        return <Label>{moment(event.extendedProps.sTime).format("hh:mm A")}</Label>;
      }
    } else if (event.startTime !== undefined) {
      return <Label>{moment(event.startTime).format("hh:mm A")}</Label>;
    }
    if (event !== undefined)
      return (
        <Moment format="hh:mm a">{moment(event.start)}</Moment>
      );
    return <Label>No data to show</Label>;
  };

  const getEndTime = event => {
    if (event.extendedProps !== undefined) {
      if (event.extendedProps.override) {
        return <Label>{moment(event.extendedProps.eTime).format("hh:mm A")}</Label>;
      }
    } else if (event.endTime !== undefined) {
      return <Label>{moment(event.endTime).format("hh:mm A")}</Label>;
    }
    if (event !== undefined)
      return <Moment format="hh:mm a">{moment(event.end)}</Moment>;
    return <Label>No data to show</Label>;
  };

  const defineAllDay = () => {
    if(match === undefined){
      return <Label>No data to show</Label>
    }
    return (
      <TimeColumn>
        <Time>
          <Label>
            <Moment format="dddd DD/MM">
              {moment(match.start)}
            </Moment>
          </Label>
          <Label>&nbsp;to&nbsp;</Label>
          <Label>
            <Moment format="dddd DD/MM">
              {moment(match.end).subtract(1, 'd')}
            </Moment>
          </Label>
        </Time>
        <Time>
          <Label>{getStartTime(match)}</Label>
          <Label>-&nbsp;</Label>
          <Label>{getEndTime(match)}</Label>
        </Time>
      </TimeColumn>
    );
  };

  return (
    <Modal show={true} onHide={handleClose} className="modal-pick" centered>
      <Modal.Header>
        <HeaderTitle>Edit Daypart</HeaderTitle>
      </Modal.Header>
      <Modal.Body>
        <DateContainer>
          {!event.allDay ? (
            <Time>
              <Label>
                <Moment format="dddd">
                  {moment(event.start)}
                </Moment>
              </Label>
              <Label>&nbsp;</Label>
              <Label>{getStartTime(event)}</Label>
              <Label>-&nbsp;</Label>
              <Label>{getEndTime(event)}</Label>
            </Time>
          ) : (
            defineAllDay()
          )}
          <Playlist color={color}>{event.title}</Playlist>
        </DateContainer>
        <Content>
          {event.allDay && <EditButton onClick={handleEdit}>Edit</EditButton>}
          <DeleteButton onClick={() => handleDelete(event)}>Delete</DeleteButton>
        </Content>
      </Modal.Body>
    </Modal>
  );
};
