import React from "react";
import styled from "styled-components";
import {ToggleButtonGroup} from "react-bootstrap";
import {RadioButton} from "./Radio.style";

const ToggleBG = styled(ToggleButtonGroup)`
  width: 100%;
  margin-top: 10px;
`;

const Radio = styled(RadioButton)`
& + & {
  margin-left: 40px;
}
`

export const FormRadioButton = ({ name, value, options, handleChange }) => (
  <ToggleBG type="radio" name={name} value={value} onChange={handleChange}>
    {options.map((opt, index) => (
      <Radio key={`radio-button-${index}`} value={opt} defaultValue={value} handleChange={() => null}>{opt}</Radio>
    ))}
  </ToggleBG>
);
