import {albumConstants} from "_constants";
import {albumService} from "_services";
import {alertActions} from "./alert.actions";

export const albumActions = {
  get,
  getOne,
  addOne
};

function get() {
  return dispatch => {
    dispatch(request());

    albumService.get().then(
      album => {
        dispatch(success(album));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: albumConstants.GET_REQUEST };
  }
  function success(album) {
    return { type: albumConstants.GET_SUCCESS, album };
  }
  function failure(error) {
    return { type: albumConstants.GET_FAILURE, error };
  }
}

function getOne(id) {
  return dispatch => {
    dispatch(request());

    albumService.getOne(id).then(
      album => {
        dispatch(success(album));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: albumConstants.GET_ONE_REQUEST };
  }
  function success(album) {
    return { type: albumConstants.GET_ONE_SUCCESS, album };
  }
  function failure(error) {
    return { type: albumConstants.GET_ONE_FAILURE, error };
  }
}

function addOne(album) {
  return dispatch => {
    dispatch(request());

    albumService.addOne(album).then(
      album => {
        dispatch(success(album));
        dispatch(alertActions.success())
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: albumConstants.ADD_ONE_REQUEST };
  }
  function success(album) {
    return { type: albumConstants.ADD_ONE_SUCCESS, album };
  }
  function failure(error) {
    return { type: albumConstants.ADD_ONE_FAILURE, error };
  }
}
