import React from "react";
import Img from 'react-image';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled, {ThemeProvider} from "styled-components";
import {albumActions} from "_actions";
import image from "_assets/images/no_image_found.png";
import {Matches} from '_helpers';
import {DefaultColumnContainer, DefaultLabel, ListViewContainer, theme} from "_styles";

const AlbumContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  margin: 15px 15px 0 15px;
  overflow-y: auto;
  padding: 20px;
  background-color: ${props => props.theme.main};
`;

const AlbumCard = styled(DefaultColumnContainer)`
  width: 100%;
`;

const Image = styled(Img)`
  background-size: cover;
  min-height: 180px;
  max-height: 250px;
  background-position: center;
  background-color: #ccc;
`;

const TextContainer = styled(DefaultColumnContainer)`
  padding: 15px 0;
`;

const Name = styled(DefaultLabel)`
  color: white;
  font-weight: 600;
  padding-right: 10px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Artist = styled(DefaultLabel)`
  color: white;
  text-align: left;
  padding-right: 10px;
  margin-top: 10px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

class Album extends React.Component {
  state = {
    search: ""
  };

  componentDidMount() {
    this.props.dispatch(albumActions.get());
  }

  handleSearch = filterText => {
    this.setState({ search: filterText });
  };

  addFilters = () => {};

  render() {
    const { album = [] } = this.props;

    let albumFocus = Matches(album, this.state.search, ["name"]);

    return (
      <ThemeProvider theme={theme}>
        <ListViewContainer title="Albums">
          {/* <TopSearchContainer handleSearch={this.handleSearch}>
            <FilterButton onClick={this.addFilters}>+ Add Filters</FilterButton>
          </TopSearchContainer> */}
          <AlbumContainer>
            {albumFocus.map((alb, index) => (
              <AlbumCard key={`album-${alb.id}`}>
                <Image src={[alb.albumArtUrl, image]} />
                <TextContainer>
                  <Name>{alb.name}</Name>
                  <Artist>{alb.name}</Artist>
                </TextContainer>
              </AlbumCard>
            ))}
          </AlbumContainer>
        </ListViewContainer>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  alert: state.alert,
  album: state.album.album
});

const connectedAlbum = withRouter(connect(mapStateToProps)(Album));
export { connectedAlbum as Album };
