import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { alertActions, scheduleActions, clientActions } from "_actions";
import {
  DefaultLabel,
  DefaultColumnContainer,
  DefaultBlackFormControl,
  CreationForms,
  Helper,
  theme
} from "_styles";
import Select from "react-select";
import image from "_assets/images/brand-wong.jpg";

// const listTypes = [
//   { variable: "zoneType", value: "Hotel", label: "Hotel" },
//   { variable: "zoneType", value: "Gym", label: "Gym" },
//   { variable: "zoneType", value: "Restaurant", label: "Restaurant" },
//   { variable: "zoneType", value: "Lobby", label: "Lobby" },
//   { variable: "zoneType", value: "Club", label: "Club" },
//   { variable: "zoneType", value: "Spa", label: "Spa" },
//   { variable: "zoneType", value: "Hospital", label: "Hospital" },
//   { variable: "zoneType", value: "Bar", label: "Bar" },
//   { variable: "zoneType", value: "Waiting Room", label: "Waiting Room" },
//   { variable: "zoneType", value: "Room", label: "Room" },
//   { variable: "zoneType", value: "Conference Room", label: "Conference Room" },
//   {
//     variable: "zoneType",
//     value: "Executive Lounge",
//     label: "Executive Lounge"
//   },
//   { variable: "zoneType", value: "Chapel", label: "Chapel" },
//   { variable: "zoneType", value: "Pool", label: "Pool" },
//   { variable: "zoneType", value: "Casino", label: "Casino" },
//   { variable: "zoneType", value: "Meeting Room", label: "Meeting Room" },
//   { variable: "zoneType", value: "Hall", label: "Hall" },
//   { variable: "zoneType", value: "Cinema", label: "Cinema" },
//   { variable: "zoneType", value: "Retail Outlet", label: "Retail Outlet" },
//   { variable: "zoneType", value: "Mall", label: "Mall" },
//   { variable: "zoneType", value: "Other", label: "Other" }
// ];

const Label = styled(DefaultLabel)`
  color: white;
  font-size: 24px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-top: 10px;
`;

const FormLabel = styled(DefaultLabel)`
  color: ${props => props.theme.formlabel};
  font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
  margin-top: 5px;
`;

const TypesContainer = styled(DefaultColumnContainer)`
  width: 100%;
  margin-top: 20px;
  align-items: flex-start;
`;

const RSelect = styled(Select)`
  width: 100%;
  margin-top: 10px;
`;


const CreateSchedule = ({ schedule = undefined, onBack, onCreate, dispatch, clients }) => {
  // Editing not implemented

  const [name, setName] = useState('');
  const [client, setClient] = useState(undefined);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    clientActions.getAllClients(dispatch);
  }, []);

  const submit = async () => {
    if (name === "" || client === undefined) {
      setError('Please fill out all the required fields');
      return;
    }

    let scheduleData = {
      name,
      client: {id: client.value}
    };

    setSubmitting(true);
    setError('');

    try {
      await scheduleActions.addOne(dispatch, scheduleData);
      setSubmitting(false);
      alertActions.notificationSuccess(dispatch, "Schedules Created", name);
      onCreate();
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CreationForms
        title="Create New Schedules"
        message="This will create a new schedule, please check the information entered above is correct."
        handleBackButton={onBack}
        handleSubmit={submit}
        submitting={submitting}
        alert={error}
        image={image}
      >
        <Label>Enter Schedules Details</Label>
        <FormContainerWithHelper>
          <FormLabel>SCHEDULE NAME</FormLabel>
          <Form
            type="text"
            name="name"
            placeholder="Name of Schedules"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </FormContainerWithHelper>
        {/*<TypesContainer>
          <FormLabel>ZONE TYPE</FormLabel>
          <RSelect
            options={listTypes}
            isMulti
            name="zone-types"
            onChange={this.handleMultiSelect}
          />
          <Helper message="You can choose more than one type" />
        </TypesContainer>*/}
        <TypesContainer>
          <Label>Choose Client</Label>
          <RSelect
            options={clients.sort((a, b) => a.name.localeCompare(b.name)).map(client => ({value: client.id, label: client.name}))}
            name="clients"
            onChange={setClient}
          />
          <Helper message="Enter one client" />
        </TypesContainer>
      </CreationForms>
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  clients: state.client.client
});

const connectedCreateSchedule = withRouter(
  connect(mapStateToProps)(CreateSchedule)
);
export { connectedCreateSchedule as CreateSchedule };
