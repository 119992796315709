import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { DefaultColumnContainer, DefaultLabel, DefaultRowContainer } from "_styles";

const Container = styled(DefaultColumnContainer)`
  background-color: #222222;
`;

const TitleRow = styled(DefaultRowContainer)`
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const TextContainer = styled(DefaultColumnContainer)`
  align-items: flex-start;
`;

const Text = styled(DefaultLabel)`
  color: white;
  font-size: 24px;
`;

const ContentContainer = styled(DefaultColumnContainer)`
  flex-grow: 1;
  color: white;
`;

class Scratch extends React.Component {
  render() {
    return (
      <Container>
        <TitleRow>
          <TextContainer>
            <Text>Scratch</Text>
          </TextContainer>
        </TitleRow>
        <ContentContainer>
          {/* {testerz(this.props.songs)} */}
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  songs: state.songs.songs || []
});
const connectedPlayerHistory = withRouter(connect(mapStateToProps)(Scratch));
export { connectedPlayerHistory as Scratch };
