import styled, {css} from "styled-components";

export const PrimaryButton = styled.div(({theme}) => css`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 46px;
  border-radius: 23px;
  padding: 0 22px;
  border: 1px solid ${theme.colors.primary};
  color: ${theme.colors.primary};

  background: linear-gradient(45deg, ${theme.colors.primary} 50%, transparent 50%);
  background-size: 220% 100%;
  background-position: right bottom;
  transition: all .25s ease-in;

  &:hover {
    background-position: left bottom;
    color: black;
  }
`);