import {feedbackConstants} from "_constants";

const initialState = {
  loading: false,
  selectedClient: { id: -1, text: "All Clients"},
  votes: [],
  clientUsers: [],
  clientDetails: {}
};
export function feedback(state = initialState, action) {
  switch (action.type) {
    case feedbackConstants.SET_SELECTED:
      return {
        ...state,
        selectedClient: action.client
      };
    case feedbackConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case feedbackConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        votes: action.votes
      };
    case feedbackConstants.GET_FAILURE:
      return {
        ...state,
        loading: false
      };
    case feedbackConstants.ARCHIVE_SUCCESS:
      return {
        ...state,
        client: state.client.filter(client => client.id === action.id)
      }
    case feedbackConstants.GET_ONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case feedbackConstants.GET_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        clientDetails: action.client
      };
    case feedbackConstants.GET_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case feedbackConstants.CREATE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case feedbackConstants.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case feedbackConstants.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case feedbackConstants.GET_CLIENT_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case feedbackConstants.GET_CLIENT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientUsers: action.client
      };
    case feedbackConstants.GET_CLIENT_USERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
