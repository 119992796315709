import {api} from './api';

export const playerService = {
    get,
    getOne,
    addOne,
    update,
    getSongUrl,
    getPlaylistSongs,
    setVolume,
    archive,
    removeUnusedSongs
};

function getSongUrl(songId) {
    return api("GET", `song/${songId}/url`, undefined, response => response);
}

function getPlaylistSongs(playlistId) {
    return api("GET", `playlist/${playlistId}/songs`, undefined, response => response);
}


function get() {
    return api("GET", "player", undefined, player => player);
}

function getOne(id) {
    return api("GET", `player/${id}`, undefined, player => player);
}

function addOne(player) {
    // TODO NotImplemented
    return api("POST", "player", undefined, playerResponse => playerResponse);
}

function update(id, name, notes) {
    return api("PUT", `player/${id}`, {name, notes}, playerResponse => playerResponse);
}

function setVolume(playerId, volume) {
    return api("GET", `player/${playerId}/setVolume/${volume}`, undefined, player => player);
}

function archive(playerId) {
    return api("GET", `player/${playerId}/archive`, undefined, player => player);
}

function removeUnusedSongs(playerId) {
    return api("POST", `player/${playerId}/removeUnusedSongs`, undefined, player => player);
}
