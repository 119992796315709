import {campaignConstants} from "_constants";
import {campaignService} from "_services";
import {alertActions} from "./alert.actions";

export const campaignActions = {
  setSelected,
  getAllCampaigns,
  getOne,
  createCampaign,
  updateCampaign,
  archive
};

function setSelected(dispatch, selected) {
  dispatch({ type: campaignConstants.SET_SELECTED, campaign: selected });
}

async function getAllCampaigns(dispatch) {
  dispatch({ type: campaignConstants.GET_REQUEST });

  try {
    const campaign = await campaignService.getAllCampaigns();
    dispatch({ type: campaignConstants.GET_SUCCESS, campaign })
  } catch (e) {
    dispatch({ type: campaignConstants.GET_FAILURE, error: e.toString() })
    throw e;
  }
}

function getOne(id) {
  return dispatch => {
    dispatch(request());

    campaignService.getOne(id).then(
      campaign => {
        dispatch(success(campaign));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: campaignConstants.GET_ONE_REQUEST };
  }
  function success(campaign) {
    return { type: campaignConstants.GET_ONE_SUCCESS, campaign };
  }
  function failure(error) {
    return { type: campaignConstants.GET_ONE_FAILURE, error };
  }
}

async function createCampaign(dispatch, campaign) {
  dispatch({ type: campaignConstants.CREATE_CAMPAIGN_REQUEST });
  try {
    const campaignResponse = await campaignService.createCampaign(campaign);
    dispatch({
      type: campaignConstants.CREATE_CAMPAIGN_SUCCESS,
      campaign: campaignResponse
    });
    return campaignResponse;
  } catch (e) {
    dispatch({
      type: campaignConstants.CREATE_CAMPAIGN_FAILURE,
      error: e.toString()
    });
    throw e;
  }
}

async function archive(dispatch, id) {
  dispatch({ type: campaignConstants.CAMPAIGN_ARCHIVE_REQUEST });
  try {
    const campaignResponse = await campaignService.archive(id);
    dispatch({type: campaignConstants.CAMPAIGN_ARCHIVE_SUCCESS, id});
    return campaignResponse;
  } catch (e) {
    dispatch({type: campaignConstants.CAMPAIGN_ARCHIVE_FAILURE, error: e.toString()});
    throw e;
  }
}

async function updateCampaign(dispatch, campaign, id) {
  dispatch({ type: campaignConstants.UPDATE_CAMPAIGN_REQUEST });
  try {
    const campaignResponse = await campaignService.updateCampaign(campaign, id);
    dispatch({
      type: campaignConstants.UPDATE_CAMPAIGN_SUCCESS,
      campaign: campaignResponse
    });
    return campaignResponse;
  } catch (e) {
    dispatch({
      type: campaignConstants.UPDATE_CAMPAIGN_FAILURE,
      error: e.toString()
    });
    throw e;
  }
}
