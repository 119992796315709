import {api, apiUpload} from './api';

export const campaignService = {
    getAllCampaigns,
    getOne,
    createCampaign,
    updateCampaign,
    uploadArtwork,
    archive
};

function getAllCampaigns() {
    return api("GET", "campaign", undefined, campaigns => campaigns);
}

function getOne(id) {
    return api("GET", `campaign/${id}`, undefined, campaign => campaign);
}

function createCampaign(campaign) {
    return api("POST", "campaign", campaign, campaignResponse => campaignResponse);
}

function updateCampaign(campaign, id) {
    return api("PUT", `campaign/${id}`, campaign, campaignResponse => campaignResponse)
}

function uploadArtwork(campaignId, file) {
    return apiUpload(`campaign/${campaignId}/artwork`, "imageFile", file, response => response)
}

function archive(campaignId) {
    return api("GET", `campaign/${campaignId}/archive`, undefined, campaign => campaign);
}
