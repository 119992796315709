import {uiConstants} from '_constants';

export const uiActions = {
  setFocus,
  clearFocus,
  setClientId,
  setNavigationExpandedState
};

function setFocus(dispatch, target) {
  return dispatch({ type: uiConstants.SET_FOCUS, target });
}

function clearFocus(dispatch) {
  return setFocus(dispatch, undefined);
}

function setClientId(dispatch, clientId) {
  return dispatch({ type: uiConstants.SET_CLIENT_ID, clientId })
}

function setNavigationExpandedState(dispatch, expanded) {
  return dispatch({ type: uiConstants.SET_NAVIGATION_EXPANDED_STATE, expanded })
}