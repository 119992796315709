export const feedbackConstants = {
  SET_SELECTED: 'FEEDBACK_SET_SELECTED',

  GET_REQUEST: 'FEEDBACK_GET_REQUEST',
  GET_SUCCESS: 'FEEDBACK_GET_SUCCESS',
  GET_FAILURE: 'FEEDBACK_GET_FAILURE',

  GET_ONE_REQUEST: 'FEEDBACK_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'FEEDBACK_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'FEEDBACK_GET_ONE_FAILURE',

  UPDATE_SONGVOTE_REQUEST: 'FEEDBACK_UPDATE_SONGVOTE_REQUEST',
  UPDATE_SONGVOTE_SUCCESS: 'FEEDBACK_UPDATE_SONGVOTE_SUCCESS',
  UPDATE_SONGVOTE_FAILURE: 'FEEDBACK_UPDATE_SONGVOTE_FAILURE',

  CREATE_CLIENT_REQUEST: 'FEEDBACK_CREATE_CLIENT_REQUEST',
  CREATE_CLIENT_SUCCESS: 'FEEDBACK_CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_FAILURE: 'FEEDBACK_CREATE_CLIENT_FAILURE',

  GET_CLIENT_USERS_REQUEST: 'FEEDBACK_GET_USERS_REQUEST',
  GET_CLIENT_USERS_SUCCESS: 'FEEDBACK_GET_USERS_SUCCESS',
  GET_CLIENT_USERS_FAILURE: 'FEEDBACK_GET_USERS_FAILURE',

  ARCHIVE_REQUEST: 'FEEDBACK_ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'FEEDBACK_ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'FEEDBACK_ARCHIVE_FAILURE',
};
