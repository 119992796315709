export const playlistConstants = {
  SET_ACTIVE_PLAYLIST_ID: 'PLAYLIST_SET_ACTIVE_PLAYLIST_ID',
  SET_SELECTED_PLAYLIST_ID: 'PLAYLIST_SET_SELECTED_PLAYLIST_ID',

  GET_SCHEDULES_REQUEST: 'PLAYLIST_GET_SCHEDULES_REQUEST',
  GET_SCHEDULES_SUCCESS: 'PLAYLIST_GET_SCHEDULES_SUCCESS',
  GET_SCHEDULES_FAILURE: 'PLAYLIST_GET_SCHEDULES_FAILURE',

  GET_ZONES_REQUEST: 'PLAYLIST_GET_ZONES_REQUEST',
  GET_ZONES_SUCCESS: 'PLAYLIST_GET_ZONES_SUCCESS',
  GET_ZONES_FAILURE: 'PLAYLIST_GET_ZONES_FAILURE',

  GET_REQUEST: 'PLAYLIST_GET_REQUEST',
  GET_SUCCESS: 'PLAYLIST_GET_SUCCESS',
  GET_FAILURE: 'PLAYLIST_GET_FAILURE',

  GET_CALENDAR_REQUEST: 'PLAYLIST_GET_CALENDAR_REQUEST',
  GET_CALENDAR_SUCCESS: 'PLAYLIST_GET_CALENDAR_SUCCESS',
  GET_CALENDAR_FAILURE: 'PLAYLIST_GET_CALENDAR_FAILURE',

  GET_DETAIL_REQUEST: 'PLAYLIST_GET_DETAIL_REQUEST',
  GET_DETAIL_SUCCESS: 'PLAYLIST_GET_DETAIL_SUCCESS',
  GET_DETAIL_FAILURE: 'PLAYLIST_GET_DETAIL_FAILURE',

  CREATE_PLAYLIST_REQUEST: 'PLAYLIST_CREATE_PLAYLIST_REQUEST',
  CREATE_PLAYLIST_SUCCESS: 'PLAYLIST_CREATE_PLAYLIST_SUCCESS',
  CREATE_PLAYLIST_FAILURE: 'PLAYLIST_CREATE_PLAYLIST_FAILURE',

  DELETE_PLAYLIST_REQUEST: 'PLAYLIST_DELETE_PLAYLIST_REQUEST',
  DELETE_PLAYLIST_SUCCESS: 'PLAYLIST_DELETE_PLAYLIST_SUCCESS',
  DELETE_PLAYLIST_FAILURE: 'PLAYLIST_DELETE_PLAYLIST_FAILURE',

  ADD_SONGS_REQUEST: 'PLAYLIST_ADD_SONGS_REQUEST',
  ADD_SONGS_SUCCESS: 'PLAYLIST_ADD_SONGS_SUCCESS',
  ADD_SONGS_FAILURE: 'PLAYLIST_ADD_SONGS_FAILURE',

  EDIT_DETAILS_REQUEST: 'PLAYLIST_EDIT_DETAILS_REQUEST',
  EDIT_DETAILS_SUCCESS: 'PLAYLIST_EDIT_DETAILS_SUCCESS',
  EDIT_DETAILS_FAILURE: 'PLAYLIST_EDIT_DETAILS_FAILURE',

  DUPLICATE_REQUEST: 'PLAYLIST_DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS: 'PLAYLIST_DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE: 'PLAYLIST_DUPLICATE_FAILURE',


  DELETE_SONGS_REQUEST: 'PLAYLIST_DELETE_SONGS_REQUEST',
  DELETE_SONGS_SUCCESS: 'PLAYLIST_DELETE_SONGS_SUCCESS',
  DELETE_SONGS_FAILURE: 'PLAYLIST_DELETE_SONGS_FAILURE',

  PUBLISH_REQUEST: 'PLAYLIST_PUBLISH_REQUEST',
  PUBLISH_SUCCESS: 'PLAYLIST_PUBLISH_SUCCESS',
  PUBLISH_FAILURE: 'PLAYLIST_PUBLISH_FAILURE',

  REVERT_REQUEST: 'PLAYLIST_REVERT_REQUEST',
  REVERT_SUCCESS: 'PLAYLIST_REVERT_SUCCESS',
  REVERT_FAILURE: 'PLAYLIST_REVERT_FAILURE',

  GET_SIMILAR_REQUEST: 'PLAYLIST_GET_SIMILAR_REQUEST',
  GET_SIMILAR_SUCCESS: 'PLAYLIST_GET_SIMILAR_SUCCESS',
  GET_SIMILAR_FAILURE: 'PLAYLIST_GET_SIMILAR_FAILURE',

  GET_HISTORY_REQUEST: 'PLAYLIST_GET_HISTORY_REQUEST',
  GET_HISTORY_SUCCESS: 'PLAYLIST_GET_HISTORY_SUCCESS',
  GET_HISTORY_FAILURE: 'PLAYLIST_GET_HISTORY_FAILURE'

};
