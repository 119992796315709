import {alertConstants} from '_constants';

export const alertActions = {
  success,
  error,
  clear,
  notificationSuccess,
  notificationError,
  removeNotification
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function notificationSuccess(dispatch, title, message) {
  dispatch({ type: alertConstants.NOTIFICATION_ADD, message, title, kind: 'success', id: (new Date()).getTime() });
}

function notificationError(dispatch, title, message) {
  dispatch({ type: alertConstants.NOTIFICATION_ADD, message, title, kind: 'danger', id: (new Date()).getTime() });
}

function removeNotification(dispatch, id) {
  dispatch({ type: alertConstants.NOTIFICATION_REMOVE, id });
}