import {locationConstants} from "_constants";

const initialState = {
  loading: false,
  location: [],
  locationDetails: {}
};
export function location(state = initialState, action) {
  switch (action.type) {
    case locationConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case locationConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        location: action.location
      };
    case locationConstants.GET_FAILURE:
      return {
        ...state,
        loading: false
      };
    case locationConstants.GET_ONE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case locationConstants.GET_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        locationDetails: action.location
      }
    case locationConstants.GET_ONE_FAILURE:
      return {
        ...state,
        loading: false
      }
      case locationConstants.ADD_ONE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case locationConstants.ADD_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        //location: action.location
      }
    case locationConstants.ADD_ONE_FAILURE:
      return {
        ...state,
        loading: false
      }
    case locationConstants.ARCHIVE_SUCCESS:
      return {
        ...state,
        location: state.location.filter(location => location.id === action.id)
      }
    default:
      return state;
  }
}
