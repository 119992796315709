import React from "react";
import styled from "styled-components";
import {DefaultButton, DefaultCancelButton} from './Button.style';
import {DefaultLabel} from './Label.style';
import {DefaultColumnContainer, DefaultRowContainer} from './Container.style';
import {DefaultAlert} from './Alert.style';
import {Modal} from "react-bootstrap";
import ReactLoading from "react-loading";
import {IconHover} from "../_components/Standard";
import ReactTooltip from 'react-tooltip';

const Title = styled(DefaultLabel)`
    font-size: 26px;
    color: white;
    width: 50%;
    text-align: left;
`;

const FooterContainer = styled(DefaultColumnContainer)`
    align-items: flex-start;
    width: 100%;
`;

const Label = styled(DefaultLabel)`
    color: white;
    font-weight: bold;
`;

const SubLabel = styled(DefaultLabel)`
    color: white;
    font-size: 14px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
`;

const ButtonsContainer = styled(DefaultRowContainer)`
    margin-top: 20px;
    justify-content: flex-end;
    width: 100%;
`;

const CancelButton = styled(DefaultCancelButton)`
    width: 35%;
    margin-right: 5px;
`;

const OkButton = styled(DefaultButton)`
    width: 35%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AlertContainer = styled(DefaultColumnContainer)`
    align-items: flex-end;
    width: 100%;
    margin-top: 10px;
`;

const Header = styled(Modal.Header)`
    padding: 30px;
`;

const Body = styled(Modal.Body)`
    padding: 30px;
`;

const Footer = styled(Modal.Footer)`
    padding: 30px;
`;

const AlertMessage = styled(DefaultAlert)``;

export const CreationForms = ({
                                  alert,
                                  children,
                                  title,
                                  message,
                                  deleting = false,
                                  onDelete,
                                  handleBackButton,
                                  handleSubmit,
                                  submitting,
                                  level = 10001
                              }) => (
    <Modal show={true} backdropClassName={level > 10001 ? 'backdrop-second-level' : ""} className="modal-pick" size="lg"
           onHide={handleBackButton} style={{zIndex: level}}>
        <Header>
            <Title>{title}</Title>
            {(onDelete && !deleting) &&
                <IconHover color='white' opacity=".3" icon="trash-alt" onClick={onDelete} data-tip="Archive"/>}
            {deleting && <ReactLoading height={24} width={24} type="spin" color="white"/>}
            <ReactTooltip effect='solid' place="bottom" type='info'/>
        </Header>
        <Body>{children}</Body>
        <Footer>
            <FooterContainer>
                <Label>Confirm</Label>
                <SubLabel>{message}</SubLabel>
                <ButtonsContainer>
                    <CancelButton onClick={handleBackButton}>Cancel</CancelButton>
                    <OkButton onClick={submitting ? () => {
                    } : handleSubmit}>
                        {!submitting ? "Ok" : (<ReactLoading height={24} width={24} type="bars" color="white"/>)}
                    </OkButton>
                </ButtonsContainer>
                <AlertContainer>
                    {alert !== undefined && <AlertMessage>{alert.message || alert.toString()}</AlertMessage>}
                </AlertContainer>
            </FooterContainer>
        </Footer>
    </Modal>
);
