import React from "react";
import styled from "styled-components";
import {DefaultRowContainer} from "./Container.style";

const SortSuggestedButtonContainer = styled(DefaultRowContainer)`
  cursor: pointer;
  height: 38px;
  min-width: 165px;
  width: auto;
  margin-top: 10px;
  border-radius: 21px;
  border: 1px solid #F3F3F3;
  background-color: transparent;
  justify-content: center;
  align-items: center;
`;

const SortSuggetedButtonTitle = styled.div`
  color: white;
  margin: 0;
  user-select: none;
  margin-right: 12px;
`;

export const SortSuggestedButton = ({onClick}) => (
    <SortSuggestedButtonContainer onClick={ onClick }>
        <SortSuggetedButtonTitle>Sort Suggested</SortSuggetedButtonTitle>
    </SortSuggestedButtonContainer>
);