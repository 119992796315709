import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg.attrs({
  className: "radio-svg"
})`
  fill: none;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  circle {
    stroke-width: 1;
    stroke: white;
    fill: #464646;
  }
  path {
    &.inner {
      stroke: #f1a822;
      stroke-width: 6;
      stroke-dasharray: 19;
      stroke-dashoffset: 19;
    }
    &.outer {
      stroke: #f1a822;
      stroke-width: 2;
      stroke-dasharray: 57;
      stroke-dashoffset: 57;
    }
  }
`;

const Input = styled.input.attrs({
  className: "radio-input",
  type: "radio"
})`
  display: none;
  &:checked + ${SVG} {
    path {
      transition: all 0.4s ease;
      &.inner {
        stroke-dashoffset: 38;
        transition-delay: 0.3s;
      }
      &.outer {
        stroke-dashoffset: 0;
      }
    }
  }
`;

const Text = styled.span.attrs({
  className: "radio-text"
})`
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: normal;
  color: white;
`;

const RadioLabel = styled.label.attrs({
  className: "radio-label"
})`
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.orientation === "row" ? 'space-evenly': 'center'};;
  user-select: none;

  ${Text} {
    margin-left: 10px;
  }

  @media screen and (max-width: 480px) {
    display: block;
    float: none;
  }
`;

export const RadioButton = ({
  id,
  name,
  value,
  defaultValue,
  children,
  handleChange,
  ...props
}) => (
    <RadioLabel {...props}>
      <Input id={id} name={name} value={value} checked={defaultValue === value} onChange={handleChange}/>
      <SVG width={"20"} height={"20"} viewBox={`0 0 20 20`}>
        <circle cx={10} cy={10} r={9} />
        <path
          d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
          className="inner"
        />
        <path
          d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
          className="outer"
        />
      </SVG>
      <Text>{children}</Text>
    </RadioLabel>
);