import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getReactSelectList} from "_helpers";
import {playlistActions, scheduleActions} from "_actions";
import {
    CheckboxButton,
    CreationForms,
    DefaultButton,
    DefaultColumnContainer,
    DefaultFormControl,
    DefaultGrayButton,
    DefaultLabel,
    DefaultRowContainer,
    Helper,
    theme
} from "_styles";
import Select from "react-select";
import DatePicker from "react-datepicker";
import image from "_assets/images/brand-wong.jpg";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const ColumnContainer = styled(DefaultColumnContainer)`
  width: 100%;
`;

const RowContainer = styled(DefaultRowContainer)`
  width: 100%;
  align-items: center;

  & + & {
    margin-top: 20px;
  }
`;

const NoHelperContainer = styled(DefaultRowContainer)`
  width: 100%;
  align-items: center;
  margin-top: 10px;
`;

const PriorityContainer = styled(DefaultRowContainer)`
  width: 50%;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1313px) {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
  }
`;

const Label = styled(DefaultLabel)`
  min-width: 100px;
  max-width: 100px;
  color: white;
  text-align: right;
  margin-right: 20px;
  overflow-wrap: break-word;
  white-space: normal;
`;

const Text = styled(DefaultLabel)`
  color: white;
  margin-right: 20px;
`;

const RSelectHalf = styled(Select)`
  width: 50%;
  min-width: 200px;
`;

const FormContainer = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
`;

const FormContainerRow = styled(DefaultRowContainer)`
  justify-content: space-between;
  width: 100%;
  align-items: center;
  overflow-wrap: break-word;
  white-space: normal;

  @media (max-width: 1313px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NumberFormControl = styled(DefaultFormControl)`
  width: 30%;
  margin-right: 10px;
  min-width: 100px;
`;

const RSelect = styled(Select)`
  width: 100%;
  min-width: 200px;
`;

const Line = styled.hr``;

const ButtonsContainer = styled(DefaultRowContainer)`
  width: 100%;
`;

const InnerContainer = styled(DefaultRowContainer)`
  flex-grow: 1;

  & + & {
    margin-left: 2px;
  }
`;

const DatePickerWrapper = styled.div`
  width: 100%;
`;

const DatePickerWrapperMargin = styled(DatePickerWrapper)`
  margin-left: 10px;
`;

const DatePickerButton = styled.button`
  border: 1px solid ${props => props.theme.optionalGray};
  color: white;
  padding: 4px 4px 4px 10px;
  letter-spacing: 1px;
  height: 50px;
  background-color: ${props => props.theme.darkGray};
  outline: none;
  box-shadow: none;
  width: 100%;
  text-align: left;

  ::placeholder {
    color: white;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: ${props => props.theme.optionalGray};
    border: 0;
    outline: none;
    box-shadow: none;
    caret-color: white;
    color: white;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const HalfDatePickerButton = styled(DatePickerButton)`
  width: 50%;
`;

class DatePickerInput extends React.Component {
    render() {
        return this.props.half ? (
            <HalfDatePickerButton onClick={this.props.onClick}>
                {this.props.value}
            </HalfDatePickerButton>
        ) : (
            <DatePickerButton onClick={this.props.onClick}>
                {this.props.value}
            </DatePickerButton>
        );
    }
}

const Active = styled(DefaultButton)`
  background-color: ${props => props.theme.highlight};
  color: white;
  align-items: flex-end;
  height: 35px;
  padding: 0;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.lightYellow};
    color: white;
  }
`;

const Inactive = styled(DefaultGrayButton)`
  color: white;
  align-items: flex-end;
  height: 35px;
  padding: 0;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.darkGray};
    color: white;
  }

  & + & {
    margin-left: 5px;
  }
`;

let typeOptions = [
    {variable: "action", value: "Play Playlist", label: "Play Playlist"},
    {variable: "action", value: "Silence", label: "Silence"},
    {variable: "action", value: "Insert Playlist", label: "Insert Playlist"},
    {variable: "action", value: "Mix Playlist", label: "Mix Playlist"}
];

let modeOptions = [
    {variable: "mode", value: "SHUFFLE", label: "Shuffle"},
    {variable: "mode", value: "ORDER", label: "Order"}
];

let repeatOptions = [
    {variable: "repeat", value: "Do not Repeat", label: "Do not Repeat"},
    {variable: "repeat", value: "Weekly", label: "Weekly"},
    {variable: "repeat", value: "Bi-Weekly", label: "Bi-Weekly"},
    {variable: "repeat", value: "Monthly", label: "Monthly"}
];

let transitionOptions = [
    {variable: "transition", value: "CUE", label: "Cue"},
    {variable: "transition", value: "INTERRUPT", label: "Interrupt"},
    {variable: "transition", value: "BREAK", label: "Break"}
];

let repeatEndsOptions = [
    {variable: "repeatEnds", value: "Never", label: "Never"},
    {variable: "repeatEnds", value: "On A Date", label: "On A Date"}
];

let oneEveryOptions = [
    {variable: "oneEveryType", value: "Tracks", label: "Tracks"},
    {variable: "oneEveryType", value: "Minutes", label: "Minutes"}
];

let daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
];

class AddDayPart extends React.Component {
    state = {
        action: "Play Playlist",
        oneEveryNumber: "",
        oneEveryType: "Tracks",
        playlist: "",
        mode: "SHUFFLE",
        allDay: false,
        start: "",
        end: "",
        allYear: false,
        startDate: new Date(),
        repeat: "Weekly",
        everyDay: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        repeatEnds: "Never",
        endDate: "",
        transition: "CUE",
        priority: false,
        shouldClose: false,
    };

    componentDidMount() {
        this.props.dispatch(playlistActions.get());
    }

    componentDidUpdate() {
        if (this.state.shouldClose && this.props.alert.type === "alert-success") {
            this.props.handleBackButton();
        }
    }

    handleFormChange = (variable, e) => {
        this.setState({[variable]: e.target.value});
    };

    handleSelect = e => {
        let vr = e.variable;
        this.setState({[vr]: e.value});
    };

    handleMultiSelect = e => {
        if (e[0] !== undefined) {
            let select = e[0].variable;
            let list = [];
            e.forEach(row => {
                list.push(row.value);
            });

            this.setState({[select]: list});
        }
    };

    handleCheckbox = (variable, e) => {
        this.setState({[variable]: e.target.checked});
    };

    handleToggle = day => {
        this.setState({[day]: !this.state[day]});
    };

    handleDateChange = (variable, value) => {
        this.setState({
            [variable]: value
        });
    };

    handleSubmit = () => {
        const {
            everyDay,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            playlist,
            startTime,
            endTime,
            startDate,
            endDate,
            mode,
            transition,
            priority
        } = this.state;

        let pl = ""
        if (playlist === "") {
            pl = 1
        } else pl = playlist;

        let finalDayPart = {
            everyday: everyDay,
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday,
            playlist: {
                id: pl
            },
            startTime: startTime,
            endTime: endTime,
            startDate: startDate,
            endDate: endDate,
            playbackMode: mode,
            transitionType: transition,
            insertSongsMode: false,
            priorityOverride: priority
        };

        this.setState({shouldClose: true}, () => {
            this.props.dispatch(scheduleActions.addOne(finalDayPart))
        })
    };

    render() {
        const {handleBackButton, playlist = []} = this.props;
        const {
            action,
            oneEveryNumber,
            allYear,
            startDate,
            endDate,
            startTime,
            endTime,
            allDay,
            everyDay,
            repeat,
            repeatEnds
        } = this.state;

        let listPlaylist = getReactSelectList(playlist, "playlist");

        return (
            <ThemeProvider theme={theme}>
                <CreationForms
                    message="This will add a new day part"
                    handleBackButton={handleBackButton}
                    handleSubmit={this.handleSubmit}
                    alert={alert}
                    image={image}
                    title="Add Day Part"
                >
                    <ColumnContainer>
                        <RowContainer>
                            <Label>Action</Label>
                            <FormContainerRow>
                                <RSelectHalf
                                    defaultValue={typeOptions[0]}
                                    options={typeOptions}
                                    onChange={this.handleSelect}
                                />

                                    <PriorityContainer>
                                        <Text>Priority</Text>
                                        <CheckboxButton
                                            onChange={e => this.handleCheckbox("priority", e)}
                                        />
                                    </PriorityContainer>

                            </FormContainerRow>
                        </RowContainer>
                        {action === "Insert Playlist" && (
                            <RowContainer>
                                <Label>Insert One Track Every</Label>
                                <FormContainerRow>
                                    <NumberFormControl
                                        placeholder="Number"
                                        value={oneEveryNumber}
                                        type="number"
                                        onChange={e => this.handleFormChange("oneEveryNumber", e)}
                                    />
                                    <RSelect
                                        defaultValue={oneEveryOptions[0]}
                                        options={oneEveryOptions}
                                        onChange={this.handleSelect}
                                    />
                                </FormContainerRow>
                            </RowContainer>
                        )}
                        {action !== "Silence" && (
                            <React.Fragment>
                                <RowContainer>
                                    <Label>Playlist</Label>
                                    <FormContainer>
                                        {action !== "Mix Playlist" ? (
                                            <RSelect
                                                options={listPlaylist}
                                                onChange={this.handleSelect}
                                            />
                                        ) : (
                                            <RSelect
                                                options={listPlaylist}
                                                isMulti
                                                onChange={this.handleMultiSelect}
                                            />
                                        )}
                                        <Helper message="Choose Playlists"/>
                                    </FormContainer>
                                </RowContainer>

                                <NoHelperContainer>
                                    <Label>Mode</Label>
                                    <FormContainerRow>
                                        <RSelectHalf
                                            defaultValue={modeOptions[0]}
                                            options={modeOptions}
                                            onChange={this.handleSelect}
                                        />
                                    </FormContainerRow>
                                </NoHelperContainer>
                            </React.Fragment>
                        )}
                        <Line/>
                        <RowContainer>
                            <Label>All Day</Label>
                            <FormContainer>
                                <CheckboxButton
                                    onChange={e => this.handleCheckbox("allDay", e)}
                                />
                            </FormContainer>
                        </RowContainer>
                        {!allDay && (
                            <React.Fragment>
                                <RowContainer>
                                    <Label>Start</Label>
                                    <DatePickerWrapper>
                                        <DatePicker
                                            selected={startTime}
                                            onChange={value =>
                                                this.handleDateChange("startTime", value)
                                            }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            dateFormat="h:mm aa"
                                            timeCaption="Time"
                                            customInput={<DatePickerInput half/>}
                                            placeholderText="Start Time"
                                        />
                                    </DatePickerWrapper>
                                </RowContainer>
                                <RowContainer>
                                    <Label>End</Label>
                                    <DatePickerWrapper>
                                        <DatePicker
                                            selected={endTime}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            dateFormat="h:mm aa"
                                            timeCaption="Time"
                                            onChange={value =>
                                                this.handleDateChange("endTime", value)
                                            }
                                            customInput={<DatePickerInput half/>}
                                            placeholderText="End Time"
                                        />
                                    </DatePickerWrapper>
                                </RowContainer>
                            </React.Fragment>
                        )}
                        <Line/>
                        <RowContainer>
                            <Label>All Year</Label>
                            <FormContainer>
                                <CheckboxButton
                                    onChange={e => this.handleCheckbox("allYear", e)}
                                />
                            </FormContainer>
                        </RowContainer>
                        {!allYear && (
                            <RowContainer>
                                <Label>Start Date</Label>
                                <DatePickerWrapper>
                                    <DatePicker
                                        value={startDate}
                                        onChange={value =>
                                            this.handleDateChange("startDate", value)
                                        }
                                        customInput={<DatePickerInput half/>}
                                    />
                                </DatePickerWrapper>
                            </RowContainer>
                        )}
                        <Line/>
                        <RowContainer>
                            <Label>Repeat</Label>
                            <FormContainerRow>
                                <RSelectHalf
                                    defaultValue={repeatOptions[1]}
                                    options={repeatOptions}
                                    onChange={this.handleSelect}
                                />
                            </FormContainerRow>
                        </RowContainer>
                        {repeat !== "Do not Repeat" && (
                            <React.Fragment>
                                <RowContainer>
                                    <Label>Every Day</Label>
                                    <FormContainer>
                                        <CheckboxButton
                                            value={everyDay}
                                            onChange={e => this.handleCheckbox("everyDay", e)}
                                        />
                                    </FormContainer>
                                </RowContainer>
                                {!everyDay && (
                                    <RowContainer>
                                        <Label>On Days</Label>
                                        <ButtonsContainer>
                                            {daysOfWeek.map((dw, index) => {
                                                let day = dw.substring(0, 3);
                                                day = day.charAt(0).toUpperCase() + day.slice(1);

                                                return (
                                                    <InnerContainer key={`day-week-${index}`}>
                                                        {this.state[dw] ? (
                                                            <Active onClick={() => this.handleToggle(dw)}>
                                                                {day}
                                                            </Active>
                                                        ) : (
                                                            <Inactive onClick={() => this.handleToggle(dw)}>
                                                                {day}
                                                            </Inactive>
                                                        )}
                                                    </InnerContainer>
                                                );
                                            })}
                                        </ButtonsContainer>
                                    </RowContainer>
                                )}
                                <RowContainer>
                                    <Label>Repeat Ends</Label>
                                    <FormContainerRow>
                                        <RSelectHalf
                                            defaultValue={repeatEndsOptions[0]}
                                            options={repeatEndsOptions}
                                            onChange={this.handleSelect}
                                        />
                                        {repeatEnds !== "Never" && (
                                            <DatePickerWrapperMargin>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={value =>
                                                        this.handleDateChange("endDate", value)
                                                    }
                                                    customInput={<DatePickerInput/>}
                                                />
                                            </DatePickerWrapperMargin>
                                        )}
                                    </FormContainerRow>
                                </RowContainer>
                            </React.Fragment>
                        )}
                        <RowContainer>
                            <Label>Transition</Label>
                            <FormContainerRow>
                                <RSelectHalf
                                    defaultValue={transitionOptions[0]}
                                    options={transitionOptions}
                                    onChange={this.handleSelect}
                                />
                            </FormContainerRow>
                        </RowContainer>
                    </ColumnContainer>
                </CreationForms>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.schedule.loading,
    alert: state.alert,
    playlist: state.playlists.playlist
});

const connectedAddDayPart = withRouter(connect(mapStateToProps)(AddDayPart));
export {connectedAddDayPart as AddDayPart};
