export const zonesConstants = {
  GET_REQUEST: 'ZONES_GET_REQUEST',
  GET_SUCCESS: 'ZONES_GET_SUCCESS',
  GET_FAILURE: 'ZONES_GET_FAILURE',

  GET_ONE_REQUEST: 'ZONES_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'ZONES_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'ZONES_GET_ONE_FAILURE',

  UPDATE_ZONE_REQUEST: 'ZONES_UPDATE_ZONE_REQUEST',
  UPDATE_ZONE_SUCCESS: 'ZONES_UPDATE_ZONE_SUCCESS',
  UPDATE_ZONE_FAILURE: 'ZONES_UPDATE_ZONE_FAILURE',

  CHANGE_VOLUME_REQUEST: 'ZONES_CHANGE_VOLUME_REQUEST',
  CHANGE_VOLUME_SUCCESS: 'ZONES_CHANGE_VOLUME_SUCCESS',
  CHANGE_VOLUME_FAILURE: 'ZONES_CHANGE_VOLUME_FAILURE',

  GET_PAGINATION_REQUEST: 'ZONES_GET_PAGINATION_REQUEST',
  GET_PAGINATION_SUCCESS: 'ZONES_GET_PAGINATION_SUCCESS',
  GET_PAGINATION_FAILURE: 'ZONES_GET_PAGINATION_FAILURE',

  ADD_ONE_REQUEST: 'ZONES_ADD_ONE_REQUEST',
  ADD_ONE_SUCCESS: 'ZONES_ADD_ONE_SUCCESS',
  ADD_ONE_FAILURE: 'ZONES_ADD_ONE_FAILURE',

  UPDATE_ONE_REQUEST: 'ZONES_UPDATE_ONE_REQUEST',
  UPDATE_ONE_SUCCESS: 'ZONES_UPDATE_ONE_SUCCESS',
  UPDATE_ONE_FAILURE: 'ZONES_UPDATE_ONE_FAILURE',

  ADD_PLAYER_REQUEST: 'ZONES_ADD_PLAYER_REQUEST',
  ADD_PLAYER_SUCCESS: 'ZONES_ADD_PLAYER_SUCCESS',
  ADD_PLAYER_FAILURE: 'ZONES_ADD_PLAYER_FAILURE',

  ADD_SCHEDULE_REQUEST: 'ZONES_ADD_SCHEDULE_REQUEST',
  ADD_SCHEDULE_SUCCESS: 'ZONES_ADD_SCHEDULE_SUCCESS',
  ADD_SCHEDULE_FAILURE: 'ZONES_ADD_SCHEDULE_FAILURE',

  DELETE_PLAYER_REQUEST: 'ZONES_DELETE_PLAYER_REQUEST',
  DELETE_PLAYER_SUCCESS: 'ZONES_DELETE_PLAYER_SUCCESS',
  DELETE_PLAYER_FAILURE: 'ZONES_DELETE_PLAYER_FAILURE',

  DELETE_ZONE_REQUEST: 'ZONES_DELETE_ZONE_REQUEST',
  DELETE_ZONE_SUCCESS: 'ZONES_DELETE_ZONE_SUCCESS',
  DELETE_ZONE_FAILURE: 'ZONES_DELETE_ZONE_FAILURE',

  DELETE_SCHEDULE_REQUEST: 'ZONES_DELETE_SCHEDULE_REQUEST',
  DELETE_SCHEDULE_SUCCESS: 'ZONES_DELETE_SCHEDULE_SUCCESS',
  DELETE_SCHEDULE_FAILURE: 'ZONES_DELETE_SCHEDULE_FAILURE',

  PLAY_HISTORY_REQUEST: 'ZONES_PLAY_HISTORY_REQUEST',
  PLAY_HISTORY_SUCCESS: 'ZONES_PLAY_HISTORY_SUCCESS',
  PLAY_HISTORY_FAILURE: 'ZONES_PLAY_HISTORY_FAILURE',

  REMOVE_FORCED_PLAYLIST_REQUEST: 'REMOVE_FORCED_PLAYLIST_REQUEST',
  REMOVE_FORCED_PLAYLIST_SUCCESS: 'REMOVE_FORCED_PLAYLIST_SUCCESS',
  REMOVE_FORCED_PLAYLIST_FAILURE: 'REMOVE_FORCED_PLAYLIST_FAILURE'
};
