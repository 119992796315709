import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Select from "react-select";
import styled, {ThemeProvider} from "styled-components";
import {alertActions, clientActions, userActions} from "_actions";
import image from "_assets/images/Record-Cropped.png";
import {getAbility} from "_helpers";
import {
    Active,
    CreationForms,
    DefaultBlackFormControl,
    DefaultColumnContainer,
    DefaultLabel,
    DefaultRowContainer,
    FormRadioButton,
    Helper,
    Inactive,
    theme
} from "_styles";
import {ValidateEmail} from "./Utils/ValidateEmail";
import {ModalDecisionBuilder} from "./Utils";

const DetailsContainer = styled(DefaultColumnContainer)`
  margin-top: 20px;
  width: 100%;
  align-items: flex-start;
`;

const Label = styled(DefaultLabel)`
  color: white;
`;

const RowContainer = styled(DefaultRowContainer)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

const HalfFormContainer = styled(DefaultColumnContainer)`
  width: 49%;
  align-items: flex-start;
`;

const FormContainerWithoutHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-top: 10px;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
`;

const FormLabel = styled(DefaultLabel)`
  color: ${props => props.theme.formlabel};
  font-size: 14px;
`;

const HalfForm = styled(DefaultBlackFormControl)`
  margin-top: 5px;
`;

const Form = styled(DefaultBlackFormControl)`
  margin-top: 5px;
`;

const ClientListContainer = styled(DefaultColumnContainer)`
  width: 100%;
  margin-top: 20px;
  align-items: flex-start;
`;

const RSelect = styled(Select)`
  width: 100%;
  margin-top: 10px;
  color:black;
`;

const ALL_CLIENTS_INDEX = -1;
const AllClientsOption = { value: ALL_CLIENTS_INDEX, label: "All Clients" };
const getClientOptions = clients => clients.sort((a, b) => a.name.localeCompare(b.name)).map(client => ({value: client.id, label: client.name}))

const CreateUser = ({ user = undefined, onBack, onCreate, dispatch, clientList }) => {
  const isEditing = user !== undefined;
  const isCreating = !isEditing;

  const [userType, setUserType] = useState(isEditing ? user.userType : 'CURATOR');
  const [firstName, setFirstName] = useState(isEditing ? user.firstName : '');
  const [lastName, setLastName] = useState(isEditing ? user.lastName : '');
  const [blocked,setBlocked] = useState(isEditing ? user.blocked : false);
  const [phone, setPhone] = useState(isEditing ? user.phoneNumber : '');
  const [email, setEmail] = useState(isEditing ? user.email : '');
  const [password, setPassword] = useState('');
  const [showConfirmBlocking, setShowConfirmBlocking] = useState(false);
  const [clients, setClients] = useState(isEditing ? (
    user.allClients ? [AllClientsOption] : getClientOptions(user.clientsAssigned)
  ) : []);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    clientActions.getAllClients(dispatch);
  }, []);

  const getAllClientsIndex = entries => entries.findIndex(entry => entry.value === ALL_CLIENTS_INDEX);
  const allClientsSelected = entries => getAllClientsIndex(entries) !== -1;

  const handleClientSelect = e => {
    const allClientsIndex = getAllClientsIndex(e);
    if (allClientsIndex === e.length - 1) {
      // Just added, clear all other entries
      setClients([e[e.length - 1]])
      return;
    }

    if (e.length > 1 && allClientsIndex !== -1) {
      setClients(e.splice(1));
      return;
    }

    setClients(e);
  };
  const handleBlockClicked = () => {
      setBlocked(!blocked);
  }

  const anyFieldMissing = () => firstName === "" || lastName === "" || email === "" || (isCreating && password === "") || clients.length === 0;

  const checkSubmit = async () => {
    if (blocked && !user.blocked) {
      setShowConfirmBlocking(true);
    } else {
      submit();
    }

  }

  const submit = async () => {
    if (!ValidateEmail(email)) {
      setError('Please insert a valid email');
      return;
    }

    if (anyFieldMissing()) {
      setError('Please fill out all the required fields');
      return;
    }

    const isAllClientsSelected = allClientsSelected(clients);
    const clientComposite = !isAllClientsSelected ? {
      clientsAssigned: clients.map(el => ({ id: el.value })),
    } : {};

    const idComposite = isEditing ? {
      id: user.id
    } : {};

    const passComposite = password !== "" ? {
      password
    } : {};

    const userData = {
      ...idComposite,
      internal: true,
      userType: userType.toUpperCase(),
      firstName,
      lastName,
      phoneNumber: phone,
      email,
      ...passComposite,
      allClients: isAllClientsSelected,
      ...clientComposite,
      blocked
    };

    setSubmitting(true);
    setError('');

    try {
      isEditing ? await userActions.updateOne(dispatch, userData, user.id) : await userActions.addOne(dispatch, userData);
      setSubmitting(false);
      alertActions.notificationSuccess(dispatch, isEditing ? "User updated" : "User created", `${firstName} ${lastName}`);
      onCreate();
    } catch (e) {
      setError(e.userMessage || e.toString());
      setSubmitting(false);
    }
  };

  const clientOptions = [AllClientsOption].concat(getClientOptions(clientList));

  let onCloseConfirmBlocking = () => {
    setShowConfirmBlocking(false);
  };
  let onConfirmBlocking = () =>{
    setShowConfirmBlocking(false);
    submit();
  };
  return (
    <ThemeProvider theme={theme}>
      <CreationForms
        message={`This will ${isEditing ? "edit an" : "create a new"} internal user. Please check the information entered above is correct.`}
        handleBackButton={onBack}
        handleSubmit={checkSubmit}
        submitting={submitting}
        alert={error}
        image={image}
        title={isEditing ? "Edit User" : "Create New User"}
      >
        <Label>Choose User Type</Label>
        <FormRadioButton
          handleChange={setUserType}
          name={`user-type-button-group`}
          value={userType}
          options={["CURATOR", "MANAGER", "ADMIN"]}
        />
        <DetailsContainer>
          <Label>Enter User Details</Label>
          {isCreating && (
            <RowContainer>
              <>
                <HalfFormContainer>
                  <FormLabel>FIRST NAME</FormLabel>
                  <HalfForm
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                </HalfFormContainer>
                <HalfFormContainer>
                  <FormLabel>LAST NAME</FormLabel>
                  <HalfForm
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                </HalfFormContainer>
              </>
            </RowContainer>
          )}
          <FormContainerWithoutHelper>
            <FormLabel>PHONE NUMBER</FormLabel>
            <Form
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
            <Helper message="You can choose this later" />
          </FormContainerWithoutHelper>
          <FormContainerWithHelper>
            <FormLabel>EMAIL</FormLabel>
            <Form
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Helper message="The email address will be used as the username" />
          </FormContainerWithHelper>
          <FormContainerWithHelper>
            <FormLabel>PASSWORD</FormLabel>
            <Form
              type="text"
              disabled={!getAbility().can("edit", "internal user")}
              name="password"
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Helper message="Enter a temporary password for the user to change" />
          </FormContainerWithHelper>
        </DetailsContainer>
        <ClientListContainer>
          <Label>Choose Client List</Label>
          <RSelect
            options={clientOptions}
            isMulti
            name="clients"
            onChange={handleClientSelect}
            value={clients}
          />
          <Helper message="Enter one or more clients, the user will only be able to see these clients" />
        </ClientListContainer>
        {isEditing && (blocked?
            (<Active style={{width:200,backgroundColor:"red"}} onClick={handleBlockClicked}>
          Blocked
        </Active>
          ) : (
        <Inactive style={{width:200,backgroundColor:"green"}} onClick={handleBlockClicked}>
          Unblocked
        </Inactive>
        ))}
        {showConfirmBlocking && (
            <ModalDecisionBuilder
                title={"Are you sure you want to block this User?"}
                handleClose={onCloseConfirmBlocking}
                handleAccept={onConfirmBlocking}
            />
        )}
      </CreationForms>
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  users: state.users.users,
  clientList: state.client.client
});

const connectedCreateUser = withRouter(connect(mapStateToProps)(CreateUser));
export { connectedCreateUser as CreateUser };
