import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { DefaultCancelButton, DefaultButton } from "_styles";

const NoButton = styled(DefaultCancelButton)`
  width: 100px;
  color: white;
`;

const YesButton = styled(DefaultButton)`
  width: 100px;
  color: black;
`;

const Title = styled.div`
  color: black;
  width: 100%;
`;

export const ModalDecision = ({
  title,
  message,
  handleAccept,
  handleClose
}) => {
  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>
        <Title>{message}</Title>
        <div style={{height: '16px'}} />
        <Title>Are you sure you want to proceed?</Title>
      </Modal.Body>
      <Modal.Footer>
        <NoButton onClick={handleClose}>No</NoButton>
        <YesButton onClick={handleAccept}>Yes</YesButton>
      </Modal.Footer>
    </Modal>
  );
};
