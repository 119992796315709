import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";

export const ShowTrigger = ({onClick, children}) => {
    const show = e => {
        //const rect = e.currentTarget.getBoundingClientRect();
        // onClick(rect.left, rect.top);
        onClick(e.pageX, e.pageY, e);
    }

    return (<div onClick={show}>
        {children}
    </div>)
};

export const TriggerContainer = ({x, y, onHideMenu, children, hide}) => {
    // Fixed position to escape any `position: relative` parent
    const Container = styled.div`
      position: fixed;
      top: ${y}px;
      left: ${x}px;
      z-index: 10000;
    `;

    const ref = useRef();
    const mouseDown = e => {
        if (hide !== false && ref.current && !ref.current.contains(e.target)) {
            e.stopPropagation();
            onHideMenu();
        }
    }

    const keyDown = e => {
        if (hide !== false && e.key === "Escape") {
            e.stopPropagation();
            onHideMenu();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', mouseDown);
        document.addEventListener('keydown', keyDown);
        return () => {
            document.removeEventListener('mousedown', mouseDown);
            document.removeEventListener('keydown', keyDown);
        }
    }, [hide]);

    return (<Container ref={ref}>
        {children}
    </Container>)
}

export const TaleaContextMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width ? props.width : '227px'};
  border: 1px solid #525252;
  border-radius: 10px;
  background-color: #24232C;
  box-shadow: 0 5px 21px 0 rgba(0, 0, 0, 0.5);
`;

const getOffset = el => {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX, top: rect.top + window.scrollY
    };
}

export const SingleContextMenu = ({
                                      onMenuClosed, triggerComponent, offset, children, hide = true
                                  }) => {
    const [show, setShow] = useState(false);
    const [x, setX] = useState(false);
    const [y, setY] = useState(false);

    const handleShow = (x, y, e) => {
        setShow(true);
        if (offset) {
            const el = getOffset(e.currentTarget);
            setX(el.left + offset.left);
            setY(el.top + offset.top);
        } else {
            setX(x);
            setY(y);
        }
    }

    let handleHide = (id) => {
        if (hide) {
            setShow(false);
        }
        if (onMenuClosed) onMenuClosed();
    }

    return (<>
        <ShowTrigger onClick={handleShow}>
            {triggerComponent}
        </ShowTrigger>
        {show && (<TriggerContainer x={x} y={y} hide={hide} onHideMenu={handleHide}>
            {children({
                hide: handleHide
            })}
        </TriggerContainer>)}
    </>);
}