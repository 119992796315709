import {api, apiUpload} from './api';

export const locationService = {
  get,
  getOne,
  addOne,
  updateOne,
  archive,
  uploadArtwork
};

function get() {
  return api("GET", "location", undefined, location => location);
}

function getOne(id) {
  return api("GET", `location/${id}`, undefined, location => location);
}

function addOne(location) {
  return api("POST", "location", location, locationResponse => locationResponse);
}

function updateOne(location, id) {
  return api("PUT", `location/${id}`, location, locationResponse => locationResponse);
}

function archive(id) {
  return api("GET", `location/${id}/archive`, undefined, location => location);
}

function uploadArtwork(locationId, file) {
  return apiUpload( `location/${locationId}/artwork`, "imageFile", file,response => response)
}
