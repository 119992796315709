import React from "react";
import styled, {ThemeProvider} from "styled-components";

import {theme} from './Theme';

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  text-align: left;
`;

const Title = styled.div`
  color: white;
  white-space: nowrap;
  // text-overflow: ellipsis;
`;

const HighlightTitle = styled(Title)`
  color: ${props => props.theme.highlight};
`;

const Text = styled.div`
  color: ${props => props.theme.formlabel};
  white-space: nowrap;
  // text-overflow: ellipsis;
`;

export const SmallRow = ({ title, name, album, artist }) => (
  <ThemeProvider theme={theme}>
    <Container>
      {name === title ? (
        <HighlightTitle>{title}</HighlightTitle>
      ) : (
        <Title>{title}</Title>
      )}
      <Text>{`${artist !== undefined ? artist.name : "BUGBUG"} : ${album !== undefined ? album.name : "BUGBUG"}`}</Text>
    </Container>
  </ThemeProvider>
);
