import React from "react";
import styled from "styled-components";
import {DefaultButton, DefaultColumnContainer, DefaultLabel, DefaultRowContainer} from "_styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import comesandgoes from "_assets/images/testing/ComesAndGoes.png";
import cominghome from "_assets/images/testing/Leon Bridges.png";
import badselfportraits from "_assets/images/testing/Lake Street Drive.png";
import allweeverknew from "_assets/images/testing/Signs of Light.jpg";
import alreadygone from "_assets/images/testing/Let's- EP.jpg";
import johnnynash from "_assets/images/testing/johnny nash.png";

const Wrapper = styled.div`
  height: 100%;
`;

/*const BackgroundImage = styled.img`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  clip: rect(80px, 80px, 80px, 80px);
  min-height: 100vh;
  filter: blur(8px);
  object-fit: cover;
`;*/

const MainContainer = styled(DefaultColumnContainer)`
  height: 100%;
`;

const VolumeContainer = styled(DefaultRowContainer)`
  width: 100%;
  justify-content: flex-end;
  padding: 20px;
`;

const VolumeButton = styled(DefaultButton)`
  background-color: transparent;
  color: white;
`;

const ContentContainer = styled(DefaultRowContainer)`
  height: 100%;
  align-items: center;
`;

const PlayHistoryContainer = styled(DefaultColumnContainer)`
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  margin: 10px;
`;

const Title = styled(DefaultLabel)`
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
`;

const RowsContainer = styled(DefaultColumnContainer)`
  align-items: flex-start;
`;

const RowContainer = styled(DefaultRowContainer)`
  align-items: center;

  & + & {
    margin-top: 20px;
  }
`;

const Number = styled(DefaultLabel)`
  font-size: 14px;
  color: white;
  width: 30px;
`;

const Cover = styled.img`
  width: 60px;
`;

const NamesContainer = styled(DefaultColumnContainer)`
  align-items: flex-start;
  margin-left: 10px;
`;

const AlbumTitle = styled(DefaultLabel)`
  font-size: 17px;
  color: white;
`;

const ArtistName = styled(DefaultLabel)`
  font-size: 15px;
  color: white;
`;

const MainView = styled(DefaultColumnContainer)`
  flex-grow: 3;
  margin: 10px;
  align-items: center;
`;

const CurrentAlbumCover = styled.img`
  max-width: 100%;
  align-self: center;
  height: auto;
`;

const MainInfoContainer = styled(DefaultColumnContainer)`
  margin-top: 20px;
  width: 100%;
`;

const InteractionContainer = styled(DefaultRowContainer)`
  justify-content: space-between;
`;

const MainTitleContainer = styled(DefaultColumnContainer)`
  align-items: flex-start;
`;

const MainTitle = styled(DefaultLabel)`
  color: white;
  font-size: 20px;
  font-weight: bold;
`;

const MainArtist = styled(DefaultLabel)`
  color: white;
  font-size: 18px;
`;

const InfoContainer = styled(DefaultRowContainer)``;

const Dislike = styled(FontAwesomeIcon)`
  color: white;
  cursor: pointer;
`;

const Like = styled(FontAwesomeIcon)`
  color: white;
  margin-left: 10px;
  cursor: pointer;
`;

const MusicPlayerContainer = styled(DefaultColumnContainer)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SkipContainer = styled(DefaultRowContainer)`
  justify-content: flex-end;
  width: 100%;
`;

const SkipButton = styled(DefaultButton)`
  background-color: transparent;
  color: white;
`;

const UpNextContainer = styled(DefaultColumnContainer)`
  flex-grow: 1;
  margin: 10px;
  align-items: center;
`;

const NextTitle = styled(DefaultLabel)`
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
`;

const NextCover = styled.img`
  max-width: 100%;
`;

const NextInfoContainer = styled(DefaultColumnContainer)`
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
`;

const NextAlbum = styled(DefaultLabel)`
  color: white;
  font-weight: bold;
`;

const NextArtist = styled(DefaultLabel)`
  font-size: 14px;
  color: white;
`;

const CurrentImageContainer = styled.div`
  max-width: 550px;
`;

const NextImageContainer = styled.div`
  max-width: 150px;
`;

const nowPlaying = {
  now: {
    cover: allweeverknew,
    name: "All We Ever Knew",
    artist: "Head and the Heart"
  },
  playhistory: [
    {
      id: 1,
      cover: cominghome,
      name: "Coming Home",
      artist: "Leon Bridges"
    },
    {
      id: 2,
      cover: comesandgoes,
      name: "Comes and Goes",
      artist: "La Felix"
    },
    {
      id: 3,
      cover: badselfportraits,
      name: "Bad Self Portraits",
      artist: "Lake Street Drive"
    },
    {
      id: 4,
      cover: johnnynash,
      name: "I Can See Clearly Now",
      artist: "Johnny Nash"
    }
  ],
  next: {
    cover: alreadygone,
    name: "Already Gone",
    artist: "Brett Deninen"
  }
};

export const NowPlaying = props => {
  return (
    <Wrapper>
      {/*<BackgroundImage src="https://upload.wikimedia.org/wikipedia/en/thumb/7/70/SignsofLight.jpg/220px-SignsofLight.jpg" />*/}
      <MainContainer>
        <VolumeContainer>
          <VolumeButton>Volume</VolumeButton>
        </VolumeContainer>
        <ContentContainer>
          <PlayHistoryContainer>
            <Title>Play History</Title>
            <RowsContainer>
              {nowPlaying.playhistory.map((history, index) => (
                <RowContainer key={history.id}>
                  <Number>{index + 1}</Number>
                  <Cover src={history.cover} />
                  <NamesContainer>
                    <AlbumTitle>{history.name}</AlbumTitle>
                    <ArtistName>{history.artist}</ArtistName>
                  </NamesContainer>
                </RowContainer>
              ))}
            </RowsContainer>
          </PlayHistoryContainer>
          <MainView>
            <CurrentImageContainer>
              <CurrentAlbumCover src={nowPlaying.now.cover} />
              <MainInfoContainer>
                <InteractionContainer>
                  <MainTitleContainer>
                    <MainTitle>{nowPlaying.now.name}</MainTitle>
                    <MainArtist>{nowPlaying.now.artist}</MainArtist>
                  </MainTitleContainer>
                  <InfoContainer>
                    <Dislike icon="thumbs-down" />
                    <Like icon="thumbs-up" />
                  </InfoContainer>
                </InteractionContainer>
              </MainInfoContainer>
              <MusicPlayerContainer />
              <SkipContainer>
                <SkipButton>Skip</SkipButton>
              </SkipContainer>
            </CurrentImageContainer>
          </MainView>
          <UpNextContainer>
            <NextTitle>Up Next</NextTitle>
            <NextImageContainer>
              <NextCover src={nowPlaying.next.cover} />
              <NextInfoContainer>
                <NextAlbum>{nowPlaying.next.name}</NextAlbum>
                <NextArtist>{nowPlaying.next.artist}</NextArtist>
              </NextInfoContainer>
            </NextImageContainer>
          </UpNextContainer>
        </ContentContainer>
      </MainContainer>
    </Wrapper>
  );
};
