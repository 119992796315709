import {songsConstants} from "_constants";

// Selectors
export const getGenres = state => state.songs.genres;

const initialState = {
  loading: false,
  genres: [],
  songs: [],
  songDetails: {},
  songSearch: [],
  songPlaylists: {
    loading: false,
    playlists: []
  }
};
export function songs(state = initialState, action) {
  switch (action.type) {
    case songsConstants.GET_SONG_PLAYLISTS_REQUEST:
      return {
        ...state,
        songPlaylists: {
          loading: true,
          playlists: []
        }
      };
    case songsConstants.GET_SONG_PLAYLISTS_SUCCESS:
      return {
        ...state,
        songPlaylists: {
          loading: false,
          playlists: action.playlists
        }
      };
    case songsConstants.GET_SONG_PLAYLISTS_FAILURE:
      return {
        ...state,
        songPlaylists: {
          loading: false,
          playlists: []
        }
      };
    case songsConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case songsConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        songs: action.songs
      };
    case songsConstants.GET_FAILURE:
      return {
        ...state,
        loading: false
      };
    case songsConstants.GET_ONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case songsConstants.GET_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        songDetails: action.song
      };
    case songsConstants.GET_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case songsConstants.SEARCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case songsConstants.SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        songSearch: action.songs
      }
    case songsConstants.SEARCH_FAILURE:
      return {
        ...state,
        loading: false
      }
    case songsConstants.GET_GENRES_SUCCESS:
      return {
        ...state,
        genres: action.genres.sort((a, b) => a.name.localeCompare(b.name))
      }
    case songsConstants.CHANGE_SONG_STATUS:
        return {
            ...state,
            songStatusChangedTime: new Date(),
        }
    default:
      return state;
  }
}
