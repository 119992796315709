import React from "react";
import {FixedSizeList as List} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
//import { songsActions } from '_actions'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class TestView extends React.Component {
  state = {
    hasNextPage: true,
    items: []
  }

  isItemLoaded = index => !this.state.hasNextPage || index < this.state.items.length;

  loadMoreItems = () => {
    if(this.props.loading){
      return {}
    } else {
      //this.props.dispatch(songsActions.getNextPage())
    }
  }

  Item = ({ index, style }) => {
    let content;
    if (!this.isItemLoaded(index)) {
      content = "Loading...";
    } else {
      content = this.state.items[index].name;
    }

    return <div style={style}>{content}</div>
  }


  render() {
    return (
      <div>
        <InfiniteLoader
          isItemLoaded={this.isItemLoaded}
          itemCount={1000}
          loadMoreItems={this.loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <List
              className="List"
              height={500}
              itemCount={1000}
              itemSize={30}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={500}
            >
              {this.Item}
            </List>
          )}
        </InfiniteLoader>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.songs.loading,
  alert: state.alert,
  songs: state.songs.songs,
});

const connectedTestView = withRouter(connect(mapStateToProps)(TestView));
export { connectedTestView as TestView };
