import {clientConstants} from "_constants";

const initialState = {
  loading: false,
  selectedClient: { id: -1, text: "All Clients"},
  client: [],
  clientUsers: [],
  clientDetails: {}
};
export function client(state = initialState, action) {
  switch (action.type) {
    case clientConstants.SET_SELECTED:
      return {
        ...state,
        selectedClient: action.client
      };
    case clientConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.client
      };
    case clientConstants.GET_FAILURE:
      return {
        ...state,
        loading: false
      };
    case clientConstants.ARCHIVE_SUCCESS:
      return {
        ...state,
        client: state.client.filter(client => client.id === action.id)
      }
    case clientConstants.GET_ONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientConstants.GET_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        clientDetails: action.client
      };
    case clientConstants.GET_ONE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case clientConstants.CREATE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientConstants.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case clientConstants.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case clientConstants.GET_CLIENT_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientConstants.GET_CLIENT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientUsers: action.client
      };
    case clientConstants.GET_CLIENT_USERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
