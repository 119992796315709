import {api} from './api';

export const zonesService = {
  get,
  getPagination,
  getOne,
  addOne,
  updateOne,
  addPlayer,
  addSchedule,
  updateZone,
  changeVolume,
  deleteZone,
  deletePlayer,
  deleteSchedule,
  getPlayHistory,
  removeForcedPlaylist
};

function get() {
  return api("GET", "zone", undefined, zones => zones);
}

function getPagination(initial, end) {
  return api("GET", `zone/${initial}/${end}`, undefined, zones => zones);
}

function getOne(id) {
  return api("GET", `zone/${id}`, undefined, zones => zones);
}

function addOne(zones) {
  return api("POST", "zone", zones, response => response);
}

function updateOne(zones, id) {
  return api("PUT", `zone/${id}`, zones, response => response);
}

function addSchedule(zoneId, scheduleId) {
  return api("POST", `zone/${zoneId}/schedule`, scheduleId, response => response);
}

function addPlayer(zoneId, playerId) {
  return api("POST", `zone/${zoneId}/player`, playerId, response => response);
}

function updateZone(zoneId, name, zoneType) {
  return api("PUT", `zone/${zoneId}`, {
    id: zoneId,
    type: zoneType,
    name
  }, response => response);
}

function removeForcedPlaylist(zoneId) {
  return api("GET", `zone/${zoneId}/removeforcedplaylist`,null, response => response);
}

function changeVolume(zoneId, volume,volumeChangeAllowed) {
  return api("PUT", `zone/${zoneId}`, {
    id: zoneId,
    volume,
    volumeChangeAllowed
  }, response => response);
}

function deleteZone(zoneId) {
    return api("GET", `zone/${zoneId}/archive`, undefined, response => response);
  //return api("DELETE", `zone/${zoneId}`, undefined, response => response);
}

function deleteSchedule(zoneId) {
  return api("DELETE", `zone/${zoneId}/schedule`, undefined, response => response);
}

function deletePlayer(zoneId) {
  return api("DELETE", `zone/${zoneId}/player`, undefined, zones => zones);
}

function getPlayHistory(zoneId, startMoment, endMoment) {
  const format = 'YYYY-DD-MM HH:mm';
  const startFormat = startMoment.format(format);
  const endFormat = endMoment.format(format);
  return api("GET", `zone/${zoneId}/playhistory?start=${startFormat}&end=${endFormat}`, undefined, zones => zones)
}
