export const campaignConstants = {
  SET_SELECTED: 'CAMPAIGN_SET_SELECTED',

  GET_REQUEST: 'CAMPAIGN_GET_REQUEST',
  GET_SUCCESS: 'CAMPAIGN_GET_SUCCESS',
  GET_FAILURE: 'CAMPAIGN_GET_FAILURE',

  GET_ONE_REQUEST: 'CAMPAIGN_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'CAMPAIGN_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'CAMPAIGN_GET_ONE_FAILURE',

  UPDATE_CAMPAIGN_REQUEST: 'CAMPAIGN_UPDATE_CAMPAIGN_REQUEST',
  UPDATE_CAMPAIGN_SUCCESS: 'CAMPAIGN_UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_CAMPAIGN_FAILURE: 'CAMPAIGN_UPDATE_CAMPAIGN_FAILURE',

  CREATE_CAMPAIGN_REQUEST: 'CAMPAIGN_CREATE_CAMPAIGN_REQUEST',
  CREATE_CAMPAIGN_SUCCESS: 'CAMPAIGN_CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILURE: 'CAMPAIGN_CREATE_CAMPAIGN_FAILURE',

  CAMPAIGN_ARCHIVE_REQUEST: 'CAMPAIGN_ARCHIVE_REQUEST',
  CAMPAIGN_ARCHIVE_SUCCESS: 'CAMPAIGN_ARCHIVE_SUCCESS',
  CAMPAIGN_ARCHIVE_FAILURE: 'CAMPAIGN_ARCHIVE_FAILURE',
};
