import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { clientActions } from "_actions";
import {
  ClientDropdownFilter,
  CreateClient,
  DropdownFilter,
  FilterSection,
  PrimaryButton,
  RowSpaced,
  SearchFilter,
  SectionGutter,
  SectionHeader,
  SectionTitle,
  SortTable
} from "_components";
import { Matches } from '_helpers';
import { StandardDateMoment, StatusBadge } from "_styles";
import { BaseUrl, getBearer } from "../_services/api";
import Dropdown from "react-bootstrap/Dropdown";

const ACCOUNT_STATUS_ACTIVE = 'ACTIVE';
const ACCOUNT_STATUS_TRIAL = 'TRIAL';
// const ACCOUNT_STATUS_SUSPENDED = 'SUSPENDED';

export const Badge = styled(StatusBadge)`
    font-size: 12px;
    border-radius: 10px;
    color: black;
    width: 90px;
    background-color: ${props => props.status === ACCOUNT_STATUS_TRIAL ?
            "purple" :
            props.status === ACCOUNT_STATUS_ACTIVE ? props.theme.onlineGreen :
                    props.status === "VOTES_PRESENT" ? '#72f75d' :
                            props.status === "NO_VOTES" ? '#cdcaca' :
                                    props.theme.offlineRed}
`;

class AllClients extends React.Component {
  state = {
    showAddClient: false,
    search: "",
    clients: undefined,
    statusFilter: undefined,
    filterClientId: -1
  };

  componentDidMount() {
    clientActions.getAllClients(this.props.dispatch);
  }

  filterClient = (client) => {
    this.setState({filterClientId: client ? client.id : -1});
  }

  handleFilterType = selected => {
    this.setState({statusFilter: selected ? selected.id : undefined});
  }

  handleSearch = filterText => {
    this.setState({search: filterText});
  };

  handleEdit = info => {
    this.setState({showAddClient: true, client: info});
  };

  closeClient = () => {
    this.setState({showAddClient: false, client: undefined});
    clientActions.getAllClients(this.props.dispatch);
  }

  successClient = () => {
    this.closeClient();
    clientActions.getAllClients(this.props.dispatch);
  };

  render() {
    const {showAddClient} = this.state;
    const {clients = [], loading} = this.props;

    let data = clients;

    // Filter client type
    if (this.state.statusFilter) {
      data = data.filter(e => e.accountStatus === this.state.statusFilter)
    }

    data = this.state.filterClientId === -1 ? data : data.filter(l => l.id === this.state.filterClientId);
    data = Matches(data, this.state.search, ["name"]);

    const handleVoteButtonClick = (e, clientId) => {
      e.stopPropagation();
      fetch(`${BaseUrl}client/${clientId}/votesReport`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': getBearer()
        })
      }).then(async (response) => {
        if (!response.ok) {
          alert("Failed to download");
          return;
        }
        const blob = await response.blob();
        let windowUrl = window.URL || window.webkitURL;
        let url = windowUrl.createObjectURL(blob);
        let anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = "client_votes_" + clientId + ".xls";
        anchor.click();
        anchor.remove();
        windowUrl.revokeObjectURL(url);
      }).catch(error => {
        console.log(error);
      });

    }


    const imagesUrls = clients.flatMap(c => c.locations.map(l => {
      l["client"] = c;
      return l;
    })).filter(l => l.logoUrl).map(l => ({
          clientId: l.client.id,
          logoUrl: l.logoUrl
        }
    ));

    return (
        <>
                {showAddClient && (
                    <CreateClient
                        onBack={this.closeClient}
                        onCreate={this.successClient}
                        client={this.state.client}
                        onArchive={this.closeClient}
                        imagesUrls={imagesUrls}
                    />
                )}
          <>
                    <SectionHeader>
                        <RowSpaced>
                            <SectionTitle>Clients</SectionTitle>
                            <PrimaryButton onClick={() => this.setState({showAddClient: true})}>+ Add
                                Client</PrimaryButton>
                        </RowSpaced>

                        <FilterSection>
                            <SearchFilter onChange={this.handleSearch} value={this.state.search} />
                            <ClientDropdownFilter clientSelect={this.filterClient} />
                            <DropdownFilter onSelect={this.handleFilterType} placeholder="Status" options={[
                              {id: 'ACTIVE', text: 'Active'},
                              {id: 'TRIAL', text: 'Trial'},
                              {id: 'SUSPENDED', text: 'Suspended'},
                            ]} />
                        </FilterSection>
                    </SectionHeader>

                    <SortTable
                        defaultSortField='name'
                        loading={loading}
                        rows={data}
                        gutter={SectionGutter}
                        onRowClick={this.handleEdit}
                        columns={[
                          {percent: 1, field: 'name', title: 'client'},
                          {percent: 1, field: 'locations.length', title: 'locations', isNumber: true},
                          {percent: 1, field: 'zoneCount', title: 'zones', isNumber: true},
                          {
                            percent: 1,
                            field: 'dateCreated',
                            title: 'Created (Local)',
                            isNumber: true,
                            rowRenderer: row => (
                                <StandardDateMoment>{new Date(row.dateCreated)}</StandardDateMoment>
                            )
                          },
                          {
                            percent: 1, field: 'accountStatus', title: 'ACCOUNT STATUS', rowRenderer: row => (
                                <Badge status={row.accountStatus}>{row.accountStatus}</Badge>
                            )
                          },
                          {
                            percent: 2, field: 'votes', title: 'VOTES', nocell: true, dim: false,
                            rowRenderer: row => {
                              const voteCount = row.votes;
                              if (voteCount === 0) {
                                return <Badge status={"NO_VOTES"}>No Votes</Badge>
                              } else {
                                return <div onClick={e => e.stopPropagation()}><Dropdown>
                                            <Dropdown.Toggle
                                                style={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  color: "black",
                                                  borderColor: "transparent",
                                                  borderRadius: "1rem",
                                                  lineHeight: 1
                                                }}>
                                                Votes {voteCount}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                style={{backgroundColor: "rgb(78,80,80)", position: "fixed"}}>
                                                <Dropdown.Item
                                                    onClick={(e) => handleVoteButtonClick(e, row.id)}>Download</Dropdown.Item>
                                                <Dropdown.Item href={'feedback?selectedClientId=' + row.id}>
                                                    View</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown></div>;
                              }
                            }
                          }

                        ]}
                    />
                </>
            </>
    );
  }
}

const mapStateToProps = state => ({
  alert: state.alert,
  loading: state.client.loading,
  clients: state.client.client
});

const connectedAllClients = withRouter(connect(mapStateToProps)(AllClients));
export { connectedAllClients as AllClients };

