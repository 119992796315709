import React from "react";
import styled from "styled-components";
import {Modal} from "react-bootstrap";
import {
    DefaultLabel,
    DefaultColumnContainer,
    DefaultRowContainer,
    DefaultBlackFormControl,
    DefaultButton,
    DefaultGrayButton,
    Helper,
    Active,
    Inactive, CheckboxButton
} from "_styles";
import {getReactSelectList} from "_helpers";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import {Tooltip} from "@material-ui/core";

const randomString = require("random-string");

const ColumnContainer = styled(DefaultColumnContainer)`
  width: 100%;
`;

const RowContainer = styled(DefaultRowContainer)`
  width: 100%;
  align-items: center;

  & + & {
    margin-top: 20px;
  }
`;

const Label = styled(DefaultLabel)`
  min-width: 120px;
  max-width: 120px;
  color: white;
  text-align: right;
  margin-right: 20px;
  overflow-wrap: break-word;
  white-space: normal;
`;

const ProbabilityExplainedLabel = styled(DefaultLabel)`
  color: white;
  text-align: left;
  margin-left: 20px;
  overflow-wrap: break-word;
  white-space: normal;
`;

const FormContainer = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
`;

const Line = styled.hr``;

const DatePickerWrapper = styled.div`
  width: 100%;
`;

const DatePickerButton = styled.button`
  border: 1px solid ${props => props.theme.optionalGray};
  color: white;
  padding: 4px 4px 4px 10px;
  letter-spacing: 1px;
  height: 50px;
  background-color: ${props => props.theme.darkGray};
  outline: none;
  box-shadow: none;
  width: 100%;
  text-align: left;

  ::placeholder {
    color: white;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: ${props => props.theme.optionalGray};
    border: 0;
    outline: none;
    box-shadow: none;
    caret-color: white;
    color: white;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const HalfDatePickerButton = styled(DatePickerButton)`
  width: 50%;
`;

class DatePickerInput extends React.Component {
    render() {
        return this.props.half ? (
            <HalfDatePickerButton onClick={this.props.onClick}>
                {this.props.value}
            </HalfDatePickerButton>
        ) : (
            <DatePickerButton onClick={this.props.onClick}>
                {this.props.value}
            </DatePickerButton>
        );
    }
}

const RSelect = styled(Select)`
  width: 100%;
  min-width: 200px;
`;

const Container = styled(DefaultColumnContainer)``;

const CancelButton = styled(DefaultGrayButton)``;

const OkButton = styled(DefaultButton)``;

const ErrorLabel = styled(DefaultLabel)`
  color: red;
`;

let timeIntervals = 15;

export class ModalAddOverride extends React.Component {
    state = {
        id: "",
        playlist: "",
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        startTime: moment().startOf("hour").toDate(),
        endTime: moment().startOf("hour").add(1, "h").toDate(),
        probability: 20,
        priority: false,
        rulesEnabled: true,
        error: "",
        updated: false
    };

    componentDidMount() {
        this.setEvent();
    }

    componentDidUpdate() {
        this.setEvent();
    }

    setEvent = () => {
        const {event, playlists} = this.props;

        if (event !== undefined && !this.state.updated) {
            let pl = playlists.find(p => p.name === event.title);
            this.setState({
                id: event.id,
                playlist: {value: pl.id, label: pl.name},
                startDate: moment(event.start).toDate(),
                endDate: moment(event.end)
                    .subtract(1, "d")
                    .toDate(),
                startTime: moment(event.start).toDate(),
                endTime: moment(event.end).toDate(),
                updated: true,
                probability: event.probability,
                priority: event.priority,
            }, () => {
                this.props.removeDetails();
            });
        }
    };

    render() {
        const {event, playlists, insertMode = false} = this.props;
        const {
            playlist,
            startDate,
            endDate,
            startTime,
            endTime,
            probability,
            priority,
            rulesEnabled,
            error
        } = this.state;

        let listPlaylist = getReactSelectList(playlists, "playlist");

        return (
            <Modal show={true} onHide={this.close} className="modal-pick" size="lg">
                <Modal.Header>
                    <Label>{event ? 'Edit' : 'Add'} {insertMode ? 'Insert' : 'Override'}</Label>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <ColumnContainer>
                            <React.Fragment>
                                <RowContainer>
                                    <Label>Playlist</Label>
                                    <FormContainer>
                                        <RSelect
                                            options={listPlaylist}
                                            value={playlist}
                                            onChange={e => this.handleChangePlaylist("playlist", e)}
                                        />
                                        <Helper message="Choose Playlist"/>
                                    </FormContainer>
                                </RowContainer>
                            </React.Fragment>
                            <Line/>
                            <React.Fragment>
                                <RowContainer>
                                    <Label>Start Time</Label>
                                    <DatePickerWrapper>
                                        <DatePicker
                                            selected={startTime}
                                            onChange={value => this.handleChange("startTime", value)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={timeIntervals}
                                            injectTimes={[
                                                moment().endOf('day').toDate()
                                            ]}
                                            dateFormat="hh:mm aa"
                                            timeCaption="Time"
                                            customInput={<DatePickerInput half/>}
                                            placeholderText="Start Time"
                                        />
                                    </DatePickerWrapper>
                                </RowContainer>
                                <RowContainer>
                                    <Label>End Time</Label>
                                    <DatePickerWrapper>
                                        <DatePicker
                                            selected={endTime}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={timeIntervals}
                                            injectTimes={[
                                                moment().endOf('day').toDate()
                                            ]}
                                            dateFormat="hh:mm aa"
                                            timeCaption="Time"
                                            onChange={value => this.handleChange("endTime", value)}
                                            customInput={<DatePickerInput half/>}
                                            placeholderText="End Time"
                                        />
                                    </DatePickerWrapper>
                                </RowContainer>
                            </React.Fragment>
                            <Line/>
                            <RowContainer>
                                <Label>Start Date</Label>
                                <DatePickerWrapper>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={value => this.handleChange("startDate", value)}
                                        customInput={<DatePickerInput half/>}
                                    />
                                </DatePickerWrapper>
                            </RowContainer>
                            <RowContainer>
                                <Label>End Date</Label>
                                <DatePickerWrapper>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={value => this.handleChange("endDate", value)}
                                        minDate={new Date(startDate)}
                                        customInput={<DatePickerInput half/>}
                                    />
                                </DatePickerWrapper>
                            </RowContainer>
                            {insertMode && (
                                <>
                                    <RowContainer>
                                        <Label>Probability %</Label>
                                        <DefaultBlackFormControl
                                            type="number"
                                            placeholder="%"
                                            style={{width: '100px'}}
                                            min="0"
                                            max="100"
                                            value={probability.toString()}
                                            onChange={e => this.setState({probability: Math.max(0, Math.min(100, e.target.value))})}
                                        />
                                        <ProbabilityExplainedLabel>Count {Math.floor(100 / probability)} then insert
                                            1</ProbabilityExplainedLabel>
                                    </RowContainer>
                                    <Tooltip
                                        title="Check this to enable this insert on top of client's forced playlists">
                                        <RowContainer>
                                            <Label>Priority</Label>
                                            <CheckboxButton
                                                checked={priority}
                                                onChange={e => this.setState({priority: e.target.checked})}
                                            />
                                        </RowContainer>
                                    </Tooltip>
                                </>
                            )}
                            {!insertMode && (
                                <RowContainer>
                                    <Label>Rules</Label>
                                    {rulesEnabled ? (
                                        <Active onClick={e => this.setState({rulesEnabled: false})}>
                                            Enabled
                                        </Active>
                                    ) : (
                                        <Inactive onClick={e => this.setState({rulesEnabled: true})}>
                                            Disabled
                                        </Inactive>
                                    )}
                                </RowContainer>
                            )}
                        </ColumnContainer>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ErrorLabel>{error}</ErrorLabel>
                    <CancelButton onClick={this.close}>Cancel</CancelButton>
                    <OkButton onClick={this.handleCompleteOverride}>Save</OkButton>
                </Modal.Footer>
            </Modal>
        );
    }

    handleChangePlaylist = (variable, value) => {
        this.setState({[variable]: value});
    };

    handleChange = (variable, value) => {
        const valueMoment = moment(value);
        const startMoment = moment(this.state.startDate);
        const endMoment = moment(this.state.endDate);
        const startTimeMoment = moment(this.state.startTime);
        const endTimeMoment = moment(this.state.endTime);

        if (variable === "startDate") {
            if (valueMoment.diff(endMoment, "d") > 0) {
                this.setState({
                    startDate: startMoment.toDate(),
                    endDate: valueMoment.toDate()
                });
            }
        } else if (variable === "startTime") {
            if (valueMoment.diff(endTimeMoment, "h") >= 0) {
                this.setState({endTime: valueMoment.add(1, "h").toDate()});
            }
        } else if (variable === "endTime") {
            if (valueMoment.diff(startTimeMoment, "h", true) <= 0) {
                this.setState({startTime: valueMoment.subtract(1, "h").toDate()});
            }
        }

        this.setState({[variable]: valueMoment.toDate()});
        this.setState({[variable]: moment(value).toDate()});
    };

    close = () => {
        this.resetForm();
        this.props.handleClose();
    };

    resetForm = () => {
        this.setState({
            playlist: "",
            startDate: moment().toDate(),
            endDate: moment().toDate(),
            startTime: moment()
                .startOf("hour")
                .toDate(),
            endTime: moment()
                .startOf("hour")
                .add(1, "h")
                .toDate(),
            error: "",
            updated: false
        });
    };

    handleCompleteOverride = () => {
        const {id, playlist, startTime, endTime, startDate, endDate, probability, priority} = this.state;

        if (
            playlist === "" ||
            (startTime === "" || endTime === "") ||
            (startDate === "" || endDate === "")
        ) {
            this.setError("Fill the entire form");
        } else {
            this.setError("");

            let randomId = randomString({length: 10});
            const insertMode = !!this.props.insertMode;

            this.props.handleSubmit({
                id: id !== "" ? id : randomId,
                title: playlist.label,
                playlistId: playlist.value,
                start: this.concatDateTimeToMoment(startDate, moment(startTime).format("HH:mm")).toDate(),
                end: this.concatDateTimeToMoment(endDate, moment(endTime).format("HH:mm")).add(1, "d").toDate(),
                color: insertMode ? "green" : "red",
                borderColor: "white",
                backgroundColor: "#464645",
                allDay: true,
                override: !insertMode,
                insert: insertMode,
                ...(insertMode ? {probability} : {}),
                ...(insertMode ? {priority} : {})
            });

            this.resetForm();
        }
    };

    setError = error => {
        this.setState({error});
    };

    concatDateTimeToMoment = (date, timeString) => {
        const startDateString = moment(date).format("MM-DD-YYYY");
        return moment(`${startDateString} ${timeString}`);
    };
}
