import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "./Theme";
import {Badge} from "react-bootstrap";

export const DefaultContainer = styled.div`
  display: flex;
`;
export const DefaultColumnContainer = styled(DefaultContainer)`
  flex-direction: column;
`;

export const DefaultRowContainer = styled(DefaultContainer)`
  flex-direction: row;
`;

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.background};
  height: 100%;
`;

export const MainContainer = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Container>{children}</Container>
  </ThemeProvider>
);

export const ContentContainer = styled(DefaultColumnContainer)`
  height: 100%;
`;

const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Title = styled(Badge)`
  font-size: 32px;
  color: white;
  padding: 0 20px 20px 20px;

  line-height: 1.2;
  letter-spacing: 1px;
  font-weight: normal;
  margin-bottom: unset;
  margin: 0;
`;

export const ListViewContainer = ({ title, children }) => (
  <ListContainer>
    <Title>{title}</Title>
    {children}
  </ListContainer>
);
