import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "./Theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const CircleIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.highlight};
  font-size: 20px;
  cursor: pointer;
`;

const PlayFontIcon = styled(FontAwesomeIcon)`
  color: white;
  cursor: pointer;
`;

export const PlayIcon = ({ onClick }) => {
  return (
    <ThemeProvider theme={theme}>
      <PlayFontIcon onClick={onClick} icon="play" />
    </ThemeProvider>
  );
};
