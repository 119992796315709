import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import styled, { ThemeProvider } from "styled-components";
import { alertActions, clientActions, userActions } from "_actions";
import {
  CreationForms,
  DefaultBlackFormControl,
  DefaultColumnContainer,
  DefaultLabel,
  FormRadioButton,
  Helper,
  theme
} from "_styles";
import { ModalDecisionBuilder } from './Utils';

import { clientService } from "../_services";
import { ImagePickModal } from "./ImagePickModal";


const listTypes = [
  {value: "Co-working", label: "Co-working"},
  {value: "Retail", label: "Retail"},
  {value: "Hotel", label: "Hotel"},
  {value: "Casino", label: "Casino"},
  {value: "Airport", label: "Airport"},
  {value: "Hospital", label: "Hospital"},
  {value: "Gym", label: "Gym"},
  {value: "Restaurant", label: "Restaurant"},
  {value: "Mall", label: "Mall"},
  {value: "Group", label: "Group"},
  {value: "Other", label: "Other"}
];

const DetailsContainer = styled(DefaultColumnContainer)`
    margin-top: 30px;
    width: 100%;
    align-items: flex-start;
`;


const Label = styled(DefaultLabel)`
    color: white;
    font-size: 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
`;

const Image = styled.div`
    background-color: hsl(${props => props.hue}, 90.6%, 66.7%);
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    display: flex;
`;
const FormLabel = styled(DefaultLabel)`
    color: ${props => props.theme.formlabel};
    font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
    margin-top: 5px;
`;

const TypesContainer = styled(DefaultColumnContainer)`
    width: 100%;
    margin-top: 20px;
    align-items: flex-start;
`;

const RSelect = styled(Select)`
    width: 100%;
    margin-top: 10px;
`;

function hashCode(s) {
  var h = 0, l = s.length, i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
}

const ALL_USERS_INDEX = -1;
const AllUsersOption = {value: ALL_USERS_INDEX, label: "All Users"};
const getUserOptions = users => users.sort((a, b) => (a.firstName + " " + a.lastName).localeCompare(b.firstName + " " + b.lastName)).map(user => ({
  value: user.id,
  label: user.firstName + " " + user.lastName
}));

const CreateClient = ({
                        client = undefined,
                        onBack,
                        onCreate,
                        onArchive,
                        dispatch,
                        imagesUrls = []
                      }) => {
  const isEditing = client !== undefined;

  const [accountStatus, setAccountStatus] = useState(isEditing ? client.accountStatus : 'TRIAL');
  const [name, setName] = useState(isEditing ? client.name : '');
  const [address, setAddress] = useState(isEditing ? client.address : '');
  const [type, setType] = useState(isEditing ? {value: client.type, label: client.type} : []);
  const [users] = useState(isEditing ? (client.allUsers ? [AllUsersOption] : getUserOptions(client.users)) : []);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [clientLogoUrl, setClientLogoUrl] = useState(isEditing ? client.logoUrl : undefined);
  const [imagePicked, setImagePicked] = useState(false);
  const [confirmArchiving, setConfirmArchiving] = useState(false);
  const imageUrlInput = useRef();
  const [showPickImageModal, setShowPickImageModal] = useState(false);

  useEffect(() => {
    userActions.getUsers(dispatch);
  }, []);

  const getAllUsersIndex = entries => entries.findIndex(entry => entry.value === ALL_USERS_INDEX);
  const allUsersSelected = entries => getAllUsersIndex(entries) !== -1;

  const doArchiving = async () => {
    if (archiving)
      return;
    setArchiving(true);
    setError('');
    setConfirmArchiving(false);

    try {
      await clientActions.archive(dispatch, client.id);
      setArchiving(false);
      alertActions.notificationSuccess(dispatch, "Client archived", name);
      onArchive();
    } catch (e) {
      console.log(e);
      setError(e.userMessage);
      setArchiving(false);
    }
  }

  const closeDeleteConfirmationDialog = async () => {
    setConfirmArchiving(false);
  }

  const handleArchive = async () => {
    if (confirmArchiving)
      return;
    setConfirmArchiving(true);
  }

  const submit = async () => {
    if (name === "" || accountStatus === "") {
      setError('Please fill out all required fields');
      return;
    }

    setSubmitting(true);
    setError('');

    const isAllUsersSelected = allUsersSelected(users);
    const userComposite = !isAllUsersSelected ? {
      users: users.map(el => ({id: el.value})),
    } : {};

    let clientData = {
      ...(isEditing ? {id: client.id} : {}),
      name,
      address,
      accountStatus,
      ...userComposite,
      allUsers: isAllUsersSelected,
      type: type.value,
      ...(imagePicked && {logoUrl: clientLogoUrl})
    };

    try {
      isEditing ? await clientActions.updateClient(dispatch, clientData, client.id) : await clientActions.createClient(dispatch, clientData);
      setSubmitting(false);
      alertActions.notificationSuccess(dispatch, isEditing ? "Client updated" : "Client created", `${name}`);
      onCreate();
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  const clientLogoImageClicked = () => {
    imageUrlInput.current.click();
  }
  const handleImagePickClose = () => {
    setShowPickImageModal(false);
  }

  const handleImagePickComplete = async (imageUrl) => {
    setShowPickImageModal(false);
    setClientLogoUrl(imageUrl);
    setImagePicked(true);
  }

  const onImageFileChanged = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    await clientService.uploadArtwork(client.id, file)
    const imageUrl = URL.createObjectURL(file);
    setClientLogoUrl(imageUrl);
  };
  const imagesUrlsByClient = isEditing && [...new Set(imagesUrls.filter(i => i.clientId === client.id).map(i => i.logoUrl))];
  // const userOptions = [AllUsersOption].concat(getUserOptions(propsUsers))

  return (
      <ThemeProvider theme={theme}>
            <CreationForms
                message={`This will ${
                    isEditing ? "edit a" : "create a new"
                } client, please check the information entered above is correct.`}
                handleBackButton={onBack}
                handleSubmit={submit}
                deleting={archiving}
                onDelete={isEditing ? handleArchive : null}
                submitting={submitting}
                alert={error}
                title={isEditing ? "Edit Client" : "Create Client"}
            >
                {(isEditing &&
                    <DetailsContainer style={{marginBottom: 35}}>
                        <div className={"d-flex"}>
                            <div>
                                <Image hue={!clientLogoUrl && (hashCode(client.name) % 360)} size={180}
                                    onClick={clientLogoImageClicked}>
                                    {(clientLogoUrl) &&
                                        <>
                                            <img alt={"client logo"}
                                                style={{maxWidth: '100%', maxHeight: '100%'}}
                                                src={clientLogoUrl} />
                                        </>}
                                  <input type='file' id='file' ref={imageUrlInput}
                                      style={{display: 'none'}}
                                      onChange={onImageFileChanged}
                                      accept="image/png, image/jpeg" />
                                </Image>
                            </div>
                            <div style={{marginLeft: 20}} className={"d-flex flex-column"}>
                                <div style={{color: "white", fontSize: 24}}>Logo</div>
                                <div style={{color: "#808080", marginTop: 10, lineHeight: "1.5em"}}
                                    className={"flex-fill"}>Upload a Logo.
                                    This will be
                                    displayed in the client's mobile application.
                                    <br />
                                    Please ensure logo is exact square, at least 512x512 and in JPG format
                                </div>
                              {imagesUrlsByClient && imagesUrlsByClient.length > 0 &&
                                  <div style={{
                                    color: "#FFB800",
                                    cursor: "pointer"
                                  }} onClick={() => setShowPickImageModal(true)}>Choose
                                        from existing...</div>}
                              <div style={{color: "#FFB800", marginTop: 10, cursor: "pointer"}}
                                  onClick={clientLogoImageClicked}>Upload from computer...
                                </div>
                            </div>
                        </div>

                    </DetailsContainer>)}
              {/*{(isEditing &&*/}
              {/*    <DetailsContainer>*/}
              {/*        <Image hue={!clientLogoUrl && (hashCode(client.name) % 360)} size={180}*/}
              {/*               onClick={clientLogoImageClicked}>*/}
              {/*            {(clientLogoUrl) &&*/}
              {/*                <>*/}
              {/*                    <img style={{maxWidth: '100%', maxHeight: '100%'}}*/}
              {/*                         src={clientLogoUrl}/>*/}
              {/*                </>}*/}
              {/*            <input type='file' id='file' ref={imageUrlInput}*/}
              {/*                   style={{display: 'none'}}*/}
              {/*                   onChange={onImageFileChanged}*/}
              {/*                   accept="image/png, image/jpeg"/>*/}
              {/*        </Image>*/}
              {/*    </DetailsContainer>)}*/}
              <Label>Account Status</Label>
                <FormRadioButton
                    handleChange={setAccountStatus}
                    name={`account-status-button-group`}
                    value={accountStatus}
                    options={["ACTIVE", "SUSPENDED", "TRIAL"]}
                />
                <DetailsContainer>
                    <Label>Enter Client Details</Label>
                    <FormContainerWithHelper>
                        <FormLabel>CLIENT NAME</FormLabel>
                        <Form
                            type="text"
                            placeholder="Name of Business"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormContainerWithHelper>
                    <TypesContainer>
                        <FormLabel>CLIENT TYPE</FormLabel>
                        <RSelect
                            options={listTypes}
                            name="client-types"
                            value={type}
                            onChange={setType}
                        />
                        <Helper message="You can choose more than one type, you can choose this later" />
                    </TypesContainer>
                    <FormContainerWithHelper>
                        <FormLabel>Notes</FormLabel>
                        <Form
                            as="textarea"
                            rows="3"
                            name="primaryAddress"
                            placeholder="Notes"
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                        />
                        <Helper message="You can choose this later" />
                    </FormContainerWithHelper>
                </DetailsContainer>
              {/* <UserListContainer>
          <Label>Users Who Can Manage This Client</Label>
          <RSelect
            options={userOptions}
            isMulti
            name="users-list"
            onChange={handleUserSelect}
            value={users}
          />
          <Helper message="You can add more than one user, you can choose this later" />
        </UserListContainer> */}
              {imagesUrlsByClient && <ImagePickModal imagesUrls={imagesUrlsByClient} show={showPickImageModal}
                  onHide={handleImagePickClose}
                  onImagePicked={handleImagePickComplete} />}
              {confirmArchiving && (
                  <ModalDecisionBuilder
                      title={"Are you sure you want to archive this client?"}
                      handleClose={closeDeleteConfirmationDialog}
                      handleAccept={doArchiving}
                  />
              )}
            </CreationForms>
        </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  users: state.users.users,
  clients: state.client.client
});

const connectedCreateClient = withRouter(connect(mapStateToProps)(CreateClient));
export { connectedCreateClient as CreateClient };
