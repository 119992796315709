import React from "react";
import styled from "styled-components";
import {DefaultRowContainer} from "./Container.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BackContainer = styled(DefaultRowContainer)`
  align-items: center;
  z-index: 5;
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 22px;
  cursor: pointer;
`;

export const BackButton = ({ handleBackButton, props }) => (
  <BackContainer onClick={handleBackButton} {...props}>
    <Icon icon="chevron-left" />
  </BackContainer>
);
