import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {theme} from "./Theme";

const CMenu = styled(ContextMenu)`
  z-index: 900;
  background-color: ${props => props.theme.offWhite};
`;

const Container = styled.div``;

const Text = styled.span`
  color: white;
  cursor: pointer;
  font-size: 20px;
`;

export const CustomContextMenu = ({
  id,
  handleDelete,
  handleEdit,
  hideDefault,
  children
}) => {
  return (
    <ThemeProvider theme={theme}>
    <React.Fragment>
      <ContextMenuTrigger id={`${id}-provider`} holdToDisplay={0}>
        <Container>
          <Text>...</Text>
        </Container>
      </ContextMenuTrigger>
      <CMenu id={`${id}-provider`}>
        <React.Fragment>
          {children}
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Archive</MenuItem>
        </React.Fragment>
      </CMenu>
    </React.Fragment>
    </ThemeProvider>
  );
};
