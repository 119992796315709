import {AbilityBuilder} from "@casl/ability";

const USER_ROLES = {
  "ADMIN": "admin",
  "MANAGER": "manager",
  "CURATOR": "curator",
};

const admin = AbilityBuilder.define((can, cannot) => {
  can('add', 'internal user');
  can('edit', 'internal user');
  can('add', 'client user');
  can('edit', 'client user');
  can('add', 'client');
  can('edit', 'client');
});

const manager = AbilityBuilder.define((can, cannot) => {
  cannot('add', 'internal user');
  cannot('edit', 'internal user');
  cannot('add', 'client user');
  cannot('edit', 'client user');
  can('add', 'client');
  cannot('edit', 'client');
});

const curator = AbilityBuilder.define((can, cannot) => {
  cannot('add', 'internal user');
  cannot('edit', 'internal user');
  cannot('add', 'client user');
  cannot('edit', 'client user');
  can('add', 'client');
  cannot('edit', 'client');
});

const signedOut = AbilityBuilder.define(can => { });

const roles = { admin, manager, curator, signedOut };

export const getAbility = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  return roles[currentUser ? USER_ROLES[currentUser.userType] : "signedOut"];
};

