import React, {useEffect, useState} from "react";
import styled, {ThemeProvider} from "styled-components";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {alertActions, clientActions, locationActions, zonesActions} from "_actions";
import {CreationForms, DefaultBlackFormControl, DefaultColumnContainer, DefaultLabel, Helper, theme} from "_styles";
import Select from "react-select";
import image from "_assets/images/elena-saharova.jpg";

const listTypes = [
    {value: "Hotel", label: "Hotel"},
    {value: "Gym", label: "Gym"},
    {value: "Restaurant", label: "Restaurant"},
    {value: "Lobby", label: "Lobby"},
    {value: "Club", label: "Club"},
    {value: "Spa", label: "Spa"},
    {value: "Hospital", label: "Hospital"},
    {value: "Bar", label: "Bar"},
    {value: "Waiting Room", label: "Waiting Room"},
    {value: "Room", label: "Room"},
    {value: "Conference Room", label: "Conference Room"},
    {value: "Executive Lounge", label: "Executive Lounge"},
    {value: "Chapel", label: "Chapel"},
    {value: "Pool", label: "Pool"},
    {value: "Casino", label: "Casino"},
    {value: "Meeting Room", label: "Meeting Room"},
    {value: "Hall", label: "Hall"},
    {value: "Cinema", label: "Cinema"},
    {value: "Retail Outlet", label: "Retail Outlet"},
    {value: "Mall", label: "Mall"},
    {value: "Other", label: "Other"}
];

const DetailsContainer = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;

  & + & {
    margin-top: 20px;
  }
`;

const Label = styled(DefaultLabel)`
  color: white;
  font-size: 24px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const FormContainerWithoutHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-top: 20px;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-top: 10px;
`;

const FormLabel = styled(DefaultLabel)`
  color: ${props => props.theme.formlabel};
  font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
  margin-top: 5px;
`;

const RSelect = styled(Select)`
  width: 100%;
  margin-top: 10px;
`;

const CreateZone = ({zone = undefined, onBack, onCreateOrEdit, dispatch, clients, locations}) => {
    const isEditing = zone !== undefined;

    const [name, setName] = useState(isEditing ? zone.name : '');
    const [zoneType, setZoneType] = useState(isEditing ? {
        value: zone.type,
        label: zone.type
    } : undefined);
    const [location, setLocation] = useState('');
    const [client, setClient] = useState(undefined);
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        clientActions.getAllClients(dispatch);
        dispatch(locationActions.get());
    }, []);

    const submit = async () => {
        let zoneData;
        if (isEditing) {
            if (name === "") {
                setError('Please provide a name');
                return;
            }
        } else {
            if (name === "" || client === "" || location === "" || !zoneType.value) {
                setError('Please fill out all the required fields');
                return;
            }

            zoneData = {
                name,
                type: zoneType.value,
                location: {id: location.value},
                client: {id: client.value}
            };
        }

        setSubmitting(true);
        setError('');

        try {
            isEditing ? await zonesActions.updateZone(dispatch, zone.id, name, zoneType.value) : await zonesActions.addOne(dispatch, zoneData);
            setSubmitting(false);
            alertActions.notificationSuccess(dispatch, isEditing ? "Zone updated" : "Zone created", name);
            onCreateOrEdit();
        } catch (e) {
            setError(e);
            setSubmitting(false);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CreationForms
                message={`This will ${isEditing ? 'update a' : 'create a new'} client zone, please check the information entered above is correct.`}
                handleBackButton={onBack}
                handleSubmit={submit}
                submitting={submitting}
                alert={error}
                image={image}
                title={isEditing ? 'Edit Zone' : 'Create Zone'}
            >
                {!isEditing && (
                    <React.Fragment>
                        <DetailsContainer>
                            <Label>Choose Client</Label>
                            <RSelect
                                options={clients.sort((a, b) => a.name.localeCompare(b.name)).map(client => ({
                                    value: client.id,
                                    label: client.name
                                }))}
                                name="client-list"
                                value={client}
                                onChange={setClient}
                            />
                            <Helper message="Choose one client"/>
                        </DetailsContainer>
                        <DetailsContainer>
                            <Label>Choose Location</Label>
                            <RSelect
                                // isDisabled={this.state.client === ""}
                                options={client ? locations.filter(loc => loc.client.id === client.value).map(loc => ({
                                    value: loc.id,
                                    label: loc.name
                                })) : []}
                                name="location-list"
                                value={location}
                                onChange={setLocation}
                            />
                            <Helper message="Choose one location"/>
                        </DetailsContainer>
                    </React.Fragment>
                )}
                <DetailsContainer>
                    <Label>Zone Details</Label>
                    <FormContainerWithoutHelper>
                        <FormLabel>ZONE NAME</FormLabel>
                        <Form
                            type="text"
                            name="name"
                            placeholder="Zone Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        <Helper message="The email address will be used as the username"/>
                    </FormContainerWithoutHelper>
                    <FormContainerWithHelper>
                        <FormLabel>ZONE TYPE</FormLabel>
                        <RSelect
                            options={listTypes}
                            // isMulti
                            name="zone-type-list"
                            value={zoneType}
                            onChange={setZoneType}
                        />
                        <Helper message="The zone type. Choose from the list"/>
                    </FormContainerWithHelper>
                </DetailsContainer>
            </CreationForms>
        </ThemeProvider>
    );
}

const mapStateToProps = state => ({
    clients: state.client.client,
    locations: state.location.location || []
});

const connectedCreateZones = withRouter(connect(mapStateToProps)(CreateZone));
export {connectedCreateZones as CreateZone};
