import React, {useEffect} from "react";

export const getPercent = (time, duration) => duration === 0 ? 0 : (time === 0 ? 0 : (time / duration) * 100);

export const pad = (num, size) => {
    let s = "000000000" + num;
    return s.substr(s.length - size);
}

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export const minutesSecondsString = seconds => {
    const minutes = Math.floor(seconds / 60);
    const sec = pad(Math.floor(seconds % 60), 2);
    return `${minutes}:${sec}`;
}

export const durationToString = (duration) => {
    const hours = Math.floor(duration / (60 * 60));
    duration -= hours * 60 * 60;
    let minutes = Math.floor(duration / 60);
    let seconds = Math.floor(duration % 60);
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (hours > 0) return `${hours}:${minutes}:${seconds}`;
    return `${minutes}:${seconds}`;
}

export const truncateString = (str, num) => str.length <= num ? str : str.slice(0, num) + '...';


export const useOutsideAlerter = (ref, outSideCallback) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                outSideCallback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const addLineBreaks = (notes, everyNCharacters = 50) => {
    if (!notes) {
        return;
    }
    everyNCharacters = everyNCharacters || 50;
    const regex = new RegExp(".{1," + everyNCharacters + "}", "g");
    const match = notes.match(regex);
    return match ? match.reduce((result, item) => <>{result}<br/>{item}</>) : null;
};

export const stringContainsWords = (str, words) => {
    const regex = new RegExp("\\b" + words.join("\\b|\\b")+"\\b", "gi");
    return regex.test(str);
}

export const wordsContainedInString = (str, words) => {
    const regex = new RegExp("\\b" + words.join("\\b|\\b")+"\\b", "gi");
    const matches = str.match(regex);
    return [...new Set(matches.map(word => word.toLowerCase()))];
}