import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash.get";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { alertActions, scheduleActions, zonesActions } from "_actions";
import {
  ClientDropdownFilter,
  CreateSchedule,
  CreateZone,
  DropdownFilter,
  FilterSection,
  PrimaryButton,
  RowSpaced,
  RowSpacing,
  SearchFilter,
  SectionGutter,
  SectionHeader,
  SectionTitle,
  ZoneDetails
} from "_components";
import { Row } from "_components/Standard/SectionHeader";
import { Match } from "_helpers";
import { theme } from '_styles/Theme';

const Gutter = `${SectionGutter}px`

const ClientRow = styled(Row)`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.01px;
    line-height: 30px;

    margin: 62px ${Gutter} 0 ${Gutter};
    margin-top: 62px;
    padding-bottom: 12px;
    border-bottom: 1px solid #2e2e2e;
`;

const LocationRow = styled(Row)`
    margin: 20px ${Gutter} 24px ${Gutter};
    margin-top: 20px;
    margin-bottom: 24px;
`;

const LocationTitle = styled.div`
    margin-left: 6px;
    margin-right: 14px;
    height: 21px;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: -0.76px;
    line-height: 22px;
`;

const CountText = styled.div`
    color: #4d4d4d;
`;

const ZoneRowPadding = 26;

const ZoneRow = styled(Row)`
    flex: 1;
    height: 42px;
    margin-bottom: 6px;
    margin: 0 ${Gutter} 6px ${Gutter};
    padding: 0 ${ZoneRowPadding}px;
    border-radius: ${SectionGutter / 2}px;
    background-color: #d8d8d807;
    box-sizing: border-box;
    transition: all 250ms ease-out;
    cursor: pointer;

    &:hover {
        margin: 0 0 6px 0;
        padding: 0 ${SectionGutter + ZoneRowPadding}px;
        border-radius: 0;
        background-color: ${theme.rowHover};
    }
`;

const Unassigned = styled.div`
    font-style: italic;
    color: ${theme.dim};

    &:before {
        content: 'Unassigned';
    }
`;

const ZoneCell = styled.div`
    display: flex;
    font-size: 16px;
    flex: ${props => props.width ? `0 1 ${props.width}px` : '1'};
    align-items: center;
`;

const FrameClient = 'client-header';
const FrameLocation = 'location-header';
const FrameZone = 'zone';

const generateRenderFrames = data => {
  let lastClientId = -1;
  let lastLocationId = -1;
  let result = [];
  [...data].sort((a, b) => {
    if (a.location === undefined || b.location === undefined) return 0;
    const clientComp = a.location.client.name.toLowerCase().localeCompare(b.location.client.name.toLowerCase());
    if (clientComp !== 0) {
      return clientComp;
    } else {
      const locationComp = a.location.name.toLowerCase().localeCompare(b.location.name.toLowerCase());
      if (locationComp !== 0) {
        return locationComp;
      } else {
        const zoneComp = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        return zoneComp;
      }
    }
  }).forEach(entry => {
    const clientId = entry.location.client.id;
    const locationId = entry.location.id;

    if (clientId !== lastClientId) {
      result.push({
        _renderType: FrameClient,
        ...entry
      });
      lastClientId = clientId;
    }

    if (locationId !== lastLocationId) {
      result.push({
        _renderType: FrameLocation,
        zoneCount: 0, // to be computed
        ...entry
      });
      lastLocationId = locationId;
    }

    result.push({
      _renderType: FrameZone,
      ...entry
    });
  });

  // Run counts in seperate loop for cleaner logic O(n)
  let count = 0;
  result.slice().reverse().forEach(result => {
    if (result._renderType === FrameZone) count++;
    else if (result._renderType === FrameLocation) {
      result.zoneCount = count;
      count = 0;
    }
  });

  return result;
};

const STATUS_FILTER_ACTIVE = 1;
const STATUS_FILTER_UNASSIGNED = 2;
const STATUS_FILTER_OFFLINE = 3;

// Server values
const PLAYER_STATUS_ONLINE = "online";
const PLAYER_STATUS_OFFLINE = "offline";

class Zones extends React.Component {
  state = {
    filterText: sessionStorage.getItem('zone_filter_text') || "",
    statusFilter: sessionStorage.getItem('zone_filter_status') || undefined,

    zones: [],
    frames: [],
    zone: undefined,

    zoneId: -1,

    showAddZones: false,
    showZoneSettings: false,
    showCreateSchedule: false,

    isFetchingZone: true,

    filterClientId: -1
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const selectedZoneId = query.get("zoneId");
    if (selectedZoneId) {
      this.setState({zoneId: parseInt(selectedZoneId), showZoneSettings: true});
    }
    const {dispatch} = this.props;

    dispatch(zonesActions.get());
    dispatch(scheduleActions.get());

    this.interval = setInterval(() => {
      this.props.dispatch(zonesActions.get());
    }, 30000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.zones && prevProps.zones !== this.props.zones) {
      this.filter(this.state.filterText, this.state.statusFilter, this.state.filterClientId);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  openSchedule = scheduleId => {
    this.props.history.push(`/schedule/${scheduleId}`);
  };

  openZoneDetails = e => {
    const zoneId = e.currentTarget.dataset.zoneid;
    this.setState({
      showZoneSettings: true,
      zoneId: parseInt(zoneId)
    });
  };

  addScheduleSuccess = name => {
    this.setState({showCreateSchedule: false}, () => {
      this.props.dispatch(scheduleActions.get());
    });
  };

  handleSearch = textFilter => {
    this.filter(textFilter, this.state.statusFilter, this.state.filterClientId);
  };

  handleFilterStatus = selected => {
    this.filter(this.state.filterText, selected ? selected.id : undefined, this.state.filterClientId);
  };

  filter = (filterText, statusFilter, clientId) => {
    const statusMatch = zone => {
      const playerStatus = get(zone, "player.status");
      if (
          statusFilter === STATUS_FILTER_ACTIVE &&
          playerStatus !== PLAYER_STATUS_ONLINE
      )
        return false;
      if (statusFilter === STATUS_FILTER_UNASSIGNED && zone.player)
        return false;
      if (
          statusFilter === STATUS_FILTER_OFFLINE &&
          playerStatus !== PLAYER_STATUS_OFFLINE
      )
        return false;
      return true;
    };

    const zones = this.props.zones.filter(zone => {
      if (statusFilter !== undefined && !statusMatch(zone)) return false;
      if (clientId !== -1 && get(zone, 'location.client.id', -2) !== clientId) return false;
      if (filterText.length > 0 && !Match(zone, filterText, ["name", "location.name", "location.client.name"])) return false;
      return true;
    });

    this.setState({
      filterText: filterText,
      statusFilter,
      zones,
      frames: generateRenderFrames(zones)
    }, () => {
      sessionStorage.setItem('zone_filter_text', filterText);
      sessionStorage.setItem('zone_filter_status', statusFilter);
    });
  };

  handleCloseZone = () => {
    this.setState({showAddZones: false, isFetchingZone: true, zone: undefined});
  }

  handleCreateZone = () => {
    this.handleCloseZone();
    this.props.dispatch(zonesActions.get());
  }

  handleDeleteZone = async () => {
    const {dispatch} = this.props;
    this.setState({showZoneSettings: false});

    try {
      await zonesActions.deleteZone(this.props.dispatch, this.state.zoneId);
      this.props.dispatch(zonesActions.get());
      alertActions.notificationSuccess(dispatch, "Zone archived", "");
    } catch (e) {
      alertActions.notificationError(dispatch, e.userMessage, "");
    }

  }

  filterClient = (client) => {
    const id = client ? client.id : -1;
    this.setState({filterClientId: id});
    this.filter(this.state.filterText, this.state.statusFilter, id);
  }

  renderStatus = (text, icon, dim) => {
    return (
        <RowSpacing>
                <FontAwesomeIcon icon={icon} color={dim ? theme.fade : 'white'} fixedWidth />
                <span style={{color: dim ? theme.fade : 'white'}}>{text}</span>
            </RowSpacing>
    )
  }

  renderPlayerStatus = zone => {
    if (!zone.schedule) return this.renderStatus('No schedule assigned', 'calendar-exclamation', true);

    // No player assigned
    if (!zone.player) return this.renderStatus('No player assigned', 'volume-off', true);

    // Offline
    if (zone.player.status === PLAYER_STATUS_OFFLINE) return this.renderStatus('Cannot be reached', 'wifi-slash', true);

    // Online and silent
    if (!zone.currentSong) return this.renderStatus('Nothing playing', 'volume-slash', false);

    // Playing song
    return this.renderStatus(zone.currentSong.title, 'volume-up', false);
  }

  render() {
    const {showAddZones, showZoneSettings, zones = [], frames, showCreateSchedule} = this.state;
    const {schedules = []} = this.props;

    return (
        <>
                <div style={{color: 'white'}}>
                    {showAddZones && (
                        <CreateZone onBack={this.handleCloseZone} onCreateOrEdit={this.handleCreateZone} />
                    )}

                  {showCreateSchedule && (
                      <CreateSchedule
                          handleBackButton={() =>
                              this.setState({showCreateSchedule: false})
                          }
                          closeCreateSuccess={this.addScheduleSuccess}
                      />
                  )}

                  {showZoneSettings && zones.length > 0 && (
                      <ZoneDetails
                          id={this.state.zoneId}
                          schedules={schedules}
                          handleClose={() => {
                            this.setState({showZoneSettings: false});
                            this.props.dispatch(zonesActions.get());
                          }}
                          onDeleteZone={this.handleDeleteZone}
                          openSchedule={this.openSchedule}
                          createSchedule={() => this.setState({showCreateSchedule: true})}
                      />
                  )}

                  <SectionHeader>
                        <RowSpaced>
                            <SectionTitle>Zones</SectionTitle>
                            <PrimaryButton onClick={() => this.setState({showAddZones: true})}>
                                + Add Zone
                            </PrimaryButton>
                        </RowSpaced>
                        <FilterSection>
                            <SearchFilter onChange={this.handleSearch} value={this.state.filterText} />
                            <ClientDropdownFilter clientSelect={this.filterClient} />
                            <DropdownFilter
                                selectedId={this.state.statusFilter ? parseInt(this.state.statusFilter) : undefined}
                                onSelect={this.handleFilterStatus}
                                placeholder="Status"
                                options={[
                                  {id: STATUS_FILTER_ACTIVE, text: "Active"},
                                  {id: STATUS_FILTER_OFFLINE, text: "Offline"},
                                  {id: STATUS_FILTER_UNASSIGNED, text: "Unassigned"}
                                ]}
                            />
                        </FilterSection>
                    </SectionHeader>

                  {frames.map(frame => {
                    if (frame._renderType === FrameClient) {
                      return <ClientRow
                          key={`${frame.location.client.id}-client`}>{frame.location.client.name}</ClientRow>;
                    }

                    if (frame._renderType === FrameLocation) {
                      return (
                          <LocationRow key={`${frame.location.id}-location`}>
                                    <FontAwesomeIcon color="#64636C" icon="map-marker-alt" />
                                    <LocationTitle>{frame.location.name}</LocationTitle>
                                    <CountText>({frame.zoneCount})</CountText>
                                </LocationRow>
                      );
                    }

                    return (
                        <Row key={`${frame.id}-zone`}>
                                <ZoneRow data-zoneid={frame.id} onClick={this.openZoneDetails}>
                                    <ZoneCell>
                                        {frame.name}
                                    </ZoneCell>
                                    <ZoneCell>
                                        <FontAwesomeIcon icon="calendar-alt" size='lg' opacity={.16}
                                            style={{marginRight: 16}} />
                                      {frame.schedule ? frame.schedule.name : <Unassigned />}
                                    </ZoneCell>
                                    <ZoneCell width={60}>
                                        <FontAwesomeIcon icon="arrow-alt-right" size='lg' opacity={.16} />
                                    </ZoneCell>
                                    <ZoneCell>
                                        <div style={{marginRight: '16px'}}
                                            className={`dot-status dot-status-${get(frame, "player.status", "none")}`} />
                                      {frame.player ? frame.player.name : <Unassigned />}
                                    </ZoneCell>
                                    <ZoneCell>
                                        {this.renderPlayerStatus(frame)}
                                    </ZoneCell>
                                </ZoneRow>
                            </Row>
                    );
                  })}
                </div>
            </>
    );
  }

  setData = data => {
    let finalData = [];

    if (data.length === 0) {
      return [];
    }

    data.forEach(el => {
      if (el.location !== undefined && el.location.client !== undefined) {
        let pos = finalData.findIndex(
            fd => fd.name === el.location.client.name
        );

        if (pos === -1) {
          finalData.push({
            name:
                el.location !== undefined && el.location.client !== undefined
                    ? el.location.client.name
                    : "",
            id: el.id,
            locations: [el]
          });
        } else {
          finalData[pos].locations.push(el);
        }
      } else {
        let pos = finalData.findIndex(fd => fd.name === "");

        if (pos === -1) {
          finalData.push({name: "", id: el.id, locations: [el]});
        } else {
          finalData[pos].locations.push(el);
        }
      }
    });

    return this.setLocations(finalData);
  };

  setLocations = data => {
    data.forEach((dt, index) => {
      let finalData = [];
      dt.locations.forEach((loc, locIndex) => {
        let pos = finalData.findIndex(fd => fd.name === loc.location.name);

        if (pos === -1) {
          finalData.push({name: loc.location.name, zones: [loc]});
        } else {
          finalData[pos].zones.push(loc);
        }
      });

      dt.locations = finalData;
    });

    return data;
  };
}

const mapStateToProps = state => ({
  loading: state.zones.loading,
  zones: state.zones.zone,
  schedules: state.schedule.schedule
});

const connectedZones = withRouter(connect(mapStateToProps)(Zones));
export { connectedZones as Zones };
