import {FormCheck, FormControl} from "react-bootstrap";
import styled from "styled-components";

export const DefaultFormControl = styled(FormControl).attrs({
  className: "form-control"
})`
  border: 1px solid ${props => props.theme.optionalGray};
  color: white;
  padding: 4px 4px 4px 10px;
  letter-spacing: 1px;
  height: 50px;
  background-color: ${props => props.theme.darkGray};
  outline: none;
  box-shadow: none;

  ::placeholder {
    color: white;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: ${props => props.theme.optionalGray};
    outline: none;
    box-shadow: none;
    caret-color: white;
    color: white;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const DefaultBlackFormControl = styled(DefaultFormControl)`
  border: 1px solid white;
  color: black;
  background-color: white;

  ::placeholder {
    color: #99999E;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: white;
    caret-color: black;
    color: black;
  }
`;

export const LeftIconFormControl = styled(DefaultFormControl)`
  border: 0;
  background-color: transparent;
`;

export const DefaultCheckbox = styled(FormCheck)``;
