import React, {useEffect, useState} from "react";
import {TaleaAudioPlayer} from "./AudioPlayer";
import {playerActions, playlistActions} from "../../_actions";

export const TaleaPlaybackController = (props) => {
    const {
        songId,
        source = '',
        title = '',
        artist = '',
        artworkUrl = '',
        loading,
        activePlaylistId,
        activeSongs,
        dispatch
    } = props;
    const [playlistIndex, setPlaylistIndex] = useState(0);
    const [currentSong, setCurrentSong] = useState(null);
    const [songs, setSongs] = useState(null);

    useEffect(() => {
        setPlaylistIndex(0)
        if (props.playlistPlayback) {
            const songs = props.playlistPlayback.songs;
            // move currentSong to the front of the array
            if (currentSong) {
                const currentSongIndex = songs.findIndex(s => s.id === currentSong.id);
                if (currentSongIndex > 0) {
                    const currentSong = songs[currentSongIndex];
                    songs.splice(currentSongIndex, 1);
                    songs.unshift(currentSong);
                }
            }
            setSongs(songs);
        } else {
            setSongs(null);
        }

    }, [props.playlistPlayback]);

    const handleAddSong = () => {
        playlistActions.addSongs(dispatch, activePlaylistId, [songId])
    };

    const handleRemoveSong = () => {
        playlistActions.deleteSongs(dispatch, activePlaylistId, [songId])
    };
    const songAddedToActivePlaylist = activeSongs[songId];

    if (props.playlistPlayback && songs) {
        const nextSong = (skipCount = 1) => {
            if (playlistIndex + skipCount < songs.length) {
                let newPlaylistIndex;
                if (skipCount === 1) {
                    newPlaylistIndex = playlistIndex + skipCount;
                } else {
                    newPlaylistIndex = (Math.round((playlistIndex + skipCount) / 10) * 10) - 1;
                }
                song = songs[newPlaylistIndex];
                setCurrentSong(song);
                playerActions.nextPlaylistSongPlaying(dispatch, song.id);
                setPlaylistIndex(newPlaylistIndex);
            }
        }
        const prevSong = (skipCount = 1) => {
            if (playlistIndex - skipCount >= 0) {
                let newPlaylistIndex;
                if (skipCount === 1) {
                    newPlaylistIndex = playlistIndex - skipCount;
                } else {
                    newPlaylistIndex = (Math.round((playlistIndex - skipCount) / 10) * 10) - 1;
                }
                song = songs[newPlaylistIndex];
                setCurrentSong(song);
                setPlaylistIndex(newPlaylistIndex);
            }
        }
        let song = currentSong;
        let songTitles = songs.map(s => s.title);
        if (song === null || !songTitles.includes(song.title)) {
            song = songs[playlistIndex];
            setCurrentSong(song);
        }
        return <TaleaAudioPlayer loading={loading} source={song.songUrl} title={song.title} artist={song.artist.name}
                                 artworkUrl={song.artworkUrl}
                                 playlistName={props.playlistPlayback.name}
                                 playlistIndex={playlistIndex}
                                 playlistId={props.playlistPlayback.id}
                                 playlistTotal={songs.length}
                                 nextSong={nextSong}
                                 prevSong={prevSong}
                                 handleAddSong={handleAddSong}
                                 handleRemoveSong={handleRemoveSong}
                                 activePlaylistId={activePlaylistId}
                                 songAdded={songAddedToActivePlaylist}
                                 songId={songId}
        />
    } else {
        if ((source !== '' && !currentSong) || (currentSong && currentSong.songUrl !== source)) {
            setCurrentSong({id: songId, songUrl: source, title: title, artist: {name: artist}, artworkUrl: artworkUrl});
        }
        return <TaleaAudioPlayer loading={loading} source={source}
                                 title={title} artist={artist}
                                 artworkUrl={artworkUrl}
                                 handleAddSong={handleAddSong}
                                 handleRemoveSong={handleRemoveSong}
                                 songAdded={songAddedToActivePlaylist}
                                 activePlaylistId={activePlaylistId}
                                 songId={songId}
        />
    }
}