import {api} from './api';

export const sampleService = {
  getSampleDetails,
  getPlaylist,
  getPlaylistSongs,
  voteSong,
  postSampleFeedback,
  postPlaylistFeedback
};

function postPlaylistFeedback(token, playlistId, feedback) {
  return api("POST", `campaign/${token}/playlist/${playlistId}/feedback`, {sampleToken:token,feedback}, response => response,null,true);
}
function postSampleFeedback(token, feedback, name) {
  return api("POST", `campaign/${token}/feedback`, {sampleToken:token,feedback,name}, response => response,null,true);
}
function getSampleDetails(token) {
  return api("GET", `campaign/client/${token}`, undefined, sample => sample,null,true);
}
function getPlaylist(token,playlistId) {
  return api("GET", `campaign/client/${token}/playlist/${playlistId}`, undefined, sample => sample,null,true);
}

function getPlaylistSongs(token,playlistId) {
  return api("GET", `campaign/client/${token}/playlist/${playlistId}/songs`, undefined, response => response,null,true);
}

function voteSong(token,playlistId,songId,downvote,feedback) {
  return api("POST", `campaign/${token}/song/${songId}/vote`, {sampleToken:token,playlistId,songId,downvote,feedback}, response => response,null,true);
}
