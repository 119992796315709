import {playlistConstants} from "_constants";
import get from 'lodash.get';

// Helpers
function durationToString(duration) {
    const hours = Math.floor(duration / (60 * 60));
    duration -= hours * 60 * 60;
    const minutes = Math.floor(duration / 60);
    if (hours > 0) return `${hours}h ${minutes}m`;
    return `${minutes}m`;
}

const idArrayToIndex = ary => {
    const obj = {}
    const length = ary.length;
    for (let i = 0; i < length; i++) obj[ary[i].id] = i;  //ary[i];
    return obj;
}

// Constants
export const SONG_STATE_PUBLISHED = 'PUBLISHED';
export const SONG_STATE_REMOVED = 'REMOVED';
export const SONG_STATE_ADDED = 'ADDED';
export const SONG_STATE_NONE = 'NONE';
export const SONG_STATE_PENDING_REMOVE = 'PENDING_REMOVE';  // local internal state
export const SONG_STATE_PENDING_ADD = 'PENDING_ADD';  // local internal state

// Selectors
export const getPlaylist = (state, id) => state.playlists.playlists[id];

export const isPublishing = (state, id) => get(getPlaylist(state, id), `publishing`, false);
export const getPlaylistSongs = (state, id) => get(getPlaylist(state, id), 'songs', []);

export const isPlaylistReady = (state, id) => {
    const playlist = getPlaylist(state, id);
    return playlist && !playlist.loading && !playlist.error;
}

// A view into the playlist, presents published changes as published
export const getPlaylistLookupView = (state, id) => get(getPlaylist(state, id), 'view.has', {});
export const getPlaylistSongsView = (state, id) => get(getPlaylist(state, id), 'view.songs', []);
export const getPlaylistStats = (state, id) => get(getPlaylist(state, id), 'stats');

export const getPlaylistHistory = (state, id) => get(getPlaylist(state, id), 'stats');

export const getPlaylistChanges = (state, id) => {
    // Sort ADDED top, REMOVED bottom
    const songs = get(getPlaylist(state, id), "songs", [])
    return songs.filter(song => song.state !== SONG_STATE_PUBLISHED).sort((a, b) => a.state.localeCompare(b.state));
}

export const isLoadingPlaylistZones = (state, id) => get(getPlaylist(state, id), 'zones.loading', false)
export const getPlaylistZones = (state, id) => get(getPlaylist(state, id), 'zones.data', [])
export const isLoadingPlaylistSchedules = (state, id) => get(getPlaylist(state, id), 'schedules.loading', false)
export const getPlaylistSchedules = (state, id) => get(getPlaylist(state, id), 'schedules.data', [])

export const isLoadingList = (state) => get(state, `playlists.lists.loading`, false);
export const isLoadingCalendarList = (state) => get(state, `calendarplaylists.lists.loading`, false);
export const getPlaylists = (state) => state.playlists.lists.list;

export const getSimilarPlaylists = (state) => state.playlists.similarPlaylists.list;
export const getSimilarPlaylistsLoading = (state) => state.playlists.similarPlaylists.loading;

export const getSelectedPlaylistId = state => state.playlists.selectedPlaylistId;

export const getActivePlaylistId = (state) => state.playlists.activePlaylistId;
export const getActivePlaylist = (state) => getPlaylist(state, getActivePlaylistId(state));
export const haveActivePlaylist = state => getActivePlaylistId(state) !== -1;

const getLookupView = songs => {
    const lookup = {}
    songs.forEach(song => {
        if (song.state === SONG_STATE_REMOVED || song.state === SONG_STATE_PENDING_REMOVE) return;
        lookup[song.id] = song;
    });
    return lookup;
}

const getSongsView = songs => {
    return songs.filter(song => song.state !== SONG_STATE_REMOVED && song.state !== SONG_STATE_PENDING_REMOVE);
}

const getSongStats = songs => {
    const changes = songs.filter(song => song.state !== SONG_STATE_PUBLISHED);
    const added = changes.reduce((count, song) => song.state === SONG_STATE_ADDED ? count + 1 : count, 0);
    const removed = changes.reduce((count, song) => song.state === SONG_STATE_REMOVED ? count + 1 : count, 0);

    const visibleSongs = songs.filter(song => song.state !== SONG_STATE_REMOVED);
    const duration = visibleSongs.reduce((duration, song) => duration + song.trackLength, 0);
    const noMatch = 10000;
    const bpmMin = visibleSongs.filter(x => x.bpm).reduce((prev, cur) => prev.bpm < cur.bpm ? prev : cur, {bpm: noMatch});
    const bpmMax = visibleSongs.filter(x => x.bpm).reduce((prev, cur) => prev.bpm > cur.bpm ? prev : cur, {bpm: 0});

    return {
        duration,
        durationString: durationToString(duration),
        changes: added + removed > 0,
        added,
        removed,
        bpm: [bpmMin.bpm === noMatch ? 0 : bpmMin.bpm, bpmMax.bpm]
    }
}

const computeSongsUpdate = songs => {
    return {
        songs,
        stats: getSongStats(songs),
        view: {
            has: getLookupView(songs),
            songs: getSongsView(songs)
        }
    }
}

const updateSongStatus = (state, playlistId, songIds, songState) => {
    const playlist = state.playlists[playlistId];
    if (!playlist) return state;

    return {
        ...state,
        playlists: {
            ...state.playlists,
            [playlistId]: {
                ...state.playlists[playlistId],
                ...computeSongsUpdate(
                    state.playlists[playlistId].songs.map(song => {
                        if (!songIds.includes(song.id)) {
                            return song;
                        }
                        return {
                            ...song,
                            state: songState
                        }
                    })
                )
            }
        }
    }
}

const addSongsUpdate = (state, playlistId, response) => {
    const playlist = state.playlists[playlistId];
    if (!playlist) return state;

    const idToSongIndex = idArrayToIndex(playlist.songs);

    let songsList = [...playlist.songs];
    let add = []

    response.forEach(entry => {
        // Server won't fold these fields
        const song = {
            ...entry.song,
            state: entry.song.status
        }

        const songIndex = idToSongIndex[song.id];
        const exists = songIndex !== undefined;

        if (exists) {
            // Do updates in loop
            songsList[songIndex] = song;
        } else {
            // Save adds for after loop to not break indexing
            add.push(song)
        }
    });

    songsList = [...add, ...songsList];

    return {
        ...state,
        playlists: {
            ...state.playlists,
            [playlistId]: {
                ...state.playlists[playlistId],
                ...computeSongsUpdate(songsList)
            }
        }
    }
}

// SONG_STATE_REMOVED, SONG_STATE_NONE
const removeSongsUpdate = (state, playlistId, response) => {
    const playlist = state.playlists[playlistId];
    if (!playlist) return state;

    const noneIds = response.filter(s => s.status === SONG_STATE_NONE).map(s => s.id);
    const removedIds = response.filter(s => s.status === SONG_STATE_REMOVED).map(s => s.id);

    // Remove songs in state none
    let songsList = noneIds.length > 0 ? playlist.songs.filter(song => !noneIds.includes(song.id)) : [...playlist.songs];

    // Mark songs removed
    songsList = songsList.map(song => {
        if (!removedIds.includes(song.id)) return song;
        return {
            ...song,
            state: SONG_STATE_REMOVED
        }
    })

    return {
        ...state,
        playlists: {
            ...state.playlists,
            [playlistId]: {
                ...state.playlists[playlistId],
                ...computeSongsUpdate(songsList)
            }
        }
    }
};

const initialState = {
    activePlaylistId: -1,
    selectedPlaylistId: -1,
    lists: {
        loading: false,
        list: []
    },
    calendarlists: {
        loading: false,
        list: []
    },
    playlists: {},
    similarPlaylists: {
        loading: false,
        list: []
    },
    playlistHistory: {
        loading: false,
        list: []
    }

};

export function playlists(state = initialState, action) {
    switch (action.type) {
        case playlistConstants.SET_ACTIVE_PLAYLIST_ID:
            return {
                ...state,
                activePlaylistId: action.playlistId
            };

        case playlistConstants.SET_SELECTED_PLAYLIST_ID:
            return {
                ...state,
                selectedPlaylistId: action.playlistId
            };

        case playlistConstants.GET_REQUEST:
            return {
                ...state,
                lists: {
                    loading: true,
                    list: []
                }
            };
        case playlistConstants.GET_SUCCESS:
            return {
                ...state,
                lists: {
                    loading: false,
                    list: action.playlist
                }
            };
        case playlistConstants.GET_FAILURE:
            return {
                ...state,
                lists: {
                    loading: false,
                    list: []
                }
            };
        case playlistConstants.GET_CALENDAR_REQUEST:
            return {
                ...state,
                calendarlists: {
                    loading: true,
                    list: []
                }
            };
        case playlistConstants.GET_CALENDAR_SUCCESS:
            return {
                ...state,
                calendarlists: {
                    loading: false,
                    list: action.playlist
                }
            };
        case playlistConstants.GET_CALENDAR_FAILURE:
            return {
                ...state,
                calendarlists: {
                    loading: false,
                    list: []
                }
            };
        case playlistConstants.GET_DETAIL_REQUEST:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        loading: true
                    }
                }
            };

        case playlistConstants.CREATE_PLAYLIST_SUCCESS:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    list: [
                        ...state.lists.list,
                        action.playlist
                    ]
                },
                playlists: {
                    ...state.playlists,
                    [action.playlist.id]: {
                        loading: false,
                        ...action.playlist,
                        ...computeSongsUpdate(action.playlist.songs)
                    }
                }
            };

        case playlistConstants.GET_DETAIL_SUCCESS:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.playlist.id]: {
                        ...state.playlists[action.playlist.id],
                        loading: false,
                        ...action.playlist,
                        ...computeSongsUpdate(action.playlist.songs)
                    }
                }
            };

        case playlistConstants.GET_DETAIL_FAILURE:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        loading: false,
                        error: action.error
                    }
                }
            };

        case playlistConstants.GET_SCHEDULES_REQUEST:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        ...state.playlists[action.id],
                        schedules: {
                            loading: true
                        }
                    }
                }
            };

        case playlistConstants.GET_SCHEDULES_SUCCESS:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        ...state.playlists[action.id],
                        schedules: {
                            loading: false,
                            data: action.schedules
                        }
                    }
                }
            };

        case playlistConstants.GET_SCHEDULES_FAILURE:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        ...state.playlists[action.id],
                        schedules: {
                            loading: false,
                            error: action.error
                        }
                    }
                }
            };

        case playlistConstants.GET_ZONES_REQUEST:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        ...state.playlists[action.id],
                        zones: {
                            loading: true
                        }
                    }
                }
            };

        case playlistConstants.GET_ZONES_SUCCESS:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        ...state.playlists[action.id],
                        zones: {
                            loading: false,
                            data: action.zones
                        }
                    }
                }
            };

        case playlistConstants.GET_ZONES_FAILURE:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        ...state.playlists[action.id],
                        zones: {
                            loading: false,
                            error: action.error
                        }
                    }
                }
            };

        case playlistConstants.DELETE_SONGS_REQUEST:
            return updateSongStatus(state, action.playlistId, action.songIds, SONG_STATE_PENDING_REMOVE);

        case playlistConstants.ADD_SONGS_REQUEST:
            return updateSongStatus(state, action.playlistId, action.songIds, SONG_STATE_PENDING_ADD);

        case playlistConstants.ADD_SONGS_SUCCESS:
            return addSongsUpdate(state, action.playlistId, action.response);

        case playlistConstants.DELETE_SONGS_SUCCESS:
            return removeSongsUpdate(state, action.playlistId, action.response);

        // case playlistConstants.ADD_SONGS_FAILURE:
        // case playlistConstants.DELETE_SONGS_FAILURE:
        // TODO impl

        case playlistConstants.REVERT_SUCCESS:
        case playlistConstants.PUBLISH_SUCCESS:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.playlist.id]: {
                        loading: false,
                        ...action.playlist,
                        ...computeSongsUpdate(action.playlist.songs)
                    }
                },
                lists: {
                    ...state.lists,
                    list: state.lists.list.map(l => {
                        if (l.id !== action.playlist.id) return l;
                        return action.playlist;
                    })
                }
            }

        case playlistConstants.PUBLISH_REQUEST:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.playlistId]: {
                        ...state.playlists[action.playlistId],
                        publishing: true
                    }
                }
            }

        case playlistConstants.PUBLISH_FAILURE:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.playlistId]: {
                        ...state.playlists[action.playlistId],
                        publishing: false
                    }
                }
            }
        case playlistConstants.GET_SIMILAR_REQUEST:
            return {
                ...state,
                similarPlaylists: {
                    loading: true,
                    list: []
                }
            };
        case playlistConstants.GET_SIMILAR_SUCCESS:
            return {
                ...state,
                similarPlaylists: {
                    loading: false,
                    list: action.similarPlaylists
                }
            };
        case playlistConstants.GET_SIMILAR_FAILURE:
            return {
                ...state,
                similarPlaylists: {
                    loading: false,
                    list: []
                }
            };

        case playlistConstants.GET_HISTORY_REQUEST:
            return {
                ...state,
                playlistHistory: {
                    loading: true,
                    list: []
                }
            };
        case playlistConstants.GET_HISTORY_SUCCESS:
            return {
                ...state,
                playlistHistory: {
                    loading: false,
                    list: action.playlistHistory
                }
            };
        case playlistConstants.GET_HISTORY_FAILURE:
            return {
                ...state,
                playlistHistory: {
                    loading: false,
                    list: []
                }
            };

        case playlistConstants.EDIT_DETAILS_SUCCESS:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.id]: {
                        ...state.playlists[action.id],
                        name: action.name,
                        description: action.description,
                        tags: action.tags,
                        playlistGroup: action.playlistGroup,
                        internalDescription: action.internalDescription,
                        allowedExplicitType: action.allowedExplicitType
                    }
                },
                lists: {
                    ...state.lists,
                    list: (state.lists.list || []).map(e => {
                        if (e.id !== action.id) return e;
                        return {
                            ...e,
                            name: action.name,
                            playlistGroup: action.playlistGroup
                        }
                    })
                }
            }
        default:
            return state;
    }
}
