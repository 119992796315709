import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { alertActions, clientActions, playlistActions, songsActions, uiActions } from "_actions";
import { SideNavbar } from '_components';
import { getNotifications } from "_reducers/alert.reducer";
import {
  getNowPlayingArtist,
  getNowPlayingArtworkUrl,
  getNowPlayingLoading,
  getNowPlayingTitle,
  getNowPlayingUrl,
  getPlayerSongId,
  getPlaylistPlayback
} from "_reducers/player.reducer";
import { getActivePlaylistId, getPlaylistLookupView, getPlaylists } from "_reducers/playlist.reducer";
import { getNavigationPlaylistExpand } from "_reducers/ui.reducer";
import { TaleaPlaybackController } from "../_components/Standard/TaleaPlaybackController";

const ContentContainer = styled.div`
    position: absolute;
    left: 300px;
    top: 0px;
    bottom: 0px;
    right: 0px;
`;

const FooterHeight = 84;
const InnerContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: ${FooterHeight}px;
    overflow: auto;
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: ${FooterHeight}px;
    border-top: 1px solid ${props => props.theme.dim};
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`;

class Layout extends React.Component {

  componentDidMount() {
    const {dispatch} = this.props;

    // Get all clients
    clientActions.getAllClients(this.props.dispatch);

    // Get playlists
    dispatch(playlistActions.getSimple());

    // Load all genres
    songsActions.getGenres(dispatch);

    // Get all songs
    const getSongs = () => songsActions.getSongs(this.props.dispatch);
    getSongs();
    // setInterval(getSongs, 1000 * 60 * 5);
  }

  componentDidUpdate() {
    const {userDetails = {}} = this.props;

    if (Object.keys(userDetails).length > 0) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.userType = userDetails.userType;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  handleSongDrop = (songs, playlistId) => {
    playlistActions.addSongs(this.props.dispatch, playlistId, songs.map(s => s.id))
  }

  pinPlaylist = playlistId => {
    playlistActions.getPlaylist(this.props.dispatch, playlistId);
    this.props.dispatch(playlistActions.setActivePlaylistId(playlistId));
  };

  onAlertDismissed = alert => {
    alertActions.removeNotification(this.props.dispatch, alert.id);
  };

  onNavigationExpand = items => {
    uiActions.setNavigationExpandedState(this.props.dispatch, items);
  }

  render() {
    const {pathname} = this.props.location;
    const {playlists, activePlaylistId, expanded} = this.props;
    const fullPageView = pathname.includes("schedule/") || pathname.includes("devices/");

    return (
        <div className='page'>
                {!fullPageView &&
                    <div className='side'>
                        <SideNavbar
                            playlists={playlists}
                            pinnedRecentId={activePlaylistId}
                            handlePin={this.pinPlaylist}
                            handleSongDrop={this.handleSongDrop}
                            expanded={expanded}
                            onExpand={this.onNavigationExpand}
                        />
                    </div>}
          <ContentContainer style={fullPageView ? {left: 0} : {}}>
                    <AlertList
                        position={"top-right"}
                        alerts={this.props.alerts.map(alert => ({
                          id: alert.id,
                          type: alert.type,
                          headline: alert.title,
                          message: alert.message
                        }))}
                        timeout={5000}
                        dismissTitle="Close"
                        onDismiss={this.onAlertDismissed}
                    />
                    <InnerContainer>
                        {this.props.children}
                    </InnerContainer>
                    <Footer>
                        <FooterContainer>
                            <TaleaPlaybackController loading={this.props.nowPlayingLoading}
                                source={this.props.songUrl}
                                songId={this.props.songId}
                                title={this.props.songTitle}
                                artist={this.props.artist}
                                playlistPlayback={this.props.playlistPlayback}
                                artworkUrl={this.props.artworkUrl}
                                activePlaylistId={this.props.activePlaylistId}
                                activeSongs={this.props.activeSongs}
                                dispatch={this.props.dispatch}>
                            </TaleaPlaybackController>
                        </FooterContainer>
                    </Footer>
                </ContentContainer>
            </div>
    );
  }
}

const mapStateToProps = state => ({
  nowPlayingLoading: getNowPlayingLoading(state),
  artist: getNowPlayingArtist(state),
  artworkUrl: getNowPlayingArtworkUrl(state),
  songId: getPlayerSongId(state),
  songUrl: getNowPlayingUrl(state),
  songTitle: getNowPlayingTitle(state),
  loading: state.authentication.loading,
  userDetails: state.users.profileUser,
  playlists: getPlaylists(state),
  expanded: getNavigationPlaylistExpand(state),
  activePlaylistId: getActivePlaylistId(state),
  alerts: getNotifications(state),
  playlistPlayback: getPlaylistPlayback(state),
  activeSongs: getPlaylistLookupView(state, getActivePlaylistId(state)),
});

const connectedLayout = withRouter(connect(mapStateToProps)(Layout));
export { connectedLayout as Layout };

