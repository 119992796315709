import React from "react";
import styled from "styled-components";
import {Alert, AlertContainer} from "react-bs-notifier";
import {DefaultLabel} from "./Label.style";

export const DefaultAlert = styled(DefaultLabel)`
  color: red;
`;

export const AlertMessageContainer = ({
  position,
  alertType,
  clearAlerts,
  children
}) => (
  <AlertContainer position={position}>
    {alertType === "alert-success" ? (
      <Alert
        type="success"
        timeout={1000}
        onDismiss={clearAlerts}
        headline="Success"
      >
        {children}
      </Alert>
    ) : (
      <Alert
        type="danger"
        timeout={1000}
        onDismiss={clearAlerts}
        headline="Failed"
      >
        {children}
      </Alert>
    )}
  </AlertContainer>
);
