import React, {useCallback, useState} from "react"
import styled from "styled-components";


import {Creatable} from "react-select"
import {Button, TextField} from "@material-ui/core";


const CreatableStyled = styled(Creatable)`
  .MuiButton-label {
    text-transform: none;
  }
`
const CreatableEditableSelect = ({options, value: propValue, onChange}) => {
    const [editingValue, setEditingValue] = useState()
    const handleChange = useCallback(
        newValue => {
            setEditingValue(undefined)
            onChange(newValue)
        },
        [onChange]
    )

    const handleEditChange = useCallback(
        (inputValue) => {
            let newValue;
            if (inputValue) {
                newValue = {value: inputValue.trim(), data: inputValue.trim()}
            } else {
                newValue = {value: undefined, data: undefined};
            }
            onChange(newValue)
            setEditingValue(undefined)
        },
        [propValue, onChange]
    )

    const EditableValueLabel = useCallback(
        ({data}) => {
            if (editingValue !== undefined/*&& editingValue === data.value*/) {
                return (
                    <TextField
                        defaultValue={data.value}
                        value={editingValue}
                        onKeyDown={ev => {
                            ev.stopPropagation();
                            if (ev.key === "Escape") {
                                ev.preventDefault();
                                setEditingValue(undefined);
                            }
                            if (ev.key === "Enter") {
                                handleEditChange(editingValue);
                            }
                        }}
                        onChange={ev => {
                            ev.stopPropagation()
                            setEditingValue(ev.target.value ? ev.target.value : undefined)
                        }}
                        autoFocus
                    />
                )
            }
            return (
                !data.value ? <></> :
                    <Button
                        onClick={() => {
                            setEditingValue(data.value.trim())
                        }}
                    >
                        {data.value.trim()}
                    </Button>
            )
        },
        [handleEditChange, editingValue]
    )
    return (
        <CreatableStyled
            value={propValue}
            onChange={handleChange}
            options={options}
            components={{
                SingleValue: EditableValueLabel
            }}
        />
    )
}

export default CreatableEditableSelect
