import {api} from './api';

export const artistService = {
  get,
  getOne,
  addOne
};

function get() {
  return api("GET", `artist`, undefined, artists => artists);
}

function getOne(id) {
  return api("GET", `artist/${id}`, undefined, artist => artist);
}

function addOne(artist) {
  // TODO NotImplemented
  return api("POST", `artist`, undefined, artist => artist);
}