export const playerConstants = {
  PLAY_REQUEST: 'PLAYER_PLAY_REQUEST',
  PLAY_SUCCESS: 'PLAYER_PLAY_SUCCESS',
  PLAY_FAILURE: 'PLAYER_PLAY_FAILURE',

  PLAY_PLAYLIST_REQUEST: 'PLAYER_PLAY_PLAYLIST_REQUEST',
  PLAY_PLAYLIST_SUCCESS: 'PLAYER_PLAY_PLAYLIST_SUCCESS',
  PLAY_PLAYLIST_FAILURE: 'PLAYER_PLAY_PLAYLIST_FAILURE',

  NEXT_PLAYLIST_SONG_PLAYING: 'NEXT_PLAYLIST_SONG_PLAYING',

  GET_REQUEST: 'PLAYER_GET_REQUEST',
  GET_SUCCESS: 'PLAYER_GET_SUCCESS',
  GET_FAILURE: 'PLAYER_GET_FAILURE',

  UPDATE_ONE_REQUEST: 'PLAYER_UPDATE_REQUEST',
  UPDATE_ONE_SUCCESS: 'PLAYER_UPDATE_SUCCESS',
  UPDATE_ONE_FAILURE: 'PLAYER_UPDATE_FAILURE',

  GET_ONE_REQUEST: 'PLAYER_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'PLAYER_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'PLAYER_GET_ONE_FAILURE',

  ADD_ONE_REQUEST: 'PLAYER_ADD_ONE_REQUEST',
  ADD_ONE_SUCCESS: 'PLAYER_ADD_ONE_SUCCESS',
  ADD_ONE_FAILURE: 'PLAYER_ADD_ONE_FAILURE',

  VOLUME_REQUEST: 'PLAYER_VOLUME_REQUEST',
  VOLUME_SUCCESS: 'PLAYER_VOLUME_SUCCESS',
  VOLUME_FAILURE: 'PLAYER_VOLUME_FAILURE',

  ARCHIVE_REQUEST: 'PLAYER_ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'PLAYER_ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'PLAYER_ARCHIVE_FAILURE'

};
