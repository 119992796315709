import React, {useState, useEffect} from "react";
import styled, {ThemeProvider} from "styled-components";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {clientActions, locationActions, alertActions, zonesActions} from "_actions";
import {
    DefaultLabel,
    DefaultColumnContainer,
    DefaultBlackFormControl,
    CreationForms,
    Helper,
    theme
} from "_styles";
import Select from "react-select";
import image from "_assets/images/elena-saharova.jpg";
import {ContextMenu} from "react-contextmenu";
import {Col, Container, Row} from "react-bootstrap";

const sortByTypes = [
    {value: 1, label: "Similar playlists to target playlist", selected: true},
    //{value: 2, label: "Gym"},

];

const DetailsContainer = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;

  & + & {
    margin-top: 20px;
  }
`;

const Label = styled(DefaultLabel)`
  color: white;
  font-size: 24px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const FormContainerWithoutHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-top: 20px;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-top: 10px;
`;

const FormLabel = styled(DefaultLabel)`
  color: ${props => props.theme.formlabel};
  font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
  margin-top: 10px;
  max-height: 37px;
`;

const RSelect = styled(Select)`
  width: 100%;
  margin-top: 10px;
`;

const SortSuggestedOptionsDialog = ({onBack, dispatch, playlists, clients, onOptionsSelected, playlistId}) => {
    const [sortByType, setSortByType] = useState();
    const [targetPlaylist, setTargetPlaylist] = useState();
    const [selectedClients, setSelectedClients] = useState([]);
    const [minimumTracks, setMinimumTracks] = useState(0);
    const [minimumPercentage, setMinimumPercentage] = useState(0);

    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        clientActions.getAllClients(dispatch);
    }, []);

    const submit = async () => {
        if (!targetPlaylist) {
            setError('Please select target playlist');
            return;
        }

        setSubmitting(true);
        setError('');
        try {
            onOptionsSelected(sortByType, targetPlaylist, clients, minimumTracks, minimumPercentage);
        } catch (e) {
            setError(e);
            setSubmitting(false);
        }
    }

    let playlistOptions = playlists.map(playlist => ({
        value: playlist.id,
        label: playlist.name
    }));
    let playlistDefaultValue = playlistOptions.filter(playlist => playlist.value === playlistId);;
    return (
        <ContextMenu>
            <ThemeProvider theme={theme}>
                <CreationForms
                    message={``}
                    handleBackButton={onBack}
                    handleSubmit={submit}
                    submitting={submitting}
                    alert={error}
                    image={image}
                    title={'Sort Suggested'}
                >
                    <DetailsContainer>
                        <FormLabel>Sort by</FormLabel>
                        <RSelect
                            options={sortByTypes}
                            // isMulti
                            name="zone-type-list"
                            defaultValue={sortByTypes[0]}
                            onChange={item => {
                                setSortByType(item.value);
                            }
                            }
                        />
                    </DetailsContainer>
                    <DetailsContainer>
                        <FormLabel>Target Playlist</FormLabel>
                        <RSelect
                            options={playlistOptions}
                            name="playlist-list"
                            defaultValue={playlistDefaultValue}
                            onChange={item => {
                                setTargetPlaylist(item.value);
                            }
                            }

                        />
                    </DetailsContainer>
                    {/*<DetailsContainer>*/}
                    {/*    <Container style={{padding: 0}}>*/}
                    {/*        <Label>Filter results</Label>*/}
                    {/*        <Row>*/}
                    {/*            <Col>*/}
                    {/*                <FormContainerWithoutHelper>*/}
                    {/*                    <FormLabel>Clients</FormLabel>*/}
                    {/*                    <RSelect*/}
                    {/*                        options={clients}*/}
                    {/*                        // isMulti*/}
                    {/*                        name="zone-type-list"*/}
                    {/*                        value={selectedClients}*/}
                    {/*                        onChange={() => {*/}

                    {/*                        }}*/}
                    {/*                    />*/}
                    {/*                </FormContainerWithoutHelper>*/}
                    {/*            </Col>*/}
                    {/*            <Col>*/}
                    {/*                <FormContainerWithoutHelper>*/}
                    {/*                    <FormLabel>Minimum no. of tracks</FormLabel>*/}
                    {/*                    <Form*/}
                    {/*                        type="text"*/}
                    {/*                        name="minimumTracks"*/}
                    {/*                        type="number"*/}
                    {/*                        min={0}*/}
                    {/*                        max={10000}*/}
                    {/*                        value={minimumTracks}*/}
                    {/*                        onChange={e => {*/}
                    {/*                            let value = e.target.value;*/}
                    {/*                            if (value < 0) value = 0;*/}
                    {/*                            if (value > 10000) value = 10000;*/}
                    {/*                            setMinimumTracks(value);*/}
                    {/*                        }}*/}
                    {/*                    />*/}
                    {/*                </FormContainerWithoutHelper>*/}
                    {/*            </Col>*/}
                    {/*            <Col>*/}
                    {/*                <FormContainerWithoutHelper>*/}
                    {/*                    <FormLabel>Minimum %.</FormLabel>*/}
                    {/*                    <Form*/}
                    {/*                        type="text"*/}
                    {/*                        name="minimumPercentage"*/}
                    {/*                        type="number"*/}
                    {/*                        value={minimumPercentage}*/}
                    {/*                        min={0}*/}
                    {/*                        max={100}*/}
                    {/*                        onChange={e => {*/}
                    {/*                            let value = e.target.value;*/}
                    {/*                            if (value < 0) value = 0;*/}
                    {/*                            if (value > 100) value = 100;*/}
                    {/*                            setMinimumPercentage(value);*/}
                    {/*                        }}*/}
                    {/*                    />*/}
                    {/*                </FormContainerWithoutHelper>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Container>*/}
                    {/*</DetailsContainer>*/}
                </CreationForms>
            </ThemeProvider>
        </ContextMenu>
    );
}

const mapStateToProps = state => ({
    clients: state.client.client,
});

const connectedSortSuggestedDialog =
    withRouter(connect(mapStateToProps)(SortSuggestedOptionsDialog));
export {connectedSortSuggestedDialog as SortSuggestedOptionsDialog};
