import {combineReducers} from 'redux'

import {alert} from './alert.reducer'
import {authentication} from './authentication.reducer'
import {users} from './users.reducer'
import {songs} from './songs.reducer'
import {schedule} from './schedule.reducer'
import {playlists} from './playlist.reducer'
import {samples} from './sample.reducer'
import {album} from './album.reducer'
import {artist} from './artist.reducer'
import {player} from './player.reducer'
import {client} from './client.reducer'
import {feedback} from './feedback.reducer'
import {campaign} from './campaign.reducer'
import {location} from './location.reducer'
import {zones} from './zones.reducer'
import {ui} from './ui.reducer'

const rootReducer = combineReducers({
  ui,
  alert,
  authentication,
  users,
  songs,
  schedule,
  playlists,
  samples,
  album,
  artist,
  player,
  feedback,
  client,
  campaign,
  location,
  zones
});

export default rootReducer;
