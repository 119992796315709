import React from "react";
import styled from "styled-components";
import {DefaultRowContainer} from './Container.style';
import {DefaultLabel} from './Label.style';

const TabContainer = styled(DefaultRowContainer)`
  flex: 1 1 auto;
`;

const Label = styled(DefaultLabel)`
  color: white;
  border-radius: 0;
  cursor: pointer;
  border-bottom: 2px solid
    ${props => (props.selected ? props.theme.highlight : "unset")};
  padding-bottom: 5px;

  &:hover,
  &:active,
  &:focus {
    border-radius: 0;
    border-bottom: 2px solid ${props => props.theme.highlight};
  }

  & + & {
    margin-left: 20px;
  }
`;

export class TabControl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: this.props.selectedIndex || 0
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedIndex && prevProps.selectedIndex !== this.props.selectedIndex) {
      this.setState({selectedIndex: this.props.selectedIndex});
    }
  }

  select = (index) => {
    this.setState({
      selectedIndex: index
    });

    this.props.controls[index].onClick();
  }

  render() {
    return (
      <TabContainer>
        {this.props.controls.map((control, index) => (
          <Label
            key={index}
            selected={this.state.selectedIndex === index}
            onClick={() => this.select(index)}
          >{control.title}</Label>
        ))}
      </TabContainer>
    )
  }
}