import {feedbackConstants} from "_constants";
import {feedbackService} from "_services";

export const feedbackActions = {
  getAllVotes,
  updateSongVote,
  archive
};

async function getAllVotes(dispatch) {
  dispatch({ type: feedbackConstants.GET_REQUEST });
  try {
    const votes = await feedbackService.getAllVotes();
    dispatch({ type: feedbackConstants.GET_SUCCESS, votes })
  } catch (e) {
    dispatch({ type: feedbackConstants.GET_FAILURE, error: e.toString() })
    throw e;
  }
}

async function archive(dispatch, id) {
  dispatch({ type: feedbackConstants.ARCHIVE_REQUEST });
  try {
    const clientResponse = await feedbackService.archive(id);
    dispatch({type: feedbackConstants.ARCHIVE_SUCCESS, id});
    return clientResponse;
  } catch (e) {
    dispatch({type: feedbackConstants.ARCHIVE_FAILURE, error: e.toString()});
    throw e;
  }
}

async function updateSongVote(dispatch, songVote, id) {
  dispatch({ type: feedbackConstants.UPDATE_SONGVOTE_REQUEST });
  try {
    const songVoteResponse = await feedbackService.updateSongVote(songVote, id);
    dispatch({
      type: feedbackConstants.UPDATE_SONGVOTE_SUCCESS,
      songVote: songVoteResponse
    });
    return songVoteResponse;
  } catch (e) {
    dispatch({
      type: feedbackConstants.UPDATE_SONGVOTE_FAILURE,
      error: e.toString()
    });
    throw e;
  }
}