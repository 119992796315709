export const songsConstants = {
  GET_REQUEST: 'SONGS_GET_REQUEST',
  GET_SUCCESS: 'SONGS_GET_SUCCESS',
  GET_FAILURE: 'SONGS_GET_FAILURE',

  GET_ONE_REQUEST: 'SONGS_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'SONGS_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'SONGS_GET_ONE_FAILURE',

  SEARCH_REQUEST: 'SONGS_SEARCH_REQUEST',
  SEARCH_SUCCESS: 'SONGS_SEARCH_SUCCESS',
  SEARCH_FAILURE: 'SONGS_SEARCH_FAILURE',

  ARCHIVE_REQUEST: 'SONGS_ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'SONGS_ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'SONGS_ARCHIVE_FAILURE',

  GET_SONG_PLAYLISTS_REQUEST: 'SONGS_GET_SONG_PLAYLISTS_REQUEST',
  GET_SONG_PLAYLISTS_SUCCESS: 'SONGS_GET_SONG_PLAYLISTS_SUCCESS',
  GET_SONG_PLAYLISTS_FAILURE: 'SONGS_GET_SONG_PLAYLISTS_FAILURE',

  GET_GENRES_REQUEST: 'SONGS_GET_GENRES_REQUEST',
  GET_GENRES_SUCCESS: 'SONGS_GET_GENRES_SUCCESS',
  GET_GENRES_FAILURE: 'SONGS_GET_GENRES_FAILURE',

  CHANGE_SONG_STATUS: 'SONGS_CHANGE_SONG_STATUS'

};
