export const sampleConstants = {
  GET_ZONES_REQUEST: 'SAMPLE_GET_ZONES_REQUEST',
  GET_ZONES_SUCCESS: 'SAMPLE_GET_ZONES_SUCCESS',
  GET_ZONES_FAILURE: 'SAMPLE_GET_ZONES_FAILURE',

  GET_DETAIL_REQUEST: 'SAMPLE_GET_DETAIL_REQUEST',
  GET_DETAIL_SUCCESS: 'SAMPLE_GET_DETAIL_SUCCESS',
  GET_DETAIL_FAILURE: 'SAMPLE_GET_DETAIL_FAILURE',

  GET_PLAYLIST_REQUEST: 'SAMPLE_GET_PLAYLIST_REQUEST',
  GET_PLAYLIST_SUCCESS: 'SAMPLE_GET_PLAYLIST_SUCCESS',
  GET_PLAYLIST_FAILURE: 'SAMPLE_GET_PLAYLIST_FAILURE',

  PLAY_PLAYLIST_REQUEST: 'SAMPLE_PLAY_PLAYLIST_REQUEST',
  PLAY_PLAYLIST_SUCCESS: 'SAMPLE_PLAY_PLAYLIST_SUCCESS',
  PLAY_PLAYLIST_FAILURE: 'SAMPLE_PLAY_PLAYLIST_FAILURE',

  PLAY_NEXT_SONG_IN_PLAYLIST: 'PLAY_NEXT_SONG_IN_PLAYLIST',
  PLAY_PREV_SONG_IN_PLAYLIST: 'PLAY_PREV_SONG_IN_PLAYLIST',

  SONG_VOTE_SUCCESS: 'SONG_VOTE_SUCCESS',
  SONG_VOTE_FAILURE: 'SONG_VOTE_FAILURE',

  SHOW_SONG_DETAILS: 'SHOW_SONG_DETAILS',
  HIDE_SONG_DETAILS: 'HIDE_SONG_DETAILS',

  PAUSE_SONG_PLAYING: 'PAUSE_SONG_PLAYING',
  RESUME_SONG_PLAYING: 'RESUME_SONG_PLAYING',

  UPDATE_PLAYBACK_TIME: 'UPDATE_PLAYBACK_TIME',
  SKIP_PLAYBACK_TO: 'SKIP_PLAYBACK_TO',

  POST_PLAYLIST_FEEDBACK_SUCCESS:'POST_PLAYLIST_FEEDBACK_SUCCESS',
  POST_PLAYLIST_FEEDBACK_FAILURE:'POST_PLAYLIST_FEEDBACK_FAILURE',

  POST_SAMPLE_FEEDBACK_SUCCESS:'POST_SAMPLE_FEEDBACK_SUCCESS',
  POST_SAMPLE_FEEDBACK_FAILURE:'POST_SAMPLE_FEEDBACK_FAILURE'

};
