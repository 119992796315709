import styled from "styled-components";

export const LiveText = styled.span`
  color: white;
  cursor: pointer;
  margin: 0px 4px;

  &:hover {
    text-decoration: underline;
  }
`;