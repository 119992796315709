import {zonesConstants} from "_constants";
import {zonesService} from "_services";
import {alertActions} from "./alert.actions";

export const zonesActions = {
  get,
  getPagination,
  getOne,
  addOne,
  updateOne,
  updateZone,
  changeVolume,
  addPlayer,
  addSchedule,
  deleteZone,
  deletePlayer,
  deleteSchedule,
  getPlayHistory,
  removeForcedPlaylist
};

function get() {
  return dispatch => {
    dispatch(request());

    zonesService.get().then(
      zones => {
        dispatch(success(zones));
        dispatch(alertActions.success());
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: zonesConstants.GET_REQUEST };
  }
  function success(zones) {
    return { type: zonesConstants.GET_SUCCESS, zones };
  }
  function failure(error) {
    return { type: zonesConstants.GET_FAILURE, error };
  }
}

function getPagination(initial, end) {
  return dispatch => {
    dispatch(request());

    zonesService.getPagination(initial, end).then(
      zones => {
        dispatch(success(zones));
        dispatch(alertActions.success());
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: zonesConstants.GET_PAGINATION_REQUEST };
  }
  function success(zones) {
    return { type: zonesConstants.GET_PAGINATION_SUCCESS, zones };
  }
  function failure(error) {
    return { type: zonesConstants.GET_PAGINATION_FAILURE, error };
  }
}

function getOne(id) {
  return dispatch => {
    dispatch(request());

    zonesService.getOne(id).then(
      zones => {
        dispatch(success(zones));
        dispatch(alertActions.success());
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: zonesConstants.GET_ONE_REQUEST };
  }
  function success(zones) {
    return { type: zonesConstants.GET_ONE_SUCCESS, zones };
  }
  function failure(error) {
    return { type: zonesConstants.GET_ONE_FAILURE, error };
  }
}

async function updateZone(dispatch, id, name,zoneType) {
  dispatch({ type: zonesConstants.UPDATE_ZONE_REQUEST });
  try {
    const zoneResponse = await zonesService.updateZone(id, name, zoneType);
    dispatch({ type: zonesConstants.UPDATE_ZONE_SUCCESS, id, name, zoneType});
    return zoneResponse;
  } catch (e) {
    dispatch({type: zonesConstants.UPDATE_ZONE_FAILURE, error: e.toString()});
    throw e;
  }
}

async function removeForcedPlaylist(dispatch, id) {
  dispatch({ type: zonesConstants.REMOVE_FORCED_PLAYLIST_REQUEST });
  try {
    const zoneResponse = await zonesService.removeForcedPlaylist(id);
    dispatch({ type: zonesConstants.REMOVE_FORCED_PLAYLIST_SUCCESS, id });
    return zoneResponse;
  } catch (e) {
    dispatch({type: zonesConstants.REMOVE_FORCED_PLAYLIST_FAILURE, error: e.toString()});
    throw e;
  }
}

async function changeVolume(dispatch, id, volume, volumeChangeAllowed) {
  dispatch({ type: zonesConstants.CHANGE_VOLUME_REQUEST });
  try {
    const zoneResponse = await zonesService.changeVolume(id,volume,volumeChangeAllowed);
    dispatch({ type: zonesConstants.CHANGE_VOLUME_SUCCESS, id, volume, volumeChangeAllowed });
    return zoneResponse;
  } catch (e) {
    dispatch({type: zonesConstants.CHANGE_VOLUME_FAILURE, error: e.toString()});
    throw e;
  }
}

async function addOne(dispatch, zones) {
  dispatch({ type: zonesConstants.ADD_ONE_REQUEST });
  try {
    const zoneResponse = await zonesService.addOne(zones);
    dispatch({ type: zonesConstants.ADD_ONE_SUCCESS, zone: zoneResponse });
    return zoneResponse;
  } catch (e) {
    dispatch({type: zonesConstants.ADD_ONE_FAILURE, error: e.toString()});
    throw e;
  }
}

async function updateOne(dispatch, zones, id) {
  dispatch(request());
  try {
    const zoneResponse = await zonesService.updateOne(zones, id);
    dispatch(success(zoneResponse));
    return zoneResponse;
  } catch (e) {
    dispatch(failure(e.toString()));
    throw e;
  }

  function request() {
    return { type: zonesConstants.UPDATE_ONE_REQUEST };
  }
  function success(zones) {
    return { type: zonesConstants.UPDATE_ONE_SUCCESS, zones };
  }
  function failure(error) {
    return { type: zonesConstants.UPDATE_ONE_FAILURE, error };
  }
}

async function addPlayer(dispatch, zoneId, playerId) {
  dispatch({ type: zonesConstants.ADD_PLAYER_REQUEST });

  try {
    const zones = await zonesService.addPlayer(zoneId, playerId);
    dispatch({ type: zonesConstants.ADD_PLAYER_SUCCESS, zones });
    return zones;
  } catch (e) {
    dispatch({ type: zonesConstants.ADD_PLAYER_FAILURE, error: e.toString() })
    throw e;
  }
}

async function addSchedule(dispatch, zoneId, scheduleId) {
  dispatch({ type: zonesConstants.ADD_SCHEDULE_REQUEST });

  try {
    const zones = await zonesService.addSchedule(zoneId, scheduleId);
    dispatch({ type: zonesConstants.ADD_SCHEDULE_SUCCESS, zones });
    return zones;
  } catch (e) {
    dispatch({ type: zonesConstants.ADD_SCHEDULE_FAILURE, error: e.toString() })
    throw e;
  }
}

async function deleteZone(dispatch, zoneId) {
  dispatch({ type: zonesConstants.DELETE_ZONE_REQUEST });

  try {
    const zones = await zonesService.deleteZone(zoneId);
    dispatch({ type: zonesConstants.DELETE_ZONE_SUCCESS, zones });
    return zones;
  } catch (e) {
    dispatch({ type: zonesConstants.DELETE_ZONE_FAILURE, error: e.toString() })
    throw e;
  }
}

async function deletePlayer(dispatch, zoneId) {
  dispatch({ type: zonesConstants.DELETE_PLAYER_REQUEST });

  try {
    const zones = await zonesService.deletePlayer(zoneId);
    dispatch({ type: zonesConstants.DELETE_PLAYER_SUCCESS, zones });
    return zones;
  } catch (e) {
    dispatch({ type: zonesConstants.DELETE_PLAYER_FAILURE, error: e.toString() })
    throw e;
  }
}

async function deleteSchedule(dispatch, zoneId) {
  dispatch({ type: zonesConstants.DELETE_SCHEDULE_REQUEST });

  try {
    const zones = await zonesService.deleteSchedule(zoneId);
    dispatch({ type: zonesConstants.DELETE_SCHEDULE_SUCCESS, zones });
    return zones;
  } catch (e) {
    dispatch({ type: zonesConstants.DELETE_SCHEDULE_FAILURE, error: e.toString() })
    throw e;
  }
}

function getPlayHistory(zoneId, startMoment, endMoment) {
  return dispatch => {
    dispatch(request());

    zonesService.getPlayHistory(zoneId, startMoment, endMoment).then(
      zones => {
        dispatch(success(zones));
        dispatch(alertActions.success());
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: zonesConstants.PLAY_HISTORY_REQUEST };
  }
  function success(zones) {
    return { type: zonesConstants.PLAY_HISTORY_SUCCESS, zones };
  }
  function failure(error) {
    return { type: zonesConstants.PLAY_HISTORY_FAILURE, error };
  }
}
