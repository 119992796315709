import React, {useEffect, useRef, useState} from "react";

export const WidthContext = React.createContext(1)
export const WidthProvider = WidthContext.Provider
export const WidthConsumer = WidthContext.Consumer

export const WidthWatcher = ({children, ...props}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(1);

  const watchResize = () => {
    if (!ref.current) return;

    const thisWidth = ref.current.getBoundingClientRect().width;
    if (thisWidth === width) return;

    setWidth(thisWidth);
  }

  useEffect(() => {
    const timerId = setInterval(watchResize, 100);
    return () => clearInterval(timerId);
  }, [true]);
  
  return (
    <WidthProvider value={width}>
      {/* Create div to simplify getting ref to child */}
      <div ref={ref} {...props}>
        {children}
      </div>
    </WidthProvider>
  )
}