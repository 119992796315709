import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import nowPlayingImage from "_assets/images/ic-equalizer-animated.gif";
import albumImagePlaceholder from "_assets/images/talea-album-placeholder.png";
import {isLoadingList} from "../../_reducers/playlist.reducer";
import {DefaultButton} from "../../_styles";
import PlayOverlayImage from "../../_assets/images/PlayOverlay.png";
import {Row as TaleaRow, RowSpaced,} from '../Standard';
import {Button, Col, Container, Image, Modal, Row} from "react-bootstrap";
import {sampleActions} from "../../_actions/sample.actions";
import TALEA_LOGO from "../../_assets/images/talea_logo_white.png";
import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import {Comment, CommentRounded, CommentSharp, Home, ThumbDown, ThumbUp} from "@material-ui/icons";
import media from "../../_helpers/media";
import {SamplesModal} from "./SamplesStyledWidgets";
import {isMobileDevice} from "react-select/lib/utils";
import {durationToString} from "../Utils";

const MenuDropdown = styled(Dropdown)`
    .dropdown-toggle::after {
        display: none !important;
    }

    //button:focus {
    //  box-shadow: 0 0 0 0 rgb(0 0 0 / 50%) !important;
    //}
    //
    //button:active:focus{
    //  box-shadow: 0 0 0 0 rgb(0 0 0 / 50%) !important; 
    //}
`;

const SubmitFeedbackButton = styled(Button)`
    background-color: #2D2D32;
    border-color: white;
    color: white;
    border-radius: 50px;
    padding: 12px;
    width: 250px;
    font-size: 15px;

    :hover, :focus {
        color: white;
        background-color: #ECA521;
        border-color: white;
    }
`
const NextPlaylistButton = styled(Button)`
    ${media.mobile`      
  width: 90px;
  height: 35px 
  font-size: 12px;
  margin-left:15px;
  margin-top:3px;
`};
    ${media.tablet`
  width: 250px;
  font-size: 15px;
`}
    background-color: #ECA521;
    border-color: transparent;
    color: white;
    border-radius: 50px;


    :hover, :focus {
        color: white;
        background-color: #ECA521;
        border-color: white;
    }
`


const SongRow = styled(RowSpaced)`
    :hover {
        cursor: pointer;
    }

    height: 70px;
`;

const RememberSubmitLink = styled.a`
    :hover {
        cursor: pointer;
    }

`

const PlaylistItem = styled(Col)`
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
`;

const PlaylistImageContainer = styled.div`
    :hover {
        cursor: pointer;
    }

    ${media.mobile`    
  height: 90%;
  width: 90%; 
`};
    ${media.tablet`
height: 40%;
  width: 40%;
`}

    position: relative;
    max-width: 250px;
    max-height: 250px;
    color: white;
`;

const PlayOverlay = styled(Image)`
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`
const PlaylistImage = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 50%;
    color: white;
`;

const PlaylistName = styled.div`
    font-size: 32px;
    margin-top: 40px;
    color: white;
    text-align: center;
`;

const PlaylistDescription = styled.div`
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #AEAEAE;
    text-align: center;
`;

const Brand = styled.img`
    width: 150px;
    margin: auto;
`;

const SongTitle = styled(TaleaRow)`
    font-size: 16px;
    color: white;
    margin-bottom: 5px;
    width: 100%;
    flex-grow: 0
`;
const SongArtist = styled(TaleaRow)`
    font-size: 16px;
    color: #AEAEAE;
    width: 100%
`;
const SongStatus = styled(Col)`
    flex-basis: 0;
    flex-grow: 0;
    max-width: 0px;
    text-align: end;
    color: #AEAEAE;
    font-size: 14px;
`;

const SongImage = styled.img`
    height: 50px;
    width: 50px;
`;
const SongDuration = styled(Col)`
    font-size: 14px;
    color: white;
`;

class SamplePlaylist extends React.Component {
  state = {
    id: "",
    currentlyPlaying: "",
    name: "",
    showVoteModal: false,
    showPlaylistFeedbackModal: false,
    upvotedSongs: [],
    downvotedSongs: [],
    feedback: "",
    playlistFeedback: "",
    showNoVotesModal: false,
  };

  submitPlaylistFeedbackClicked = () => {
    sampleActions.postPlaylistFeedback(this.props.dispatch, this.props.sample.token, this.props.playlistId, this.state.playlistFeedback);
    this.setState({
      playlistFeedback: "",
      showPlaylistFeedbackModal: false
    })
  }

  submitVoteClicked = () => {
    sampleActions.voteSong(this.props.dispatch, this.props.sample.token, this.props.playlistId, this.state.songToVote.id, this.state.downvote, this.state.feedback);
    this.setState({
      feedback: "",
      showVoteModal: false
    })
  }

  PlaylistFeedbackModal = () => {
    return <SamplesModal centered style={{color: 'white'}} show={true} backdrop={true} size={'small'}
                         onHide={() => {
                           this.setState({
                             playlistFeedback: "",
                             showPlaylistFeedbackModal: false
                           });
                         }}>
      <Modal.Header style={{
        backgroundColor: "#353341",
      }} closeButton>
        <Modal.Title className="w-100 text-center">Overall playlist Feedback</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{backgroundColor: "#353341"}}>
        <TaleaRow style={{marginBottom: 40}}>
          <CommentRounded style={{fontSize: 40, margin: "auto"}}></CommentRounded>
        </TaleaRow>
        <TaleaRow style={{marginBottom: 40}}>
                    <textarea onChange={(event) => this.setState({playlistFeedback: event.target.value})}
                              value={this.state.playlistFeedback}
                              placeholder={"Leave your feedback..."}
                              style={{
                                borderRadius: 15,
                                backgroundColor: "#424052",
                                height: 100,
                                width: "90%",
                                margin: "auto",
                                padding: 20
                              }}></textarea>
        </TaleaRow>
        <TaleaRow>
          <DefaultButton
              style={{width: 150, border: 0, color: "black", backgroundColor: "#DFDFDF", margin: "auto"}}
              onClick={() => {
                this.submitPlaylistFeedbackClicked();
              }}>
            Submit
          </DefaultButton>
        </TaleaRow>
      </Modal.Body>
    </SamplesModal>
  }

  VoteModal = () => {
    return <SamplesModal centered style={{color: 'white'}} show={true} backdrop={true} size={'small'}
                         onHide={() => {
                           this.setState({showVoteModal: false, feedback: ""});
                         }}>
      <Modal.Header style={{
        backgroundColor: "#353341",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }} closeButton>
        <Modal.Title style={{width: "90%", paddingLeft: "29%"}}>Vote and comment</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{backgroundColor: "#353341"}}>
        <TaleaRow style={{marginBottom: 40}}>
          {this.state.downvote ?
              <ThumbDown style={{fontSize: 40, margin: "auto"}}></ThumbDown> :
              <ThumbUp style={{fontSize: 40, margin: "auto"}}></ThumbUp>}
        </TaleaRow>
        <TaleaRow style={{marginBottom: 40}}>
                    <textarea onChange={(event) => this.setState({feedback: event.target.value})}
                              value={this.state.feedback}
                              placeholder={"Leave a comment..."}
                              style={{
                                backgroundColor: "#424052",
                                borderRadius: 15,
                                height: 150,
                                width: "90%",
                                margin: "auto",
                                padding: 20
                              }}></textarea>
        </TaleaRow>
        <TaleaRow>
          <DefaultButton
              style={{
                width: 150,
                height: 45,
                border: 0,
                color: "black",
                backgroundColor: "#DFDFDF",
                margin: "auto"
              }}
              onClick={() => {
                this.submitVoteClicked();
              }}>
            Submit
          </DefaultButton>
        </TaleaRow>
      </Modal.Body>
    </SamplesModal>
  }

  NoVotesModal = () => {
    return <SamplesModal centered style={{color: 'white'}} show={true} backdrop={true} size={'small'}
                         onHide={() => {
                           this.setState({showNoVotesModal: false});
                         }}>
      <Modal.Header style={{
        backgroundColor: "#353341",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }} closeButton>
        <Modal.Title style={{width: "90%", paddingLeft: "29%"}}>Not Voted yet</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{backgroundColor: "#353341"}}>
        <TaleaRow style={{marginBottom: 40}}>
          You haven’t left any votes yet, are you sure you want to go to the next playlist?
        </TaleaRow>
        <TaleaRow>
          <NextPlaylistButton style={{width: 250, margin: "auto"}} onClick={() => {
            this.nextPlaylistClicked(true);
          }}>Next Playlist &nbsp;<FontAwesomeIcon
              icon={"arrow-right"}/></NextPlaylistButton>
        </TaleaRow>
      </Modal.Body>
    </SamplesModal>
  }

  SongItem = function ({song, position}) {
    const sampleVotes = this.props.sample.votes;
    const handleSongClick = this.songClicked.bind(this);
    const songVoteClicked = this.songVoteClicked.bind(this);
    const playlistId = this.props.playlistId;

    const upvoted = sampleVotes.find(sv => sv.song && sv.song.id === song.id &&
        sv.playlist && sv.playlist.id === playlistId && !sv.downvote);
    const downvoted = sampleVotes.find(sv => sv.song && sv.song.id === song.id &&
        sv.playlist && sv.playlist.id === playlistId
        && sv.downvote);

    const SongMenu = (() => {
      return <MenuDropdown>
        <div onClick={(e => e.stopPropagation())}>
          <Dropdown.Toggle style={{backgroundColor: "transparent", border: 0}}>
            {upvoted || downvoted ?
                (upvoted ? <ThumbUp fontSize="small"></ThumbUp> :
                    <ThumbDown fontSize="small"></ThumbDown>)
                :
                <Comment fontSize="small"></Comment>}
          </Dropdown.Toggle>
        </div>
        <Dropdown.Menu style={{backgroundColor: "#353341", color: "white"}}>
          <Dropdown.Item
              style={{
                backgroundColor: "#353341",
                borderBottom: 2,
                borderColor: "white",
                color: "white",
                paddingLeft: "0.25rem",
                paddingRight: "0.25rem",
                paddingTop: 10,
                paddingBottom: 10
              }} onClick={(event) => {
            event.stopPropagation();
            songVoteClicked(song, false)
          }}>
            <Container className={"no-gutters"}><TaleaRow className={"no-gutters"}><Col xs={10}
                                                                                        className="no-gutters align-items-start d-flex justify-content-start">Vote
              up</Col><Col xs={2}><ThumbUp/></Col></TaleaRow></Container>
          </Dropdown.Item>
          <Dropdown.Item
              style={{
                backgroundColor: "#353341",
                color: "white",
                paddingLeft: "0.25rem",
                paddingRight: "0.25rem",
                paddingTop: 10,
                paddingBottom: 10
              }}
              onClick={(event) => {
                event.stopPropagation();
                songVoteClicked(song, true)
              }}>
            <Container className={"no-gutters"}><TaleaRow className={"no-gutters"}><Col xs={10}
                                                                                        className="no-gutters align-items-start d-flex justify-content-start">Vote
              down</Col><Col xs={2}><ThumbDown/></Col></TaleaRow></Container>
          </Dropdown.Item>
        </Dropdown.Menu>
      </MenuDropdown>;
    }).bind(this);

    return <SongRow style={this.props.songPlayingId === song.id ? {background: "#2D2D32"} : {}} key={song.id}
                    onClick={() => handleSongClick(song)}>
      <SongStatus className={"d-none d-md-block"}>
        {this.props.songPlayingId === song.id ?
            <img alt="now playing" style={{color: "red", height: 25, width: 20}} src={nowPlayingImage}></img>
            : (position > 9 ? position : '0' + position)
        }
      </SongStatus>
      <Col xs={"auto"}>
        <SongImage src={song.artworkUrl || albumImagePlaceholder}></SongImage>
      </Col>
      <Col md>
        <SongTitle
            style={this.props.songPlayingId === song.id ? {color: "#ECA521"} : {}}>{song.title}</SongTitle>
        <SongArtist>{song.artist.name}</SongArtist>
      </Col>
      <SongDuration xs={1} className={"d-none d-md-block"}>{durationToString(song.trackLength)}</SongDuration>
      <SongMenu></SongMenu>
    </SongRow>;
  }

  songVoteClicked = (song, downvote) => {
    this.setState({showVoteModal: true, songToVote: song, downvote: downvote, feedback: null,});
  }

  componentDidMount() {
    let token = this.props.sample.token;
    if (!token) {
      const currentUrl = window.location.href;
      token = currentUrl.split("/")[4];
    }
    console.log("token:" + this.props.sample.token + " url:" + window.location.href);
    const action = sampleActions.getPlaylist(token, this.props.playlistId, this.props.loadedPlaylists);
    this.props.dispatch(action);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.playlistId !== this.props.playlistId) {
      const action = sampleActions.getPlaylist(this.props.sample.token, nextProps.playlistId, this.props.loadedPlaylists);
      this.props.dispatch(action);
    }
  }

  componentDidUpdate() {
  }

  songClicked(song) {
    sampleActions.playPlaylist(this.props.dispatch, this.props.sample.token, this.props.playlistId, song, this.props.loadedPlaylists)
  }

  playAllClicked() {
    sampleActions.playPlaylist(this.props.dispatch, this.props.sample.token, this.props.playlistId, null, this.props.loadedPlaylists)
  }

  nextPlaylistClicked(confirmed) {
    const {
      sample,
      playlist,
    } = this.props;
    const voted = sample.votes.filter(v => v.playlist && v.playlist.id === playlist.id).length > 0;
    if (!confirmed && !voted) {
      this.setState({showNoVotesModal: true})
    } else {
      this.setState({showNoVotesModal: false})
      const playlistIndex = (sample.playlists ? sample.playlists.map(p => p.id).indexOf(playlist.id) : -1);
      const nextPlaylist = sample.playlists[(playlistIndex + 1) % this.props.sample.playlists.length];
      this.props.history.replace("/clientsample/" + sample.token + "/playlist/" + nextPlaylist.id);
    }
  }

  render() {
    const {
      sample,
      playlist,
    } = this.props;

    const songs = playlist && playlist.songs || [];
    const SongItem = this.SongItem.bind(this);

    const homeClicked = () => {
      this.props.history.replace("/clientsample/" + sample.token);
    }

    return (
        <>
          <Container style={{marginTop: 0}} fluid>
            <Row>
              <Col xs={5} style={{padding: 0}}>
                <Row style={{color: 'white', paddingTop: 30, paddingLeft: isMobileDevice() ? 20 : 50}}>
                  <Col xs={window.innerWidth > 768 ? "auto" : 1}>
                    <div style={{cursor: "pointer", paddingTop: 3}} onClick={homeClicked}>
                      <Home/>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={3} style={{padding: "30px 0px"}}
                   onClick={() => {
                     this.props.history.replace("/clientsample/" + sample.token)
                   }
                   }>
                <Brand src={TALEA_LOGO}/>
              </Col>
              <Col xs={4} style={{color: "white", paddingTop: 23}} className="float-right">
                <NextPlaylistButton onClick={() => this.nextPlaylistClicked(false)}>
                  {isMobileDevice() ? "Next" : "Next Playlist"} &nbsp;<FontAwesomeIcon
                    icon={"arrow-right"}/>
                </NextPlaylistButton>

              </Col>
            </Row>
          </Container>
          <Container style={{marginBottom: 30}}>
            {playlist && <><Row>
              <PlaylistItem
                  onClick={() => this.props.history.push(`/clientsample/${sample.token}/playlist/${playlist.id}`)}>
                <PlaylistImageContainer>
                  {playlist.artworkUrl && <PlayOverlay onClick={this.playAllClicked.bind(this)}
                                                       src={PlayOverlayImage}></PlayOverlay>}
                  <PlaylistImage onClick={this.playAllClicked.bind(this)}
                                 src={playlist.artworkUrl || albumImagePlaceholder}></PlaylistImage>
                </PlaylistImageContainer>
                <PlaylistName>{playlist.name}</PlaylistName>
                <PlaylistDescription>{playlist.description}</PlaylistDescription>
              </PlaylistItem>
            </Row>
              <Row style={{marginTop: 40}}>
                <Col xs={12} className={"d-flex justify-content-center"}>
                  <Container>
                    <Row className={"d-flex justify-content-center"}>
                      <SubmitFeedbackButton
                          onClick={() => this.setState({showPlaylistFeedbackModal: true})}
                      >
                        <CommentSharp style={{marginRight: 10}}></CommentSharp>
                        Leave playlist feedback
                      </SubmitFeedbackButton>
                    </Row>
                    <Row className={"d-flex justify-content-center"}
                         style={{color: "white", marginTop: 20}}>* Remember to <RememberSubmitLink
                        onClick={homeClicked} style={{color: "#ECA521"}}>&nbsp;submit final
                      feedback&nbsp;</RememberSubmitLink> on the home
                      page
                    </Row>
                  </Container>
                </Col>
              </Row>
            </>
            }
          </Container>
          <TaleaRow>
            <Col xs={12} style={{color: "#AEAEAE"}}>
              <div style={{width: '100%', textAlign: 'right'}}>vote</div>
            </Col>
          </TaleaRow>
          {songs.map(s => <SongItem key={s.id} song={s} position={songs.indexOf(s) + 1}
                                    upvoted={this.state.upvotedSongs.indexOf(s.id) !== -1}
                                    downvoted={this.state.downvotedSongs.indexOf(s.id) !== -1}></SongItem>)}
          {this.state.showVoteModal && <this.VoteModal></this.VoteModal>}
          {this.state.showPlaylistFeedbackModal && <this.PlaylistFeedbackModal></this.PlaylistFeedbackModal>}
          {this.state.showNoVotesModal && <this.NoVotesModal></this.NoVotesModal>}
        </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  playlistId: props.match.params.playlistId ? parseInt(props.match.params.playlistId) : null,
  loading: isLoadingList(state),
  sample: state.samples.sample,
  playlist: state.samples.playlist,
  alert: state.alert,
  songPlayingId: state.samples.songId,
  loadedPlaylists: state.samples.loadedPlaylists,
});

const connectedSamplePlaylist = withRouter(connect(mapStateToProps)(SamplePlaylist));
export {connectedSamplePlaylist as SamplePlaylist};
