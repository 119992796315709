import React from 'react'
import styled from 'styled-components'
import {Modal} from 'react-bootstrap'
import {DefaultButton, DefaultLabel, DefaultRowContainer} from '_styles'

import "_styles/custom-fullcalendar.css";

const Row = styled(DefaultRowContainer)`
  margin: 10px 0;
  justify-content: space-between;
  cursor: pointer;
`;

const Name = styled(DefaultLabel)`
  color: white;

  &:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: ${props => props.color};
    margin-right: 10px;
    text-align: center;
  }
`;

export const ModalPicker = ({
  randomColors,
  playlist,
  addEvent,
  handleClose,
  addPlaylist
}) => {
  const Container = styled(DefaultRowContainer)`
    justify-content: space-between;
    align-items: center;
  `;

  const Button = styled(DefaultButton)`
    width: 150px;
    height: 40px;
  `;

  return (
    <Modal show={true} onHide={handleClose} className="modal-pick" centered>
      <Modal.Header>
        <DefaultLabel style={{ color: "white", fontSize: "18px" }}>
          Pick a playlist
        </DefaultLabel>
      </Modal.Header>
      <Modal.Body>
        {playlist !== undefined && playlist.length > 0 ? (
          playlist.map((pl, index) => (
            <Row
              key={`playlist-schedule-modal-${pl.id}`}
              onClick={() => addEvent(pl, randomColors[index])}
            >
              <Name color={randomColors[index]}>{pl.name}</Name>
            </Row>
          ))
        ) : (
          <Container>
            <DefaultLabel style={{ color: "white" }}>
              Please add a playlist first
            </DefaultLabel>
            <Button onClick={addPlaylist}>Add Playlist</Button>
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
};