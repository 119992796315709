export const getReactSelectList = (variable, name, options) => {
  if (variable.length === 0) {
    return [];
  }

  let list = [];

  if (options !== undefined && options.length > 0) {
    options.forEach(opt => {
      if (opt !== undefined) {
        list.push({ variable: name, value: opt.value, label: opt.label });
      }
    });
  }

  variable.forEach(cl => {
    if (cl.name !== undefined && cl.id !== undefined) {
      list.push({ variable: name, value: cl.id, label: cl.name });
    }
  });
  return list.sort((c1,c2) => c1.label.localeCompare(c2.label));
};

export const getReactUsersSelectList = (variable, name) => {
  if (variable.length === 0) {
    return [];
  }

  let list = [];
  variable.forEach(cl => {
    list.push({
      variable: name,
      value: cl.id,
      label: cl.firstName + " " + cl.lastName
    });
  });

  return list;
};
