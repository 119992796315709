import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import styled from "styled-components";
import {playlistActions} from "_actions";
import {
    getPlaylistStats,
    getPlaylist,
    getActivePlaylistId,
    getPlaylistSongsView,
    haveActivePlaylist,
    isLoadingList,
    isPlaylistReady
} from '_reducers/playlist.reducer';
import {DefaultColumnContainer, DefaultRowContainer, LiveText, Loading, ReviewButton, SmallRow} from "_styles";
import {ModalVerifyPlaylistChanges} from './ModalVerifyPlaylistChanges';
import {DragTarget, IconCheck, SortTable} from './Standard';

const Gutter = 46;

const PlaylistMenu = styled(DefaultColumnContainer)`
    flex: 1;
    width: 420px;
    padding-top: 56px;
`;

const DragTargetTable = styled(DragTarget)`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const Title = styled.div`
    color: white;
    font-size: 18px;
`;

const Creator = styled.div`
    color: #9B9B9B;
    font-size: 14px;
`;

const TopView = styled(DefaultRowContainer)`
    justify-content: space-between;
    padding: 20px 12px 20px ${Gutter}px;
    border-bottom: 1px solid #333;
`;

const TopViewLeft = styled(DefaultColumnContainer)`
    align-items: start;
`;

const TopViewRight = styled(DefaultColumnContainer)`
    flex: 1 0 auto;
    align-items: center;
`;

const ChangeStats = styled.div`
    font-size: 12px;
    color: #8a8377;
    margin-top: 2px;
`;

const HeaderHeight = 33;
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    height: ${HeaderHeight}px;
    border-top-left-radius: 16px;
    background-color: #F1A823;
    padding-left: ${Gutter}px;
    color: white;
    font-size: 14px;
`;

const Close = styled(FontAwesomeIcon)`
    cursor: pointer;
    margin-right: 8px;
    width: 12px;
    height: 12px;
`;

const IconContainer = styled.div`
    display: flex;
    flex: 0 0 ${Gutter}px;
    justify-content: center;
    align-items: center;
`;

class ActivePlaylist extends React.Component {
    state = {
        showChangeDialog: false,
    }

    clearActivePlaylist = () => {
        this.props.dispatch(playlistActions.clearActivePlaylistId());
    }

    handleDeleteSong = song => {
        playlistActions.deleteSongs(this.props.dispatch, this.props.id, [song.id]);
    };

    handleReview = () => {
        this.setState({
            showChangeDialog: true,
        });
    }

    closeChangeDialog = () => {
        this.setState({
            showChangeDialog: false
        });
    }

    handleSongDrop = songs => playlistActions.addSongs(this.props.dispatch, this.props.id, songs.map(s => s.id));
    deleteSongs = songs => playlistActions.deleteSongs(this.props.dispatch, this.props.id, songs.map(s => s.id));

    render() {
        const {haveActivePlaylist, songs, playlistReady, activePlaylist, changeStats} = this.props;

        if (!haveActivePlaylist) return null;

        return (
            <PlaylistMenu>
                {this.state.showChangeDialog && (
                    <ModalVerifyPlaylistChanges publish={this.state.publish} id={this.props.id}
                                                onClose={this.closeChangeDialog}/>
                )}

                <Header>
                    PINNED PLAYLIST
                    <Close icon='times' size='sm' onClick={this.clearActivePlaylist} fixedWidth/>
                </Header>

                {!playlistReady && (<Loading/>)}

                {playlistReady && (<>
                    <TopView>
                        <TopViewLeft>
                            <Link to={`/playlist/${this.props.id}`}>
                                <Title>{activePlaylist.name}</Title>
                            </Link>
                            <Creator>Created
                                by <LiveText>{activePlaylist.creator.firstName} {activePlaylist.creator.lastName}</LiveText></Creator>
                            <Creator>{activePlaylist.songs.length} songs, {changeStats.durationString}</Creator>
                        </TopViewLeft>
                        <TopViewRight>
                            <ReviewButton enabled={changeStats.changes} onClick={this.handleReview}/>
                            <ChangeStats>{changeStats.added} added {changeStats.removed} removed</ChangeStats>
                        </TopViewRight>
                    </TopView>
                    <DragTargetTable
                        dragClassName='drag-hover'
                        mimeType={'application/songs'}
                        onDrop={this.handleSongDrop}
                    >
                        <SortTable
                            tableName={'active_playlist'}
                            rows={songs}
                            onDelete={this.deleteSongs}
                            columns={[
                                {
                                    fixed: Gutter, rowRenderer: row => (
                                        <IconContainer>
                                            <IconCheck color='orange' onClick={e => {
                                                e.stopPropagation();
                                                this.handleDeleteSong(row)
                                            }}/>
                                        </IconContainer>
                                    )
                                }, {
                                    percent: 1, title: 'title', field: 'title', rowRenderer: row => (
                                        <SmallRow name={row.name} title={row.title} album={row.album}
                                                  artist={row.artist}/>
                                    )
                                }]}
                        />
                    </DragTargetTable>
                </>)}
            </PlaylistMenu>
        )
    }
}

const mapStateToProps = state => {
    const activePlaylistId = getActivePlaylistId(state);
    const stats = getPlaylistStats(state, activePlaylistId);

    return {
        id: activePlaylistId,
        haveActivePlaylist: haveActivePlaylist(state),
        loading: isLoadingList(state),
        playlistReady: isPlaylistReady(state, activePlaylistId),
        activePlaylist: getPlaylist(state, activePlaylistId),
        songs: getPlaylistSongsView(state, activePlaylistId),
        changeStats: stats,
    }
};

const activePlaylist = withRouter(connect(mapStateToProps)(ActivePlaylist));
export {activePlaylist as ActivePlaylist};

