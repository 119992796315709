import React, {useRef} from "react";

export const DragTarget = ({onDrop, dropData, dragClassName, mimeType, children, ...props}) => {
  const targetRef = useRef();

  const isMatchingEvent = e => (e.dataTransfer.types || []).includes(mimeType);

  const handleDrop = e => {
    e.stopPropagation();
    if (!isMatchingEvent(e)) return;

    const songs = JSON.parse(e.dataTransfer.getData(mimeType));
    handleDragLeave(e);

    if (onDrop) onDrop(songs, dropData);
  }

  const handleDragEnter = e => {
    e.preventDefault();
    if (!isMatchingEvent(e)) return;

    if (dragClassName) {
      targetRef.current.classList.add(dragClassName);
    }
  }

  const handleDragLeave = e => {
    if (targetRef.current.contains(e.relatedTarget)) return;

    if (dragClassName) {
      targetRef.current.classList.remove(dragClassName);
    }
  }

  const handleDragOver = e => {
    e.preventDefault();
  }

  return (
    <div
      ref={targetRef}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      {...props}
    >
      {children}
    </div>
  )
}