import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled, {ThemeProvider} from "styled-components";
import {alertActions, playerActions, zonesActions} from "_actions";
import {CreateZone} from "_components";
import {Row} from '_components/Standard';
import {
    ContextRow,
    DefaultButton,
    DefaultCancelButton,
    DefaultColumnContainer,
    Loading,
    RulesContainer,
    SettingsContainer,
    TabControl,
    theme,
    ZoneSummaryView
} from "_styles";
import {ModalDecisionBuilder} from './Utils';
import get from 'lodash.get';

const Container = styled(DefaultColumnContainer)`
  padding: 15px;
`;

const Title = styled.div`
  font-size: 36px;
	color: white;
	margin-right: 12px;
`;
const TitleZoneType = styled.div`
  padding-top:6px;
  font-size: 16px;
	color: white;
	margin-right: 12px;
`;

const ButtonsContainer = styled(Row)`
  margin-top: 20px;
  justify-content: flex-end;
  width: 100%;
`;

const CancelButton = styled(DefaultCancelButton)`
  width: 35%;
  margin-right: 5px;
`;

const OkButton = styled(DefaultButton)`
  width: 35%;
  margin-left: 5px;
`;

const Content = styled(DefaultColumnContainer)`
  margin-top: 25px;
`;

const CHANGE_NONE = 0;
const CHANGE_DELETE = 1;
const CHANGE_CHANGE = 2;

class ZoneDetails extends React.Component {
    state = {
        showEditName: false,
        showConfirmText: undefined,
        option: 1,
        loadingZone: true,
        rule: [
            // {
            //   id: 1,
            //   rule: "Block Explicit Songs",
            //   value: null,
            //   type: "dropdown",
            //   active: true
            // },
            // {
            //   id: 2,
            //   rule: "Block Christmas Music",
            //   start: "",
            //   end: "",
            //   type: "datepicker",
            //   active: true
            // },
            // {
            //   id: 3,
            //   rule: "BPM Filter",
            //   min: 80,
            //   max: 120,
            //   type: "input",
            //   active: true
            // },
            {
                id: 4,
                rule: "Separate Titles",
                value: "60 minutes",
                type: "blocked",
                active: true
            },
            {
                id: 5,
                rule: "Separate Artists",
                value: "30 minutes",
                type: "blocked",
                active: true
            },
            {
                id: 6,
                rule: "Prevent Song Repeat",
                value: "24 Hours",
                type: "blocked",
                active: true
            },
            {
                id: 7,
                rule: "Prevent Song Repeat",
                value: "In Same Hour as Yesterday",
                type: "blocked",
                active: true
            }
        ],

        // Settings remarked for future release
        setting: [
            {
                id: 4,
                setting: "Firmware Update Window",
                type: "datepicker",
                start: "",
                end: ""
            },
            {
                id: 5,
                setting: "Music Update Window",
                type: "datepicker",
                start: "",
                end: ""
            },
            {
                id: 8,
                setting: "Volume",
                type: "input",
                value: 100
            },
            {
                id: 10,
                setting: "Enable volume control",
                type: "boolean",
                value: false
            },
            {
                id: 9,
                setting: "Time Zone",
                type: "dropdown",
                value: ""
            }
        ],
        detailsChange: [],
        interval: "",

        playerUpdate: -1,
        scheduleUpdate: -1
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.zone !== this.props.zone){
            const setting = this.state.setting.slice();
            if (this.props.zone.volume){
                setting[2].value=this.props.zone.volume;
                setting[3].value=this.props.zone.volumeChangeAllowed;
            }else{
                setting[2].value=100;
                setting[3].value=false;
            }
            this.setState({setting:setting});
        }
    }

    componentDidMount() {
        const {id, dispatch} = this.props;

        dispatch(playerActions.get());
        dispatch(zonesActions.getOne(id));

        let intervalSong = setInterval(() => {
            dispatch(zonesActions.getOne(id));
        }, 30000);

        this.setState({
            interval: intervalSong, setting: [{
                id: 4,
                setting: "Firmware Update Window",
                type: "datepicker",
                start: "",
                end: ""
            },
                {
                    id: 5,
                    setting: "Music Update Window",
                    type: "datepicker",
                    start: "",
                    end: ""
                },
                {
                    id: 8,
                    setting: "Volume",
                    type: "input",
                    value: this.props.zone.volume ? this.props.zone.volume : 100
                },
                {
                    id: 10,
                    setting: "Enable volume control",
                    type: "boolean",
                    value: this.props.zone.volumeChangeAllowed
                },
                {
                    id: 9,
                    setting: "Time Zone",
                    type: "dropdown",
                    value: ""
                }]
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    setActive = id => {
        let currentRules = this.state.rule;
        let detailsChange = this.state.detailsChange;

        let tempRule = currentRules.findIndex(rule => rule.id === id);
        currentRules[tempRule].active = !currentRules[tempRule].active;

        detailsChange.push({
            method: "active",
            rule: currentRules[tempRule].rule,
            id: id
        });

        this.setState({rule: currentRules, detailsChange});
    };

    setOption = value => {
        if (value !== this.state.option) {
            this.setState({option: value});
        }
    };

    handleBoolean = (id, variable, value, e) => {
        let current = this.state[variable];
        let detailsChange = this.state.detailsChange;

        let index = current.findIndex(ct => ct.id === id);
        current[index][value] = e;

        detailsChange.push({method: [variable], [variable]: value, [value]: e});

        this.setState({[variable]: current, detailsChange});
    };

    handleDate = (id, variable, value, e) => {
        let current = this.state[variable];
        let detailsChange = this.state.detailsChange;

        let index = current.findIndex(ct => ct.id === id);
        current[index][value] = e;

        detailsChange.push({method: [variable], [variable]: value, [value]: e});

        this.setState({[variable]: current});
    };

    handleInput = (id, variable, value, e) => {
        let current = this.state[variable];
        let detailsChange = this.state.detailsChange;

        let index = current.findIndex(ct => ct.id === id);

        let vl;
        if (value === "min") {
            vl = e.target.value < 0 ? 0 : e.target.value;
        } else {
            vl =
                e.target.value < current[index].min
                    ? current[index].min
                    : e.target.value;
        }
        current[index][value] = parseInt(vl);

        let dc = detailsChange.findIndex(dc => dc[variable] === current[index][variable]);

        if (dc !== -1) {
            detailsChange[dc][value] = parseInt(vl);
        } else {
            detailsChange.push({
                method: variable,
                [variable]: current[index][variable],
                [value]: vl
            });

        }

        this.setState({[variable]: current, detailsChange});
    };

    handleSelect = (id, e) => {
        let currentRules = this.state.rule;

        let index = currentRules.findIndex(rules => rules.id === id);
        currentRules[index].value = e;

        this.setState({rule: currentRules});
    };

    handlePlayerSelect = player => {
        this.setState({
            playerUpdate: player
        });
    }

    handleScheduleSelect = schedule => {
        this.setState({
            scheduleUpdate: schedule
        });
    }

    setContent = zone => {
        const {dispatch,schedules, players, openSchedule, song = []} = this.props;
        const {option, rule, setting} = this.state;

        switch (option) {
            case 1: {
                const availablePlayers = players.filter(p => !p.archived && (p.zone === undefined || p.zone.id === this.props.id));

                return (
                    <ZoneSummaryView
                        schedules={schedules}
                        dispatch={dispatch}
                        zone={zone}
                        players={availablePlayers}
                        song={song}
                        onPlayerSelect={this.handlePlayerSelect}
                        onScheduleSelect={this.handleScheduleSelect}
                        openSchedule={openSchedule}
                        onPlayerHistoryClicked={(id) => this.props.history.push(`/devices/${id}`)}
                        onScheduleOpen={schedule => this.props.history.push(`/schedule/${schedule.id}`)}
                        onPlayerOpen={player => this.props.history.push(`/devices/${player.id}`)}
                        createSchedule={this.props.createSchedule}
                    />
                );
            }
            case 2:
                return (
                    <RulesContainer
                        rules={rule}
                        active={this.setActive}
                        setBPM={this.handleInput}
                        setDropdown={this.handleSelect}
                        setDate={this.handleDate}
                    />
                );
            case 3:
            default:
                return (
                    <SettingsContainer
                        settings={setting}
                        setDate={this.handleDate}
                        setInput={this.handleInput}
                        setBoolean={this.handleBoolean}
                        onShowConfirmArchiving={this.onShowConfirmContainer}
                        onCloseConfirmArchiving={this.onCloseConfirmContainer}
                        onConfirmArchiving={this.onConfirmArchiving}
                        confirmArchiving={this.state.confirmArchiving}
                        onDelete={this.props.onDeleteZone}
                    />
                );
        }
    };

    getChangeSet = () => {
        let scheduleChange = CHANGE_NONE;
        let playerChange = CHANGE_NONE;

        // No zone was loaded, deltas cannot be computed
        if (this.props.zone) {
            const {playerUpdate, scheduleUpdate} = this.state;

            if (playerUpdate !== -1 && get(playerUpdate, 'id') !== get(this.props.zone, 'player.id')) {
                playerChange = playerUpdate === undefined ? CHANGE_DELETE : CHANGE_CHANGE;
            }

            if (scheduleUpdate !== -1 && get(scheduleUpdate, 'id') !== get(this.props.zone, 'schedule.id')) {
                scheduleChange = scheduleUpdate === undefined ? CHANGE_DELETE : CHANGE_CHANGE;
            }
        }

        return [scheduleChange, playerChange];
    }

    review = () => {
        const [scheduleChange, playerChange] = this.getChangeSet();

        let message;
        if (scheduleChange === CHANGE_NONE && playerChange !== CHANGE_NONE) {
            message = "Player has been modified";
        } else if (scheduleChange !== CHANGE_NONE && playerChange === CHANGE_NONE) {
            message = "Schedule has been modified";
        } else if (scheduleChange !== CHANGE_NONE && playerChange !== CHANGE_NONE) {
            message = "Schedule & player has been modified";
        }

        if (message) {
            this.setState({showConfirmText: message});
        } else {
            this.submit();
        }
    }

    submit = async () => {
        const {zone, dispatch} = this.props;

        // No zone was loaded, deltas cannot be computed
        if (!zone) return;

        const [scheduleChange, playerChange] = this.getChangeSet();

        const {playerUpdate, scheduleUpdate} = this.state;

        if (playerChange === CHANGE_DELETE) {
            await zonesActions.deletePlayer(dispatch, zone.id);
        } else if (playerChange === CHANGE_CHANGE) {
            await zonesActions.addPlayer(dispatch, zone.id, playerUpdate.id);
        }

        if (scheduleChange === CHANGE_DELETE) {
            await zonesActions.deleteSchedule(dispatch, zone.id);
        } else if (scheduleChange === CHANGE_CHANGE) {
            await zonesActions.addSchedule(dispatch, zone.id, scheduleUpdate.id);
        }
        let volume = this.state.setting[2].value;
        let volumeChangeAllowed = this.state.setting[3].value;
        await zonesActions.changeVolume(dispatch, zone.id, volume, volumeChangeAllowed);
        zone.volume = volume;
        zone.volumeChangeAllowed = volumeChangeAllowed;
        alertActions.notificationSuccess(dispatch, "Zone updated", `Your changes have been applied to '${zone.name}'`);
        this.props.handleClose();
    }

    editName = () => {
        this.setState({showEditName: true});
    }

    handleCloseZone = () => {
        this.setState({showEditName: false});
    }

    onShowConfirmContainer = async () => {
        this.setState({confirmArchiving: true});
    }

    onCloseConfirmContainer = async () => {
        this.setState({confirmArchiving: false});
    }

    onConfirmArchiving = async () => {
        this.setState({confirmArchiving: false});
        this.props.onDeleteZone()
    }


    closeDeleteConfirmationDialog = async () => {
        this.setState({confirmArchiving: false});
    }


    render() {
        const {handleClose, zone} = this.props;

        return (
            <ThemeProvider theme={theme}>
                <>
                    <Modal show={true} onHide={handleClose} className="modal-pick" size="lg">
                        <Modal.Body>
                            {zone.id !== this.props.id ? (<Loading/>) : (
                                <Container>
                                    <ContextRow area="zones"
                                                title={`${zone.location.client.name} > ${zone.location.name}`}
                                                onClick={() => {
                                                }}/>
                                    <Row onClick={this.editName} style={{marginBottom: '24px'}}>
                                        <Title>{zone.name}</Title>{zone.type && <TitleZoneType>{"("+zone.type+")"}</TitleZoneType>}
                                        <FontAwesomeIcon style={{cursor: 'pointer'}} icon="pencil-alt" color="white"/>
                                    </Row>
                                    <TabControl controls={[
                                        {
                                            title: "Zone",
                                            onClick: () => this.setOption(1)
                                        },
                                        {
                                            title: `Rules`,
                                            onClick: () => this.setOption(2)
                                        },
                                        {
                                            title: `Settings`,
                                            onClick: () => this.setOption(3)
                                        }
                                    ]}
                                    />
                                    {Object.keys(zone).length > 0 ? (
                                        <Content>{this.setContent(zone)}</Content>
                                    ) : (
                                        <Content>
                                            <Loading/>
                                        </Content>
                                    )}
                                </Container>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonsContainer>
                                <CancelButton onClick={() => {
                                    this.setState({detailsChanges: []});
                                    handleClose();
                                }}>
                                    Cancel
                                </CancelButton>
                                <OkButton onClick={this.review}>Save</OkButton>
                            </ButtonsContainer>
                        </Modal.Footer>
                    </Modal>
                    {this.state.showEditName && <CreateZone zone={this.props.zone} onBack={this.handleCloseZone}
                                                            onCreateOrEdit={this.handleCloseZone}/>}
                    {this.state.showConfirmText && (
                        <ModalDecisionBuilder
                            title="Confirm"
                            content={`${this.state.showConfirmText}. Continue?`}
                            handleClose={() => this.setState({showConfirmText: undefined})}
                            handleAccept={this.submit}
                        />
                    )}
                </>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.zones.loading,
    zone: state.zones.zoneDetails,
    song: state.songs.songDetails,
    players: state.player.player
});

const connectedZoneDetails = withRouter(connect(mapStateToProps)(ZoneDetails));
export {connectedZoneDetails as ZoneDetails};

