import React, {useEffect, useState} from "react";
import styled, {ThemeProvider} from "styled-components";

import {
    CreationForms,
    DefaultBlackFormControl,
    DefaultColumnContainer,
    DefaultLabel,
    FormRadioButton,
    StandardMoment,
    theme
} from "_styles";
import {ModalDecisionBuilder} from './Utils';
import {ThumbDown, ThumbUp} from "@material-ui/icons";
import {alertActions, feedbackActions} from "../_actions";

const DetailsContainer = styled(DefaultColumnContainer)`
  margin-top: 5px;
  width: 100%;
  align-items: flex-start;
`;


const Label = styled(DefaultLabel)`
  color: white;
  font-size: 24px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-top: 10px;
`;

const FormLabel = styled(DefaultLabel)`
  color: ${props => props.theme.formlabel};
  font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
  margin-top: 5px;
`;

export const EditSongVote = ({songVote, onBack, onEdit, onArchive, dispatch}) => {
    const [songVoteStatus, setSongVoteStatus] = useState(songVote.status != null ? songVote.status : "NEW");
    const [notes, setNotes] = useState(songVote.notes);
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [archiving, setArchiving] = useState(false);
    const [confirmArchiving, setConfirmArchiving] = useState(false);


    useEffect(() => {

    }, []);

    const doArchiving = async () => {
        if (archiving)
            return;
        setArchiving(true);
        setError('');
        setConfirmArchiving(false);
        try {
            /*           await songVoteActions.archive(dispatch, songVote.id);
                       setArchiving(false);
                       alertActions.notificationSuccess(dispatch, "Client archived", name);
                       onArchive();*/
        } catch (e) {
            setError(e.userMessage);
            setArchiving(false);
        }
    }

    const closeDeleteConfirmationDialog = async () => {
        setConfirmArchiving(false);
    }

    const handleArchive = async () => {
        if (confirmArchiving)
            return;
        setConfirmArchiving(true);
    }

    const submit = async () => {
        setSubmitting(true);
        setError('');
        let songVoteData = {
            notes,
            status: songVoteStatus.toUpperCase(),
        };
        try {
            await feedbackActions.updateSongVote(dispatch, songVoteData, songVote.id);
            setSubmitting(false);
            alertActions.notificationSuccess(dispatch, "Song Vote Updated");
            onEdit();
        } catch (e) {
            setError(e);
            setSubmitting(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CreationForms
                message={`This will edit the song vote, please check the information entered above is correct.`}
                handleBackButton={onBack}
                handleSubmit={submit}
                deleting={archiving}
                onDelete={handleArchive}
                submitting={submitting}
                alert={error}
                title={"Edit Song Vote"}>
                <Label>Vote Details</Label>
                <DetailsContainer style={{backgroundColor: "##464645", padding: 15}}>
                    <div style={{padding: 20, paddingTop: 0}}>
                        {songVote.downvote ? <ThumbDown style={{color: "red"}}></ThumbDown> :
                            <ThumbUp style={{color: "green"}}></ThumbUp>}
                    </div>
                    <FormLabel>id:<FormLabel style={{color: "white"}}>{songVote.id}</FormLabel></FormLabel>
                    <FormLabel>Date:<FormLabel style={{color: "white"}}><StandardMoment>{songVote.date}</StandardMoment></FormLabel></FormLabel>
                    <FormLabel>Client:<FormLabel
                        style={{color: "white"}}> {songVote.client.name}</FormLabel></FormLabel>
                    <FormLabel>Zone:<FormLabel style={{color: "white"}}> {songVote.zone.name}</FormLabel></FormLabel>
                    <FormLabel>Playlist:<FormLabel
                        style={{color: "white"}}> {songVote.playlist ? songVote.playlist.id : ""}</FormLabel></FormLabel>
                    <FormLabel>voter:<FormLabel
                        style={{color: "white"}}> {songVote.voter.firstName}</FormLabel></FormLabel>
                    <FormLabel>comments:<FormLabel style={{color: "white"}}> {songVote.comments}</FormLabel></FormLabel>
                </DetailsContainer>
                <Label>Status</Label>
                <FormRadioButton
                    handleChange={setSongVoteStatus}
                    name={`account-status-button-group`}
                    value={songVoteStatus.toUpperCase()}
                    options={["NEW", "DONE", "IGNORE", "REMOVED"]}
                />
                <DetailsContainer>
                    <Label>Song Vote Details</Label>
                    <FormContainerWithHelper>
                        <FormLabel>Notes</FormLabel>
                        <Form
                            as="textarea"
                            rows="3"
                            name="primaryAddress"
                            placeholder="Notes"
                            value={notes}
                            onChange={e => setNotes(e.target.value)}
                        />

                    </FormContainerWithHelper>
                </DetailsContainer>
                {confirmArchiving && (
                    <ModalDecisionBuilder
                        title={"Are you sure you want to archive this Song Vote?"}
                        handleClose={closeDeleteConfirmationDialog}
                        handleAccept={doArchiving}
                    />
                )}
            </CreationForms>
        </ThemeProvider>
    );
}

/*const mapStateToProps = state => ({
    users: state.users.users,
    clients: state.client.client
});

const connectedEditSongVote = withRouter(connect(mapStateToProps)(EditSongVote));
export {connectedEditSongVote as EditSongVote};*/
