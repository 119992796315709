import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {playerActions} from "_actions";
import {
    ClientDropdownFilter,
    EditPlayer,
    FilterSection,
    PlayerConnectMenu,
    PrimaryButton,
    RowSpaced,
    SearchFilter,
    SectionGutter,
    SectionHeader,
    SectionTitle,
} from "_components";
import {SortTable} from '_components/Standard';
import {Matches} from '_helpers';
import {StandardMoment} from '_styles';
import get from 'lodash.get';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ButtonGroup} from "react-bootstrap";
import moment from "moment-timezone";
import {Checkbox, FormControlLabel, Tooltip} from "@material-ui/core";
import {Notes} from "@material-ui/icons";


const diskUnitString = (megabytes) => (megabytes * 0.001).toFixed(2);

class AllDevices extends React.Component {
    state = {
        search: sessionStorage.getItem('players_search_text') || "",
        showEditPlayer: false,
        showPlayer: undefined,
        playerUpdated: "",
        filterClientName: "",
        showArchived: sessionStorage.getItem('players_show_archived') === 'true',
        showExtended: sessionStorage.getItem('players_extended_view') === 'true'
    };

    componentDidMount() {
        this.props.dispatch(playerActions.get());
    }

    handleSearch = filterText => {
        this.setState({
            search: filterText.toLowerCase()
        }, () => sessionStorage.setItem('players_search_text', filterText.toLowerCase()));
    };

    switchExtendedView = (value) => {
        this.setState({showExtended: value}, () => sessionStorage.setItem('players_extended_view', value));
    };

    handleEditPlayer = async (id, name, notes) => {
        try {
            await playerActions.update(this.props.dispatch, id, name, notes);
        } catch (e) {
            console.error("Error editing player", e);
        }

        this.setState({showEditPlayer: false, showPlayer: undefined});
    };

    showEdit = (player) => {
        this.setState({showEditPlayer: true, showPlayer: player});
    }

    filterClient = (client) => {
        this.setState({filterClientName: client ? client.text : undefined});
    }

    handleShowArchivedChange() {
        this.setState({showArchived: !this.state.showArchived},
            () => sessionStorage.setItem('players_show_archived', this.state.showArchived));
    }

    render() {
        const {players = [], loading} = this.props;
        const {filterClientName, showArchived} = this.state;

        let data = !filterClientName ? players : players.filter(l => get(l, 'client.name', '') === filterClientName);
        data = showArchived ? data : data.filter(p => !p.archived);
        data = Matches(data, this.state.search, ["name", "macAddress", "id"]);
        data.forEach(d => {
                d['clientName'] = d.client ? d.client.name : "Unassigned";
                d['version'] = d.info && d.info.version ? d.info.version : "_unknown";
                d['kernelVersion'] = d.info && d.info["kernel_version"] ? d.info["kernel_version"] : "_unknown";
                d['model'] = d.info && d.info["model"] ? d.info["model"] : "_unknown";
            }
        );
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        const oneDayAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 1);
        let tenMinutesAgo = new Date();
        tenMinutesAgo = new Date(tenMinutesAgo.getTime() - 10 * 60000);
        const oneMonthAgo = new Date();
        oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
        data.forEach(row => {
            let lastStartDateUnix;
            if (row.info && row.info["last start"]) {
                const lastStartArray = row.info["last start"].split(" ");
                const timezone = lastStartArray[4];
                delete lastStartArray[4];
                delete lastStartArray[0];
                const lastStartString = lastStartArray.join(' ');
                const lastStartDate = moment(lastStartString, "MMM DD hh:mm:ss YYYY").tz(timezone).toDate();
                lastStartDateUnix = lastStartDate.getTime();
                row['lastStart'] = lastStartDateUnix;
            } else {
                row['lastStart'] = 0;
            }
            row['diskUsage'] = 100 - get(row, 'percentageFree', 100);

        })

        const allColumns = [
            {
                title: <FontAwesomeIcon icon="download"/>,
                textTitle: "Pending Downloads",
                fixed: 40,
                isNumber: true,
                field: 'info.unfinishedDownloads',
                rowRenderer: row => {
                    function format_date(s) {

                        const options = {
                            year: 'numeric', month: 'numeric', day: 'numeric',
                            hour: 'numeric', minute: 'numeric', second: 'numeric',
                            hour12: false,
                        };
                        const dtFormat = new Intl.DateTimeFormat('en-US', options);
                        try {
                            return dtFormat.format(new Date(s));
                        } catch {
                            return "parse error";
                        }
                    }

                    let info = row.info;
                    let messages = [];
                    let color = "";
                    if (info) {
                        let {scheduleId, scheduleUpdateDate} = info;
                        let {serverScheduleId, serverScheduleUpdateDate} = row;
                        if (scheduleId && scheduleId !== serverScheduleId) {
                            messages.push("Schedule in player is not the currently assigned.");
                            color = 'orange';
                        } else if (scheduleId) {
                            if ((!scheduleUpdateDate && serverScheduleUpdateDate) || (scheduleUpdateDate && !serverScheduleUpdateDate) || (serverScheduleUpdateDate && scheduleUpdateDate && format_date(scheduleUpdateDate) !== format_date(serverScheduleUpdateDate))) {
                                messages.push("Schedule not updated");
                                color = 'yellow';
                            }
                        }
                    }
                    let pendingDownloads = false;
                    if (row.info) {
                        let unfinishedDownload = row.info.unfinishedDownloads;
                        if (!unfinishedDownload || unfinishedDownload !== "0") {
                            pendingDownloads = true;
                        }
                        if (messages.length === 0) {
                            color = pendingDownloads ? "red" : "green"
                        }
                        return (unfinishedDownload ?
                            <div title={messages.join("<br/>")}><FontAwesomeIcon icon="download"
                                                                                 color={color}/>{unfinishedDownload > 0 ? unfinishedDownload : ""}
                            </div> : "")
                    } else if (messages.length !== 0) {
                        return <div title={messages.join("<br/>")}><FontAwesomeIcon icon="download"
                                                                                    color={color}/></div>
                    }

                }
            },
            {
                title: <div className={`dot-status`} style={{backgroundColor: 'grey'}}/>,
                textTitle: "Player status",
                fixed: 25,
                field: 'status',
                rowRenderer: row => {
                    return <div className={`dot-status dot-status-${row.status}`}/>
                }
            },
            {
                fixed: 30,
                title: "notes",
                rowRenderer: row => {
                    if (row.notes) {
                        return <Tooltip title={<span
                            style={{whiteSpace: 'pre-line'}}>{row.notes}</span>}>
                            <Notes
                                style={{color: "white", fontSize: 16}}></Notes></Tooltip>;
                    }
                    return <></>;
                }
            },
            {fixed: 50, isNumber: true, title: 'ID', field: 'id'},
            {
                fixed: 150, title: 'Client', field: 'clientName', rowRenderer: row => {
                    return <span
                        style={{color: (row.archived ? 'red' : 'white')}}>{row.client ? row.client.name : "Unassigned"}</span>
                }
            },
            {
                fixed: 150, title: 'Player', field: 'name', rowRenderer: row => {
                    return <span
                        style={{color: (row.archived ? 'red' : 'white')}}>{row.name}</span>
                }
            },
            {
                fixed: 150, title: 'Zone', field: 'zone.name', rowRenderer: row => (
                    row.zone ? row.zone.name : "Unassigned"
                )
            },
            {fixed: this.state.showExtended ? 80 : 160, title: 'Version', field: 'version', collapse: 1150},
            {fixed: 80, title: 'Kernel', field: 'kernelVersion', collapse: 1150},
            {fixed: 100, title: 'Model', field: 'model', collapse: 1150},
            {
                fixed: 200,
                title: 'Disk (Used/Total Gb)',
                field: 'diskUsage',
                isNumber: true,
                rowRenderer: row => (
                    <div>{diskUnitString(row.totalSize - row.usableSize)} / {diskUnitString(row.totalSize)} ({100 - get(row, 'percentageFree', 100)}%)</div>
                )
            },
            {fixed: 160, title: 'MAC', field: 'macAddress', collapse: 1150, dim: true},
            {fixed: 80, title: 'Timezone', field: 'timeZoneOffset', dim: true},
            {
                fixed: 160,
                title: 'Created (Local)',
                isNumber: true,
                field: 'dateCreated',
                collapse: 1150,
                dim: true,
                rowRenderer: row => (
                    <StandardMoment>{row.dateCreated}</StandardMoment>
                )
            },
            {
                fixed: 160,
                title: 'Last Ping',
                isNumber: true,
                field: 'lastPing',
                collapse: 1150,
                dim: true,
                rowRenderer: row => {
                    let style = {color: "white"}
                    if (row.lastPing) {
                        const lastPingDate = new Date(row.lastPing);
                        if (lastPingDate < tenMinutesAgo) {
                            if (lastPingDate < oneMonthAgo) {
                                style['color'] = "";
                            } else if (lastPingDate < threeDaysAgo) {
                                style['color'] = "red";
                            } else if (lastPingDate < tenMinutesAgo) {
                                style['color'] = "yellow";
                            }
                        }
                    }
                    return <div style={style}><StandardMoment>{row.lastPing}</StandardMoment></div>;
                }

            },
            {
                fixed: 160,
                title: 'Last restart',
                isNumber: true,
                collapse: 1150,
                field: 'lastStart',
                dim: true,
                rowRenderer: row => {
                    let style = {color: "white"}
                    if (row.lastStart !== 0) {
                        const lastStartDate = new Date(row.lastStart);
                        if (lastStartDate > oneDayAgo) {
                            style['color'] = "red";
                        } else if (lastStartDate > threeDaysAgo) {
                            style['color'] = "yellow";
                        }
                    }
                    return <div style={style}>{row.lastStart === 0 ? <></> :
                        <StandardMoment>{row.lastStart}</StandardMoment>}</div>;
                }
            },
            {
                fixed: 160,
                height: 300,
                title: 'logs',
                nocell: true,
                dim: false,
                rowRenderer: row => (
                    <div onClick={e => e.stopPropagation()}>
                        <PlayerConnectMenu playerId={row.id}/>
                    </div>
                )
            },
        ];
        let columns = this.state.showExtended ? allColumns :
            [allColumns[0], allColumns[1], allColumns[2], allColumns[3], allColumns[4], allColumns[5], allColumns[6], allColumns[7], allColumns[10], allColumns[12], allColumns[16]];


        return (
            <>
                <SectionHeader>
                    <RowSpaced>
                        <SectionTitle>Players</SectionTitle>
                        <ButtonGroup>
                            {!this.state.showExtended ?
                                <PrimaryButton onClick={() => this.switchExtendedView(true)}>Extended
                                    View</PrimaryButton> :
                                <PrimaryButton onClick={() => this.switchExtendedView(false)}>Simple
                                    View</PrimaryButton>}
                        </ButtonGroup>
                    </RowSpaced>

                    <FilterSection>
                        <SearchFilter onChange={this.handleSearch} value={this.state.search}/>
                        <ClientDropdownFilter clientSelect={this.filterClient}/>
                        <FormControlLabel style={{color: '#979797'}} control={<Checkbox style={{color: '#979797'}}
                                                                                        checked={this.state.showArchived}
                                                                                        onChange={this.handleShowArchivedChange.bind(this)}/>}
                                          label={"Show Archived"}/>
                    </FilterSection>
                </SectionHeader>

                {this.state.showEditPlayer && (
                    <EditPlayer
                        player={this.state.showPlayer}
                        dispatch={this.props.dispatch}
                        handlePlayerEdit={this.handleEditPlayer}
                        handleBackButton={() =>
                            this.setState({showEditPlayer: false, showPlayer: undefined})
                        }
                        onArchive={() => {
                            this.setState({showEditPlayer: false, showPlayer: undefined});
                            this.props.dispatch(playerActions.get());
                        }}
                    />
                )}

                <SortTable
                    tableName={"players"}
                    loading={loading}
                    rows={data}
                    gutter={SectionGutter}
                    onRowClick={this.showEdit}
                    columns={columns}
                    style={{minWidth: this.state.showExtended ? 2000 : 1000}}
                    showTooltips={true}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.player.loading,
    players: state.player.player
});

const connectedAllDevices = withRouter(connect(mapStateToProps)(AllDevices));
export {connectedAllDevices as AllDevices};

