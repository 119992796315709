import React from "react";
import styled, {ThemeProvider} from "styled-components";

import {DefaultColumnContainer, DefaultRowContainer} from './Container.style';
import {DefaultLabel} from './Label.style';
import {DefaultButton} from './Button.style';
import {DefaultAlert} from './Alert.style';
import {LeftIconFormControl} from './Input.style';
import {theme} from "./Theme";

import {InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import image from "_assets/images/talea_logo_white.png";
import {TALEA_ENVIRONMENT} from "../_services/api";

const Container = styled(DefaultColumnContainer)`
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.main};
  width: 100%;
  height: 100%;
  min-width: 800px;
`;

const InnerContainer = styled(DefaultColumnContainer)`
  width: 30%;
  align-items: center;
`;

const Image = styled.img`
  display: flex;
  width: 50%;
  margin: auto auto;
  margin-bottom: 40px;
`;

const IGroup = styled(InputGroup)`
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 5px;
  background-color: ${props => props.theme.darkGray};
  color: white;

  & + & {
    margin-top: 20px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.optionalGray};
    color: white;
  }
`;

const IGroupPrepend = styled(InputGroup.Prepend)``;

const IGroupText = styled(InputGroup.Text)`
  border: 0;
  color: white;
  background-color: transparent;
`;

const LoginForm = styled(LeftIconFormControl)``;

const TextContainer = styled(DefaultRowContainer)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

const Text = styled(DefaultLabel)`
  color: ${props => props.theme.lightGray};
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
`;

const Button = styled(DefaultButton)`
  margin-top: 30px;
  padding: 10px;
  font-weight: bold;
  width: 100%;
`;

export const LoginContainer = ({
  alert,
  username,
  password,
  onChange,
  handleSubmit
}) => (
  <ThemeProvider theme={theme}>
    <Container>
      <InnerContainer>
        <form onSubmit={e => {e.preventDefault(); handleSubmit();}}>
        <Image src={image} />
        <IGroup>
          <IGroupPrepend>
            <IGroupText id="basic-addon1">
              <Icon icon="envelope" />
            </IGroupText>
          </IGroupPrepend>
          <LoginForm
            name="username"
            type="text"
            placeholder="Email"
            aria-label="Email"
            aria-describedby="basic-addon1"
            value={username}
            onChange={onChange}
          />
        </IGroup>
        <IGroup>
          <IGroup.Prepend>
            <IGroupText id="basic-addon2">
              <Icon icon="lock" />
            </IGroupText>
          </IGroup.Prepend>
          <LoginForm
            name="password"
            type="password"
            placeholder="Password"
            aria-label="Password"
            aria-describedby="basic-addon2"
            value={password}
            onChange={onChange}
          />
        </IGroup>
        <TextContainer>
          <a href={"mailto:contact@talea-agency.com?subject=I forgot my password&body=Hi Talea support,I could not access "+TALEA_ENVIRONMENT+" with my email and password. My email is "+username}>
            <Text>Forgot Password?</Text>
          </a>
        </TextContainer>
        <Button onClick={handleSubmit}>Login</Button>
        {/* Have enter key perform submit without rewiring above button */}
        <input type="submit" style={{display: "none"}} />
        <DefaultAlert style={{marginTop:10}} color={'error'}>{alert}&nbsp;</DefaultAlert>
        </form>
      </InnerContainer>
    </Container>
  </ThemeProvider>
);
