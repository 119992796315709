import {alertConstants} from '_constants';

// Selectors
export const getNotifications = state => state.alert.notifications;

const initialState = {
  type: '',
  message: '',
  notifications: []
}

export function alert(state = initialState, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        ...state,
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        ...state,
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.CLEAR:
      return {
        ...state,
        type: '',
        message: '',
      };
    case alertConstants.NOTIFICATION_ADD:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {id: action.id, title: action.title, message: action.message, type: action.kind}
        ]
      }

    case alertConstants.NOTIFICATION_REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(not => not.id !== action.id)
      }

    default:
      return state
  }
}