import React from "react";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "./Theme";
import {DefaultLabel} from "./Label.style";
import {DefaultColumnContainer} from "./Container.style";
import DonutChart from "react-svg-donut-chart";

const Clickable = styled.div``;

const LabelPlayers = styled(DefaultLabel)`
  margin-left: 20px;
  color: grey;
  font-size: large;

  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: ${props => props.color};
    margin-right: 10px;
    text-align: center;
  }

  & + & {
    margin-top: 5px;
  }
`;

const LabelContainer = styled(DefaultColumnContainer)`
  align-items: flex-start;
`;

const LabelTotal = styled(DefaultLabel)`
  color: white;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ListUsersCard = ({ data }) => (
  <ThemeProvider theme={theme}>
    <Clickable>
      <DonutChart data={data} spacing={1} active={"true"} />
      <LabelTotal>{`${data[0].value + data[1].value+data[2].value} players assigned`}</LabelTotal>
      <LabelContainer>
        <LabelPlayers color="green">{`${data[0].value} players online`}</LabelPlayers>
        <LabelPlayers color="red">{`${data[1].value} players offline`}</LabelPlayers>
        <LabelPlayers color="lightblue">{`${data[2].value} players unassigned`}</LabelPlayers>
          <LabelPlayers color="darkgrey">{`${data[3].value} players inactive`}</LabelPlayers>
      </LabelContainer>
    </Clickable>
  </ThemeProvider>
);
