import {uiConstants} from '_constants';

// Selectors
export const getFocus = state => state.ui.focus;
export const getNavigationPlaylistExpand = state => state.ui.expanded;

const initialState = {
  expanded: [],
  focus: undefined,
  clientId: -1
}

export function ui(state = initialState, action) {
  switch (action.type) {
    case uiConstants.SET_FOCUS:
      return {
        ...state,
        focus: action.target
      };
    case uiConstants.SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.clientId,
      }
    case uiConstants.SET_NAVIGATION_EXPANDED_STATE:
      return {
        ...state,
        expanded: action.expanded
      }
    default:
      return state
  }
}