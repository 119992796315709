import React from 'react';
import styled from "styled-components";
import {LiveText} from './LiveText.style';

const Context = styled.div`
  display: flex;
  flex-direction: row;
  color: #aeaeae;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const ContextRow = ({area, title, onClick}) => (
  <Context>
    {area} • 
    <LiveText onClick={onClick}>
      {title}
    </LiveText>
  </Context>
)