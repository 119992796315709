import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Select from "react-select";
import styled, {ThemeProvider} from "styled-components";
import {alertActions, clientActions, locationActions} from "_actions";
import image from "_assets/images/jurica-koletic.jpg";
import {CreationForms, DefaultBlackFormControl, DefaultColumnContainer, DefaultLabel, Helper, theme} from "_styles";
import {ModalDecisionBuilder} from './Utils';
import {locationService} from "../_services";
import * as PropTypes from "prop-types";
import {ImagePickModal} from "./ImagePickModal";
import {Button, Col, Row} from "react-bootstrap";

const DetailsContainer = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;

    & + & {
        margin-top: 20px;
    }
`;

const Image = styled.div`
    background-color: hsl(${props => props.hue}, 90.6%, 66.7%);
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    display: flex;
`;

const Label = styled(DefaultLabel)`
    color: white;
    font-size: 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
`;

const FormContainerWithoutHelper = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;
    margin-top: 20px;
`;

const FormContainerWithHelper = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
`;

const FormLabel = styled(DefaultLabel)`
    color: ${props => props.theme.formlabel};
    font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
    margin-top: 5px;
`;

const RSelect = styled(Select)`
    width: 100%;
    margin-top: 10px;
`;

function hashCode(s) {
    var h = 0, l = s.length, i = 0;
    if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
    return h;
}

;

const CreateLocation = ({
                            editLocation: location = undefined,
                            onBack,
                            onCreate,
                            onArchive,
                            dispatch,
                            clients,
                            imagesUrls
                        }) => {
    const isEditing = location !== undefined;

    const [name, setName] = useState(isEditing ? location.name : '');
    const [address, setAddress] = useState(isEditing ? location.address : '');
    const [phone, setPhone] = useState(isEditing ? location.phoneNumber : '');
    const [email, setEmail] = useState(isEditing ? location.email : '');
    const [pointOfContact, setPointOfContact] = useState(isEditing ? location.pointOfContact : '');
    const [client, setClient] = useState(isEditing ? {
        value: location.client.id,
        label: location.client.name
    } : undefined);
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [archiving, setArchiving] = useState(false);
    const [confirmArchiving, setConfirmArchiving] = useState(false);
    const [locationLogoUrl, setLocationLogoUrl] = useState(isEditing ? location.logoUrl : undefined);
    const [imagePicked, setImagePicked] = useState(false);
    const [showPickImageModal, setShowPickImageModal] = useState(false);
    const imageUrlInput = useRef();

    useEffect(() => {
        clientActions.getAllClients(dispatch);
        dispatch(clientActions.getUsers());
    }, []);

    const doArchiving = async () => {
        if (archiving)
            return;

        setArchiving(true);
        setError('');
        setConfirmArchiving(false);

        try {
            await locationActions.archive(dispatch, location.id);
            setArchiving(false);
            alertActions.notificationSuccess(dispatch, "Location archived", name);
            onArchive();
        } catch (e) {
            setError(e.userMessage);
            setArchiving(false);
        }
    }


    const closeDeleteConfirmationDialog = async () => {
        setConfirmArchiving(false);
    }


    const handleArchive = async () => {
        if (confirmArchiving)
            return;
        setConfirmArchiving(true);
    }

    const handleImagePickComplete = async (imageUrl) => {
        setShowPickImageModal(false);
        setLocationLogoUrl(imageUrl);
        setImagePicked(true);
    }

    const locationLogoImageClicked = () => {
        imageUrlInput.current.click();
    }

    const onImageFileChanged = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        await locationService.uploadArtwork(location.id, file)
        const imageUrl = URL.createObjectURL(file);
        setLocationLogoUrl(imageUrl);
    };

    const submit = async () => {
        if (name === "" || client === "") {
            setError('Please fill out all the required fields');
            return;
        }

        const locationData = {
            ...(isEditing ? {id: location.id} : {}),
            name,
            address,
            phoneNumber: phone,
            email,
            client: {id: client.value},
            pointOfContact,
            ...(imagePicked && {logoUrl: locationLogoUrl})
        };

        setSubmitting(true);
        setError('');

        try {
            isEditing ? await locationActions.updateOne(dispatch, locationData, location.id) : await locationActions.addOne(dispatch, locationData);
            setSubmitting(false);
            alertActions.notificationSuccess(dispatch, isEditing ? "Location updated" : "Location created", name);
            onCreate();
        } catch (e) {
            setError(e);
            setSubmitting(false);
        }
    }

    const handleImagePickClose = () => {
        setShowPickImageModal(false);
    }

    const imagesUrlsByClient = isEditing && [...new Set(imagesUrls.filter(i => i.clientId === client.value).map(i => i.logoUrl))];

    return (
        <ThemeProvider theme={theme}>
            <CreationForms
                message={`This will ${isEditing ? 'update a' : 'create a new'} location, please check the information entered above is correct.`}
                handleBackButton={onBack}
                handleSubmit={submit}
                submitting={submitting}
                deleting={archiving}
                onDelete={handleArchive}
                alert={error}
                image={image}
                title={isEditing ? 'Edit Location' : 'Create Location'}
                level={10002}
            >
                {(isEditing &&
                    <DetailsContainer style={{marginBottom:35}}>
                        <div className={"d-flex"}>
                            <div>
                                <Image hue={!locationLogoUrl && (hashCode(location.name) % 360)} size={180}
                                       onClick={locationLogoImageClicked}>
                                    {(locationLogoUrl) &&
                                        <>
                                            <img alt={"location logo"}
                                                 style={{maxWidth: '100%', maxHeight: '100%'}}
                                                 src={locationLogoUrl}/>
                                        </>}
                                    <input type='file' id='file' ref={imageUrlInput}
                                           style={{display: 'none'}}
                                           onChange={onImageFileChanged}
                                           accept="image/png, image/jpeg"/>
                                </Image>
                            </div>
                            <div style={{marginLeft: 20}} className={"d-flex flex-column"}>
                                <div style={{color: "white", fontSize: 24}}>Logo</div>
                                <div style={{color: "#808080", marginTop: 10, lineHeight: "1.5em"}}
                                     className={"flex-fill"}>Upload a Logo.
                                    This will be
                                    displayed in the client's mobile application.
                                    <br/>
                                    Please ensure logo is exact square, at least 512x512 and in JPG format
                                </div>
                                {imagesUrlsByClient && imagesUrlsByClient.length > 0 &&
                                    <div style={{
                                        color: "#FFB800",
                                        cursor: "pointer"
                                    }} onClick={() => setShowPickImageModal(true)}>Choose
                                        from existing...</div>}
                                <div style={{color: "#FFB800", marginTop: 10, cursor: "pointer"}}
                                     onClick={locationLogoImageClicked}>Upload
                                    from computer...
                                </div>
                            </div>
                        </div>

                    </DetailsContainer>)}
                <DetailsContainer>
                    <Label>Choose Client</Label>
                    <RSelect
                        options={clients.sort((a, b) => a.name.localeCompare(b.name)).map(client => ({
                            value: client.id,
                            label: client.name
                        }))}
                        name="client-list"
                        onChange={setClient}
                        value={client}
                    />
                    <Helper message="Choose one client"/>
                </DetailsContainer>
                <DetailsContainer>
                    <Label>Enter Location Details</Label>
                    <FormContainerWithoutHelper>
                        <FormLabel>LOCATION NAME</FormLabel>
                        <Form
                            type="text"
                            name="name"
                            placeholder="Name of Location"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormContainerWithoutHelper>
                    <FormContainerWithoutHelper>
                        <FormLabel>LOCATION ADDRESS</FormLabel>
                        <Form
                            type="text"
                            name="address"
                            placeholder="Enter Location Address"
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                        />
                        <Helper message="You can choose this later"/>
                    </FormContainerWithoutHelper>
                    <FormContainerWithHelper>
                        <FormLabel>LOCATION PHONE NUMBER</FormLabel>
                        <Form
                            type="text"
                            name="phoneNumber"
                            placeholder="Enter Location Phone Number"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                        <Helper message="You can choose this later"/>
                    </FormContainerWithHelper>
                    <FormContainerWithHelper>
                        <FormLabel>LOCATION EMAIL</FormLabel>
                        <Form
                            type="email"
                            name="email"
                            placeholder="Enter Location Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Helper message="You can choose this later"/>
                    </FormContainerWithHelper>
                    <FormContainerWithHelper>
                        <FormLabel>LOCATION POINT OF CONTACT</FormLabel>
                        <Form
                            type="text"
                            name="pointofcontact"
                            placeholder="Enter Location Point of Contact"
                            value={pointOfContact}
                            onChange={e => setPointOfContact(e.target.value)}
                        />
                        <Helper message="You can choose this later"/>
                    </FormContainerWithHelper>
                </DetailsContainer>
                {imagesUrlsByClient && <ImagePickModal imagesUrls={imagesUrlsByClient} show={showPickImageModal}
                                                       onHide={handleImagePickClose}
                                                       onImagePicked={handleImagePickComplete}/>}

                {confirmArchiving && (
                    <ModalDecisionBuilder
                        title={"Are you sure you want to archive this location?"}
                        handleClose={closeDeleteConfirmationDialog}
                        handleAccept={doArchiving}
                    />
                )}
            </CreationForms>
        </ThemeProvider>
    );
}

const mapStateToProps = state => ({
    clients: state.client.client
});

const connectedCreateLocation = withRouter(
    connect(mapStateToProps)(CreateLocation)
);
export {connectedCreateLocation as CreateLocation};

