import {css} from "styled-components"

const sizes = {
    uhd: 1980,
    widescreen: 1366,
    desktop: 1024,
    tablet: 768,
    mobile: 100
}

export default Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
    return acc
}, {})
