import React from "react";
import styled from "styled-components";
import {DefaultRowContainer} from './Container.style';
import {DefaultLabel} from './Label.style';

const HelperContainer = styled(DefaultRowContainer)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  width: 100%
`;

const HelperMessage = styled(DefaultLabel)`
  font-size: 12px;
  color: ${props => props.theme.formlabel};
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

export const Helper = ({ message }) => (
  <HelperContainer>
    <HelperMessage>{message}</HelperMessage>
  </HelperContainer>
);
