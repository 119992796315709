import {userConstants} from "_constants";
import {userService} from "_services";
import {alertActions} from "./alert.actions";

export const userActions = {
  login,
  login2fa,
  logout,
  register,
  archive,
  getUser,
  getUsers,
  addOne,
  updateOne,
  getDashboard,
  submit2FACode
};

async function login(dispatch, user) {
  dispatch({ type: userConstants.LOGIN_REQUEST });
  try {
    const userResponse = await userService.login(user);
    if (userResponse === 1){
      dispatch({ type: userConstants.LOGIN_VALIDATION, validation: userResponse });
      return userResponse;
    }
    dispatch({ type: userConstants.LOGIN_SUCCESS, user: userResponse });
    return userResponse;
  } catch (e) {
    dispatch({ type: userConstants.LOGIN_FAILURE, error: e.toString() });
    throw e;
  }
}

async function login2fa(dispatch, user) {
  dispatch({ type: userConstants.LOGIN_2FA_REQUEST });
  try {
    const twoFAData = await userService.login2fa(user);
    if (twoFAData === 1){
      dispatch({ type: userConstants.LOGIN_VALIDATION, validation: twoFAData });
      return twoFAData;
    }
    dispatch({ type: userConstants.LOGIN_2FA_SUCCESS, twoFAData: twoFAData });
    return twoFAData;
  } catch (e) {
    dispatch({ type: userConstants.LOGIN_2FA_FAILURE, error: e.toString() });
    throw e;
  }
}

async function submit2FACode(dispatch, twoFAData) {
  dispatch({ type: userConstants.SEND_2FACODE_REQUEST });
  try {
    const userResponse = await userService.submit2FACode(twoFAData);
    if (userResponse === 1){
      dispatch({ type: userConstants.LOGIN_VALIDATION, validation: userResponse });
      return userResponse;
    }
    dispatch({ type: userConstants.SEND_2FACODE_SUCCESS, user: userResponse });
    return userResponse;
  } catch (e) {
    dispatch({ type: userConstants.SEND_2FACODE_FAILURE, error: e.toString(),twoFAData: twoFAData});
    throw e;
  }
}

async function archive(dispatch, id) {
  dispatch({ type: userConstants.ARCHIVE_REQUEST });
  try {
    const userResponse = await userService.archive(id);
    dispatch({type: userConstants.ARCHIVE_SUCCESS, id});
    return userResponse;
  } catch (e) {
    dispatch({type: userConstants.ARCHIVE_FAILURE, error: e.toString()});
    throw e;
  }
}

async function getDashboard(dispatch, userId) {
  dispatch({ type: userConstants.DASHBOARD_REQUEST });
  try {
    const dashboard = await userService.getDashboard(userId);
    dispatch({ type: userConstants.DASHBOARD_SUCCESS, dashboard });
    return dashboard;
  } catch (e) {
    dispatch({ type: userConstants.DASHBOARD_FAILURE, error: e.toString() });
    throw e;
  }
}

function register(user, changeLogin) {
  return dispatch => {
    dispatch(request(user));

    userService.register(user).then(
      user => {
        dispatch(success(user));
        changeLogin();
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getUser(username) {
  return dispatch => {
    dispatch(request());

    userService.getUser(username).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_ONE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_ONE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_ONE_FAILURE, error };
  }
}

async function getUsers(dispatch) {
  dispatch({ type: userConstants.GET_REQUEST });
  try {
    const users = await userService.getUsers();
    dispatch({ type: userConstants.GET_SUCCESS, users })
  } catch (e) {
    dispatch({ type: userConstants.GET_FAILURE, error: e.toString() })
    throw e;
  }
}

async function addOne(dispatch, user) {
  dispatch(request());
  try {
    const userResponse = await userService.addOne(user);
    dispatch(success(userResponse));
    return userResponse;
  } catch (e) {
    dispatch(failure(e.toString()));
    throw e;
  }

  function request() {
    return { type: userConstants.ADD_ONE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.ADD_ONE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.ADD_ONE_FAILURE, error };
  }
}

async function updateOne(dispatch, user, id) {
  dispatch(request());
  try {
    const userResponse = await userService.updateOne(user, id);
    dispatch(success(userResponse));
    return userResponse;
  } catch (e) {
    dispatch(failure(e.toString()));
    throw e;
  }

  function request() {
    return { type: userConstants.UPDATE_ONE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.UPDATE_ONE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_ONE_FAILURE, error };
  }
}

function logout(history) {
  userService.logout();
  return dispatch => {
    dispatch(request());
    history.push("/");
  };

  function request() {
    return { type: userConstants.LOGOUT };
  }
}
