import {Col, Container, Modal, Row} from "react-bootstrap";
import React, {useState} from "react";

import * as PropTypes from "prop-types";
import {PrimaryButton} from "./Standard";
import styled, {ThemeProvider} from 'styled-components'
import {DefaultButton, DefaultCancelButton} from "../_styles";


const OkButton = styled(DefaultButton)`
    margin-right: 10px;
`;


const CancelButton = styled(DefaultCancelButton)`
    margin-right: 10px;
`;


CancelButton.propTypes = {
    onClick: PropTypes.any,
    children: PropTypes.node
};

export function ImagePickModal(props) {
    const {onImagePicked, imagesUrls, onHide, show} = props;
    const [selectedIndex, setSelectedIndex] = useState();


    return <Modal backdropClassName={"backdrop-second-level"} show={show} onHide={onHide} className="modal-pick" size="lg" style={{zIndex: 100000}}>
        <Modal.Header>
            <div style={{color: "white"}}>Choose location logo</div>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    {imagesUrls.map((url, index) => {
                        return <Col xs={2} key={index} style={{paddingTop: 15}}>
                            <img src={url} alt="location image"
                                 onClick={() => setSelectedIndex(index)}
                                 style={{
                                     width: '100px',
                                     border: selectedIndex === index ? "3px solid #F7A83C" : null
                                 }}/>
                        </Col>
                    })}
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <CancelButton onClick={onHide}>Cancel</CancelButton>
            <OkButton
                onClick={() => {
                    if (selectedIndex !== undefined) {
                        onImagePicked(imagesUrls[selectedIndex]);
                    }
                }}>Save</OkButton>
        </Modal.Footer>
    </Modal>;
}