import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {zonesActions} from "_actions";
import {FilterSection, RowSpaced, RowSpacing, SectionHeader, SectionTitle} from "_components";
import {BaseUrl, getBearer} from '_services/api';
import {DefaultCancelButton, DefaultColumnContainer, DefaultLabel, Loading, StandardMoment} from "_styles";
import {SortTable} from '_components/Standard';
import moment from 'moment';
import get from 'lodash.get';

const Container = styled(DefaultColumnContainer)`
    background-color: ${prop => prop.bodyBackground};
`;

const TextSubtitle = styled(DefaultLabel)`
    margin-top: 5px;
    color: grey;
`;

const ContentContainer = styled(DefaultColumnContainer)`
    margin-top: 20px;
    padding: 0px 54px;
`;

const Button = styled(DefaultCancelButton)`
    height: 40px;
`

class PlayerHistory extends React.Component {
  state = {
    downloading: false
  }

  componentDidMount() {
    this.props.dispatch(zonesActions.getOne(this.props.zoneId));

    // Add an hour for potential clock skew
    const endTime = moment.utc().add(1, 'hour');
    const startTime = endTime.clone().subtract(30, 'days');

    this.props.dispatch(zonesActions.getPlayHistory(this.props.zoneId, startTime, endTime));
  }

  goBack = () => {
    this.props.history.push("/zones");
  }

  download = () => {
    if (this.state.downloading) return;

    this.setState({downloading: true});
    fetch(`${BaseUrl}zone/${this.props.zoneId}/playhistoryexcel`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': getBearer()
      })
    }).then(async (response) => {
      this.setState({downloading: false});

      if (!response.ok) {
        alert("Failed to download");
        return;
      }

      const blob = await response.blob();
      var windowUrl = window.URL || window.webkitURL;
      var url = windowUrl.createObjectURL(blob);
      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'history.xls';
      anchor.click();
      anchor.remove();
      windowUrl.revokeObjectURL(url);
    });
  }

  render() {
    const {zones = [], details = {}} = this.props;

    const offset = get(details, 'player.timeZoneOffset', '0:0');

    return (
        <Container>
          <SectionHeader>
            <RowSpaced>
              <SectionTitle>Play History</SectionTitle>
              <RowSpacing>
                <Button onClick={this.download}>
                  {this.state.downloading ? <Loading/> : "Download"}
                </Button>
                <Button onClick={this.goBack}>Close</Button>
              </RowSpacing>
            </RowSpaced>

            <FilterSection style={{flexDirection: 'column', alignItems: 'start'}}>
              {zones.length > 0 && (<TextSubtitle>{zones[0].zone.name}</TextSubtitle>)}
              <TextSubtitle>Player Timezone Offset: {offset}</TextSubtitle>
            </FilterSection>
          </SectionHeader>

          <ContentContainer>
            <SortTable
                rows={zones}
                columns={[
                  {
                    percent: 1, field: 'timePlayed', title: 'time (local)', isNumber: true, rowRenderer: row => (
                        <StandardMoment>{row.timePlayed}</StandardMoment>
                    )
                  },
                  {percent: 1, field: 'timePlayedPlayer', title: `Time (player)`},
                  {percent: 1, field: 'playlist.name', title: 'playlist'},
                  {percent: 1, field: 'song.title', title: 'song'},
                  {percent: 1, field: 'song.artist.name', title: 'artist'}
                ]}
            />
          </ContentContainer>
        </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  zoneId: props.match.params.id ? parseInt(props.match.params.id) : null,
  zones: state.zones.zones,
  details: state.zones.zoneDetails
});

const connectedPlayerHistory = withRouter(
    connect(mapStateToProps)(PlayerHistory)
);
export {connectedPlayerHistory as PlayerHistory};

