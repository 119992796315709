import React from 'react'
import styled from 'styled-components'

const Header = styled.div`
  text-align: ${props => props.align};
  font-size: 14px;
  color: #686868;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &:hover {
    color: white;
  }
`

export const TableHeader = ({ children, align}) => (
  <Header align={align}>{children}</Header>
)


const Cell = styled.div`
text-align: ${props => props.align === undefined ? "left" : props.align};
cursor: pointer;
font-size: ${props => props.fontSize};
white-space: pre-wrap;
text-overflow: ellipsis;
color: ${props => props.color === undefined ? 'white' : props.color};
`

export const TableCell = ({ fontSize = 14, align, color, children, onClick }) => (
  <Cell onClick={onClick} fontSize={fontSize} align={align} color={color}>{children}</Cell>
);

/*export const CustomReactTable = ({ data, columns }) => {
  return (
    <ReactTable
      data={data}
      columns={columns.map((column, index) => (
        {
          Header: () => <TableHeader align={column.header.align}>{column.header.text.toUpperCase()}</TableHeader>,
          maxWidth: column.maxWidth,

        }
      ))}
    />
  )
}*/