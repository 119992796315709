import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { IconCheck, IconPlus } from "./BlitzTable";
import { getPercent, minutesSecondsString } from "../Utils";
import imagePlaceHolder from '../../_assets/images/talea-album-placeholder.png';

// Give fixed sized to prevent resizes while numbers update
const TimeLabel = styled.div`
    color: white;
    font-size: 12px;
    margin-left: 8px;
    width: 80px;
    text-align: right;
`;

const TrackName = styled.div`
    color: white;
`;

const ArtistName = styled.div`
    color: white;
    font-size: 12px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: right;
`;


const PlaylistName = styled.div`
    color: white;
    font-size: 12px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: right;
`;

const SongIndex = styled.div`
    color: white;
    font-size: 12px;
    margin-left: 8px;
    margin-right: 8px;
    width: 150px;
    text-align: center;
    width: 60px;
`;

const PlayPause = styled.div`
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Container = styled(Row)`
    flex: 1;
    padding-right: 25px;
`;

const RowSep = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const AlbumArt = styled.img`
    border: none;
    width: 84px;
    height: 84px;
    margin-right: 8px;
`;

const Slider = ({value, onChange, ...props}) => {
  const [val, setVal] = useState(0);

  const handleChange = e => {
    const v = e.target.value;
    setVal(v);
    onChange(v);
  }

  useEffect(() => setVal(value), [value]);

  return (
      <input {...props} type="range" min="0" max="100" step="0.01" value={val} onChange={handleChange} />
  )
}

const AudioPlayer = ({source = '', onTimeUpdate, onPlayStateChange, play, volume, ...props}) => {
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = createRef();

  const handleTimeUpdate = e => {
    const ltime = e.target.currentTime || 0;
    const lduration = e.target.duration || 0;

    setTime(ltime);
    setDuration(lduration);
    onTimeUpdate(ltime, lduration);
  }

  useEffect(() => {
    const ref = audioRef.current;
    ref.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      ref.removeEventListener('timeupdate', handleTimeUpdate);
    }
  });

  useEffect(() => {
    if (play) audioRef.current.play();
    else audioRef.current.pause();
  }, [play]);

  useEffect(() => {
    audioRef.current.volume = volume * .01;
  }, [volume]);

  const handleChange = val => {
    if (duration === 0) return;

    audioRef.current.play();
    audioRef.current.currentTime = val * .01 * duration;
  }

  const playingHandler = () => {
    onPlayStateChange(false);
  }

  const pausedHandler = () => {
    onPlayStateChange(true);
  }

  return (
      <>
        <audio ref={audioRef} src={source} controls={false} autoPlay onPlay={playingHandler}
            onPause={pausedHandler} />
        <Slider {...props} value={getPercent(time, duration)} onChange={handleChange} />
      </>
  )
}


const transparent = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export const TaleaAudioPlayer = ({
                                   source = '',
                                   title = '',
                                   artist = '',
                                   artworkUrl = '',
                                   loading,
                                   playlistIndex,
                                   playlistId,
                                   playlistName,
                                   playlistTotal,
                                   nextSong,
                                   prevSong,
                                   handleAddSong,
                                   handleRemoveSong,
                                   activePlaylistId,
                                   songAdded,
                                   songId
                                 }) => {
  const [timeString, setTimeString] = useState('0:00');
  const [durationString, setDurationString] = useState('0:00');
  const [paused, setPaused] = useState(true);
  const [volume, setVolume] = useState(100);
  const [nextButtonColor, setNextButtonColor] = useState('white');
  const [prevButtonColor, setPrevButtonColor] = useState('white');


  useEffect(() => {
    const keydownHandler = (event) => {
      if (event.code === "Space" && songId) {
        // check if an input is currently in focus
        if (document.activeElement.nodeName.toLowerCase() != "input"
            && document.activeElement.nodeName.toLowerCase() != "textarea"
            && document.activeElement.nodeName.toLowerCase() != "select"
            && document.activeElement.nodeName.toLowerCase() != "button") {
          event.preventDefault();
          event.stopPropagation();
          setPaused((currentPaused) => {
            if (!currentPaused) return true;
            return false;
          });
        }
      }
    };
    window.addEventListener("keydown", keydownHandler);
    return () => window.removeEventListener("keydown", keydownHandler);
  }, []);

  const timeUpdate = (time, duration) => {
    setTimeString(minutesSecondsString(time));
    setDurationString(minutesSecondsString(duration));
  }

  const playStateChange = paused => {
    setPaused(paused);
    if (timeString === durationString && timeString !== "0:00") {
      if (nextSong) {
        nextSong();
        setPaused(false);
      }
    }
  }

  const pausePlay = () => {
    setPaused(!paused);
  }

  const haveSong = source.length !== 0;

  const showPlaylistControls = playlistName != null;

  return (
      <Container>
        <AlbumArt
            src={!haveSong ? transparent : !artworkUrl || artworkUrl.length === 0 ? imagePlaceHolder : artworkUrl}
            onError={({currentTarget}) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = imagePlaceHolder;
            }} />
        <PlayPause onClick={pausePlay} playing={!paused}>
          {paused ? <FontAwesomeIcon color="white" icon={['fas', 'play']} /> :
              <FontAwesomeIcon color="white" icon={['fas', 'pause']} />}
        </PlayPause>
        <Column style={{flex: 1}}>
          <AudioPlayer volume={volume} play={!paused} source={source} onTimeUpdate={timeUpdate}
              onPlayStateChange={playStateChange} />
          <RowSep>
            <Row>
              {haveSong && (
                  <>
                    <Link to={`/song/${songId}`}>
                      <TrackName>{title}</TrackName></Link>
                    <Link to={`/song/${songId}`}>
                      <ArtistName>&nbsp; by {artist}</ArtistName></Link>
                    {activePlaylistId !== -1 && (songAdded ? (
                        <IconCheck color='orange' onClick={e => {
                          e.stopPropagation();
                          handleRemoveSong()
                        }} />
                    ) : (
                        <IconPlus color='white' onClick={e => {
                          e.stopPropagation();
                          handleAddSong()
                        }} />
                    ))}
                  </>
              )}
            </Row>
            <Row>
              {showPlaylistControls ?
                  <>
                    <FontAwesomeIcon color={prevButtonColor} icon={['fas', 'step-backward']}
                        onClick={(event) => {
                          setPrevButtonColor('orange');
                          let clickCount = event.detail;
                          if (clickCount > 1) {
                            if (document.selection && document.selection.empty) {
                              document.selection.empty();
                            } else if (window.getSelection) {
                              var sel = window.getSelection();
                              sel.removeAllRanges();
                            }
                          }
                          let skipCount = 1 + ((clickCount - 1) * 10);
                          prevSong(skipCount);
                          setTimeout(() => setPrevButtonColor('white'), 400);
                        }} />
                    <SongIndex> {playlistIndex + 1} / {playlistTotal}</SongIndex>
                    <FontAwesomeIcon color={nextButtonColor} style={{marginRight: '8px'}}
                        icon={['fas', 'step-forward']} onClick={(event) => {
                      setNextButtonColor('orange');
                      let clickCount = event.detail;
                      if (clickCount > 1) {
                        event.preventDefault();
                        if (document.selection && document.selection.empty) {
                          document.selection.empty();
                        } else if (window.getSelection) {
                          var sel = window.getSelection();
                          sel.removeAllRanges();
                        }
                      }
                      let skipCount = 1 + ((clickCount - 1) * 10);
                      nextSong(skipCount);
                      setTimeout(() => setNextButtonColor('white'), 400);
                    }} />
                    <PlaylistName><Link style={{color: "white"}}
                        to={"/playlist/" + playlistId}>{playlistName}</Link></PlaylistName>
                  </> : <></>}

              <FontAwesomeIcon color="white" style={{marginRight: '8px'}} icon={['fas', 'volume-up']} />
              <Slider value={volume} onChange={setVolume} className="volume_slider" />
              <TimeLabel>{timeString} / {durationString}</TimeLabel>
            </Row>
          </RowSep>
        </Column>
      </Container>
  )
}
