import React from 'react'
import {DropdownFilter} from './Standard/DropdownFilter';

export const PlaylistsDropdownFilter = ({playlistSelected, activePlaylistId, playlists}) => {
    const options = playlists.map(p => ({id: p.id, text: p.name}));

    const onSelect = (playlist) => {
        playlistSelected(playlist);
    }

    return <DropdownFilter selectedTextColor='orange' onSelect={onSelect} selectedId={activePlaylistId}
                           placeholder="Playlist" options={options}/>;
}

/*const mapStateToProps = state => ({
  options: state.playlist.playlist.sort((a, b) => a.name.localeCompare(b.name)).map(c => ({id: c.id, text: c.name})),
  activePlaylistId: state.ui.playlistId
});

const _PlaylistsDropdownFilter = connect(mapStateToProps)(PlaylistsDropdownFilter);
export { _PlaylistsDropdownFilter as PlaylistsDropdownFilter };*/
