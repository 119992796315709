import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import styled from "styled-components";
import {DefaultButton, DefaultGrayButton} from './Button.style';
import {DefaultColumnContainer, DefaultRowContainer} from './Container.style';
import {DefaultFormControl} from './Input.style';
import {DefaultLabel} from './Label.style';


const ContentContainer = styled(DefaultColumnContainer)``;

const RowContainer = styled(DefaultRowContainer)`
  width: 100%;
  align-items: center;
`;

const InnerContainer = styled(DefaultRowContainer)`
  width: 100%;
  align-items: center;
  min-height: 60px;
`;

const Rule = styled(DefaultLabel)`
  color: white;
  min-width: 220px;
  text-align: left;
`;

const ActiveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
`;

const Active = styled(DefaultButton)`
  background-color: ${props => props.theme.highlight};
  color: white;
  align-items: flex-end;
  width: 100px;
  height: 35px;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.lightYellow};
    color: white;
  }
`;

const Inactive = styled(DefaultGrayButton)`
  color: white;
  align-items: flex-end;
  width: 100px;
  height: 35px;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.darkGray};
    color: white;
  }
`;

const Row = styled(DefaultRowContainer)`
  align-items: center;
`;

const Form = styled(DefaultFormControl)`
  width: 60px;
  height: 40px;

  & + & {
    margin-left: 10px;
  }
`;

const Label = styled(DefaultLabel)`
  color: ${props => props.theme.lightGray};
`;

const RowLabel = styled(DefaultLabel)`
  margin-right: 5px;
  color: white;
`;

const SecondRowLabel = styled(RowLabel)`
  margin-left: 5px;
`;

const Container = styled(DefaultRowContainer)`
  width: 100%;
`;

const RSelect = styled(Select)`
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  width: 50%;

  & + & {
    margin-left: 10px;
  }
`;

const DatePickerButton = styled.button`
  border: 1px solid ${props => props.theme.optionalGray};
  border-radius: 5px;
  color: white;
  padding: 4px 4px 4px 10px;
  letter-spacing: 1px;
  height: 40px;
  background-color: ${props => props.theme.darkGray};
  outline: none;
  box-shadow: none;
  width: 100%;
  text-align: left;

  ::placeholder {
    color: white;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: ${props => props.theme.optionalGray};
    border: 0;
    outline: none;
    box-shadow: none;
    caret-color: white;
    color: white;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

class DatePickerInput extends React.Component {
  render() {
    return (
      <DatePickerButton onClick={this.props.onClick}>
        {this.props.value === "" ? this.props.label : this.props.value}
      </DatePickerButton>
    );
  }
}

let explicit = [
  { value: "Explicit", label: "Explicit" },
  { value: "Mild", label: "Mild" }
];

export const RulesContainer = ({
  rules,
  active,
  setBPM,
  setDropdown,
  setDate
}) => {
  const getOptions = rule => {
    switch (rule.type) {
      case "input":
        return (
          <Row>
            <Row>
              <RowLabel>Min</RowLabel>
              <Form
                type="number"
                placeholder="Min"
                value={rule.min}
                onChange={e => setBPM(rule.id, 'rule', "min", e)}
              />
            </Row>
            <Row>
              <SecondRowLabel>Max</SecondRowLabel>
              <Form
                type="number"
                value={rule.max}
                onChange={e => setBPM(rule.id, 'rule', "max", e)}
                placeholder="Max"
              />
            </Row>
          </Row>
        );
      case "dropdown":
        return (
          <RSelect options={explicit} value={rule.value} onChange={e => setDropdown(rule.id, e)} />
        );
      case "datepicker":
        return (
          <React.Fragment>
            <DatePickerWrapper>
              <DatePicker
                selected={rule.start}
                onChange={value => setDate(rule.id, 'rule', "start", value)}
                customInput={<DatePickerInput label="Start Date" />}
              />
            </DatePickerWrapper>
            <DatePickerWrapper>
              <DatePicker
                selected={rule.end}
                onChange={value => setDate(rule.id, 'rule', "end", value)}
                minDate={new Date(rule.start)}
                customInput={<DatePickerInput label="End Date" />}
              />
            </DatePickerWrapper>
          </React.Fragment>
        );
      default:
      case "blocked":
        return <Label>{rule.value}</Label>;
    }
  };

  return (
    <ContentContainer>
      {rules.map((rule, index) => (
        <RowContainer key={`rules-${index}-${rule.id}`}>
          <InnerContainer>
            <Rule>{rule.rule}</Rule>
            <Container>{getOptions(rule)}</Container>
            <ActiveContainer>
              {rule.type === "blocked" ? (
                <Active disabled>Active</Active>
              ) : rule.active ? (
                <Active onClick={() => active(rule.id)}>Active</Active>
              ) : (
                <Inactive onClick={() => active(rule.id)}>Inactive</Inactive>
              )}
            </ActiveContainer>
          </InnerContainer>
        </RowContainer>
      ))}
    </ContentContainer>
  );
};
