import React from "react";
import styled from "styled-components";
import Select, {components} from "react-select";
import {DefaultRowContainer} from "./Container.style";

const RSelect = styled(Select)`
  width: 15%;

  & + & {
    margin-left: 10px;
  }
`;

const IndicatorsContainer = props => {
  return (
    <div style={{ background: "transparent", borderRadius: "15px" }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

export const FilterContainer = styled(DefaultRowContainer)`
  padding: 10px 20px;
  margin-bottom: 30px;
`;

export const FilterDropdown = ({ value, options, onChange }) => (
    <RSelect
      components={{ IndicatorsContainer }}
      value={value}
      options={options}
      onChange={onChange}
      styles={{
        indicatorSeparator: base => ({ display: 'none' }),
        singleValue: base => ({ ...base, color: "white" }),
        valueContainer: base => ({
          ...base,
          background: "transparent",
          color: "white",
          width: "100%",
          justifyContent: "center",
        }),
        downChevron: base => ({ ...base, color: "white" }),
        control: base => ({
          ...base,
          alignItems: 'flex-start',
          textAlign: 'left',
          boxShadow: "unset",
          backgroundColor: "transparent",
          border: 'unset',
          borderRadius: 'unset',
          borderBottom: "1px solid lightgray", // default border color
          "&:hover": { borderColor: "#464645" } // border style on hover
          // You can also use state.isFocused to conditionally style based on the focus state
        }),
        option: base => ({
          ...base,
          backgroundColor: "lightgray",
          color: "black",
          borderRadius: "10px",
          textAlign: "center",
          "&:hover": { backgroundColor: "gray" }
        }),
        menu: base => ({
          ...base,
          backgroundColor: "lightgray",
        })
      }}
    />
);
