import {userConstants} from "../_constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loading: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loading: false,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return { loading: false };
    case userConstants.LOGIN_2FA_REQUEST:
      return {
        loading: true
      };
    case userConstants.LOGIN_2FA_SUCCESS:
      return {
        loading: false,
        twoFAData: action.twoFAData
      };
    case userConstants.LOGIN_2FA_FAILURE:
      return { loading: false };

    case userConstants.SEND_2FACODE_REQUEST:
      return {
        loading: true
      };
    case userConstants.SEND_2FACODE_SUCCESS:
      return {
        loading: false,
        user: action.user
      };
    case userConstants.SEND_2FACODE_FAILURE:
      return { loading: false,
              twoFAData: action.twoFAData};
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
