import {albumConstants} from "_constants";

const initialState = {
  loading: false,
  album: [],
  albumDetails: {}
};
export function album(state = initialState, action) {
  switch (action.type) {
    case albumConstants.GET_REQUEST:
      return {
        loading: true
      };
    case albumConstants.GET_SUCCESS:
      return {
        loading: false,
        album: action.album
      };
    case albumConstants.GET_FAILURE:
      return {
        loading: false
      };
    case albumConstants.GET_ONE_REQUEST:
      return {
        loading: true
      };
    case albumConstants.GET_ONE_SUCCESS:
      return {
        loading: false,
        albumDetails: action.album
      };
    case albumConstants.GET_ONE_FAILURE:
      return {
        loading: false
      };
    case albumConstants.ADD_ONE_REQUEST:
      return {
        loading: true
      };
    case albumConstants.ADD_ONE_SUCCESS:
      return {
        loading: false,
        album: action.album
      };
    case albumConstants.ADD_ONE_FAILURE:
      return {
        loading: false
      };
    default:
      return state;
  }
}
