import {sampleConstants} from "_constants";
import {sampleService} from "_services";
import {alertActions} from "./alert.actions";

export const sampleActions = {
    getSample,
    getPlaylist,
    playPlaylist,
    playNextSongInPlaylist,
    playPrevSongInPlaylist,
    voteSong,
    showSongDetails,
    hideSongDetails,
    switchPlayPause,
    updatePlaybackTime,
    postSampleFeedback,
    postPlaylistFeedback,
    skipPlaybackTo
};


function getSample(token) {
    return dispatch => {
        dispatch(request());

        sampleService.getSampleDetails(token).then(
            sample => {
                dispatch(success(sample));
                dispatch(alertActions.success())
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return {type: sampleConstants.GET_DETAIL_REQUEST};
    }

    function success(sample) {
        return {type: sampleConstants.GET_DETAIL_SUCCESS, sample};
    }

    function failure(error) {
        return {type: sampleConstants.GET_DETAIL_FAILURE, error};
    }
}

function getPlaylist(token, playlistId, loadedPlaylists) {
    return dispatch => {
        dispatch(request());
        const loadedPlaylist = loadedPlaylists.find(p => p.id === playlistId)
        if (loadedPlaylist) {
            dispatch(success(loadedPlaylist));
        }
        sampleService.getPlaylist(token, playlistId).then(
            playlist => {
                dispatch(success(playlist));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return {type: sampleConstants.GET_PLAYLIST_REQUEST};
    }

    function success(playlist) {
        return {type: sampleConstants.GET_PLAYLIST_SUCCESS, playlist};
    }

    function failure(error) {
        return {type: sampleConstants.GET_PLAYLIST_FAILURE, error};
    }
}
async function showSongDetails(dispatch) {
    dispatch({type: sampleConstants.SHOW_SONG_DETAILS})
}

async function hideSongDetails(dispatch) {
    dispatch({type: sampleConstants.HIDE_SONG_DETAILS});
}

async function playPlaylist(dispatch, token, playlistId, song, loadedPlaylists) {
    dispatch({type: sampleConstants.PLAY_PLAYLIST_REQUEST, playlistId});
    const loadedPlaylist = loadedPlaylists.find(p => p.id == playlistId)
    dispatch({type: sampleConstants.PLAY_PLAYLIST_SUCCESS, playlistId, playlistPlayback: loadedPlaylist, song});
    return loadedPlaylist;
}

async function playNextSongInPlaylist(dispatch, songId) {
    dispatch({type: sampleConstants.PLAY_NEXT_SONG_IN_PLAYLIST, songId});
}

async function playPrevSongInPlaylist(dispatch, songId) {
    dispatch({type: sampleConstants.PLAY_PREV_SONG_IN_PLAYLIST, songId});
}


async function voteSong(dispatch, token, playlistId, songToVoteId, downvote, feedback) {
    try {
        await sampleService.voteSong(token, playlistId, songToVoteId, downvote, feedback);
        dispatch({type: sampleConstants.SONG_VOTE_SUCCESS, vote: {downvote: downvote, song: {id: songToVoteId}}});
    } catch (e) {
        dispatch({type: sampleConstants.SONG_VOTE_FAILURE});
        throw e;
    }
}

async function postSampleFeedback(dispatch, token, feedback,name) {
    try {
        await sampleService.postSampleFeedback(token, feedback,name);
        dispatch({type: sampleConstants.POST_SAMPLE_FEEDBACK_SUCCESS});
    } catch (e) {
        dispatch({type: sampleConstants.POST_SAMPLE_FEEDBACK_FAILURE});
        throw e;
    }
}

async function postPlaylistFeedback(dispatch, token,playlistId, feedback) {
    try {
        await sampleService.postPlaylistFeedback(token, playlistId, feedback);
        dispatch({type: sampleConstants.POST_PLAYLIST_FEEDBACK_SUCCESS });
    } catch (e) {
        dispatch({type: sampleConstants.POST_PLAYLIST_FEEDBACK_FAILURE});
        throw e;
    }
}

async function switchPlayPause(dispatch,paused){
    dispatch({type: (paused?sampleConstants.RESUME_SONG_PLAYING:sampleConstants.PAUSE_SONG_PLAYING)});
}

async function updatePlaybackTime(dispatch,time,duration){
    dispatch({type: sampleConstants.UPDATE_PLAYBACK_TIME,time:time,duration:duration});
}

async function skipPlaybackTo(dispatch, time) {
    dispatch({type: sampleConstants.SKIP_PLAYBACK_TO,time:time});
}
