export const clientConstants = {
  SET_SELECTED: 'CLIENT_SET_SELECTED',

  GET_REQUEST: 'CLIENT_GET_REQUEST',
  GET_SUCCESS: 'CLIENT_GET_SUCCESS',
  GET_FAILURE: 'CLIENT_GET_FAILURE',

  GET_ONE_REQUEST: 'CLIENT_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'CLIENT_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'CLIENT_GET_ONE_FAILURE',

  UPDATE_CLIENT_REQUEST: 'CLIENT_UPDATE_CLIENT_REQUEST',
  UPDATE_CLIENT_SUCCESS: 'CLIENT_UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_FAILURE: 'CLIENT_UPDATE_CLIENT_FAILURE',

  CREATE_CLIENT_REQUEST: 'CLIENT_CREATE_CLIENT_REQUEST',
  CREATE_CLIENT_SUCCESS: 'CLIENT_CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_FAILURE: 'CLIENT_CREATE_CLIENT_FAILURE',

  GET_CLIENT_USERS_REQUEST: 'CLIENT_GET_USERS_REQUEST',
  GET_CLIENT_USERS_SUCCESS: 'CLIENT_GET_USERS_SUCCESS',
  GET_CLIENT_USERS_FAILURE: 'CLIENT_GET_USERS_FAILURE',

  ARCHIVE_REQUEST: 'CLIENT_ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'CLIENT_ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'CLIENT_ARCHIVE_FAILURE',
};