export const albumConstants = {
  GET_REQUEST: 'ALBUM_GET_REQUEST',
  GET_SUCCESS: 'ALBUM_GET_SUCCESS',
  GET_FAILURE: 'ALBUM_GET_FAILURE',

  GET_ONE_REQUEST: 'ALBUM_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'ALBUM_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'ALBUM_GET_ONE_FAILURE',

  ADD_ONE_REQUEST: 'ALBUM_ADD_ONE_REQUEST',
  ADD_ONE_SUCCESS: 'ALBUM_ADD_ONE_SUCCESS',
  ADD_ONE_FAILURE: 'ALBUM_ADD_ONE_FAILURE'
};