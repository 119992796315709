import {playerConstants} from "_constants";

// Selectors
export const getNowPlayingLoading = state => state.player.loadingNowPlaying;
export const getNowPlayingArtist = state => state.player.songArtist;
export const getNowPlayingSongId = state => state.player.songId;
export const getNowPlayingUrl = state => state.player.songUrl;
export const getPlayerSongId = state => state.player.songId;
export const getNowPlayingTitle = state => state.player.songTitle;
export const getNowPlayingArtworkUrl = state => state.player.songArtworkUrl;
export const getPlaylistPlayback = state => state.player.playlistPlayback;

// Reducer
const initialState = {
    loading: false,
    loadingNowPlaying: false,
    songId: -1,
    songUrl: "",
    songTitle: "",
    songArtist: '',
    songArtworkUrl: undefined,
    player: [],
    playerDetails: {},
    playlistPlayback: undefined
};

export function player(state = initialState, action) {
    switch (action.type) {
        case playerConstants.PLAY_REQUEST:
            return {
                loadingNowPlaying: true,
                ...state
            }

        case playerConstants.PLAY_SUCCESS:
            return {
                ...state,
                loadingNowPlaying: false,
                songId: action.songId,
                songUrl: action.songUrl,
                songTitle: action.songTitle,
                songArtworkUrl: action.songArtworkUrl,
                songArtist: action.artist,
                playlistPlayback: undefined
            }

        case playerConstants.NEXT_PLAYLIST_SONG_PLAYING:
            return {
                ...state,
                songId: action.songId
            }


        case playerConstants.PLAY_FAILURE:
            return {
                loadingNowPlaying: false,
                ...state
            }

        case playerConstants.PLAY_REQUEST:
            return {
                loadingNowPlaying: true,
                ...state
            }

        case playerConstants.PLAY_PLAYLIST_REQUEST:
            return {
                ...state,
                loadingNowPlaying: false,
            }
        case playerConstants.PLAY_PLAYLIST_SUCCESS:
            return {
                ...state,
                loadingNowPlaying: false,
                playlistPlayback: action.playlistPlayback
            }

        case playerConstants.PLAY_PLAYLIST_FAILURE:
            return {
                loadingNowPlaying: false,
                ...state
            }

        case playerConstants.GET_PLAYLIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case playerConstants.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                player: action.player
            };
        case playerConstants.GET_FAILURE:
            return {
                ...state,
                loading: false
            };
        case playerConstants.GET_ONE_REQUEST:
            return {
                ...state,
                loading: true,
                playerDetails: {}
            };
        case playerConstants.GET_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                playerDetails: action.player
            };
        case playerConstants.GET_ONE_FAILURE:
            return {
                ...state,
                loading: false,
                playerDetails: {}
            };
        case playerConstants.ADD_ONE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case playerConstants.ADD_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                player: action.player
            };
        case playerConstants.ADD_ONE_FAILURE:
            return {
                ...state,
                loading: false
            };
        case playerConstants.UPDATE_ONE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case playerConstants.UPDATE_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                player: (state.player || []).map(p => {
                    if (p.id !== action.id) return p;
                    return {
                        ...p,
                        name: action.name,
                        notes: action.notes
                    };
                })
            };
        case playerConstants.UPDATE_ONE_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
