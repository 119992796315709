import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import styled from "styled-components";
import {feedbackActions, playerActions, playlistActions, songsActions, uiActions} from "_actions";
import {
    SectionGutter,
    ClientDropdownFilter,
    DropdownFilter,
    FilterSection,
    RowSpaced,
    SearchFilter,
    SectionHeader,
    SectionTitle,
    SortTable, PlaylistsDropdownFilter, IconPlay
} from "_components";
import {Matches} from '_helpers';
import {StandardMoment, StatusBadge} from "_styles";
import {Comment, Info, ThumbDown, ThumbsUpDown, ThumbUp} from "@material-ui/icons";
import {EditSongVote} from "../_components/EditSongVote";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getActivePlaylistId} from "../_reducers/playlist.reducer";
import {Tooltip} from "@material-ui/core";
import Dropdown from "react-bootstrap/Dropdown";
import {songsService} from "../_services";

function uniqBy(a, key) {
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
}

export const Badge = styled(StatusBadge)`
  font-size: 12px;
  border-radius: 10px;
  color: black;
  width: 90px;
  background-color: ${props =>
          props.status === "NEW" ? '#EBAD18' :
                  props.status === "DONE" ? '#09D38A' :
                          props.status === "IGNORE" ? '#8F8C8C' :
                                  props.status === "REMOVED" ? '#fd4e4e' : ''
  }
`;

class Feedback extends React.Component {
    state = {
        showEditSongVote: false,
        search: "",
        songVote: undefined,
        statusFilter: "NEW",
        filterClientId: -1,
        filterPlaylistId: -1
    };

    componentDidMount() {
        feedbackActions.getAllVotes(this.props.dispatch);
        const query = new URLSearchParams(this.props.location.search);
        const selectedClientId = query.get("selectedClientId");
        if (selectedClientId) {
            this.setState({filterClientId: Number(selectedClientId)});
            uiActions.setClientId(this.props.dispatch, Number(selectedClientId));
        }
    }

    filterClient = (client) => {
        this.setState({filterClientId: client ? client.id : -1, filterPlaylistId: -1});
    }

    filterPlaylist = (playlist) => {
        this.setState({filterPlaylistId: playlist ? playlist.id : -1});
    }

    handleFilterStatus = selected => {
        this.setState({statusFilter: selected ? selected.id : undefined});
    }

    handleSearch = filterText => {
        this.setState({search: filterText});
    };

    handleEdit = songVote => {
        this.setState({showEditSongVote: true, songVote: songVote});
    };

    closeEditFeedback = () => {
        this.setState({showEditSongVote: false, songVote: undefined});
    }

    successEditFeedback = () => {
        this.closeEditFeedback();
        feedbackActions.getAllVotes(this.props.dispatch);
    };

    pinPlaylist = playlistId => {
        playlistActions.getPlaylist(this.props.dispatch, playlistId);
        this.props.dispatch(playlistActions.setActivePlaylistId(playlistId));
    };

    changeStatus = (songVoteId, status, songId, playlistId) => {
        feedbackActions.updateSongVote(this.props.dispatch, {status: status}, songVoteId);
        if (status === "REMOVED") {
            this.pinPlaylist(playlistId);
            setTimeout(() => {
                playlistActions.deleteSongs(this.props.dispatch, playlistId, [songId]);
            }, 3000);
        }
        const vote = this.props.votes.find(v => v.id === songVoteId);
        vote.status = status;
        this.setState({votes: [...this.props.votes]});
    }

    playSong = async (song) => {
        let songDetails = await songsService.getOne(song.id);
        playerActions.play(this.props.dispatch, songDetails.id, songDetails.title, songDetails.artist.name, songDetails.artworkUrl);
    }

    render() {
        const {showEditSongVote} = this.state;
        const {votes = [], loading} = this.props;

        let data = votes;
        data.forEach(v => {
            if (!v.status){
                v.status = "NEW";
            }
        });

        // Filter client type
        if (this.state.statusFilter) {
            data = data.filter(e => e.status === this.state.statusFilter || this.state.statusFilter === "ALL")
        }

        data = this.state.filterClientId === -1 ? data : data.filter(sv => sv.client && sv.client.id === this.state.filterClientId);
        data = this.state.filterPlaylistId === -1 ? data : data.filter(sv => sv.playlist && sv.playlist.id === this.state.filterPlaylistId);
        data = data.filter(sv => sv.song);
        const voteIcons = [<ThumbUp style={{color: "green", fontSize: 16}}></ThumbUp>,
            <ThumbDown style={{color: "red", fontSize: 16}}></ThumbDown>,
            <Comment style={{color: "green", fontSize: 16}}></Comment>,
            <Comment style={{color: "red", fontSize: 16}}></Comment>]

        const playlists = uniqBy(this.state.filterClientId === -1 ? [] : data.map(sv => sv.playlist).filter(p => p && p.client && p.client.id === this.state.filterClientId), (p) => p.id);

        const StatusToggle = (status, id, songId, playlistId) => {
            const statusText = status === null ? "NEW" : status;
            const backgroundColor = statusText === "NEW" ? "#EBAD18" : statusText === "IGNORE" ? "#8F8C8C" : statusText === "DONE" ? "#09D38A" : statusText === "REMOVED" ? "#fd4e4e" : ""
            return <Dropdown>
                <Dropdown.Toggle
                    style={{
                        width: 90,
                        fontSize: 12,
                        color: "black",
                        backgroundColor: backgroundColor,
                        borderColor: "transparent",
                        borderRadius: "1rem",
                        lineHeight: 1
                    }}>
                    {statusText}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{backgroundColor: "rgba(34,33,41,0.68)", position: "fixed"}}>
                    <Dropdown.Item><Badge status={"NEW"}
                                          onClick={() => this.changeStatus(id, "NEW")}>New</Badge></Dropdown.Item>
                    <Dropdown.Item><Badge
                        status={"DONE"}
                        onClick={() => this.changeStatus(id, "DONE")}>Done</Badge></Dropdown.Item>
                    <Dropdown.Item><Badge
                        status={"IGNORE"}
                        onClick={() => this.changeStatus(id, "IGNORE")}>Ignore</Badge></Dropdown.Item>
                    <Dropdown.Item><Badge
                        status={"REMOVED"}
                        onClick={() => this.changeStatus(id, "REMOVED", songId, playlistId)}>Removed</Badge></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>;
        }
        const columns = [
            {
                fixed: 30, rowRenderer: row => (
                    <div className="onRowHover">
                        <IconPlay onClick={e => {
                            e.stopPropagation();
                            this.playSong(row.song);
                        }}/>
                    </div>
                )
            },
            {
                percent: 0.7,
                field: 'downvote',
                isNumber: true,
                title: <ThumbsUpDown></ThumbsUpDown>,
                rowRenderer: row => {
                    let index = row.downvote ? row.comments ? 3 : 1 : row.comments ? 2 : 0;
                    let voteIcon = voteIcons[index]
                    if (row.comments) {
                        voteIcon = <Tooltip title={<span
                            style={{whiteSpace: 'pre-line'}}>{row.comments}</span>}>{voteIcon}</Tooltip>;
                    }
                    return voteIcon;
                }
            },
            {percent: 3, field: 'client.name', title: 'Client'},
            {percent: 3, field: 'zone.name', title: 'Zone'},
            {percent: 3, field: 'playlist.name', title: 'Playlist', rowRenderer: row => {
                    if (!row.playlist) {
                        return <></>;
                    }
                    const pinned = row.playlist.id === this.props.activePlaylistId;
                    return <div><FontAwesomeIcon icon="thumbtack" size="sm"
                                                 style={{
                                                     color: pinned ? "orange" : "gray",
                                                     cursor: "pointer"
                                                 }}
                                                 onClick={e => {
                                                     // stop row click handler
                                                     e.stopPropagation();
                                                     playlistActions.getPlaylist(this.props.dispatch, row.playlist.id);
                                                     this.props.dispatch(playlistActions.setActivePlaylistId(row.playlist.id));
                                                 }}
                    /> <Link style={{color: "white"}}
                             to={"/playlist/" + row.playlist.id}>{row.playlist.name}</Link></div>
                }
            },
            {percent: 3, field: 'song.title', title: 'Track'},
            {percent: 3, field: 'artist.name', title: 'Artist'},
            {percent: 3, collapse:500,field: 'voter.firstName', title: 'Voter'},
            {
                percent: 1, collapse:500,field: 'notes', title: 'Notes', rowRenderer: row => {
                    return row.notes ? <Tooltip title={<span
                        style={{whiteSpace: 'pre-line'}}>{row.notes}</span>}
                                                placement="top"><Info
                        style={{fontSize: 16, marginLeft: 10}}></Info></Tooltip> : null;
                }
            },
            {
                percent: 2, collapse:300,field: 'date', title: 'Date', isNumber: true, rowRenderer: row => (
                    <StandardMoment>{new Date(row.date)}</StandardMoment>
                )
            }
        ];
        let statusIndex = 8;
        if (this.props.activePlaylistId != -1) {
            statusIndex = 8;
        }
        columns.splice(statusIndex,0, {
            percent: 2, field: 'status', title: 'Status',
            collapse: 200,
            nocell: true,
            dim: false,
            rowRenderer: row => {
                return <div
                    onClick={e => e.stopPropagation()}>{StatusToggle(row.status, row.id, row.song.id, row.playlist ? row.playlist.id : null)}</div>;
            }
        });
        return (
            <>
                {showEditSongVote && (
                    <EditSongVote
                        dispatch={this.props.dispatch}
                        onBack={this.closeEditFeedback}
                        onEdit={this.successEditFeedback}
                        songVote={this.state.songVote}
                        onArchive={this.closeEditFeedBack}
                    />
                )}
                <>
                    <SectionHeader>
                        <RowSpaced>
                            <SectionTitle>Feedback</SectionTitle>
                        </RowSpaced>

                        <FilterSection>
                            <SearchFilter onChange={this.handleSearch} value={this.state.search}/>
                            <ClientDropdownFilter clientSelect={this.filterClient}/>
                            <PlaylistsDropdownFilter activePlaylistId={this.state.filterPlaylistId}
                                                     playlists={playlists}
                                                     playlistSelected={this.filterPlaylist}/>
                            <DropdownFilter onSelect={this.handleFilterStatus} placeholder="Status" options={[
                                {id: 'NEW', text: 'New'},
                                {id: 'DONE', text: 'Done'},
                                {id: 'IGNORE', text: 'Ignore'},
                                {id: 'REMOVED', text: 'Removed'},
                                {id: 'ALL', text: 'All'}
                            ]}/>
                        </FilterSection>
                    </SectionHeader>

                    <SortTable
                        defaultSortField={"date"}
                        defaultSortOrder={"desc"}
                        loading={loading}
                        rows={data}
                        gutter={SectionGutter - 30}
                        onRowClick={this.handleEdit}
                        columns={columns}
                    />
                </>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert,
    loading: state.client.loading,
    votes: state.feedback.votes,
    activePlaylistId: getActivePlaylistId(state)
});

const connectedFeedback = withRouter(connect(mapStateToProps)(Feedback));
export {connectedFeedback as Feedback};

