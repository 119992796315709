import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {DefaultColumnContainer, DefaultFormControl, DefaultLabel, DefaultRowContainer, Helper} from "_styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Container = styled(DefaultColumnContainer)`
  align-items: flex-start;
  width: 100%;
`;

const InnerContainer = styled(DefaultRowContainer)`
  flex-wrap: wrap;
`;

const Tag = styled(DefaultLabel)`
  color: black;
`;

const TagContainer = styled(DefaultRowContainer)`
  background-color: white;
  padding: 10px;
  margin-top: 1px;
  align-items: center;
  margin-right: 4px;
`;

const Icon = styled(FontAwesomeIcon)`
  color: black;
  margin-left: 5px;
  cursor: pointer;
`;

const FormLabel = styled(DefaultLabel)`
  color: ${props => props.theme.formlabel};
  font-size: 14px;
`;

const Form = styled(DefaultFormControl)`
  margin-top: 5px;
`;

export const Tags = ({ returnTags,currentTags }) => {
  const [tags, setTags] = useState(currentTags?currentTags:[]);
  const [inputTags, setInputTags] = useState("");
  const [shouldAdd, setShouldAdd] = useState(false);
  const tagRef = useRef();

  useEffect(() => {
    tagRef.current.addEventListener("keydown", keyPress, false);
    return () => {
      tagRef.current.removeEventListener("keydown", keyPress);
    };
  }, []);

  const addTag = () => {
    if (inputTags.length > 0 && tags.indexOf(inputTags) === -1) {
      returnArrayTags();
      setInputTags("");
      setShouldAdd(false);
    }
  };

  const returnArrayTags = () => {
    returnTags(tags.concat(inputTags));
    setTags(tags.concat(inputTags));
  }

  const removeTag = index => {
    if (tags.length === 1) {
      setTags([]);
      returnTags([]);
    } else {
      let tg = [...tags.slice(0, index), ...tags.slice(index + 1)];
      setTags(tg);
      returnTags(tg);
    }

  };

  const inputHandleChange = e => {
    setInputTags(e.target.value)
  }

  const keyPress = event => {
    if (event.keyCode === 13 || event.keyCode === 188) {
      event.preventDefault();
      setShouldAdd(true);
    }
  };

  if(shouldAdd){
    addTag();
  }

  return (
    <Container>
      <FormLabel>Tags</FormLabel>
      <Form
        ref={tagRef}
        type="text"
        name="tags"
        placeholder="Insert tag"
        value={inputTags}
        onChange={inputHandleChange}
      />
      <Helper message="Separate Tags by comma or enter" />
      <InnerContainer>
        {tags.map((el, index) => (
          <TagContainer key={`tag-${index}`}>
            <Tag>{el}</Tag>
            <Icon icon="times" onClick={() => removeTag(index)} />
          </TagContainer>
        ))}
      </InnerContainer>
    </Container>
  );
};
